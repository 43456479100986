import {
    Box,
    Flex,
    HStack,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Spinner,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { MdOutlineEdit } from 'react-icons/md';
import * as Yup from 'yup';
import FormikForm from '../forms/FormikForm';
import FormikInput from '../forms/FormikInput';
import { WvwButton } from './inputs';
import LoadingSpinner from './LoadingSpinner';
import WvwDeleteIconButton from './WvwDeleteIconButton';

type FileType = {
    name: string;
    fileUrl: string;
    loading?: boolean;
};

type PropTypes = {
    files: FileType[];
    loading?: boolean;
    onRename: (fileUrl: string, newName: string) => void;
    onRemove: (file: FileType) => void;
};

const FileUploadFileList = (props: PropTypes) => {
    const {
        files,
        loading = false,
        onRename,
        onRemove,
    } = props;

    const { t } = useTranslation('common');

    const [editing, setEditing] = useState<FileType | null>(null);

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <VStack
            align="start"
            marginBlock="1rem"
            w="100%"
        >
            {files.map(file => (
                <Flex
                    key={file.fileUrl}
                    align="center"
                    border="1px solid #ebebeb"
                    borderRadius="8"
                    paddingInline="4"
                    w="100%"
                >
                    <Box
                        marginInlineEnd="1rem"
                    >
                        {file.loading ? <Spinner /> : <HiOutlineDocumentText />}
                    </Box>

                    <Text variant="secondary">
                        {file.name}
                    </Text>

                    <Spacer />

                    {!file.loading && (
                        <HStack spacing="2">
                            <IconButton
                                aria-label="Edt label"
                                icon={<MdOutlineEdit />}
                                variant="ghost"
                                onClick={() => setEditing(file)}
                            />

                            <WvwDeleteIconButton
                                onClick={() => onRemove(file)}
                                color="black"
                            />
                        </HStack>
                    )}
                </Flex>
            ))}

            <Modal
                isOpen={!!editing}
                onClose={() => setEditing(null)}
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>
                        {t('editFile')}
                    </ModalHeader>

                    <ModalCloseButton />

                    <FormikForm
                        initialValues={{
                            name: editing?.name,
                        }}
                        onSubmit={values => {
                            let name = values.name.trim();

                            if (name !== editing?.name) {
                                const extention = editing?.name?.split('.')?.pop()?.toLowerCase() || '';

                                if (name.split('.').pop().toLowerCase() !== extention) {
                                    name += `.${extention}`;
                                }
                            }

                            onRename?.(editing?.fileUrl || '', name);

                            setEditing(null);
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required('File name is required'),
                        })}
                    >
                        <ModalBody>
                            <VStack
                                w="100%"
                            >
                                <Text
                                    color="wvwGreen"
                                    w="100%"
                                >
                                    {t('fileName')}
                                </Text>

                                <FormikInput
                                    name="name"
                                />
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <HStack
                                alignItems="flex-end"
                                spacing="2"
                            >
                                <WvwButton
                                    content={t('button.cancel')}
                                    onClick={() => setEditing(null)}
                                    variant="danger"
                                />

                                <WvwButton
                                    content={t('button.save')}
                                    formSubmitButton
                                    onClick={() => { }}
                                    variant="primary"
                                />
                            </HStack>
                        </ModalFooter>
                    </FormikForm>
                </ModalContent>
            </Modal>
        </VStack>
    );
};

export default FileUploadFileList;
