import { Box, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMyEntity } from "../../../../../utils/api/hooks";
import { standardPropertyTypes } from "../../../../../common/vars/valuationsAndMembershipTypes";
import {
    FormikCloudSelect,
    FormikConditionalSection,
} from "../../../../../common/forms";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import storage from "../../../../../utils/storage/storage";
import UploadCertificate from "../../../../../common/components/UploadCertificate";

const EditCompanySampleValuations = () => {
    const { t } = useTranslation("account");

    const initialLoading: { [index: string]: boolean } = [] as any;

    standardPropertyTypes.forEach((valuationType) => {
        initialLoading[valuationType] = false;
    });

    const [loadingUploads, setLoadingUploads] = useState(initialLoading);

    const {
        data: entity,
        isLoading: entityIsLoading,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
        entitySampleValuationsRefresh,
    } = useMyEntity();

    if (entityIsLoading || entitySampleValuationsIsLoading) {
        return <LoadingSpinner />;
    }

    return (
        <VStack
            spacing="4"
            w="100%"
            align="left"
        >
            <Box>
                <Text fontWeight="bold">{t("whichProps")}</Text>

                <Text color="wvwGrey40">{t("selectProps")}</Text>
            </Box>

            <FormikCloudSelect
                name="valuationTypes"
                options={standardPropertyTypes.map((propertyType) => ({
                    label: t(propertyType, {
                        ns: "formik",
                        defaultValue: propertyType,
                    }),
                    value: propertyType.toUpperCase(),
                }))}
            />

            {standardPropertyTypes.map((valuationType) => {
                const files = entitySampleValuations
                    .filter(
                        (i) =>
                            i.type?.toLowerCase() ===
                            valuationType.toLowerCase()
                    )
                    .map((i) => ({
                        name: t(i.label, {
                            ns: "formik",
                            defaultValue: i.label,
                        }),
                        fileUrl: i.file.fullPath,
                    }));

                return (
                    <FormikConditionalSection
                        key={valuationType}
                        formDataTarget="valuationTypes"
                        condition={(value) =>
                            value?.includes(valuationType.toUpperCase())
                        }
                    >
                        <VStack
                            align="start"
                            backgroundColor="wvwGrey05"
                            borderRadius="8"
                            padding="1rem"
                            spacing="4"
                            w="100%"
                        >
                            <Text color="wvwGreen">
                                {`${t(valuationType, {
                                    ns: "formik",
                                    defaultValue: valuationType,
                                })} ${t("sampleValuations", { ns: "wizard" })}`}
                            </Text>

                            <UploadCertificate
                                defaultFileName={
                                    files.length === 0
                                        ? `${t(valuationType, {
                                              ns: "formik",
                                              defaultValue: valuationType,
                                          })} ${t("sample", { ns: "common" })}`
                                        : undefined
                                }
                                files={files}
                                loading={loadingUploads[valuationType]}
                                onFileDelete={(fileUrl) => {
                                    storage
                                        .deleteUserFile(fileUrl)
                                        .then(entitySampleValuationsRefresh);
                                }}
                                onRename={(fileUrl, label) => {
                                    storage
                                        .updateFileMetadata(fileUrl, label)
                                        .then(entitySampleValuationsRefresh);
                                }}
                                onUpload={(fileList) => {
                                    setLoadingUploads({
                                        ...loadingUploads,
                                        [valuationType]: true,
                                    });

                                    fileList.forEach((file) => {
                                        storage
                                            .uploadEntitySampleValuations(
                                                entity?.id,
                                                file,
                                                valuationType
                                            )
                                            .then(entitySampleValuationsRefresh)
                                            .finally(() => {
                                                setLoadingUploads({
                                                    ...loadingUploads,
                                                    [valuationType]: false,
                                                });
                                            });
                                    });
                                }}
                            />
                        </VStack>
                    </FormikConditionalSection>
                );
            })}
        </VStack>
    );
};

export default EditCompanySampleValuations;
