import {
    Box,
    Flex,
    Heading,
    HStack,
    Spacer,
    Text,
    useDisclosure,
    VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import PublicCompanyProfilePicture from '../../../../../common/components/PublicCompanyProfilePicture';
import { WvwButton } from '../../../../../common/components/inputs';
import useToast from '../../../../../ui/v2/common/hooks/useToast';
import {
    useClientInvitesGetReceived,
    useClientInvitesRespond,
    useEntityPanelValuers,
} from '../../../../../utils/api/hooks';

const ClientRecievedClientInvites = () => {
    const { t } = useTranslation(['account', 'common']);

    const { createToast } = useToast();

    const [selectedInvite, setSelectedInvite] = useState<string | number>('');

    const {
        isOpen: isDeclineOpen,
        onOpen: onDeclineOpen,
        onClose: onDeclineClose,
    } = useDisclosure();

    const { refresh: refreshValuerPanels } = useEntityPanelValuers();
    const {
        data: receivedClientInvites,
        isLoading: receievedClientInvitesLoading,
        error: receivedClientInvitesError,
        refresh,
    } = useClientInvitesGetReceived();

    const { update } = useClientInvitesRespond({
        onSuccess: () => {
            refresh();
            refreshValuerPanels();
        },
        onError: error => {
            createToast({
                description: error.message,
                status: 'error',
            });
        },
    });

    return (
        <>
            <VStack
                w="70%"
                paddingTop="1.5rem"
            >
                {receievedClientInvitesLoading && (
                    <LoadingSpinner />
                )}

                {receivedClientInvitesError !== null && (
                    <VStack>
                        <Heading
                            size="sm"
                        >
                            {t('common:error.weAreSorry')}
                        </Heading>

                        <Text>
                            {t('riListError')}
                        </Text>
                    </VStack>
                )}

                {receivedClientInvites?.length === 0 && (
                    <Text>
                        {t('invitesByValuers.noReceivedInvitesMsg')}
                    </Text>
                )}

                {receivedClientInvites?.map(invite => (
                    <Flex
                        w="100%"
                        height="3.5rem"
                        border="1px solid"
                        borderColor="wvwGrey40"
                        bg="wvwGrey05"
                        borderRadius="8"
                        paddingInline="1rem"
                        key={invite.id}
                    >
                        <HStack>
                            <PublicCompanyProfilePicture
                                companyId={invite.fromEntityId as number}
                                size="2.5rem"
                            />

                            <Box paddingBlock="1rem">
                                <Text
                                    color="wvwGrey80"
                                    fontWeight="medium"
                                >
                                    {invite.fromEntityName}
                                </Text>
                            </Box>
                        </HStack>

                        <Spacer />

                        <HStack spacing="4">
                            <WvwButton
                                variant="danger"
                                onClick={() => {
                                    setSelectedInvite(invite.id);
                                    onDeclineOpen();
                                }}
                                content={t('button.decline', { ns: 'common' })}
                            />

                            <WvwButton
                                variant="success"
                                onClick={() => update({ inviteId: invite.id, response: 'ACCEPTED' })}
                                content={t('button.accept', { ns: 'common' })}
                            />
                        </HStack>
                    </Flex>
                ))}
            </VStack>

            <ConfirmationModal
                cancelButton={t('button.close', { ns: 'common' })}
                continueButton={t('button.continue', { ns: 'common' })}
                isOpen={isDeclineOpen}
                title={t('warning.declineInvitation.title', { ns: 'common' })}
                content={t('warning.declineInvitation.prompt', { ns: 'common' })}
                onCancel={onDeclineClose}
                onContinue={() => {
                    onDeclineClose();
                    update({ inviteId: Number(selectedInvite), response: 'DECLINED' });
                }}
            />
        </>
    );
};

export default ClientRecievedClientInvites;
