import { useField, useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { Text } from "@chakra-ui/react";
import { useSmartTranslation } from "../hooks";
import MapsAutocomplete from "../components/inputs/MapsAutocomplete";
import { LabelAndInput } from "../components/display";
import { FormikValidationContext } from "./FormikForm";

type PropsType = {
    cityName: string;
    countryName: string;
    streetName: string;
    postalCodeName: string;
    label?: string;
    latName: string;
    lngName: string;
    placeholder: string;
};

const FormikMapsAutocomplete = (props: PropsType) => {
    const {
        cityName,
        countryName,
        streetName,
        postalCodeName,
        label,
        latName,
        lngName,
        placeholder,
    } = props;

    const t = useSmartTranslation("formik");

    const { setFieldValue, setFieldTouched, validateForm, errors } =
        useFormikContext();

    const [streetField, streetMeta] = useField(streetName);
    const [postalCodeField, postalCodeMeta] = useField(postalCodeName);
    const [cityField, cityMeta] = useField(cityName);
    const [countryField, countryMeta] = useField(countryName);
    const [latField, latMeta] = useField(latName);
    const [lngField, lngMeta] = useField(lngName);

    const isFieldRequired = useContext(FormikValidationContext);

    const isStreetRequired = isFieldRequired(streetName);
    const isPostalCodeRequired = isFieldRequired(postalCodeName);
    const isCityRequired = isFieldRequired(cityName);
    const isCountryRequired = isFieldRequired(countryName);
    const isLatRequired = isFieldRequired(latName);

    const isRequired =
        isStreetRequired ||
        isPostalCodeRequired ||
        isCityRequired ||
        isCountryRequired ||
        isLatRequired;

    const isTouched =
        streetMeta.touched ||
        postalCodeMeta.touched ||
        cityMeta.touched ||
        countryMeta.touched ||
        latMeta.touched ||
        lngMeta.touched;

    const errorsMeta = [
        streetMeta.error,
        postalCodeMeta.error,
        cityMeta.error,
        countryMeta.error,
        latMeta.error,
        lngMeta.error,
    ];

    const currentError = errorsMeta.find((i) => i);

    const [needsValidation, setNeedsValidation] = useState(false);

    useEffect(() => {
        if (!needsValidation) return;

        validateForm();

        setNeedsValidation(false);
    }, [needsValidation]);

    return (
        <LabelAndInput
            label={label && `${label}${isRequired ? " *" : ""}`}
            input={
                <>
                    <MapsAutocomplete
                        value={{
                            street: streetField.value,
                            postalCode: postalCodeField.value,
                            city: cityField.value,
                            country: countryField.value,
                            lat: latField.value,
                            lng: lngField.value,
                        }}
                        onChange={async (values) => {
                            await Promise.all([
                                setFieldValue(streetName, values.street),
                                setFieldValue(
                                    postalCodeName,
                                    values.postalCode
                                ),
                                setFieldValue(cityName, values.city),
                                setFieldValue(countryName, values.country),
                                setFieldValue(latName, values.lat),
                                setFieldValue(lngName, values.lng),
                                setFieldTouched(streetName, true),
                                setFieldTouched(postalCodeName, true),
                                setFieldTouched(cityName, true),
                                setFieldTouched(countryName, true),
                                setFieldTouched(latName, true),
                                setFieldTouched(lngName, true),
                            ]);

                            setNeedsValidation(true);
                        }}
                        placeholder={placeholder}
                    />

                    {isTouched && currentError && (
                        <Text
                            color="red"
                            fontSize={"sm"}
                        >
                            {t(currentError, { defaultValue: currentError })}
                        </Text>
                    )}
                </>
            }
        />
    );
};

export default FormikMapsAutocomplete;
