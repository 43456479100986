import { useState, useEffect } from "react";
import { ButtonGroup, Box } from "@chakra-ui/react";
import { useAdminView } from "../../../../common/features/admin-view/AdminViewContext";
import { BsBuilding } from "react-icons/bs";
import { MdOutlineAccountCircle } from "react-icons/md";
import ToggleButton from "../../../../common/components/MenuToggleButton";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";

type ToggleOption = "company" | "me";

const PerspectiveToggleSwitch = () => {
    const { toggleAdminView, inAdminView } = useAdminView();
    const [selected, setSelected] = useState<ToggleOption>(
        inAdminView ? "company" : "me"
    );

    const t = useSmartTranslation();

    useEffect(() => {
        setSelected(inAdminView ? "company" : "me");
    }, [inAdminView]);

    const handleToggle = (view: ToggleOption) => {
        if (selected !== view) {
            setSelected(view);
            toggleAdminView();
        }
    };

    return (
        <Box
            bg="blue.900"
            p="2px"
            borderRadius="lg"
        >
            <ButtonGroup isAttached>
                <ToggleButton
                    label={t("menubar.perspective.company")}
                    icon={<BsBuilding size="20px" />}
                    isSelected={selected === "company"}
                    onClick={() => handleToggle("company")}
                />
                <ToggleButton
                    label={t("menubar.perspective.me")}
                    icon={<MdOutlineAccountCircle size="20px" />}
                    isSelected={selected === "me"}
                    onClick={() => handleToggle("me")}
                />
            </ButtonGroup>
        </Box>
    );
};

export default PerspectiveToggleSwitch;
