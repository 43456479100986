import {
    Box,
    Button,
    Link as ChakraLink,
    Divider,
    Flex,
    Heading,
    HStack,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdOutlineVerticalAlignCenter } from 'react-icons/md';
import WvwDeleteIconButton from '../../../../common/components/WvwDeleteIconButton';
import { displayDateWithCurrentLocale } from '../../../../common/functions/displayDateInLocale';
import { FirebaseCertificateType } from '../../../../utils/storage/storage';

type PropsType = {
    allowEditing: boolean;
    title: string;
    files: FirebaseCertificateType[];
    onDelete: (file: FirebaseCertificateType) => void;
    onEdit: () => void;
};

export const CompanyDocsTable = (props: PropsType) => {
    const {
        allowEditing,
        title,
        files,
        onDelete,
        onEdit,
    } = props;

    const { t } = useTranslation(['profile', 'common']);

    return (
        <Box>
            <Flex
                h="3rem"
                w="100%"
            >
                <Heading
                    alignSelf="center"
                    size="sm"
                    paddingLeft="1rem"
                >
                    {title}
                </Heading>

                <Spacer />

                {allowEditing && (
                    <Button
                        variant="none"
                        color="wvwGreen60"
                        onClick={onEdit}
                    >
                        {files.length > 0
                            ? t("button.editSlashUpload", { ns: "common" })
                            : t("button.upload", { ns: "common" })}
                    </Button>
                )}
            </Flex>

            <HStack
                w="100%"
                paddingBlock=".8rem"
                bg="wvwGrey05"
                borderRadius="10"
            >
                <Box
                    w="40%"
                    paddingLeft="1rem"
                >
                    <HStack>
                        <MdOutlineVerticalAlignCenter
                            size=".9rem"
                            color="wvwGrey"
                        />
                        <Text
                            as="b"
                            color="wvwGrey80"
                            fontSize=".8rem"
                        >
                            {t("sharedDocuments.document.name")}
                        </Text>
                    </HStack>
                </Box>

                <Box w="30%">
                    <HStack>
                        <MdOutlineVerticalAlignCenter
                            size=".9rem"
                            color="wvwGrey"
                        />
                        <Text
                            as="b"
                            color="wvwGrey80"
                            fontSize=".8rem"
                        >
                            {t("sharedDocuments.document.uploadDate")}
                        </Text>
                    </HStack>
                </Box>

                <Box w="17%">
                    <HStack>
                        <MdOutlineVerticalAlignCenter
                            size=".9rem"
                            color="wvwGrey"
                        />
                        <Text
                            as="b"
                            color="wvwGrey80"
                            fontSize=".8rem"
                        >
                            {t("sharedDocuments.document.size")}
                        </Text>
                    </HStack>
                </Box>
            </HStack>

            {files.map((i) => (
                <VStack key={i.url}>
                    <HStack
                        w="100%"
                        paddingBlock=".4rem"
                    >
                        <Text
                            paddingLeft="2.4rem"
                            w="43%"
                        >
                            <ChakraLink
                                href={i.url}
                                isExternal
                                target="_blank"
                                color="wvwGreen"
                            >
                                {i.label}
                            </ChakraLink>
                        </Text>

                        <Text w="30%">
                            {displayDateWithCurrentLocale(i.meta.updated)}
                        </Text>

                        <Text>
                            {`${(i.meta.size / 1000000).toFixed(2)} MB`}
                        </Text>

                        <Spacer />

                        {allowEditing && (
                            <WvwDeleteIconButton
                                onClick={() => onDelete(i)}
                                color="black"
                            />
                        )}
                    </HStack>

                    <Divider />
                </VStack>
            ))}

            {files.length === 0 && (
                <Text
                    color="wvwGrey"
                    paddingLeft="2.4rem"
                    paddingTop=".4rem"
                >
                    {t("sharedDocuments.noDocs")}
                </Text>
            )}
        </Box>
    );
};
