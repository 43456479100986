import { StandardSystemStandardsOfferedWithOther } from "./standardSystemStandardsOffered";

const enquiryCommonValuerMemberships: (StandardSystemStandardsOfferedWithOther | 'More')[] = [
    'Öffentlich Bestellt',
    'DIA',
    'DIN 17024',
    'Sprengnetter',
    'HypZert',
    'RICS - Red Book',
    'BIIS',
    'BVS',
    'More',
];

export default enquiryCommonValuerMemberships;
