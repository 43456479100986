import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { Link as ReactLink } from "react-router-dom";
import { read, utils } from "xlsx";
import { EntityType } from "../../../../../../types";
import { UserInvitesCreateDto } from "../../../../../../utils/api/dto";
import { useUserInvitesCreate } from "../../../../../../utils/api/hooks";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../common/hooks/useToast";
import { FileUploadInput } from "../../../../common/inputs";

type UserType = Omit<UserInvitesCreateDto, "entityId">;

type PropsType = {
    entity: EntityType;
    onSendInvite: () => void;
};

const readFile = (
    file: File,
    setUsers: Dispatch<SetStateAction<UserType[]>>
) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
        // Parse data
        const bstr = e.target?.result;
        const wb = read(bstr, { type: rABS ? "binary" : "array" });

        // Get first worksheet
        const wsName = wb.SheetNames[0];
        const ws = wb.Sheets[wsName];

        // Convert array of arrays
        const xData: any = utils.sheet_to_json(ws, { header: 1 });

        const data = xData.reduce((acc: any, curr: any) => {
            const [firstName, lastName, email, admin = "NO"] = curr;

            if (firstName === "First_Name") return acc;
            if (!firstName || !lastName || !email) return acc;

            acc.push({
                firstName,
                lastName,
                email,
                role: admin.toUpperCase() === "YES" ? "ADMIN" : "USER",
            });

            return acc;
        }, []);

        setUsers(data);
    };

    if (rABS) reader.readAsBinaryString(file as Blob);
    else reader.readAsArrayBuffer(file as Blob);
};

const useTeamGroupInviteModal = (props: PropsType) => {
    const { entity, onSendInvite } = props;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { createToast } = useToast();

    const [users, setUsers] = useState<UserType[]>([]);

    const { update: sendInvite } = useUserInvitesCreate({
        onSuccess: () => {
            onClose();
            onSendInvite();
        },
        onError: (error) => {
            createToast({
                description: error.message,
                status: "error",
            });
        },
    });

    const addUsers = () => {
        users.forEach((user) => {
            sendInvite({
                entityId: entity?.id,
                ...user,
            });
        });

        closeModal();
    };

    const closeModal = () => {
        setUsers([]);
        onClose();
    };

    const groupInviteModal = (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    {t("account.teamInvites.uploadExcel")}
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody>
                    {users.length === 0 && (
                        <VStack
                            align="start"
                            spacing="3"
                            w="100%"
                        >
                            <Button
                                variant={"outline"}
                                colorScheme="blue"
                                size={"sm"}
                                as={ReactLink}
                                to={`${process.env.PUBLIC_URL}/excel/wvw-users-excel-upload-example.xlsx`}
                                target="_blank"
                                download="wvw-users-excel-upload-example.xlsx"
                            >
                                {t("account.teamInvites.downloadTemplate")}
                            </Button>

                            <FileUploadInput
                                noRename
                                fileTypes={[".xlsx", ".xls", ".csv"]}
                                onChange={(files) =>
                                    readFile(files[0], setUsers)
                                }
                            />
                        </VStack>
                    )}

                    {users.map((u) => (
                        <Flex key={u.email}>
                            <Text>
                                {`${u.firstName} ${u.lastName} - ${u.email}`}
                            </Text>

                            <Spacer />

                            <Text>{u.role === "ADMIN" ? "Admin" : "User"}</Text>
                        </Flex>
                    ))}
                </ModalBody>

                <ModalFooter gap={2}>
                    <Button
                        variant="default"
                        onClick={closeModal}
                    >
                        {t("common.button.cancel")}
                    </Button>

                    {users.length > 0 && (
                        <Button
                            variant="primary"
                            onClick={addUsers}
                        >
                            {t("common.button.add")}
                        </Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );

    return {
        groupInviteModal,
        openGroupInviteModal: onOpen,
        closeGroupInviteModal: onClose,
    };
};

export default useTeamGroupInviteModal;
