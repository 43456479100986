import { AddIcon } from "@chakra-ui/icons";
import {
    Box, Button, Flex, Heading, HStack,
    Text, VStack
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { FormikListSelect, FormikRadioGroup } from "../../../../common/forms";
import { useEntityPanelValuers } from "../../../../utils/api/hooks";

const Valuers = () => {
    const { t } = useTranslation(["enquiryform", "formik", "common"]);

    const navigate = useNavigate();

    const { valuerList, isLoading, error } = useEntityPanelValuers();

    const [isOpen, setIsOpen] = useState(false);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <>
            {isLoading && <LoadingSpinner />}

            {error !== null && (
                <VStack>
                    <Heading size="sm">{t("common:error.weAreSorry")}</Heading>

                    <Text>{t("common:error.valuerList")}</Text>
                </VStack>
            )}

            {valuerList?.length === 0 && (
                <VStack>
                    <Text>{t("newEnquiry.valuerSection.noValuers")}</Text>
                </VStack>
            )}

            <FormikListSelect
                width="100%"
                name="toEntityIds"
                options={valuerList?.map((valuer) => ({
                    label: valuer.name,
                    value: valuer.id,
                }))}
            />

            <Button
                variant="none"
                color="wvwGreen"
                fontWeight="500"
                alignSelf="flex-end"
                onClick={() => setIsOpen(true)}
            >
                <HStack>
                    <AddIcon width=".8rem" />

                    <Text>{t("button.addValuers", { ns: "common" })}</Text>
                </HStack>
            </Button>

            <Flex align="center">
                <Box
                    w="100%"
                    bg="wvwGrey05"
                    borderRadius="10"
                    padding="1rem"
                >
                    <Heading
                        size="md"
                        paddingBottom=".5rem"
                        color="wvwGreen"
                    >
                        {t(
                            "newEnquiry.valuerSection.allowedValuers.sectionTitle"
                        )}
                    </Heading>

                    <Text>
                        {t("newEnquiry.valuerSection.allowedValuers.blurb")}
                    </Text>

                    <Box
                        display="inline-block"
                        maxW="50rem"
                        width="100%"
                    >
                        <FormikRadioGroup
                            name="allowFullMatchmaking"
                            options={[
                                {
                                    label: t("yes", { ns: "common" }),
                                    value: "true",
                                },
                                {
                                    label: t("no", { ns: "common" }),
                                    value: "false",
                                },
                            ]}
                        />
                    </Box>
                </Box>
            </Flex>

            <ConfirmationModal
                cancelButton={t('button.continueEditing', { ns: "common" })}
                continueButton={t('button.exit', { ns: "common" })}
                isOpen={isOpen}
                title={t('warning.exit.title', { ns: "common" })}
                content={t('newEnquiry.exitModalText', { ns: "enquiryform" })}
                onCancel={() => setIsOpen(false)}
                onContinue={() => { navigate("/dashboard/account/valuer-panel") }}
            />
        </>
    );
};

export default Valuers;
