import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/css'
import {
    Button,
    Center,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Square,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    label: string;
    onChange: (color: string) => void;
    value: string;
};

const ColorPick = (props: PropTypes) => {
    const { t } = useTranslation(['wizard', 'common']);
    const {
        label,
        onChange,
        value,
    } = props;

    const [color, setColor] = useColor(value);

    const {
        isOpen,
        onOpen,
        onClose,
    } = useDisclosure();

    return (
        <Flex>
            <Text fontSize="1.1rem">
                {label}
            </Text>

            <Spacer />

            <HStack
                spacing="0"
                borderRadius="10"
                border="1px solid lightwvwGrey"
                h="2rem"
            >
                <Square
                    bgColor={value}
                    size="2rem"
                    borderLeftRadius="10"
                />

                <Button
                    variant="none"
                    onClick={onOpen}
                >
                    {t('selectColor')}
                </Button>

                <Modal
                    onClose={onClose}
                    isOpen={isOpen}
                    isCentered
                >
                    <ModalOverlay />

                    <ModalContent>
                        <ModalHeader>{t('chooseColor')}</ModalHeader>

                        <ModalCloseButton />

                        <ModalBody>
                            <Center>
                                <ColorPicker
                                    height={150}
                                    color={color}
                                    onChange={setColor}
                                    hideInput={['hsv']}
                                />
                            </Center>
                        </ModalBody>

                        <ModalFooter justifyContent="center">
                            <Button
                                variant="primary"
                                onClick={() => {
                                    onChange(color.hex);
                                    onClose();
                                }}
                            >
                                {t('choose')}
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </HStack>
        </Flex>
    );
};

export default ColorPick;
