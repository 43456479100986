const defaultProfilePicture = {
    user: {
        valuer: '/images/AvatarValuer.png',
        client: '/images/AvatarendCust.png',
    },
    entity: {
        valuer: '/images/AvatarValuer-Company.png',
        client: '/images/AvatarEndcust-Company.png',
    },
};

export default defaultProfilePicture;
