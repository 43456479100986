import {
    AuthError,
    EmailAuthProvider,
    User,
    reauthenticateWithCredential,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { auth } from "../../../firebase";
import useToast from "../../../../ui/v2/common/hooks/useToast";

type ParamsType = {
    onSuccess?: () => void;
    onError?: (error: AuthError) => void;
};

const useReauthenticate = (params: ParamsType) => {
    const { onSuccess, onError } = params;

    const { t } = useTranslation("common");
    const { createToast } = useToast();

    const [fbUser] = useAuthState(auth);

    const { mutate: update } = useMutation(
        (password: string) => {
            const credential = EmailAuthProvider.credential(
                fbUser?.email as string,
                password
            );

            return reauthenticateWithCredential(fbUser as User, credential);
        },
        {
            onSuccess,
            onError: (error: AuthError) => {
                switch (error.code) {
                    case "auth/email-already-in-use":
                        createToast({
                            status: "error",
                            description: t("error.authError.emailAlreadyInUse"),
                        });
                        break;
                    case "auth/invalid-email":
                        createToast({
                            status: "error",
                            description: t("error.authError.invalidEmail"),
                        });
                        break;
                    case "auth/requires-recent-login":
                        createToast({
                            status: "error",
                            description: t(
                                "error.authError.requiresRecentLogin"
                            ),
                        });
                        break;
                    default:
                        createToast({
                            description: error.message,
                            status: "error",
                        });
                }

                onError?.(error);
            },
        }
    );

    return {
        update,
    };
};

export default useReauthenticate;
