import { CheckIcon } from "@chakra-ui/icons";
import { HStack, Icon, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { MdClose } from "react-icons/md";
import toTitleCase from "../../../../../../common/functions/toTitleCase";
import {
    standardPropertyTypes,
    standardValuationOccasions,
} from "../../../../../../common/vars/valuationsAndMembershipTypes";
import { StandardPropertyType } from "../../../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";
import specialValuationTypes, {
    StandardSpecialValuationType,
} from "../../../../../../common/vars/valuationsAndMembershipTypes/standardSpecialValuationTypes";
import { StandardValuationOccasion } from "../../../../../../common/vars/valuationsAndMembershipTypes/standardValuationOccasions";
import standardValuationTypes, {
    StandardValuationType,
} from "../../../../../../common/vars/valuationsAndMembershipTypes/standardValuationTypes";
import { UserType } from "../../../../../../types";
import { useDevice } from "../../../../common/hooks/useDevice";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../../common/MISSING_TRANSLATION";
import { InformationRowItem } from "../InformationRowItem";

type ProfileValuationDetailsProps = {
    user: UserType;
};

const ProfileValuationDetails = (props: ProfileValuationDetailsProps) => {
    const {
        user: {
            valuationTypes = [],
            specialValuationTypes: userSpecialValuationTypes = [],
            otherValuationTypes = [],
            valuationOccasions = [],
            otherValuationOccasions = [],
            valuationPropertyTypes = [],
        },
    } = props;

    const t = useSmartTranslation();

    const scopes = (
        <InformationRowItem
            label={t("profile.valuationCompetence.valuationScope")}
            customContent={
                <ValuationList
                    valuations={valuationTypes}
                    basicValudations={[...standardValuationTypes]}
                    translationKey={"valuationType"}
                />
            }
        />
    );

    const specialScopes = (
        <InformationRowItem
            label={t("profile.valuationCompetence.specificValuationScopes")}
            customContent={
                <ValuationList
                    valuations={userSpecialValuationTypes}
                    basicValudations={[...specialValuationTypes]}
                    translationKey={"specialValuationType"}
                />
            }
        />
    );

    const otherScopes = otherValuationTypes.length > 0 && (
        <InformationRowItem
            label={t("profile.valuationCompetence.otherValuationScopes")}
            customContent={<ValuationList valuations={otherValuationTypes} />}
        />
    );

    const ocasions = (
        <InformationRowItem
            label={t("profile.valuationCompetence.valuationOccasions")}
            customContent={
                <ValuationList
                    valuations={valuationOccasions}
                    basicValudations={[...standardValuationOccasions]}
                    translationKey={"valuationOccasion"}
                />
            }
        />
    );

    const otherCcasions = otherValuationOccasions.length > 0 && (
        <InformationRowItem
            label={t("profile.valuationCompetence.otherValuationOccasions")}
            customContent={
                <ValuationList valuations={otherValuationOccasions} />
            }
        />
    );

    const propertyTypesToTitleCase = valuationPropertyTypes.map((i) =>
        toTitleCase(i)
    );
    const propertyTypes = (
        <InformationRowItem
            label={t("profile.valuationCompetence.propertyTypes")}
            customContent={
                <ValuationList
                    valuations={propertyTypesToTitleCase}
                    basicValudations={[...standardPropertyTypes]}
                    translationKey={"propertyType"}
                />
            }
        />
    );

    return (
        <VStack
            spacing={4}
            align={"stretch"}
        >
            {scopes}
            {specialScopes}
            {otherScopes}
            {ocasions}
            {otherCcasions}
            {propertyTypes}
        </VStack>
    );
};

type ValuationListProps = {
    valuations: Array<
        | StandardValuationType
        | StandardSpecialValuationType
        | StandardValuationOccasion
        | StandardPropertyType
        | string
    >;
    translationKey?: string;
    basicValudations?: Array<
        | StandardValuationType
        | StandardSpecialValuationType
        | StandardValuationOccasion
        | StandardPropertyType
    >;
};

const ValuationList = (props: ValuationListProps) => {
    const { valuations, basicValudations, translationKey } = props;

    const t = useSmartTranslation();

    const device = useDevice();

    const standards =
        basicValudations &&
        basicValudations?.sort()?.map((valuation) => (
            <ValuationStandard
                key={valuation}
                label={
                    translationKey
                        ? t(
                              `domain.standards.${translationKey}.${(
                                  valuation
                              )}`,
                              {
                                  defaultValue: MISSING_TRANSLATION,
                              }
                          )
                        : valuation
                }
                checked={valuations.includes(valuation)}
            />
        ));

    const otherStandards =
        !basicValudations &&
        valuations?.sort()?.map((valuation) => (
            <ValuationStandard
                key={valuation}
                label={valuation}
                checked
            />
        ));

    return (
        <>
            <SimpleGrid
                gap={1}
                w={"100%"}
                gridTemplateColumns={
                    device === "mobile"
                        ? "minmax(0, 50%) minmax(0, 50%)"
                        : "minmax(0, 33%) minmax(0, 33%) minmax(0, 33%)"
                }
            >
                {standards}
                {otherStandards}
            </SimpleGrid>
        </>
    );
};

type ValuationStandardProps = {
    label: string;
    checked: boolean;
};

const ValuationStandard = (props: ValuationStandardProps) => {
    const { label, checked } = props;

    const icon = checked ? (
        <Icon
            as={CheckIcon}
            color={"green.500"}
        />
    ) : (
        <Icon
            as={MdClose}
            color={"gray.400"}
        />
    );

    return (
        <HStack
            padding={".5rem .75rem"}
            bg={checked ? "green.50" : "gray.50"}
            borderRadius={"lg"}
        >
            {icon}
            <Text
                fontSize={"sm"}
                fontWeight={"bold"}
                color={checked ? "green.500" : "gray.900"}
            >
                {label}
            </Text>
        </HStack>
    );
};

export { ValuationStandard, ProfileValuationDetails };
