import { Icon, IconProps } from '@chakra-ui/icons';
import { OmitCommonProps } from '@chakra-ui/react';
import { JSX, SVGProps } from 'react';

type PropTypes = JSX.IntrinsicAttributes & OmitCommonProps<SVGProps<SVGSVGElement>, keyof IconProps> & IconProps & { as?: 'svg' | undefined; };

const WVWLogoWhite = (props: PropTypes) => (
    <Icon
        viewBox="0 0 80 40"
        h="auto"
        // eslint-disable-next-line react/jsx-props-no-spreading
        as={'svg'}
        {...props}
    >
        <svg width="66" height="40" viewBox="0 0 66 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2108_161)">
                <path d="M20.9243 1.3129C20.6568 0.708441 21.0817 0 21.7427 0H33.717C39.9863 0.451291 43.5687 6.76936 40.4341 12.1848L26.9999 35.652C24.313 40.1649 19.835 41.0675 15.8047 38.811C12.2222 36.5545 10.8788 31.5903 13.5657 27.5287L19.835 16.2465C22.3191 11.2395 23.2721 6.61832 20.9243 1.3129Z" fill="white" />
                <path d="M45.1196 1.3129C44.8522 0.708441 45.277 0 45.938 0H57.9123C64.1816 0.451291 67.764 6.76936 64.6294 12.1848L51.1952 35.652C48.5083 40.1649 44.0303 41.0675 40 38.811C36.4175 36.5545 35.0741 31.5903 37.761 27.5287L44.0303 16.2465C46.5144 11.2395 47.4674 6.61832 45.1196 1.3129Z" fill="white" />
                <path d="M17 8.24137C17 9.89067 16.5003 11.4268 15.6397 12.7153C14.5578 14.3351 11.3775 20.5296 9.64943 23.3642C9.13263 24.2119 7.86737 24.2119 7.35057 23.3642C5.62248 20.5296 2.44222 14.3351 1.36028 12.7153C0.499686 11.4268 0 9.89067 0 8.24137C0 3.68979 3.80558 0 8.5 0C13.1944 0 17 3.68979 17 8.24137Z" fill="url(#paint0_linear_2108_161)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_2108_161" x1="0" y1="12" x2="17" y2="12" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FF8F00" />
                    <stop offset="1" stopColor="#FFB300" />
                </linearGradient>
                <clipPath id="clip0_2108_161">
                    <rect width="66" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </Icon>
);

export default WVWLogoWhite;
