import {
    Box,
    Flex,
} from '@chakra-ui/react';
import { ElementType, ReactNode } from 'react';
import VerticalDivider from './VerticalDivider';
import WVWTitle from './typography/WVWTitle';

type PropTypes = {
    contentLeft: ReactNode;
    contentRight: ReactNode;
    dividerIcon?: ElementType;
    splitPercentage?: number;
    titleLeft?: string;
    titleRight?: string;
};

const SplitLayout = (props: PropTypes) => {
    const {
        contentLeft,
        contentRight,
        dividerIcon,
        splitPercentage = 50,
        titleLeft,
        titleRight,
    } = props;

    return (
        <Flex>
            <Box w={`${splitPercentage}%`}>
                { titleLeft && (
                    <WVWTitle
                        content={titleLeft}
                        level="2"
                    />
                ) }

                {contentLeft}
            </Box>

            <VerticalDivider icon={dividerIcon} />

            <Box w={`${100 - splitPercentage}%`}>
                { titleRight && (
                    <WVWTitle
                        content={titleRight}
                        level="2"
                    />
                ) }

                {contentRight}
            </Box>
        </Flex>
    );
};

export default SplitLayout;
