import { FormikErrors, FormikValues, useFormikContext } from 'formik';
import { ReactElement, useContext } from 'react';
import { FormikWarning, FormikWarningsContext } from './FormikForm';

type PropTypes = {
    condition: (values: FormikValues, meta: { errors: FormikErrors<any>, warnings: FormikWarning[]}) => boolean;
    children: ReactElement;
};

const FormikConditionalSection = (props: PropTypes) => {
    const { condition, children } = props;

    const { values, errors } = useFormikContext<any>();
    const warnings = useContext(FormikWarningsContext);

    if (!condition(values, { errors, warnings })) {
        return null;
    }

    return children;
};

export default FormikConditionalSection;
