import {
    Button,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
} from "@chakra-ui/react";
import { FormikValues } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { object } from "yup";
import PasswordConfirmModal from "../../../../../../../common/components/PasswordConfirmModal";
import { useRole } from "../../../../../../../common/hooks";
import {
    standardSystemStandardsOffered,
    standardValuationTypes,
} from "../../../../../../../common/vars/valuationsAndMembershipTypes";
import { StandardSystemStandardsOfferedWithOther } from "../../../../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";
import { useUpdateEmail } from "../../../../../../../utils/api/hooks";
import useMe from "../../../../../app/useMeV2";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import Logo from "../../../../../common/components/Logo";
import { Forms } from "../../../../../common/forms/Forms";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import ProfileContactDetailsEdit from "./ProfileContactDetailsEdit";
import {
    clientValidationFields,
    valuerValidationFields,
} from "./ProfileEditFormOutput";
import ProfileOfficeDetailsEdit from "./ProfileOfficeDetailsEdit";
import ProfileValuationStandardsEdit from "./ProfileValuationStandardsEdit";
import ProfileValuationTypesEdit from "./ProfileValuationTypesEdit";
import ProfileValuationOccasionsEdit from "./ProfileValuationOccasionsEdit";
import ProfilePropertyTypesEdit from "./ProfilePropertyTypesEdit";

const ProfileDetailsEdit = () => {
    const t = useSmartTranslation();

    const navigate = useNavigate();

    const { userIsClient, userIsValuer, userIsCompanyAdmin, roleLoading } =
        useRole();

    const {
        data: user,
        isLoading,
        updateMe,
    } = useMe({
        onUpdateSuccess: () => {
            onComplete();
        },
    });

    const { update: updateEmail } = useUpdateEmail({
        onSuccess: () => {
            updateUser(formDataForEmailUpdate);
            setFormDataForEmailUpdate(undefined);
        },
    });

    const [formDataForEmailUpdate, setFormDataForEmailUpdate] =
        useState<any>(undefined);

    if (!user || isLoading || roleLoading) {
        return <LoadingSpinner />;
    }

    const updateUser = (values: FormikValues) => {
        const {
            maxOperatingDistance: formMaxOperatingDistance,
            systemStandardsOffered: formSystemStandardsOffered,
            otherStandardsOffered: formOtherStandardsOffered,
            valuationTypes: formValuationTypes,
            valuationOccasions,
            otherValuationOccasions: formOtherValuationOccasions,
            ...rest
        } = values;

        const maxOperatingDistance = parseInt(formMaxOperatingDistance, 10);

        const systemStandardsOffered = formSystemStandardsOffered.filter(
            (i: any) => standardSystemStandardsOffered.includes(i)
        );

        const otherStandardsOffered = formSystemStandardsOffered.includes(
            "Other"
        )
            ? formOtherStandardsOffered
            : [];

        const valuationTypes = formValuationTypes.filter((i: any) =>
            standardValuationTypes.includes(i)
        );

        const otherValuationOccasions = valuationOccasions.includes("Other")
            ? formOtherValuationOccasions
            : [];

        updateMe({
            ...rest,
            maxOperatingDistance,
            systemStandardsOffered,
            otherStandardsOffered,
            valuationTypes,
            valuationOccasions,
            otherValuationOccasions,
        });
    };

    const onComplete = () => {
        return navigate("..");
    };

    const validationSchema = object(
        userIsClient ? clientValidationFields : valuerValidationFields
    );

    const initalFormData = {
        ...user,
        systemStandardsOffered: (user.systemStandardsOffered || []).filter(
            (i) => standardSystemStandardsOffered.includes(i)
        ) as StandardSystemStandardsOfferedWithOther[],
        otherStandardsOffered: user.otherStandardsOffered || [],
        valuationTypes: user.valuationTypes || [],
        specialValuationTypes: user.specialValuationTypes || [],
        otherValuationTypes: user.otherValuationTypes || [],
        valuationOccasions: user.valuationOccasions || [],
        otherValuationOccasions: user.otherValuationOccasions || [],
        valuationPropertyTypes: user.valuationPropertyTypes || [],
    };

    if (initalFormData.otherStandardsOffered.length > 0) {
        initalFormData.systemStandardsOffered.push("Other");
    }

    // render

    const header = (
        <HStack spacing="4">
            <Logo
                size="2.5rem"
                withColor
            />

            <Heading size={"sm"}>{t("profile.heading.edit")}</Heading>
        </HStack>
    );

    const valuationDataEdit = !userIsClient && user.doesValuations && (
        <>
            <ProfileValuationStandardsEdit />

            <ProfileValuationTypesEdit />

            <ProfileValuationOccasionsEdit />

            <ProfilePropertyTypesEdit />
        </>
    );

    const body = (
        <Forms.FormikForm
            validationSchema={validationSchema}
            initialValues={initalFormData}
            onSubmit={(values) => {
                if (values.email !== user?.email) {
                    setFormDataForEmailUpdate(values);
                } else {
                    updateUser(values);
                }
            }}
        >
            <VStack
                align={"stretch"}
                maxW={"1520px"}
                m={"0 auto"}
                spacing={4}
            >
                <ProfileContactDetailsEdit />

                <ProfileOfficeDetailsEdit
                    userIsValuer={userIsValuer}
                    userIsCompanyAdmin={userIsCompanyAdmin}
                    userDoesValuations={user.doesValuations}
                    streetName="street"
                    postalCodeName="postalCode"
                    cityName="city"
                    countryName="country"
                    latName="latitude"
                    lngName="longitude"
                    officeTelDialCodeName="officeTelDialCode"
                    officeTelName="officeTel"
                    officeFaxDialCodeName="officeFaxDialCode"
                    officeFaxName="officeFax"
                />

                {valuationDataEdit}

                <HStack
                    justify={"end"}
                    mt={4}
                >
                    <Button
                        variant={"primary"}
                        w={240}
                        type="submit"
                    >
                        {t("common.button.save")}
                    </Button>
                </HStack>
            </VStack>
        </Forms.FormikForm>
    );

    return (
        <Modal
            isOpen
            size="full"
            closeOnOverlayClick={false}
            scrollBehavior={"inside"}
            onClose={onComplete}
        >
            <ModalOverlay />

            <ModalContent bg={"gray.50"}>
                <ModalHeader
                    bg={"white"}
                    p={".75rem 1rem"}
                >
                    {header}
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody
                    py={4}
                    px={6}
                >
                    {body}
                </ModalBody>

                <PasswordConfirmModal
                    isOpen={!!formDataForEmailUpdate}
                    onCancel={() => setFormDataForEmailUpdate(undefined)}
                    onContinue={() => updateEmail(formDataForEmailUpdate.email)}
                />
            </ModalContent>
        </Modal>
    );
};

export default ProfileDetailsEdit;
