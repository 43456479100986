import {
    Box,
    Flex,
    Heading,
    HStack,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { StandardCompanyDocumentType } from "../../../../../../../common/vars/valuationsAndMembershipTypes/standardCompanyDocumentTypes";
import { EntityType } from "../../../../../../../types";
import { useMyEntity } from "../../../../../../../utils/api/hooks";
import { FirebaseCertificateType } from "../../../../../../../utils/storage/storage";
import {
    filesizeToString,
    getFileTypeBasedOnName,
} from "../../../../../common/components/display/File";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { Forms } from "../../../../../common/forms/Forms";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { FileTypeIcon } from "../../../../../common/icons/FileTypeIcon";
import { documentColumns } from "../../../../../domain/document/columns/DocumentColumns";
import { useDocumentViewerModal } from "../../../../../domain/document/useDocumentViewerModal";
import { Section } from "../../../../../layout";
import DocumentsUpload from "../../documents/DocumentsUpload";

const DOCUMENT_TYPE: StandardCompanyDocumentType = "pi";

const CompanyDetailsPiCoverEdit = () => {
    const t = useSmartTranslation();

    const {
        isLoading,
        entityCertificates = [],
        entityCertificatesIsLoading,
        entityCertificatesRefresh,
    } = useMyEntity();

    const { documentView, viewDocument } = useDocumentViewerModal();

    const { values } =
        useFormikContext<
            Pick<
                EntityType,
                "piCoverValueUnlimited" | "piCoverPercentageUnlimited"
            >
        >();

    if (isLoading || entityCertificatesIsLoading) {
        return <LoadingSpinner />;
    }

    const files = entityCertificates.filter(
        (i) => i.type.toLowerCase() === DOCUMENT_TYPE
    );

    // render

    const piCover = (
        <VStack
            align="stretch"
            w="100%"
        >
            <Forms.FormikCurrencyInput
                disabled={values?.piCoverValueUnlimited}
                name="piCoverValue"
                label={t("account.valuationCapabilities.piCover.maxOf")}
                suffix="€"
            />

            <Forms.FormikCheckbox
                name="piCoverValueUnlimited"
                label={t("account.valuationCapabilities.piCover.noLimit")}
            />
        </VStack>
    );

    const piCoverAbsolute = (
        <VStack
            align="stretch"
            w="100%"
        >
            <Forms.FormikCurrencyInput
                disabled={values?.piCoverPercentageUnlimited}
                name="piCoverPercentage"
                label={t("account.valuationCapabilities.piCover.orPercentage")}
                suffix="%"
            />

            <Forms.FormikCheckbox
                name="piCoverPercentageUnlimited"
                label={t("account.valuationCapabilities.piCover.noLimit")}
            />
        </VStack>
    );

    const piCoverValid = (
        <VStack
            align="stretch"
            w="100%"
        >
            <Forms.FormikInput
                noSubmitOnEnter
                name="piCoverValidityDate"
                label={t(
                    "account.valuationCapabilities.piCover.validUntilLabel"
                )}
                type="date"
            />
        </VStack>
    );

    const specialArrangements = (
        <Forms.FormikSwitch
            name="piCoverSpecialArrangementsAvailable"
            label={t(
                "account.valuationCapabilities.piCover.specialArrangementsAvailable"
            )}
            subtext={t(
                "account.valuationCapabilities.piCover.specialArrangementsAvailableDescription"
            )}
        />
    );

    const certificates = (
        <>
            <VStack
                align={"stetch"}
                mb={4}
            >
                <Heading
                    size={"md"}
                    variant="blue"
                >
                    {t("account.valuationCapabilities.piCover.certificate")}
                </Heading>
                <Text
                    variant="secondary"
                    fontSize={"sm"}
                >
                    {t(
                        "account.valuationCapabilities.piCover.certificateDescription"
                    )}
                </Text>
            </VStack>

            <HStack
                align={"stretch"}
                spacing={4}
            >
                <VStack
                    align={"stretch"}
                    flex={"1 0 0%"}
                >
                    <DocumentsUpload
                        documentType={DOCUMENT_TYPE}
                        uploadSuccess={entityCertificatesRefresh}
                    />
                </VStack>

                {files.length > 0 && (
                    <VStack
                        align={"stretch"}
                        flex={"1 0 0%"}
                    >
                        {files.map((file, idx) => (
                            <FileItem
                                key={idx}
                                file={file}
                                idx={idx}
                                viewDocument={() => {
                                    viewDocument({
                                        uri: file.url,
                                        fileType: getFileTypeBasedOnName(
                                            file.label
                                        ),
                                        label: file.label,
                                    });
                                }}
                            />
                        ))}
                    </VStack>
                )}
                {documentView}
            </HStack>
        </>
    );

    return (
        <Section
            title={t("account.heading.piCover")}
            subtitle={t("account.valuationCapabilities.piCover.description")}
            content={
                <VStack
                    align={"stretch"}
                    spacing={4}
                >
                    <HStack
                        align="start"
                        spacing="4"
                        flexWrap={{
                            base: "wrap",
                            lg: "nowrap",
                        }}
                    >
                        {piCover}
                        {piCoverAbsolute}
                        {piCoverValid}
                    </HStack>

                    {specialArrangements}
                    {certificates}
                </VStack>
            }
        />
    );
};

type FileItemProps = {
    file: FirebaseCertificateType;
    idx: number;
    viewDocument: () => void;
};

const FileItem = (props: FileItemProps) => {
    const { file, idx, viewDocument } = props;

    const t = useSmartTranslation();

    return (
        <Box
            padding=".5rem 1rem"
            bg={"gray.50"}
            transition={".25s"}
            color="gray.900"
            borderRadius={"lg"}
            _hover={{
                bg: "blue.50",
                color: "blue.500",
            }}
        >
            <Flex
                w={"100%"}
                gap={3}
                align={"center"}
            >
                <FileTypeIcon
                    filetype={getFileTypeBasedOnName(file.label)}
                    size={6}
                />
                <Text
                    fontSize={"sm"}
                    cursor={"pointer"}
                    onClick={viewDocument}
                >
                    {file.label}
                </Text>
                <Spacer />
                <Text
                    fontSize={"sm"}
                    variant={"secondary"}
                >
                    {filesizeToString(file.meta.size)}
                </Text>
                <HStack spacing={0}>
                    {documentColumns.edit.render!(file, t, idx)}
                    {documentColumns.remove.render!(file, t, idx)}
                </HStack>
            </Flex>
        </Box>
    );
};

export default CompanyDetailsPiCoverEdit;
