import { TranslationFunction } from "../../../../../common/hooks/useSmartTranslation";

export const valuationSamples = (t: TranslationFunction) => {
    return {
        title: t(
            "valuerDashboard.customizeExperience.uploadValuationSample.title"
        ),
        subtext: t(
            "valuerDashboard.customizeExperience.uploadValuationSample.subtext"
        ),
        link: {
            label: t("common.button.upload"),
            nav: "/dashboard/account/documents",
        },
    };
};

export const piCover = (t: TranslationFunction) => {
    return {
        title: t("valuerDashboard.customizeExperience.uploadPiCover.title"),
        subtext: t("valuerDashboard.customizeExperience.uploadPiCover.subtext"),
        link: {
            label: t("common.button.upload"),
            nav: "/dashboard/account",
        },
    };
};

export const marketingMaterial = (t: TranslationFunction) => {
    return {
        title: t("valuerDashboard.customizeExperience.uploadMarketing.title"),
        subtext: t(
            "valuerDashboard.customizeExperience.uploadMarketing.subtext"
        ),
        link: {
            label: t("common.button.upload"),
            nav: "/dashboard/account/documents",
        },
    };
};

export const teamMembers = (t: TranslationFunction) => {
    return {
        title: t("valuerDashboard.customizeExperience.addTeamMembers.title"),
        subtext: t(
            "valuerDashboard.customizeExperience.addTeamMembers.subtext"
        ),
        link: {
            label: t("common.button.add"),
            nav: "/dashboard/account/team",
        },
    };
};

export const matchmakingInfo = (
    t: TranslationFunction,
    userIsCompanyAdmin: boolean
) => {
    return {
        title: t(
            "valuerDashboard.customizeExperience.missingMatchmakingInfo.title"
        ),
        subtext: t(
            "valuerDashboard.customizeExperience.missingMatchmakingInfo.subtext"
        ),
        link: {
            label: t("common.button.complete"),
            nav: userIsCompanyAdmin
                ? "/complete-profile/company"
                : "/complete-profile/personal",
        },
        headingColorRed: true,
    };
};

export const officeAddress = (t: TranslationFunction) => {
    return {
        title: t(
            "valuerDashboard.customizeExperience.completeValuerOfficeAddress.title"
        ),
        subtext: t(
            "valuerDashboard.customizeExperience.completeValuerOfficeAddress.subtext"
        ),
        link: {
            label: t("common.button.complete"),
            nav: "/complete-profile/personal",
        },
        headingColorRed: true,
    };
};

export const completeProfile = (t: TranslationFunction) => {
    return {
        title: t("valuerDashboard.customizeExperience.completeProfile.title"),
        subtext: t(
            "valuerDashboard.customizeExperience.completeProfile.subtext"
        ),
        link: {
            label: t("common.button.complete"),
            nav: "/complete-profile",
        },
    };
};

export const valuerCustomizations = {
    valuationSamples,
    piCover,
    marketingMaterial,
    teamMembers,
    matchmakingInfo,
    officeAddress,
    completeProfile,
};
