import { Button, useDisclosure } from "@chakra-ui/react";
import { object, string } from "yup";
import { UserType } from "../../../../types";
import { useCompanySetAccess } from "../../../../utils/api/hooks";
import ModalDynamic from "../../common/components/ModalDynamic";
import { Forms } from "../../common/forms/Forms";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useToast from "../../common/hooks/useToast";

const validationSchema = object().shape({
    doesValuations: string().required(),
});

type PropTypes = {
    user: UserType;
    updateSuccess: () => void;
};

const useTeamMemberUpdateDoesValuations = (props: PropTypes) => {
    const {
        user: { id: userId, entityId, doesValuations },
        updateSuccess,
    } = props;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { createToast } = useToast();

    const { update: updateDoesValuations } = useCompanySetAccess({
        entityId: entityId,
        onSuccess: () => {
            updateSuccess();
            onClose();
        },
        onError: (error) => {
            createToast({
                description: error.message,
                status: "error",
            });
        },
    });

    const initialValues = {
        doesValuations: doesValuations ? "true" : "false",
    };

    // render

    const updateDoesValuationsAccessModal = (
        <ModalDynamic
            size="xl"
            isOpen={isOpen}
            header={t("profile.profileDetails.doesValuations")}
            onClose={onClose}
        >
            <Forms.FormikForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    console.log("Submitting:", {
                        entityId,
                        targetUserId: userId,
                        doesValuations: values.doesValuations === "true",
                    });
                    updateDoesValuations({
                        entityId: entityId,
                        targetUserId: userId,
                        doesValuations: values.doesValuations === "true",
                    });
                }}
            >
                <Forms.FormikRadioGroup
                    name="doesValuations"
                    options={[
                        {
                            label: t("common.yes"),
                            value: "true",
                        },
                        {
                            label: t("common.no"),
                            value: "false",
                        },
                    ]}
                />

                <Button
                    variant="primary"
                    w={"calc(50% - .5rem)"}
                    mt={4}
                    type="submit"
                >
                    {t("common.button.confirm")}
                </Button>
            </Forms.FormikForm>
        </ModalDynamic>
    );

    return {
        updateDoesValuationsAccessModal,
        openUpdateDoesValuationsAccessModal: onOpen,
        closeDoesValuationsAccessModa: onClose,
    };
};

export default useTeamMemberUpdateDoesValuations;
