import { array, number, string } from "yup";

export const clientValidationFields = {
    firstName: string().required("forms.errors.required"),
    lastName: string().required("forms.errors.required"),
    email: string().email("forms.errors.invalidEmail").required("forms.errors.required"),
    mobileDialCode: string().required("forms.errors.required"),
    mobile: number()
        .required("forms.errors.required")
        .typeError("forms.errors.enterNumber"),
    telDialCode: string().test("tel", "forms.errors.required", function (value) {
        if (value === undefined) {
            if (this.parent.tel === undefined) return true;

            return false;
        }

        return true;
    }),
    tel: number()
        .typeError("forms.errors.enterNumber")
        .test("tel", "forms.errors.required", function (value) {
            if (value === undefined) {
                if (this.parent.telDialCode === undefined) return true;

                return false;
            }

            return true;
        }),
    faxDialCode: string().test("fax", "forms.errors.required", function (value) {
        if (value === undefined) {
            if (this.parent.fax === undefined) return true;

            return false;
        }

        return true;
    }),
    fax: number()
        .typeError("forms.errors.enterNumber")
        .test("fax", "forms.errors.required", function (value) {
            if (value === undefined) {
                if (this.parent.faxDialCode === undefined) return true;

                return false;
            }

            return true;
        }),
    officeTelDialCode: string().test(
        "officeTel",
        "forms.errors.required",
        function (value) {
            if (value === undefined) {
                if (this.parent.officeTel === undefined) return true;

                return false;
            }

            return true;
        }
    ),
    officeTel: number()
        .typeError("forms.errors.enterNumber")
        .test("officeTel", "forms.errors.required", function (value) {
            if (value === undefined) {
                if (this.parent.officeTelDialCode === undefined) return true;

                return false;
            }

            return true;
        }),
    officeFaxDialCode: string().test(
        "officeFax",
        "forms.errors.required",
        function (value) {
            if (value === undefined) {
                if (this.parent.officeFax === undefined) return true;

                return false;
            }

            return true;
        }
    ),
    officeFax: number()
        .typeError("forms.errors.enterNumber")
        .test("officeFax", "forms.errors.required", function (value) {
            if (value === undefined) {
                if (this.parent.officeFaxDialCode === undefined) return true;

                return false;
            }

            return true;
        }),
    street: string().required("forms.errors.required"),
    city: string().required("forms.errors.required"),
    country: string().required("forms.errors.required"),
    postalCode: string().required("forms.errors.required"),
};

export const valuerValidationFields = {
    firstName: clientValidationFields.firstName,
    lastName: clientValidationFields.lastName,
    email: clientValidationFields.email,
    mobileDialCode: clientValidationFields.mobileDialCode,
    mobile: clientValidationFields.mobile,
    telDialCode: clientValidationFields.telDialCode,
    tel: clientValidationFields.tel,
    faxDialCode: clientValidationFields.faxDialCode,
    fax: clientValidationFields.fax,
    officeTelDialCode: clientValidationFields.officeTelDialCode,
    officeTel: clientValidationFields.officeTel,
    officeFaxDialCode: clientValidationFields.officeFaxDialCode,
    officeFax: clientValidationFields.officeFax,
    valuationTypes: array().min(1, "forms.errors.required"),
    latitude: number().required("forms.errors.required"),
    longitude: number().required("forms.errors.required"),
};