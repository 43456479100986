import { ChevronDownIcon } from "@chakra-ui/icons";
import {
    Avatar,
    AvatarBadge,
    Box,
    Link as ChakraLink,
    Collapse,
    Flex,
    Hide,
    HStack,
    Icon,
    IconButton,
    Show,
    VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsBuilding, BsMoon } from "react-icons/bs";
import { FiArchive, FiHome } from "react-icons/fi";
import {
    MdOutlineAccountCircle,
    MdOutlineHeadphones,
    MdOutlineLogout,
    MdOutlineNotifications,
    MdOutlineSave,
    MdPersonOutline,
} from "react-icons/md";
import { Link as ReactLink, useLocation } from "react-router-dom";
import { wvwPalette } from "../../../theme";
import { useMe, useNotificationGetUnread } from "../../../utils/api/hooks";
import useSupportModal from "../../../utils/api/hooks/useSupportModal";
import { useRole } from "../../hooks";
import ProfilePictureCompany from "../display/ProfilePictureCompany";
import DropdownMenu from "../DropdownMenu";
import { WvwIconButton, WvwLink } from "../inputs";
import LanguageToggleButton from "../LanguageToggleButton";
import Logo from "../Logo";
import NotificationPanel from "../NotificationPanel";
import WvwText from "../typography/WvwText";
import MobiMenuBarDrawer from "./MobiMenuBarDrawer";
import { Feature } from "../../../ui/v2/app/features/Feature";

const MenuBar = () => {
    const { userIsApproved, userIsSystemAdmin } = useRole();

    const [notificationsOpen, setNotificationsOpen] = useState(false);

    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

    const [burgerMenuItem, setBurgerMenuItem] = useState("");

    const { data: user, isLoading: userLoading } = useMe();

    const { notificationUnreadList } = useNotificationGetUnread(5);

    const { t } = useTranslation("menubar");

    const { pathname: path } = useLocation();

    const [active, setActive] = useState("Enquiries");

    const { modal: supportModal, openModal } = useSupportModal();

    const userAccessDeterminedMenuItemSettings = {
        isDisabled: !userIsApproved,
        tooltip: userIsApproved
            ? ""
            : t("platformAccessRequired", { ns: "common" }),
    };

    const userMenu = [
        {
            menuTitle: userLoading
                ? ""
                : `${user?.firstName} ${user?.lastName}`,
            rightIcon: <ChevronDownIcon />,
            menuList: [
                {
                    name: t("Profile"),
                    key: "profile",
                    nav: "/dashboard/account",
                    icon: <MdOutlineAccountCircle />,
                },
                {
                    name: t("Account"),
                    key: "account",
                    nav: "/dashboard/account",
                    icon: <BsBuilding />,
                },
                {
                    name: t("Logout"),
                    key: "logout",
                    nav: "/logout",
                    icon: <MdOutlineLogout />,
                },
            ],
        },
    ];

    const systemMenu = [
        {
            menuTitle: t("section.enquiries", { ns: "dashboard" }),
            rightIcon: <ChevronDownIcon />,
            menuList: [
                {
                    name: t("section.enquiries", { ns: "dashboard" }),
                    key: "enquiries",
                    nav: "/dashboard/enquiries",
                    icon: <MdOutlineSave />,
                    ...userAccessDeterminedMenuItemSettings,
                },
                {
                    name: t("section.dormantEnquiries", { ns: "dashboard" }),
                    key: "dormant-enquiries",
                    nav: "/dashboard/dormant-enquiries",
                    icon: <BsMoon />,
                    ...userAccessDeterminedMenuItemSettings,
                },
                {
                    name: t("section.draftEnquiries", { ns: "dashboard" }),
                    key: "drafts",
                    nav: "/dashboard/drafts",
                    icon: <FiArchive />,
                    ...userAccessDeterminedMenuItemSettings,
                },
            ],
        },
        {
            menuTitle: t("cases", { ns: "dashboard" }),
            rightIcon: <ChevronDownIcon />,
            menuList: [
                {
                    name: t("Active Cases", { ns: "activecases" }),
                    key: "active-cases",
                    nav: "/dashboard/active-cases",
                    icon: <BsBuilding />,
                    ...userAccessDeterminedMenuItemSettings,
                },
                {
                    name: t("section.dormantCases", { ns: "dashboard" }),
                    key: "dormant-cases",
                    nav: "/dashboard/dormant-cases",
                    icon: <BsBuilding />,
                    ...userAccessDeterminedMenuItemSettings,
                },
            ],
        },
        {
            menuTitle: t("section.system", { ns: "dashboard" }),
            rightIcon: <ChevronDownIcon />,
            menuList: [
                {
                    name: t("section.messages", { ns: "dashboard" }),
                    key: "notifications",
                    nav: "/dashboard/notifications",
                    icon: <MdOutlineNotifications />,
                },
                {
                    name: t("section.support", { ns: "dashboard" }),
                    key: "support",
                    onClick: () => openModal(),
                    icon: <MdOutlineHeadphones />,
                },
            ],
        },
    ];

    // const companyAdminMenu = {
    //     menuTitle: t('companyAdmin', { ns: 'common' }),
    //     rightIcon: <ChevronDownIcon />,
    //     menuList: [
    //         {
    //             name: t('Active Cases', { ns: 'activecases' }),
    //             key: 'cases',
    //             nav: '/company-admin/cases',
    //             icon: <BsBuilding />,
    //         },
    //         {
    //             name: t('section.enquiries', { ns: 'dashboard' }),
    //             key: 'enquiries',
    //             nav: '/company-admin/enquiries',
    //             icon: <SaveAdd />,
    //         },
    //     ],
    // };

    const adminMenu = {
        menuTitle: t("admin", { ns: "common" }),
        rightIcon: <ChevronDownIcon />,
        menuList: [
            {
                name: t("cases", { ns: "dashboard" }),
                key: "cases",
                nav: "/admin/cases",
                icon: <MdPersonOutline />,
            },
            {
                name: t("uploadedContracts", { ns: "dashboard" }),
                key: "uploadedContracts",
                nav: "/admin/uploaded-contracts",
                icon: <MdPersonOutline />,
            },
            {
                name: t("caseReports", { ns: "dashboard" }),
                key: "caseReports",
                nav: "/admin/case-reports",
                icon: <MdPersonOutline />,
            },
            {
                name: t("documents", { ns: "enquiryform" }),
                key: "documents",
                nav: "/admin/documents",
                icon: <MdPersonOutline />,
            },
            {
                name: t("enquiries", { ns: "enquiries" }),
                key: "enquiries",
                nav: "/admin/enquiries",
                icon: <MdPersonOutline />,
            },
            {
                name: t("entities", { ns: "menubar" }),
                key: "entities",
                nav: "/admin/entities",
                icon: <MdPersonOutline />,
            },
            {
                name: t("inspections", { ns: "menubar" }),
                key: "inspections",
                nav: "/admin/inspections",
                icon: <MdPersonOutline />,
            },
            {
                name: t("offers", { ns: "offer" }),
                key: "offers",
                nav: "/admin/offers",
                icon: <MdPersonOutline />,
            },
            {
                name: t("users", { ns: "menubar" }),
                key: "users",
                nav: "/admin/users",
                icon: <MdPersonOutline />,
            },
        ],
    };

    const currentPath = path.split("/")[2];

    useEffect(() => {
        if (userLoading) return;

        switch (path) {
            case "/dashboard":
                setActive(t("Dashboard"));
                break;
            case "/dashboard/dormant-enquiries":
            case "/dashboard/drafts":
            case "/dashboard/enquiries":
                setActive(t("Enquiries", { ns: "menubar" }));
                break;
            case "/dashboard/dormant-cases":
            case "/dashboard/active-cases":
                setActive(t("Cases", { ns: "menubar" }));
                break;
            case "/dashboard/notifications":
            case "/contact-us":
                setActive(t("System", { ns: "menubar" }));
                break;
            case "/dashboard/profile":
            case "/dashboard/account":
                setActive(`${user?.firstName} ${user?.lastName}`);
                break;
            case "/admin":
            case "/admin/cases":
            case "/admin/enquiries":
            case "/admin/entities":
            case "/admin/offers":
            case "/admin/users":
                setActive(t("admin", { ns: "common" }));
                break;
            case "/company-admin/cases":
            case "/company-admin/enquiries":
                setActive(t("companyAdmin", { ns: "common" }));
                break;
            default:
                break;
        }

        setBurgerMenuItem("");
        setBurgerMenuOpen(false);
    }, [path, userLoading]);

    if (Feature.isOff(Feature.FEATURES.CASE)) {
        const menuCaseSectionIndex = systemMenu.findIndex(
            (menu) => menu.menuTitle === t("cases", { ns: "dashboard" })
        );

        // Removes the cases section from the system menu
        if (menuCaseSectionIndex !== -1) {
            systemMenu.splice(menuCaseSectionIndex, 1);
        }

        // Removes the reference to Case from the admin menu
        adminMenu.menuList = adminMenu.menuList.filter(
            (menu) => menu.key !== "cases" && menu.key !== "caseReports"
        );
    }

    return (
        <Box
            bg={wvwPalette.wvwGradients.blue}
            width="100%"
        >
            <Flex
                w="100%"
                alignItems="center"
                paddingInline="1rem"
                justifyContent="space-between"
                marginLeft="auto"
                marginRight="auto"
            >
                <HStack>
                    <ChakraLink
                        paddingBlock=".8rem"
                        as={ReactLink}
                        to="/dashboard"
                    >
                        <Logo />
                    </ChakraLink>

                    <Show below="md">
                        <WvwIconButton
                            icon="burgerMenu"
                            color="white"
                            onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}
                            ariaLabel="Mobile menu"
                        />
                    </Show>

                    <Hide below="md">
                        <HStack>
                            <ChakraLink
                                as={ReactLink}
                                to="/dashboard"
                            >
                                <IconButton
                                    color="white"
                                    bgColor={
                                        path === "/dashboard"
                                            ? "var(--action-disabledBackground, #0000001F)"
                                            : "transparent"
                                    }
                                    borderRadius="8"
                                    paddingBlock=".4rem"
                                    h="min-content"
                                    _hover={{
                                        bgColor:
                                            "var(--action-disabledBackground, #0000001F)",
                                    }}
                                    aria-label="home"
                                    icon={<FiHome />}
                                />
                            </ChakraLink>

                            {systemMenu.map((item) => (
                                <DropdownMenu
                                    key={item.menuTitle}
                                    menu={item}
                                    active={active}
                                />
                            ))}

                            {userIsSystemAdmin && (
                                <DropdownMenu
                                    menu={adminMenu}
                                    active={active}
                                />
                            )}

                            {/* {userIsCompanyAdmin && (
                        <DropdownMenu
                            menu={companyAdminMenu}
                            active={active}
                        />
                    )} */}
                        </HStack>
                    </Hide>
                </HStack>

                <HStack spacing="1">
                    <LanguageToggleButton />

                    <Box
                        _hover={{
                            bg: "wvwGrey10",
                        }}
                        borderRadius="10"
                        paddingBlock=".3rem"
                    >
                        <Avatar
                            color="white"
                            bg="none"
                            icon={
                                <Icon
                                    as={MdOutlineNotifications}
                                    boxSize={"1.5rem"}
                                />
                            }
                            aria-label="notification"
                            as="button"
                            size="xs"
                            onClick={() => {
                                setNotificationsOpen(true);
                            }}
                        >
                            {Number(notificationUnreadList?.length) > 0 && (
                                <AvatarBadge
                                    boxSize=".65rem"
                                    marginBottom=".9rem"
                                    marginRight=".15rem"
                                    bg="wvwYellow"
                                    color="wvwYellow"
                                    borderColor="none"
                                />
                            )}
                        </Avatar>
                    </Box>

                    {userMenu.map((item) => (
                        <Box key={item.menuTitle}>
                            <DropdownMenu
                                menu={item}
                                active={active}
                            />
                        </Box>
                    ))}

                    <ChakraLink
                        as={ReactLink}
                        to="/dashboard/account"
                    >
                        <ProfilePictureCompany size="2rem" />
                    </ChakraLink>
                </HStack>
            </Flex>

            <MobiMenuBarDrawer
                isOpen={burgerMenuOpen}
                onClose={() => setBurgerMenuOpen(false)}
            >
                <>
                    {systemMenu.map((item) => (
                        <VStack
                            width="100%"
                            bg={
                                burgerMenuItem === item.menuTitle
                                    ? "var(--action-disabledBackground, #00000014)"
                                    : "transparent"
                            }
                            align="left"
                            key={item.menuTitle}
                        >
                            <Box
                                width="100%"
                                justifyContent="left"
                                padding="1em"
                                paddingLeft="2rem"
                                _hover={{
                                    bgColor:
                                        "var(--action-disabledBackground, #00000014)",
                                    curser: "pointer",
                                }}
                                onClick={() =>
                                    setBurgerMenuItem(item.menuTitle)
                                }
                            >
                                <WvwText
                                    color="white"
                                    bold
                                >
                                    {item.menuTitle}
                                </WvwText>
                            </Box>

                            <Collapse in={burgerMenuItem === item.menuTitle}>
                                <VStack
                                    width="100%"
                                    spacing="4"
                                    align="left"
                                    paddingBottom="1em"
                                    paddingLeft="3em"
                                >
                                    {item.menuList.map((listItem) => (
                                        <HStack
                                            color="white"
                                            key={listItem.key}
                                            onClick={() => {
                                                if (
                                                    currentPath === listItem.nav
                                                ) {
                                                    setBurgerMenuOpen(false);
                                                }
                                            }}
                                        >
                                            {listItem.icon}

                                            <WvwLink
                                                label={listItem.name}
                                                to={
                                                    listItem.nav
                                                        ? listItem.nav
                                                        : ""
                                                }
                                                invertedColor
                                            />
                                        </HStack>
                                    ))}
                                </VStack>
                            </Collapse>
                        </VStack>
                    ))}
                </>
            </MobiMenuBarDrawer>

            {notificationsOpen && (
                <NotificationPanel
                    isOpen={notificationsOpen}
                    onClose={() => setNotificationsOpen(false)}
                />
            )}

            {supportModal}
        </Box>
    );
};

export default MenuBar;
