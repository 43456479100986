import { EntityType } from "../../../../../../../types";
import { User } from "../../../../../domain";

export const isOfficeAddressComplete = (user: User): boolean => {
    return !!(user.street && user.postalCode && user.city && user.country);
};

export const hasValuerRequiredMatchmakingFields = (user: User): boolean => {
    return !!(
        user.valuationTypes &&
        user.systemStandardsOffered &&
        user.valuationOccasions &&
        user.valuationPropertyTypes &&
        user.latitude &&
        user.longitude &&
        isOfficeAddressComplete(user)
    );
};

export const hasCompanyRequiredMatchmakingFields = (
    entity: EntityType
): boolean => {
    return !!(
        entity?.valuerNumberOfPartners &&
        entity.valuerYearsOfExperience &&
        ((entity.piCoverPercentage && entity.piCoverPercentage > 0) ||
            entity.piCoverPercentageUnlimited ||
            (entity.piCoverValue && entity.piCoverValue > 0) ||
            entity.piCoverValueUnlimited)
    );
};

export const isProfileComplete = (
    user: User,
    isCompanyAdmin: boolean
): boolean => {
    const personalOk =
        !user.doesValuations || hasValuerRequiredMatchmakingFields(user);

    const companyOk =
        !isCompanyAdmin || hasCompanyRequiredMatchmakingFields(user.entity);

    return personalOk && companyOk;
};
