import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { EnquiryType, OfferWithEnquiry } from "../../../../../../types";
import { usePermissions } from "../../../../app/permissions/usePermissions";
import DataTable from "../../../../common/components/data-table/DataTable";
import {
    enrichedOfferColumns,
    EnrichedOfferWithEnquiry,
} from "../../../../common/features/enrichment/domain/offer/display/EnrichedOfferColumns";
import { earliestAvailability } from "../../../../common/features/enrichment/domain/offer/enrichers/Availability";
import { bestSecurity } from "../../../../common/features/enrichment/domain/offer/enrichers/BestSecurity";
import { earliestDelivery } from "../../../../common/features/enrichment/domain/offer/enrichers/Delivery";
import { lowestPrice } from "../../../../common/features/enrichment/domain/offer/enrichers/Price";
import { shortestTimeline } from "../../../../common/features/enrichment/domain/offer/enrichers/ShortestTimeline";
import {
    enrichData,
    EnrichedDataType,
} from "../../../../common/features/enrichment/enricher";
import { calculateRequiredAbsolutePICover } from "../../../../domain/enquiry/enquiryFunctions";

type OfferTableProps = {
    enquiry: EnquiryType;
    offers: OfferWithEnquiry[];
    checkedOffers: OfferWithEnquiry[];
    toggleOfferChecked: (offer: OfferWithEnquiry, checked: boolean) => void;
    footerContent?: ReactNode | ReactNode[];
};

const EnrichedOfferTable = (props: OfferTableProps) => {
    const {
        enquiry,
        offers,
        checkedOffers,
        toggleOfferChecked,
        footerContent,
    } = props;

    const navigate = useNavigate();

    const { permission: canEdit } = usePermissions({
        activity: "RespondToOffer",
        datatype: "Offer",
        objects: offers,
    });

    const enrichedOffers = enrichData(offers, [
        lowestPrice,
        bestSecurity,
        earliestAvailability,
        earliestDelivery,
        shortestTimeline,
    ]);

    const columns = [
        enrichedOfferColumns.valuerProfile,
        enrichedOfferColumns.commment,
        enrichedOfferColumns.price,
        enrichedOfferColumns.deliveryAndTimeline,
        enrichedOfferColumns.piCoverValue,
        enrichedOfferColumns.piCoverPercentage,
        enrichedOfferColumns.specialPi,
        enrichedOfferColumns.view,
        enrichedOfferColumns.deleteOffer,
    ];

    let toggleEnrichedOfferChecked:
        | ((offer: EnrichedOfferWithEnquiry, check: boolean) => void)
        | undefined;

    if (canEdit) {
        toggleEnrichedOfferChecked = (
            offer: EnrichedOfferWithEnquiry,
            check: boolean
        ) => toggleOfferChecked(offer.data, check);
    }

    const checkedEnrichedOffers = enrichedOffers.filter(
        ({ data }) => checkedOffers.find((o) => o.id === data.id) !== undefined
    );

    const rowColorConditions = [
        {
            condition: ({ data }: EnrichedDataType<OfferWithEnquiry>) => {
                return (
                    calculateRequiredAbsolutePICover(data.enquiry) >
                    data.piCoverValue!
                );
            },
            color: "orange.50",
        },
    ];

    return (
        <>
            <DataTable
                fullSectionWidth
                data={enrichedOffers}
                checkedRows={checkedEnrichedOffers}
                isChecked={(i) =>
                    checkedEnrichedOffers.find(
                        (o) => o.data.id === i.data.id
                    ) !== undefined
                }
                onToggleRowChecked={toggleEnrichedOfferChecked}
                columns={columns}
                footerContent={footerContent}
                stickyColumns={1}
                rowColorConditions={rowColorConditions}
                onRowClick={(offer) => {
                    navigate(
                        `/dashboard/enquiry/${offer.data.enquiryId}/offer/${offer.data.id}`
                    );
                }}
            />
        </>
    );
};

export { EnrichedOfferTable };
