import { Button, ButtonProps } from "@chakra-ui/react";
import { JSXElementConstructor, MouseEventHandler, ReactElement } from "react";

type PropTypes = {
    block?: boolean;
    content: string;
    formSubmitButton?: boolean;
    icon?: ReactElement<any, string | JSXElementConstructor<any>>;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    size?: "sm" | "md" | "lg";
    width?: string;
    variant?:
        | "danger"
        | "default"
        | "link"
        | "primary"
        | "success"
        | "outline"
        | "ghost";
    isDisabled?: boolean;
};

const dangerButton = {
    bgColor: "red.500",
    color: "white",
    _hover: { bgColor: "red80" },
};

const defaultButton = {
    _hover: { bgColor: "gray.200" },
    bgColor: "gray.100",
};

const linkButton = {
    color: "wvwGreen",
    fontWeight: "normal",
    padding: 0,
    variant: "link",
    width: "auto",
};

const primaryButton = {
    variant: "primaryYellow",
};

const successButton = {
    bgColor: "green.500",
    color: "white",
    _hover: { bgColor: "green.600" },
};

const outlineButton = {
    border: "1px solid",
    backgroundColor: "transparent",
    borderColor: "wvwGreen",
    color: "wvwGreen",
    _hover: {
        borderColor: "wvwYellow",
        color: "wvwYellow",
    },
};

const ghostButton = {
    border: "none",
    backgroundColor: "transparent",
    color: "white",
    _hover: {
        bgColor: "none",
    },
};

const WvwButton = (props: PropTypes) => {
    const {
        block = false,
        content,
        formSubmitButton = false,
        icon,
        onClick,
        size = 'md',
        width = "15rem",
        variant = 'default',
        isDisabled = false,
    } = props;

    const baseProps: ButtonProps = {
        leftIcon: icon,
        onClick: onClick
            ? (event) => {
                  event.stopPropagation();
                  onClick(event);
              }
            : undefined,
        size,
        type: formSubmitButton ? "submit" : "button",
        w: block ? "100%" : width,
        minWidth: "fit-content",
        isDisabled,
    };

    switch (variant) {
        case "danger":
            return (
                <Button
                    {...baseProps}
                    {...dangerButton}
                >
                    {content}
                </Button>
            );
        case "link":
            return (
                <Button
                    {...baseProps}
                    {...linkButton}
                >
                    {content}
                </Button>
            );
        case "primary":
            return (
                <Button
                    {...baseProps}
                    {...primaryButton}
                >
                    {content}
                </Button>
            );
        case "success":
            return (
                <Button
                    {...baseProps}
                    {...successButton}
                >
                    {content}
                </Button>
            );
        case "outline":
            return (
                <Button
                    {...baseProps}
                    {...outlineButton}
                >
                    {content}
                </Button>
            );
        case "ghost":
            return (
                <Button
                    {...baseProps}
                    {...ghostButton}
                >
                    {content}
                </Button>
            );
        default:
            return (
                <Button
                    {...baseProps}
                    {...defaultButton}
                >
                    {content}
                </Button>
            );
    }
};

export default WvwButton;
