import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";

export const accountTranslations = {
    de: {
        companyDetails: {
            billingAddress: "Rechnungsadresse",
            billingAddressDescription:
                "Rechnungsadresse, wie sie für die Ausstellung von Rechnungen verwendet wird.",
            contactPerson: "Ansprechpartner",
            contactInfo: "Informationen zum Unternehmen",
            contactInfoDescription:
                "Eckdaten zu Ihrem Unternehmen und Kontaktangaben, die Ihren Kunden zur Verfügung gestellt und im Unternehmensprofil angezeigt werden.",
            email: "E-Mail Adresse (gesch.)",
            location: "Adresse der Niederlassung",
            noNameProvided: "Kein Name angegeben",
            numberOfValuers: "Anzahl der Gutachter in Ihrem Unternehmen",
            phoneNumber: "Telefonnummer (gesch.)",
            yearsOfExperience: "Erfahrung (in Jahren)",
            website: "Webseite des Unternehmens",
            vatNumber: "USt-ID",
            editCompanyDetails: "Firmendetails bearbeiten",
            name: "Name des Unternehmens",
            city: "Stadt",
            companyLogo: "Firmenlogo",
            companyLogoDescription:
                "Offizielles Firmen-Logo, das auf der Platform zusammen mit Ihrem Firmennamen dargestellt wird sowie ergänzend neben den Profilbildern aller Ihrer Mitarbeiter.",
            companyLogoFormats: "Erlaubte Formate: JPG, GIF, PNG, HEIC",
            country: "Land",
            street: "Straße",
            postalCode: "Postleitzahl",
            firstName: "Vorname",
            lastName: "Nachname",
            dialCode: "Vorwahl",
        },
        heading: {
            companyDetails: "Firmendetails",
            piCover: "Haftpflichtdeckung",
        },
        valuationCapabilities: {
            sectionTitle: "Bewertungsleistungen",
            piCover: {
                description:
                    "Bitte machen Sie Angaben zu Ihrer Haftpflichtdeckung, je nach Vertrag als maximale Deckungssumme pro Versicherungsfall (in Euro) oder als maximalen Deckungsanteil des versicherten Wertes. Diese Informationen werden gegebenenfalls für die Zuweisung einer Anfrage für ein Wertgutachten an Ihr Unternehmen benötigt. Darüber hinaus werden sie auf Ihrem Unternehmensprofil angezeigt.",
                insuranceCoverConditions: "Angaben zur Haftpflichtdeckung",
                noLimit: "Keine Begrenzung",
                or: "{{coverValue}} oder {{coverPercentage}}",
                p_of_value: "% des Wertes",
                maximum: "maximal",
                specialArrangementsAvailable:
                    "Sonderkonditionen für die Haftpflichtdeckung sind möglich",
                specialArrangementsAvailableDescription:
                    "Wählen Sie diese Option, falls Sie über die Standardpolicen hinaus eine maßgeschneiderte Haftpflicht-Deckung anbieten, z.B. für komplexe Fälle oder besondere Risikoprofile.",
                validUntil: "gültig bis {{date}}",
                maxOf: "Maximaler Deckungsbetrag",
                orPercentage: "Oder maximaler Anteil des Wertes",
                validUntilLabel: "Die Angaben sind gültig bis",
                certificate:
                    "Nachweisdokumente für die Haftpflichtversicherung",
                certificateDescription:
                    "Laden Sie einen geeigneten Nachweis zur Deckung Ihrer Haftpflichtversicherung hoch. Diesen können Ihre potenziellen Kunden bei der Angebotsbeurteilung einsehen.",
            },
        },
        teamDetails: {
            sectionTitle: "Nutzer",
            button: {
                excelUpload: "Upload Excel-Datei",
                inviteMembers: "Mitglieder einladen",
                revokeAccess: "Zugang widerrufen",
            },
            revokeAccess: {
                text: "Sind Sie sicher, dass Sie den Zugang für diesen Nutzer widerrufen wollen?",
                title: "Zugang widerrufen",
            },
            teamMemberPreview: {
                title: "Vorschau",
            },
        },
        teamInvites: {
            sectionTitle: "Ausstehende Einladungen",
            noPendingInvitesMsg:
                "Sie haben keine ausstehenden Einladungen für Teammitglieder.",
            addTeamMember: "Teammitglied hinzufügen",
            invited: "Eingeladen",
            fieldPlaceholder: {
                email: "E-Mail Adresse",
                firstName: "Vorname",
                lastName: "Nachname",
            },
            role: "Rolle des Nutzers",
            roleDescriptions: {
                USER: "Nutzer können Fälle aktiv verwalten.",
                ADMIN: "Administratoren haben volle Zugriffsrechte auf alle Daten, und können die Daten der Firma bearbeiten.",
                ASSISTANT:
                    "Assistenten können alle Fälle der Firma einsehen, aber nicht bearbeiten.",
            },
            doesValuations: {
                title: "Wird der eingeladene Nutzer Wertgutachten anfertigen?",
            },
            deleteInvitation: {
                title: "Einladung löschen",
                prompt: "Sind Sie sicher, dass Sie diese Einladung löschen möchten?",
            },
            statusLabel: "Status",
            status: {
                PENDING: "Anhängig",
                ACCEPTED: "Angenommen",
                REJECTED: "Abgelehnt",
            },
            uploadExcel: "Excel-Datei hochladen",
            downloadTemplate: "Vorlage herunterladen",
        },
        customerPanelDetails: {
            inviteCustomer: "Kunden einladen",
            inviteNewValuer: "Neue Gutachter einladen",
            customerCompanyName: "Name des Unternehmens des Kunden",
            customerExplainerText:
                "Wenn eine Einladung gesendet wird, können neue Kunden der Plattform beitreten, während bestehende Nutzer eingeladen werden, direkt Ihrem Kunden-Panel beizutreten.",
            email: "E-Mail Adresse",
            name: "Name",
            customersList: "Aktive Kunden",
            valuersList: "Wertgutachter",
            invitationsSentList: "Verschickte Einladungen",
            invitationsReceivedList: "Erhaltene Einladungen",
            button: {
                addCustomer: "Kunde einladen",
                addValuer: "Gutachter einladen",
                accept: "Annehmen",
                reject: "Ablehnen",
            },
            columns: {
                name: "Unternehmen",
                address: "Anschrift des Büros",
                email: "E-Mail Adresse",
                website: "Webseite des Unternehmens",
                createdAt: "Beitritt",
                sent: "Gesendet",
                received: "Empfangen",
            },
            leavePanel: {
                title: "Panel verlassen",
                prompt: "Sind Sie sicher, dass Sie das Panel verlassen wollen?",
            },
            cancelInvitation: {
                title: "Einladung widerrufen",
                prompt: "Sind Sie sicher, dass Sie diese Einladung widerrufen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
            },
            declineInvitation: {
                title: "Einladung ablehnen",
                prompt: "Sind Sie sicher, dass Sie diese Einladung ablehnen möchten? Sie werden nicht mehr auf dieses Panel zugreifen können.",
            },
            removeValuer: {
                title: '$t(Valuer, { "ns": "common" }) löschen',
                prompt: 'Sind Sie sicher, dass Sie den $t(Valuer, { "ns": "common" }) löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.',
            },
            inviteAClient: {
                title: "Einladung nicht möglich",
                cannotInviteIndividualClientToPanel:
                    "Die angegebene E-Mail Adresse gehört zu einem Privatkunden. Derartige Einladungen können nur an institutionelle Kunden gesendet werden.",
                userEntityAlreadyOnPanel:
                    "Die angegebene E-Mail Adresse gehört zu einer Firma, die sich bereits in Ihrer Kunden-Liste befindet.",
                notACustomer:
                    "Die angegebene E-Mail Adresse gehört nicht zu einem Kunden-Konto.",
                emailAlreadyInvited:
                    "Für die angegebene E-Mail Adresse gibt es bereits eine ausstehende Einladung.",
            },
            inviteAValuer: {
                title: "Einladung nicht möglich",
                userEntityAlreadyOnPanel:
                    "Die angegebene E-Mail Adresse gehört zu einer Firma, die sich bereits in Ihrem Gutachter-Panel befindet.",
                notAValuer:
                    "Die angegebene E-Mail Adresse gehört nicht zu einem Gutachter-Konto.",
                emailAlreadyInvited:
                    "Für die angegebene E-Mail Adresse gibt es bereits eine ausstehende Einladung.",
            },
        },
        accountSectionLink: {
            profile: "Mein Profil",
            companyDetails: "Angaben zum Unternehmen",
            customers: "Kunden",
            integration: "Integration",
            panelValuers: "Panel Wertgutachter",
            sharedDocs: "Geteilte Dokumente",
            team: "Team",
            templates: "Vorlagen",
        },
        integration: {
            button: {
                copyLink: "Link kopieren",
                emailLink: "Link per E-Mail senden",
            },
            buttonTitle: "In die Zwischenablage kopiert",
            customerTypes: {
                private: "Privatkunde",
                institution: "Institutioneller Kunde",
            },
        },
        sharedDocuments: {
            noDocs: "Keine Dokumente hinterlegt",
            sendEmail: "E-Mail senden",
            uploadAlert: {
                label: "Hinweise",
                option1:
                    "Achten Sie auf handhabbare Dateigrößen und möglichst gängige Dateiformate (pdf, jpg..) um den Zugang zu den Informationen zu erleichtern.",
                option2:
                    "Überzeugen Sie sich vor dem Hochladen, dass die Dateien vollständig sind, gegebenenfalls korrekt formatiert worden sind und insbesondere keine bezüglich Dritten vertrauliche Informationen enthalten (z.B. Beispielgutachten).",
            },
        },
        fieldPlaceholder: {
            accessInstructions: "Zugangsanweisungen",
            city: "Stadt",
            companyName: "Meine Firma GmbH",
            comment: "Anmerkungen",
            country: "Land",
            date: "tt.mm.jjjj",
            dialCode: "Vorwahl",
            email: "E-Mail Adresse",
            companyEmail: "E-Mail Adresse der Firma",
            name: "Name",
            firstName: "Vorname",
            lastName: "Nachname",
            password: '$t(Password,  { "ns": "common" })',
            confirmPassword: '$t(Password,  { "ns": "common" }) bestätigen',
            faxNumber: "Faxnummer",
            phoneNumber: "Tel.",
            postalCode: "PLZ",
            roleInCompany: "d.h. CEO, CFO, Kreditsachbearbeiter, usw.",
            pleaseSelect: "Bitte wählen Sie",
            street: "Straße",
            url: "Webadresse (URL)",
            companyWebsite_optional: "Webadresse (URL, optional)",
            year: "Jahr",
            numberOfValuers: "Anzahl der Gutachter",
            numberOfYears: "Anzahl Jahre",
        },
    },
    en: {
        companyDetails: {
            billingAddress: "Billing Address",
            billingAddressDescription:
                "Billing address that will be used for issuing invoices or receipts.",
            contactPerson: "Contact Person",
            contactInfo: "Business information",
            contactInfoDescription:
                "Basic company information and contact details that will be available for your clients, and displayed on company profile.",
            email: "Company Email",
            location: "Company Location",
            noNameProvided: "No name provided",
            numberOfValuers: "Number of Valuers",
            phoneNumber: "Company Phone",
            yearsOfExperience: "Years of Experience",
            website: "Company Website",
            vatNumber: "VAT Number",
            editCompanyDetails: "Edit Company Details",
            name: "Company Name",
            city: "City",
            companyLogo: "Company Logo",
            companyLogoDescription:
                "Official company branding of Icon that will be displayed across the platform next to company name and all your company employees.",
            companyLogoFormats: "Allowed formats .JPG, GIF, PNG, HEIC",
            country: "Country",
            street: "Street",
            postalCode: "Postal Code",
            firstName: "First Name",
            lastName: "Last Name",
            dialCode: "Dial Code",
        },
        heading: {
            companyDetails: "Company details",
            piCover: "PI cover",
        },
        valuationCapabilities: {
            sectionTitle: "Valuation Capabilities",
            piCover: {
                description:
                    "Define your policy’s cover limits by specifying either the maximum absolute value (in EUR) and/or the maximum percentage of the insured value. This information is an important parameter for matching your services with clients’ enquiries and will be visible on your company profile.",
                insuranceCoverConditions: "Insurance Cover Conditions",
                noLimit: "No limit",
                or: "{{coverValue}} or {{coverPercentage}}",
                p_of_value: "% of value",
                maximum: "maximum",
                specialArrangementsAvailable:
                    "Special PI Arrangements Available",
                specialArrangementsAvailableDescription:
                    "Activate this option if you offer tailored PI cover beyond standard policies, e.g., for complex cases or unique risk profiles.",
                validUntil: "valid until {{date}}",
                maxOf: "Maximum absolute value",
                orPercentage: "Or maximum percentage of value",
                validUntilLabel: "Expiration date",
                certificate: "PI Cover Certificate",
                certificateDescription:
                    "Upload your Professional Indemnity Cover certificate. It will be shared with prospective clients to verify your coverage.",
            },
        },
        teamDetails: {
            sectionTitle: "Team members",
            button: {
                excelUpload: "Upload excel",
                inviteMembers: "Invite members",
                revokeAccess: "Revoke Access",
            },
            revokeAccess: {
                text: "Are you sure you want to revoke access for this user?",
                title: "Revoke Access",
            },
            teamMemberPreview: {
                title: "Preview",
            },
        },
        teamInvites: {
            sectionTitle: "Invitations",
            noPendingInvitesMsg: "You have no pending team member invites.",
            addTeamMember: "Add Team Member",
            invited: "Invited",
            fieldPlaceholder: {
                email: "Email Address",
                firstName: "First Name",
                lastName: "Last Name",
            },
            role: "User role",
            roleDescriptions: {
                USER: "Users can actively manage cases.",
                ADMIN: "Admin role users have full access rights to all data, and can edit your company data.",
                ASSISTANT:
                    "Assistants can view all your company's cases, but not participate or edit.",
            },
            doesValuations: {
                title: "Will the invited user perform valuations?",
            },
            deleteInvitation: {
                title: "Delete Invitation",
                prompt: "Are you sure you want to delete this invitation?",
            },
            statusLabel: "Status",
            status: {
                PENDING: "Pending",
                ACCEPTED: "Accepted",
                REJECTED: "Rejected",
            },
            uploadExcel: "Upload Excel",
            downloadTemplate: "Download Template",
        },
        customerPanelDetails: {
            inviteCustomer: "Invite Customer",
            inviteNewValuer: "Invite New Valuer",
            customerCompanyName: "Customer Company Name",
            customerExplainerText:
                "When an invite is sent, new customers can join the platform, while existing users are directly invited to join your Customer Panel.",
            email: "Email address",
            name: "Name",
            customersList: "Active customers",
            valuersList: "Valuers",
            invitationsSentList: "Invitations sent",
            invitationsReceivedList: "Invitations received",
            button: {
                addCustomer: "Invite customer",
                addValuer: "Add Valuer",
                accept: "Accept",
                reject: "Reject",
            },
            columns: {
                name: "Company",
                address: "Office address",
                email: "Email address",
                website: "Website",
                createdAt: "Joined",
                sent: "Sent",
                received: "Received",
            },
            leavePanel: {
                title: "Leave Panel",
                prompt: "Are you sure you want to leave the panel?",
            },
            cancelInvitation: {
                title: "Cancel Invitation",
                prompt: "Are you sure you want to cancel this invite? This action cannot be undone.",
            },
            declineInvitation: {
                title: "Decline Invitation",
                prompt: "Are you sure you want to decline this invite? You will no longer be able to access this panel.",
            },
            removeValuer: {
                title: 'Remove $t(Valuer, { "ns": "common" })',
                prompt: 'Are you sure you want to remove the $t(valuer, { "ns": "common" })? This action cannot be undone.',
            },
            inviteAClient: {
                title: "Could not invite user",
                cannotInviteIndividualClientToPanel:
                    "The email-address you entered belongs to a private customer. Only institutional clients can be invited to your list of clients.",
                userEntityAlreadyOnPanel:
                    "The email-address you entered belongs to a company that is already on your list of clients.",
                notACustomer:
                    "The email-address you entered does not belong to a customer.",
                emailAlreadyInvited:
                    "There is already an invitation pending for the email-address you entered.",
            },
            inviteAValuer: {
                title: "Could not invite user",
                userEntityAlreadyOnPanel:
                    "The email-address you entered belongs to a company that is already on your valuer panel.",
                notAValuer:
                    "The email-address you entered does not belong to a valuer.",
                emailAlreadyInvited:
                    "There is already an invitation pending for the email-address you entered.",
            },
        },
        accountSectionLink: {
            profile: "My profile",
            companyDetails: "Company account",
            customers: "Customers",
            integration: "Integrations",
            panelValuers: "Panel Valuers",
            sharedDocs: "Uploaded documents",
            team: "Team",
            templates: "Templates",
        },
        integration: {
            button: {
                copyLink: "Copy Link",
                emailLink: "Email Link",
            },
            buttonTitle: "Copied to clipboard",
            customerTypes: {
                private: "Private End Customer",
                institution: "Institutional Client",
            },
        },
        sharedDocuments: {
            noDocs: "No documents uploaded",
            sendEmail: "Send email",
            uploadAlert: {
                label: "Recommendations",
                option1:
                    "Keep file sizes reasonable and use common formats (.pdf, .docx, .xlsx, .jpg, .png) for easy access.",
                option2:
                    "Before uploading, check that files are complete, properly formatted, and don’t contain sensitive information.",
            },
        },
        fieldPlaceholder: {
            accessInstructions: "Access Instructions",
            city: "City",
            companyName: "My Company GmbH",
            comment: "Comment",
            country: "Country",
            date: "dd/mm/yyyy",
            dialCode: "Dial code",
            email: "Email Address",
            companyEmail: "Email Address",
            name: "Name",
            firstName: "First Name",
            lastName: "Last Name",
            password: "Password",
            confirmPassword: "Confirm Password",
            faxNumber: "Fax Number",
            phoneNumber: "Phone Number",
            postalCode: "Postal Code",
            roleInCompany: "i.e. CEO, CFO, Investment Manager, etc.",
            pleaseSelect: "Please select",
            street: "Street",
            url: "Website URL",
            companyWebsite_optional: "Website URL (optional)",
            year: "Year",
            numberOfValuers: "Number of valuers",
            numberOfYears: "Number of years",
        },
    },
};
