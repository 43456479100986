import { Button, Flex, Switch, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { LiaExclamationTriangleSolid } from "react-icons/lia";
import { EnquiryType, OfferOnlyType, UserType } from "../../../../../../types";
import { useEnquiryAssignValuers } from "../../../../../../utils/api/hooks";
import DataTable from "../../../../common/components/data-table/DataTable";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { EnquiryMatchInfoWithValuerAndOffer } from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";
import { companyValuerColumns } from "./CompanyValuerColumns";

type PropsType = {
    user: UserType;
    offers: OfferOnlyType[];
    usersList: UserType[];
    companyMatchInfoList: EnquiryMatchInfoWithValuerAndOffer[];
    enquiry: EnquiryType;
    onUpdate: () => any;
    onComplete?: () => void;
};

const AssignCompanyValuerToEnquiry = (props: PropsType) => {
    const {
        enquiry,
        onComplete,
        onUpdate,
        user,
        usersList,
        companyMatchInfoList,
    } = props;

    const t = useSmartTranslation();

    const [valuersToAssign, setValuersToAssign] = useState<number[]>([]);

    const { update: assignValuers, busy: assignValuersBusy } =
        useEnquiryAssignValuers({
            onSuccess: () => {
                onComplete?.();
                onUpdate();
            },
        });

    const allMatchedValuers = usersList.filter(
        (valuer) => {
            return companyMatchInfoList.some(
                (info) => info.valuerId === valuer.id
            );
        }
    );

    const companyValuerDataList = allMatchedValuers.map((valuer) => {
        const enquiryMatchInfo = companyMatchInfoList?.find(
            (info) => info.valuerId === valuer.id
        );

        return {
            ...valuer,
            enquiryMatchInfo,
        };
    });

    const [qualifiedUsersOnly, setQualifiedUsersOnly] = useState(false);

    const [inRangeUsersOnly, setInRangeUsersOnly] = useState(false);

    let companyValuerFilteredList = companyValuerDataList;

    if (qualifiedUsersOnly && inRangeUsersOnly) {
        companyValuerFilteredList = companyValuerFilteredList.filter(
            (valuer) => {
                return (
                    valuer.enquiryMatchInfo?.valuationStandards === true &&
                    valuer.enquiryMatchInfo?.valuerMaxOfficeDistance === true
                );
            }
        );
    } else if (qualifiedUsersOnly) {
        companyValuerFilteredList = companyValuerFilteredList.filter(
            (valuer) => {
                return valuer.enquiryMatchInfo?.valuationStandards === true;
            }
        );
    } else if (inRangeUsersOnly) {
        companyValuerFilteredList = companyValuerFilteredList.filter(
            (valuer) => {
                return (
                    valuer.enquiryMatchInfo?.valuerMaxOfficeDistance === true
                );
            }
        );
    }

    const userListFiltering = (
        <Flex
            borderRadius={10}
            bgColor="gray.50"
            padding="1rem"
            gap="1rem"
        >
            <Text
                color="gray.500"
                fontSize={"sm"}
            >
                {t(
                    "screens.platform.valuer.valuerEnquiryDetails.companyOffers.filterFunction.display"
                )}
                :
            </Text>

            <Switch
                alignContent="center"
                onChange={() => setQualifiedUsersOnly(!qualifiedUsersOnly)}
            />
            <Text
                alignItems="center"
                justifyContent="center"
                fontSize={"sm"}
            >
                {t(
                    "screens.platform.valuer.valuerEnquiryDetails.companyOffers.filterFunction.qualifiedOnly"
                )}
            </Text>

            <Switch
                alignContent="center"
                onChange={() => setInRangeUsersOnly(!inRangeUsersOnly)}
            />
            <Text
                alignItems="center"
                justifyContent="center"
                fontSize={"sm"}
            >
                {t(
                    "screens.platform.valuer.valuerEnquiryDetails.companyOffers.filterFunction.inRangeOnly"
                )}
            </Text>
        </Flex>
    );

    const companyValuerDataTable = (
        <DataTable
            checkedRows={usersList}
            isChecked={(valuer) => !!valuersToAssign.includes(valuer.id)}
            onToggleRowChecked={(valuer, checked) => {
                if (checked) {
                    setValuersToAssign((prev) => {
                        if (!prev) {
                            return [valuer.id];
                        }
                        return [...prev, valuer.id];
                    });
                } else {
                    setValuersToAssign((prev) => {
                        if (!prev) {
                            return [];
                        }
                        return prev.filter((id) => id !== valuer.id);
                    });
                }
            }}
            data={companyValuerFilteredList}
            columns={companyValuerColumns}
            noDataText={t(
                "screens.platform.valuer.valuerEnquiryDetails.companyOffers.noUnassignedValuers"
            )}
        />
    );

    const valuerAssignment = (
        <Flex w="100%">
            <Button
                width={"50%"}
                onClick={() => {
                    if (valuersToAssign) {
                        assignValuers({
                            enquiryId: enquiry.id,
                            valuers: valuersToAssign,
                        });
                    }
                }}
                isLoading={assignValuersBusy}
                variant={"solid"}
                colorScheme={"blue"}
                isDisabled={!valuersToAssign || valuersToAssign.length === 0}
            >
                {t("domain.enquiry.ACTIVITIES.assign.NAME")}
            </Button>
        </Flex>
    );

    return (
        <VStack
            align="left"
            spacing="4"
        >
            <Text color="gray.500">
                {`${t(
                    "screens.platform.valuer.valuerEnquiryDetails.companyOffers.selectedValuers"
                )}: ${valuersToAssign.length || 0}`}
            </Text>

            {userListFiltering}

            {companyValuerDataTable}

            <Flex
                borderRadius={8}
                bgColor="gray.50"
                padding="1rem"
                gap="1rem"
                marginTop="2rem"
            >
                <LiaExclamationTriangleSolid
                    color="gray.50"
                    fontSize="1.75rem"
                />

                <Text fontSize={"sm"}>
                    {t(
                        "screens.platform.valuer.valuerEnquiryDetails.companyOffers.submissionNotice"
                    )}
                </Text>
            </Flex>

            {valuerAssignment}
        </VStack>
    );
};

export default AssignCompanyValuerToEnquiry;
