import {
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    InputRightAddon,
    InputRightElement,
    Text,
} from "@chakra-ui/react";
import { FieldInputProps } from "formik";
import i18n from "../../../../i18n";
import { LabelAndInput } from "../components/display";
import { useSmartTranslation } from "../hooks/useSmartTranslation";
import { useFormikMeta } from "./useFormikMeta";
import { JSX } from "react";

type PropTypes = {
    name: string;
    disabled?: boolean;
    readonly?: boolean;
    label?: string;
    min?: string;
    placeholder?: string;
    prefix?: string;
    suffix?: string;
    subtext?: string;
    type?: string;
    leftinternal?: string | JSX.Element;
    rightinternal?: string | JSX.Element;
    width?: string;
    maxW?: string;
    onChange?: (value: string, field?: FieldInputProps<any>) => void;
    noSubmitOnEnter?: boolean;
    variant?: string;
    size?: string;
};

const FormikInput = (props: PropTypes) => {
    const t = useSmartTranslation();

    const {
        name,
        prefix = null,
        disabled = false,
        readonly = false,
        label = "",
        min,
        placeholder = '',
        suffix = null,
        subtext,
        leftinternal = null,
        maxW,
        rightinternal = null,
        width = '100%',
        onChange = () => {},
        noSubmitOnEnter = false,
        type = 'text',
        variant,
        size = "md",
    } = props;

    const { errorBorderColor, isRequired, isInvalid, field, meta } =
        useFormikMeta(name);

    return (
        <LabelAndInput
            label={label && `${label}${isRequired ? " *" : ""}`}
            input={
                <>
                    <InputGroup variant={variant}>
                        {prefix && (
                            <InputLeftAddon
                                minW="3rem"
                                bg={"white"}
                                fontSize={"sm"}
                                color={"gray.500"}
                            >
                                {prefix}
                            </InputLeftAddon>
                        )}

                        {leftinternal && (
                            <InputLeftElement>{leftinternal}</InputLeftElement>
                        )}

                        <Input
                            min={min}
                            placeholder={placeholder}
                            borderRadius="8"
                            disabled={disabled}
                            isReadOnly={readonly}
                            cursor={readonly ? "not-allowed" : undefined}
                            position="relative"
                            isInvalid={isInvalid}
                            errorBorderColor={errorBorderColor}
                            maxW={maxW}
                            w={width}
                            lang={i18n.language}
                            type={type}
                            size={size}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                                e.stopPropagation();

                                if (noSubmitOnEnter && e.key === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                field.onChange(e);

                                onChange?.(e.target.value, field);
                            }}
                            _disabled={{
                                bg: "gray.50",
                                color: "gray.500",
                                cursor: "not-allowed",
                                borderColor: "gray.200"
                            }}
                        />

                        {rightinternal && (
                            <InputRightElement>
                                {rightinternal}
                            </InputRightElement>
                        )}

                        {suffix && (
                            <InputRightAddon
                                minW="3rem"
                                bg={"white"}
                                fontSize={"sm"}
                                color={"gray.500"}
                            >
                                {suffix}
                            </InputRightAddon>
                        )}
                    </InputGroup>

                    {subtext && (
                        <Text
                            fontSize={"sm"}
                            color="gray.500"
                        >
                            {subtext}
                        </Text>
                    )}

                    {meta.touched && meta.error && (
                        <Text
                            fontSize={"sm"}
                            color="red"
                        >
                            {t(meta.error, { defaultValue: meta.error })}
                        </Text>
                    )}
                </>
            }
        />
    );
};

export default FormikInput;
