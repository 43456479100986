import { HStack, IconButton, Text } from "@chakra-ui/react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import { FirebaseCertificateType } from "../../../../../utils/storage/storage";
import { DataTableColumnType } from "../../../common/components/data-table/DataTable";
import {
    filesizeToString,
    getFileTypeBasedOnName,
} from "../../../common/components/display/File";
import { useDocumentViewerModal } from "../useDocumentViewerModal";
import { FileTypeIcon } from "../../../common/icons/FileTypeIcon";
import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";
import useDocumentEditName from "../useDocumentEditName";
import useDocumentRemove from "../useDocumentRemove";
import toTitleCase from "../../../../../common/functions/toTitleCase";

type Column = DataTableColumnType<FirebaseCertificateType>;

const translationNameSpace = "domain.document.columns";

const name: Column = {
    title: (t) => t(`${translationNameSpace}.name`),
    render: ({ label, url }) => {
        const { documentView, viewDocument } = useDocumentViewerModal();

        return (
            <HStack>
                <FileTypeIcon
                    filetype={getFileTypeBasedOnName(label)}
                    size={6}
                />
                <Text
                    fontSize={"sm"}
                    fontWeight={"700"}
                    _hover={{
                        color: "blue.500",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        viewDocument({
                            uri: url,
                            fileType: getFileTypeBasedOnName(label),
                            label,
                        });
                    }}
                >
                    {label}
                </Text>
                {documentView}
            </HStack>
        );
    },
};

const category: Column = {
    title: (t) => t(`${translationNameSpace}.category`),
    render: ({ type }, t) => {
        return (
            <Text fontSize={"sm"}>
                {t(`domain.document.category.${toTitleCase(type)}`, {
                    defaultValue: MISSING_TRANSLATION,
                })}
            </Text>
        );
    },
};

const createdAt: Column = {
    title: (t) => t(`${translationNameSpace}.uploadDate`),
    render: ({ meta }) => {
        const date = displayDateWithCurrentLocale(meta.timeCreated);

        return <Text fontSize={"sm"}>{date}</Text>;
    },
};

const size: Column = {
    title: (t) => t(`${translationNameSpace}.size`),
    render: ({ meta }) => {
        return <Text fontSize={"sm"}>{filesizeToString(meta.size)}</Text>;
    },
};

const edit: Column = {
    render: (file) => {
        const { editDocumentModal, openEditDocumentModal } =
            useDocumentEditName({
                file,
            });

        return (
            <>
                <IconButton
                    icon={<MdOutlineModeEditOutline />}
                    size="sm"
                    variant="ghost"
                    color="gray.400"
                    aria-label="edit"
                    onClick={(e) => {
                        e.stopPropagation();
                        openEditDocumentModal();
                    }}
                />
                {editDocumentModal}
            </>
        );
    },
};

const remove: Column = {
    render: (file) => {
        const { removeDocumentModal, openRemoveDocumentModal } =
            useDocumentRemove({
                file,
            });

        return (
            <>
                <IconButton
                    icon={<RiDeleteBin6Line />}
                    size="sm"
                    variant="ghost"
                    color="gray.400"
                    aria-label="remove"
                    onClick={(e) => {
                        e.stopPropagation();
                        openRemoveDocumentModal();
                    }}
                />
                {removeDocumentModal}
            </>
        );
    },
};

export const documentColumns = {
    name,
    category,
    createdAt,
    size,
    edit,
    remove,
};
