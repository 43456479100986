import { Box, Button } from '@chakra-ui/react';
import {
    APIProvider,
    Map,
    Marker,
} from '@vis.gl/react-google-maps';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    directionsLink?: boolean;
    latitude?: string | number;
    longitude?: string | number;
};

const { REACT_APP_GOOGLEMAPS_API_KEY = '' } = process.env;

const GoogleMap = (props: PropTypes) => {
    const { directionsLink = false, latitude, longitude } = props;

    const { t } = useTranslation(['common', 'formik']);

    if (!latitude || !longitude) return null;

    const directionsURL = `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(`${latitude},${longitude}`)}`;

    return (
        <>
            <Box h="40vh" w="100%">
                <APIProvider apiKey={REACT_APP_GOOGLEMAPS_API_KEY}>
                    <Map
                        center={{
                            lat: Number(latitude) || 53.54992,
                            lng: Number(longitude) || 10.00678,
                        }}
                        zoom={16}
                    >
                        <Marker
                            position={{
                                lat: Number(latitude) || 53.54992,
                                lng: Number(longitude) || 10.00678,
                            }}
                        />
                    </Map>
                </APIProvider>
            </Box>

            {directionsLink && (
                <Button
                    variant="primaryYellow"
                    w="100%"
                    h="2rem"
                    borderRadius="6px"
                    marginBlock=".5rem"
                    onClick={() => {
                        window.open(directionsURL, '_blank', 'noopener,noreferrer');
                    }}
                >
                    {t('button.getDirections')}
                </Button>
            )}
        </>
    );
};

export default GoogleMap;
