import { EnquiryWithFromUserAndFromEntityType } from "../../../../../../types";
import {
    useCaseActiveCases,
    useEnquiriesByStatus,
    useEnquiryGetAll,
    useEnquiryGetDormant,
    useOfferGetAllMyOffers,
} from "../../../../../../utils/api/hooks";
import { DataTableColumnType } from "../../../../common/components/data-table/DataTable";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Enquiry } from "../../../../domain";
import { EnquiryWithSenderAndOffersType } from "../../enquiry-list/EnquiryList";
import { ByOutlet } from "../../../../common/features/filters/domain/enquiry/ByOutlet";
import { ByScope } from "../../../../common/features/filters/domain/enquiry/ByScope";
import { ByStandard } from "../../../../common/features/filters/domain/enquiry/ByStandard";
import { ByStatus } from "../../../../common/features/filters/domain/enquiry/ByStatus";
import { EnquiryFilterElement } from "../../enquiry-list/filters/EnquiryFilter";
import { PlatformScreens } from "../../PlatformScreens";

type EnquiryListPrefilter = "ACTIVE" | "DRAFT" | "ARCHIVED";

type EnquiryListLoaderProps = {
    prefilter: EnquiryListPrefilter;
};

// TODO split into components in order to not load all of the user's enquiries everywhere
const EnquiryListLoader = (props: EnquiryListLoaderProps) => {
    const { prefilter } = props;

    const t = useSmartTranslation();

    // PENDING / CANCELLED
    const { data: enquiries, isLoading: isLoadingEnquiries } =
        useEnquiryGetAll();

    // ACCEPTED
    const { data: cases, isLoading: isLoadingCases } = useCaseActiveCases();

    const { data: archivedEnquiries, isLoading: isLoadingArchivedEnquiries } =
        useEnquiryGetDormant();

    const { enquiryList: draftEnquiries, isLoading: isLoadingDraftEnquiries } =
        useEnquiriesByStatus("DRAFT");

    const { data: offers = [], isLoading: isLoadingOffers } =
        useOfferGetAllMyOffers();

    if (
        !enquiries ||
        !archivedEnquiries ||
        !cases ||
        !draftEnquiries ||
        !offers ||
        isLoadingEnquiries ||
        isLoadingArchivedEnquiries ||
        isLoadingCases ||
        isLoadingDraftEnquiries ||
        isLoadingOffers
    )
        return <LoadingSpinner />;

    let prefilteredEnquiries: EnquiryWithFromUserAndFromEntityType[];
    let filters: EnquiryFilterElement[];
    let columns: DataTableColumnType<EnquiryWithSenderAndOffersType>[];

    switch (prefilter) {
        case "ACTIVE": {
            prefilteredEnquiries = cases
                .map(
                    ({
                        enquiry,
                        clientEntity: fromEntity,
                        clientUser: fromUser,
                    }) => ({
                        ...enquiry,
                        fromEntity,
                        fromUser,
                    })
                )
                .concat(enquiries);
            filters = [ByOutlet, ByStandard, ByStatus, ByScope];
            columns = tableColumns;
            break;
        }
        case "ARCHIVED": {
            prefilteredEnquiries = archivedEnquiries;
            filters = [ByOutlet, ByStandard, ByScope];
            columns = tableColumns;
            break;
        }
        case "DRAFT": {
            prefilteredEnquiries =
                draftEnquiries as EnquiryWithFromUserAndFromEntityType[];
            filters = [ByOutlet, ByStandard, ByScope];
            columns = [
                ...tableColumns,
                Enquiry.TableColumns.Enquiry.editEnquiry,
            ];
            break;
        }
    }

    const title = t(`screens.platform.enquiryList.title.${prefilter}`, {
        count: prefilteredEnquiries.length,
    });

    const enquiriesWithOffers: EnquiryWithSenderAndOffersType[] =
        prefilteredEnquiries.map((enquiry) => ({
            ...enquiry,
            offers: offers.filter((offer) => offer.enquiryId === enquiry.id),
        }));

    return (
        <PlatformScreens.EnquiryList
            enquiries={enquiriesWithOffers}
            columns={columns}
            title={title}
            filters={filters}
        />
    );
};

const tableColumns = [
    Enquiry.TableColumns.Enquiry.enquiryIdWithProperties,
    Enquiry.TableColumns.Enquiry.clientPublishedToMarket,
    Enquiry.TableColumns.Enquiry.clientPublishedToValuerPanel,
    Enquiry.TableColumns.Enquiry.estPortfolioValueEnquiry,
    Enquiry.TableColumns.Enquiry.createdAt,
    Enquiry.TableColumns.EnquiryWithOffer.lastOffer,
    Enquiry.TableColumns.Enquiry.expectedDeliveryDate,
    Enquiry.TableColumns.EnquiryWithOffer.activeOffers,
    Enquiry.TableColumns.EnquiryWithOffer.bestDelivery,
    Enquiry.TableColumns.EnquiryWithOffer.bestOffer,
];

export { EnquiryListLoader as ClientEnquiryList };
