import { Box, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../../common/components/LoadingSpinner';
import { useNotificationList, useNotificationMarkAsRead } from '../../../utils/api/hooks';
import PageWithTitleLayout from '../../../common/components/display/PageWithTitleLayout';
import WvwVStack from '../../../common/components/display/WvwVStack';
import { ContentBox, LabelAndData, NewIndicator } from '../../../common/components/display';
import { WVWTitle, WvwText } from '../../../common/components/typography';

const Notifications = () => {
    const { t } = useTranslation(['account', 'common']);

    const navigate = useNavigate();

    const {
        notificationList,
        error,
        isLoading,
    } = useNotificationList(1000);

    const { update: markAsRead } = useNotificationMarkAsRead({
        onSuccess: response => navigate(response.data.actionLink || '/dashboard'),
    });

    return (
        <PageWithTitleLayout
            allowUnapprovedUser
            title={t('notifications.sectionTitle', { ns: 'common' })}
        >
            <>
                {error && (
                    <LabelAndData
                        label={t('common:error.weAreSorry')}
                        data={t('common:error.notifications.loadingFailed')}
                    />
                )}

                {isLoading && <LoadingSpinner />}

                {notificationList?.length === 0 && (
                    <WvwText>
                        {t('notifications.noUnreads', { ns: 'common' })}
                    </WvwText>
                )}

                <WvwVStack>
                    {notificationList?.map(notification => {
                        const { title, read } = notification;
                        const titleColor = read ? 'wvwGrey60' : 'wvwYellow';

                        return (
                            <Box
                                key={notification.id}
                                as="button"
                                onClick={() => markAsRead(notification.id)}
                            >
                                <ContentBox color="white">
                                    <WvwVStack>
                                        <Flex
                                            w="100%"
                                            justifyContent="space-between"
                                        >
                                            <WVWTitle
                                                level="3"
                                                content={title}
                                                color={titleColor}
                                            />

                                            {!read && <NewIndicator />}
                                        </Flex>

                                        <WvwText>
                                            {notification.message}
                                        </WvwText>
                                    </WvwVStack>
                                </ContentBox>
                            </Box>
                        );
                    })}
                </WvwVStack>
            </>
        </PageWithTitleLayout>
    );
};

export default Notifications;
