import {
    RadioGroup,
    Text,
    Radio,
    VStack,
    Flex,
    Tooltip,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { WvwText } from '../components/typography';

type PropTypes = {
    name: string,
    width?: string,
    onChange?: (value: string, field: FieldInputProps<any>) => void,
    options: {
        label: string;
        description?: string;
        value: string;
        tooltip?: string;
    }[];
};

const FormikRadioGroup = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        width = '100%',
        options,
        onChange = () => { },
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <RadioGroup
            variant="filled"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            onChange={value => {
                field.onChange({ target: { name, value } });

                onChange?.(value, field);
            }}
            value={field.value}
            width="100%"
        >
            <Flex
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                width="100%"
                flexWrap="wrap"
                gap={2}
            >
                {options.map(({ label, description, value, tooltip }) => (
                    <Radio
                        key={value}
                        width={width}
                        minWidth="10rem"
                        maxWidth="24%"
                        value={value}
                        fontWeight={value}
                        variant="outline"
                    >
                        <Tooltip
                            key={value}
                            label={tooltip}
                            aria-label={label}
                        >
                            <VStack
                                align="left"
                                spacing="0"
                            >
                                <WvwText bold>
                                    {label}
                                </WvwText>

                                {description && (
                                    <WvwText>
                                        {description}
                                    </WvwText>
                                )}
                            </VStack>
                        </Tooltip>
                    </Radio>
                ))}
            </Flex>

            {
                (meta.touched && meta.error)
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                )
            }
        </RadioGroup>
    );
};

export default FormikRadioGroup;
