import useMe from "../../utils/api/hooks/useMe";
import useMyEntity from "../../utils/api/hooks/useMyEntity";

const useRole = () => {
    const { data: user, isLoading: isLoadingMe } = useMe();

    const { data: entity, isLoading: isLoadingEntity } = useMyEntity();

    const roleLoading = isLoadingMe || isLoadingEntity;

    const userIsApproved = !isLoadingMe && user?.systemAccess === true;

    const userIsSystemAdmin = !isLoadingMe && user?.systemAdmin === true;

    const userIsCompanyAdmin = !isLoadingMe && user?.role === "ADMIN";
    const userIsUser = !isLoadingMe && user?.role === "USER";
    const userIsAssistant = !isLoadingMe && user?.role === "ASSISTANT";

    const userIsCompany =
        !isLoadingEntity && entity?.companyOrIndividual === "COMPANY";
    const userIsIndividual =
        !isLoadingEntity && entity?.companyOrIndividual === "INDIVIDUAL";

    const userIsClient = !isLoadingEntity && entity?.accountType === "CLIENT";
    const userIsValuer = !isLoadingEntity && entity?.accountType === "VALUER";

    return {
        roleLoading,

        userIsApproved,

        userIsSystemAdmin,

        userIsCompanyAdmin,
        userIsUser,
        userIsAssistant,

        userIsCompany,
        userIsIndividual,

        userIsClient,
        userIsValuer,
    };
};

export default useRole;
