import { useDisclosure } from "@chakra-ui/react";
import {
    useEntityRemoveMeFromPanel,
    useMyValuerPanels,
} from "../../../../../../../utils/api/hooks";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../../common/hooks/useToast";

type PropTypes = {
    inviteId: number;
};

const useCustomersLeave = (props: PropTypes) => {
    const { inviteId } = props;

    const t = useSmartTranslation();

    const { createToast } = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { refresh: refreshValuerPanels } = useMyValuerPanels();

    const { update: updateRemoveMe } = useEntityRemoveMeFromPanel({
        onSuccess: () => {
            refreshValuerPanels();
        },
        onError: (err) => {
            createToast({
                description: err.message,
                status: "error",
            });
        },
    });

    const leavePanelModal = (
        <ConfirmationModal
            confirmationButtonLabel={t("common.button.confirm")}
            confirmationButtonVariant="negative"
            isOpen={isOpen}
            title={t("account.customerPanelDetails.leavePanel.title")}
            blurb={t("account.customerPanelDetails.leavePanel.prompt")}
            onClose={onClose}
            onConfirmation={() => {
                updateRemoveMe(inviteId);
                onClose();
            }}
        />
    );

    return {
        leavePanelModal,
        isOpenLeavePanelModal: isOpen,
        openLeavePanelModal: onOpen,
    };
};

export default useCustomersLeave;
