import { useDisclosure } from "@chakra-ui/react";
import { UserType } from "../../../../types";
import {
    useSuspendAccount,
    useUsersByEntity,
} from "../../../../utils/api/hooks";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type TeamInviteRemoveProps = {
    user: UserType;
    removeSuccess: () => void;
};

const useTeamMemberRemove = (props: TeamInviteRemoveProps) => {
    const {
        user: { id: userId, entityId },
        removeSuccess,
    } = props;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { refresh: refreshUsers } = useUsersByEntity(entityId);

    const { update: suspendAccount } = useSuspendAccount({
        entityId: entityId,
        onSuccess: () => {
            onClose();
            refreshUsers();
            removeSuccess();
        },
    });

    const removeMemberModal = (
        <ConfirmationModal
            confirmationButtonLabel={t("common.button.confirm")}
            confirmationButtonVariant="negative"
            isOpen={isOpen}
            title={t("account.teamDetails.revokeAccess.title")}
            blurb={t("account.teamDetails.revokeAccess.text")}
            onClose={onClose}
            onConfirmation={() => {
                suspendAccount({
                    entityId,
                    targetUserId: userId,
                });
            }}
        />
    );

    return {
        removeMemberModal,
        isOpenRemoveMemberModal: isOpen,
        openRemoveMemberModal: onOpen,
    };
};

export default useTeamMemberRemove;
