export const profileTranslations = {
    de: {
        heading: {
            contactDetails: "Ihre Kontaktdaten",
            officeLocation: "Arbeitsstandort",
            operationalArea: "Einsatzgebiet",
            valuationStandards: "Bewertungsstandards und Zertifikate",
            valuationDetails: "Details zum Bewertungsdienst",
            edit: "Profildetails bearbeiten",
            personalQualifications: "Persönliche Qualifikationen",
            otherValuationStandards: "Andere Bewertungsstandards",
            specificValuationScopes: "Spezielle Bewertungsumfänge",
            valuationOccasions: "Bewertungsanlässe",
            valuationScope: "Bewertungsumfänge",
            otherQualifications: "Weitere Qualifikationen",
            otherValuationScopes: "Sondergutachten",
            otherValuationOccasions: "Andere Bewertungsanlässe",
            propertyTypes: "Immobilienarten",
        },
        subHeading: {
            membershipsAndQualifications:
                "Wählen Sie diejenigen Standards aus, entsprechend derer Sie arbeiten und Wertgutachten erstellen. Laden Sie entsprechende Zertifikate und Nachweise hoch.",
            otherValuationStandards:
                "Geben Sie eine Beschreibung oder einen Namen für andere Bewertungsstandards an.",
            workLocation:
                "Die Adresse des Ortes an dem Sie regelmäßig geschäftlich tätig sind, z.B. Ihre Büro-Adresse",
            operationalArea:
                "Maximaler Anfahrtsweg von Ihrem Arbeitsort zu einem Bewertungsobjekt, für das Sie Aufträge annehmen möchten?",
            valuationScopes:
                "Wählen Sie mindestens einen Bewertungsumfang aus, den Sie bedienen.",
            otherValuationScopes:
                "Benennen oder umschreiben Sie Sonderumfänge, die Sie anbieten.",
            specificValuationScopes:
                "Wählen Sie optional die von Ihnen betreuten Sonderbewertungsumfänge aus.",
            valuationOccasions:
                "Wählen Sie alle Anlässe aus, für die Sie Wertgutachten anbieten.",
            propertyTypes:
                "Wählen Sie alle Immobilientypen aus, die Sie bewerten können.",
        },
        position: {
            at: "{{position}} bei {{company}}",
        },
        profileDetails: {
            doesValuations: "Fertigt Gutachten an",
            email: "E-Mail",
            faxNumber: "Faxnummer",
            location: "Adresse",
            mobileNumber: "Mobilnummer",
            officeFaxNumber: "Büro-Faxnummer",
            officeTelNumber: "Büro-Telefonnummer",
            roleInCompany: "Rolle im Unternehmen",
            telNumber: "Telefonnummer",
            firstName: "Vorname",
            lastName: "Nachname",
            dialCode: "Vorwahl",
            profilePicture: "Profilbild",
            profilePictureEditDescription:
                "Laden Sie Ihr Profilbild hoch, damit es neben Ihren Unternehmensdaten auf der Plattform angezeigt wird. Ein gut gewähltes Bild kann das Vertrauen potenzieller Kunden stärken.",
            profilePictureDescription: "Hier sehen Sie, wie Ihr Profil auf der WBW Plattform Ihren Kunden, Mitarbeitern und Kollegen präsentiert wird.",
            street: "Straße",
            postalCode: "Postleitzahl",
            city: "Stadt",
            country: "Land",
            address: "Adresse",
            valuerMaxOperationDistance:
                "Maximale Wegstrecke zum Bewertungsobjekt",
        },
        membershipsAndQualifications: {
            certificate_other: "Bescheinigungen",
            noCertificate: "Keine Bescheinigung.",
            otherCertificatesDescription: "Hochgeladene Nachweise dienen der Transparenz und schaffen Vertrauen. Sie können damit die Sichtbarkeit Ihres Angebots steigern.",
            edit: {
                addOtherValuationOccasion:
                    "Fügen Sie einen weiteren Bewertungsanlass hinzu",
                addValuationStandard: "Bewertungsstandard hinzufügen",
                addValuationScope: "Bewertungsumfang hinzufügen",
            },
            uploadCertificate: "Bescheinigungen Hochladen",
        },
        valuationCompetence: {
            propertyTypes: "Immobilienarten",
            valuationScope: "Bewertungsumfänge",
            specificValuationScopes: "Spezielle Bewertungsumfänge",
            otherValuationScopes: "Andere Bewertungsumfänge",
            valuationOccasions: "Bewertungsanlässe",
            otherValuationOccasions: "Sonstige Bewertungsanlässe",
        },
        fieldNotProvided: {
            contactPerson: "Ansprechpartner nicht angegeben",
            email: 'Keine $t(Email,  { "ns": "common" }) angegeben',
            faxNumber: "Keine Faxnummer angegeben",
            location: "Kein Standort angegeben",
            mobileNumber: "Keine Mobilnummer angegeben",
            numberOfPartners: "Keine Teilhaber angegeben",
            officeFaxNumber: "Keine Büro-Faxnummer angegeben",
            officeTelNumber: "Keine Büro-Telefonnummer angegeben",
            otherSpecialReports: "Keine sonstigen Sondergutachten angegeben",
            phoneNumber: "Keine Telefonnummer angegeben",
            propertyTypes: "Keine Immobilienkategorien angegeben",
            roleInCompany: "Keine Rolle im Unternehmen angegeben",
            specialReports: "Keine Sondergutachten angegeben",
            telNumber: "Keine Telefonnummer angegeben",
            valuerMaxOperationDistance:
                "Keine maximale Wegstrecke für Begutachtungen angegeben",
            website: "Keine Webseite angegeben",
            yearsOfExperience: "Keine Erfahrung (in Jahren) angegeben",
            vatNumber:
                "Keine Umsatzsteueridentifikationsnummer (USt-IdNr.) angegeben",
            companyName: "Kein Firmenname angegeben",
        },
        userInformation: {
            userUpdated: "Nutzer aktualisiert",
        },
        profileCompletionWizard: {
            useCompanyDetails: "Verwenden Sie Firmendaten",
        },
    },
    en: {
        heading: {
            contactDetails: "Profile information",
            officeLocation: "Office & operation area",
            operationalArea: "Operational Area",
            valuationStandards: "Valuation Standards and Certificates",
            valuationDetails: "Valuation Details",
            edit: "Edit Profile Details",
            personalQualifications: "Personal qualifications",
            otherValuationStandards: "Other Valuation Standards",
            specificValuationScopes: "Specific Valuation Scopes",
            valuationOccasions: "Valuation occasions",
            valuationScope: "Valuation scope",
            otherQualifications: "Other Qualifications",
            otherValuationScopes: "Other Valuation Scopes",
            otherValuationOccasions: "Other Valuation Occasions",
            propertyTypes: "Property types",
        },
        subHeading: {
            membershipsAndQualifications:
                "Choose the standards according to which you operate and generate reports and upload appropriate certificates document or scan",
            otherValuationStandards:
                "Provide description or name of other valuation standards.",
            workLocation:
                "The main address of the place where you conduct your business on a daily basis, eg. office address.",
            operationalArea:
                "Maximum travel distance from your work location to a property that you are willing to accept orders for?",
            valuationScopes: "Select at least one valuation scope you service.",
            otherValuationScopes:
                "Provide description or name of other valuation scopes.",
            specificValuationScopes:
                "Optionally select any specific valuation scope you service.",
            valuationOccasions:
                "Select all occasions for which you provide valuations.",
            propertyTypes: "Select all property types you are able to value.",
        },
        position: {
            at: "{{position}} at {{company}}",
        },
        profileDetails: {
            doesValuations: "Does Valuations",
            email: "Email",
            faxNumber: "Fax Number",
            location: "Address",
            mobileNumber: "Mobile Number",
            officeFaxNumber: "Office Fax Number",
            officeTelNumber: "Office Telephone Number",
            roleInCompany: "Role in Company",
            telNumber: "Telephone Number",
            firstName: "First Name",
            lastName: "Last Name",
            dialCode: "Dial Code",
            profilePicture: "Profile Picture",
            profilePictureEditDescription:
                "Upload your profile picture to be displayed alongside your company details across the platform. A well-chosen image can boost trust among potential clients.",
            profilePictureDescription: "This is how your profile looks like on WVW platform, its visible for your clients and company team members.",
            street: "Street",
            postalCode: "Postal Code",
            city: "City",
            country: "Country",
            address: "Address",
            valuerMaxOperationDistance: "Maximum Distance to Customer Property",
        },
        membershipsAndQualifications: {
            certificate_other: "Certificates",
            noCertificate: "No Certificate uploded.",
            otherCertificatesDescription: "Uploading certificates can boost your offer's visibility by enhancing transparency and trust.",
            edit: {
                addOtherValuationOccasion: "Add another valuation occasion",
                addValuationStandard: "Add a valuation standard",
                addValuationScope: "Add a valuation scope",
            },
            uploadCertificate: "Upload Certificate",
        },
        valuationCompetence: {
            propertyTypes: "Property Types",
            valuationScope: "Valuation Scope",
            specificValuationScopes: "Specific Valuation Scopes",
            otherValuationScopes: "Other Valuation Scopes",
            valuationOccasions: "Valuation Occasions",
            otherValuationOccasions: "Other Valuation Occasions",
        },
        fieldNotProvided: {
            contactPerson: "Contact person not provided",
            email: 'No $t(Email,  { "ns": "common" }) provided',
            faxNumber: "No fax number provided",
            location: "No location provided",
            mobileNumber: "No mobile number provided",
            numberOfPartners: "No partners provided",
            officeFaxNumber: "No office fax number provided",
            officeTelNumber: "No office telephone number provided",
            otherSpecialReports: "No other special reports provided",
            phoneNumber: "No phone number provided",
            propertyTypes: "No property types provided",
            roleInCompany: "No role in company provided",
            specialReports: "No special reports provided",
            telNumber: "No telephone number provided",
            valuerMaxOperationDistance:
                "No maximum operation distance provided",
            website: "No website provided",
            yearsOfExperience: "No years of experience provided",
            vatNumber: "No VAT number provided",
            companyName: "No company name provided",
        },
        userInformation: {
            userUpdated: "User updated",
        },
        profileCompletionWizard: {
            useCompanyDetails: "Use company details",
        },
    },
};
