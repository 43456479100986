import {
    Box,
    Button,
    Divider,
    Flex,
    Heading,
    HStack,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text,
    useDisclosure,
    VStack
} from '@chakra-ui/react';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import ProfilePictureDefault from '../../../../../common/components/display/ProfilePictureDefault';
import PublicEntitySlider from '../../../../../common/components/entity-details/PublicEntitySlider';
import { WvwButton } from '../../../../../common/components/inputs';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import PublicCompanyProfilePicture from '../../../../../common/components/PublicCompanyProfilePicture';
import ValuerInviteForm from '../../../../../common/components/ValuerInviteForm';
import WvwDeleteIconButton from '../../../../../common/components/WvwDeleteIconButton';
import { useRole } from '../../../../../common/hooks';
import useToast from '../../../../../ui/v2/common/hooks/useToast';
import {
    useEntityPanelValuers,
    useEntityRemovePanelValuer,
    useMyEntity,
    useUsersByEntity,
    useValuerInvitesDelete,
    useValuerInvitesSent,
} from '../../../../../utils/api/hooks';
import ClientRecievedClientInvites from './ClientRecievedClientInvites';

type EntityDrawerProps = {
    isOpen: boolean;
    entityId: number;
};

const CompanyValuers = () => {
    const { t } = useTranslation(['account', 'common']);

    const {
        isOpen: isAddOpen,
        onOpen: onAddOpen,
        onClose: onAddClose,
    } = useDisclosure();

    const {
        isOpen: isRemoveOpen,
        onOpen: onRemoveOpen,
        onClose: onRemoveClose,
    } = useDisclosure();

    const {
        isOpen: isCancelOpen,
        onOpen: onCancelOpen,
        onClose: onCancelClose,
    } = useDisclosure();

    const [openDrawer, setOpenDrawer] = useState<EntityDrawerProps>({
        isOpen: false,
        entityId: 0,
    });

    const [selectedValuer, setSelectedValuer] = useState<string | number>('');
    const [selectedInvite, setSelectedInvite] = useState<string | number>('');

    const { createToast } = useToast();

    const { userIsCompanyAdmin: userIsAdmin, userIsUser, roleLoading } = useRole();

    const {
        data: entity,
    } = useMyEntity();

    const {
        usersList = [],
    } = useUsersByEntity(entity?.id);

    const adminEmails = usersList.filter(u => u.role === 'ADMIN').map(u => u.email);

    const {
        valuerList,
        isLoading,
        error,
        refresh: panelValuerRefresh,
    } = useEntityPanelValuers();

    const {
        data: pendingInvites,
        isLoading: loadingSent,
        error: errorSent,
        refresh,
    } = useValuerInvitesSent();

    const {
        update: updateRemoveInvite,
    } = useValuerInvitesDelete({
        onSuccess: () => {
            refresh();
            createToast({
                description: t('success.inviteCancelled', { ns: 'common' }),
                status: 'success',
            });
        },
        onError: () => {
            createToast({
                description: t('error.somethingWrong', { ns: 'common' }),
                status: 'error',
            });
        },
    });

    const { update: updateRemoveValuer } = useEntityRemovePanelValuer({
        onSuccess: () => {
            panelValuerRefresh();
        },
        onError: () => {
            createToast({
                description: t('error.somethingWrong', { ns: 'common' }),
                status: 'error',
            });
        },
    });

    return (
        <VStack
            w="100%"
            align="left"
            paddingInline=".5rem"
            paddingBlock="2rem"
            spacing="10"
        >
            <Flex>
                <VStack
                    alignSelf="flex-start"
                    spacing="4"
                    w="25%"
                >
                    <Heading
                        as="h4"
                        size="md"
                        fontWeight="light"
                        w="100%"
                    >
                        {t('valuerPanelDetails.sectionTitle')}
                    </Heading>

                    <Text
                        color="wvwGrey"
                    >
                        {t('valuerPanelDetails.blurb')}
                    </Text>

                    {roleLoading && (
                        <LoadingSpinner />
                    )}

                    {userIsAdmin && (
                        <Button
                            fontWeight="light"
                            onClick={onAddOpen}
                            variant="primary"
                            w="fit-content"
                            minW="9rem"
                        >
                            {t('valuerPanelDetails.button.addValuer')}
                        </Button>
                    )}

                    {userIsUser && (
                        <Link
                            href={`mailto:${adminEmails}?subject=${t('valuerPanelDetails.button.requestValuerInvite', { ns: 'account' })}`}
                        >
                            <Button
                                fontWeight="light"
                                variant="primaryYellow"
                                w="fit"
                            >
                                {t('valuerPanelDetails.button.requestValuerInvite', { ns: 'account' })}
                            </Button>
                        </Link>
                    )}

                    <Modal isOpen={isAddOpen} onClose={onAddClose}>
                        <ModalOverlay />

                        <ModalContent minWidth="2xl">
                            <ModalHeader
                                color="black"
                            >
                                {t('inviteNewValuer')}
                            </ModalHeader>

                            <ModalCloseButton />

                            <ModalBody paddingBottom="1.5rem">
                                <ValuerInviteForm
                                    onInviteSuccess={onAddClose}
                                />
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                </VStack>

                <Spacer />

                <VStack w="70%" paddingTop="1.5rem">
                    {isLoading && (
                        <LoadingSpinner />
                    )}

                    {error !== null && (
                        <Text>
                            {t('vListError')}
                        </Text>
                    )}

                    {valuerList?.length === 0 && (
                        <VStack w="20rem">
                            <Text textAlign="center">
                                {userIsAdmin ? t('addValuers') : t('noValuers')}
                            </Text>
                        </VStack>
                    )}

                    {valuerList?.map(valuer => (
                        <Fragment key={valuer.id}>
                            <Flex
                                alignItems="center"
                                w="100%"
                                height="3.5rem"
                                border="1px solid"
                                borderColor="wvwGrey40"
                                bg="wvwGrey05"
                                borderRadius="8"
                                paddingInline="1rem"
                            >
                                <HStack>
                                    <PublicCompanyProfilePicture
                                        size="2.5rem"
                                        companyId={valuer.id}
                                    />

                                    <Box paddingBlock="1rem">
                                        <Text
                                            color="wvwGrey80"
                                            fontWeight="medium"
                                            _hover={{ color: 'wvwYellow', cursor: 'pointer' }}
                                            onClick={() => {
                                                setOpenDrawer({
                                                    isOpen: true,
                                                    entityId: valuer.id,
                                                });
                                            }}
                                        >
                                            {`${valuer.name}`}
                                        </Text>
                                    </Box>
                                </HStack>

                                <Spacer />

                                {userIsAdmin && (
                                    <WvwButton
                                        variant="danger"
                                        size="sm"
                                        content={t('valuerPanelDetails.button.removeValuer')}
                                        onClick={() => {
                                            onRemoveOpen(); setSelectedValuer(valuer.id);
                                        }}
                                    />
                                )}
                            </Flex>
                        </Fragment>
                    ))}
                </VStack>
            </Flex>

            <ConfirmationModal
                cancelButton={t('button.cancel', { ns: 'common' })}
                continueButton={t('button.remove', { ns: 'common' })}
                isOpen={isRemoveOpen}
                title={t('warning.removeValuer.title', { ns: 'common' })}
                content={t('warning.removeValuer.prompt', { ns: 'common' })}
                onCancel={onRemoveClose}
                onContinue={() => {
                    updateRemoveValuer(selectedValuer);
                    onRemoveClose();
                }}
            />

            {userIsAdmin && (
                <>
                    <Divider />

                    <Flex>
                        <VStack
                            align="left"
                            w="25%"
                            alignSelf="flex-start"
                            spacing="6"
                        >
                            <Box>
                                <Heading
                                    as="h4"
                                    size="md"
                                    fontWeight="light"
                                >
                                    {t('valuerInvites.sectionTitle')}
                                </Heading>

                                <Text
                                    color="wvwGrey"
                                    paddingTop="1rem"
                                >
                                    {t('valuerInvites.blurb')}
                                </Text>

                            </Box>
                        </VStack>

                        <Spacer />

                        <VStack
                            w="70%"
                            paddingTop="1.5rem"
                        >

                            {loadingSent && (
                                <LoadingSpinner />
                            )}

                            {errorSent !== null && (
                                <Text>
                                    {t('pvListError')}
                                </Text>
                            )}

                            {pendingInvites?.length === 0 && (
                                <Text>
                                    {t('valuerInvites.noPendingInvitesMsg')}
                                </Text>
                            )}

                            {pendingInvites?.map(invite => (
                                <Flex
                                    w="100%"
                                    height="3.5rem"
                                    border="1px solid"
                                    borderColor="wvwGrey40"
                                    bg="wvwGrey05"
                                    borderRadius="8"
                                    paddingInline="1rem"
                                    key={invite.id}
                                >
                                    <HStack>
                                        <ProfilePictureDefault
                                            accountType="VALUER"
                                            userType="ENTITY"
                                            size="2.5rem"
                                        />

                                        <Box paddingBlock="1rem">
                                            <Text
                                                color="wvwGrey80"
                                                fontWeight="medium"
                                            >
                                                {invite.valuerCompanyName}
                                            </Text>
                                        </Box>

                                        <Text color="wvwGrey40">
                                            {invite.valuerEmail}
                                        </Text>
                                    </HStack>

                                    <Spacer />

                                    <Box
                                        alignContent="center"
                                    >
                                        <WvwDeleteIconButton
                                            color="black"
                                            onClick={() => {
                                                onCancelOpen();
                                                setSelectedInvite(invite.id);
                                            }}
                                        />
                                    </Box>
                                </Flex>
                            ))}
                        </VStack>
                    </Flex>

                    <ConfirmationModal
                        cancelButton={t('button.close', { ns: 'common' })}
                        continueButton={t('button.continue', { ns: 'common' })}
                        isOpen={isCancelOpen}
                        title={t('warning.cancelInvitation.title', { ns: 'common' })}
                        content={t('warning.cancelInvitation.prompt', { ns: 'common' })}
                        onCancel={onCancelClose}
                        onContinue={() => {
                            updateRemoveInvite(selectedInvite);
                            onCancelClose();
                        }}
                    />

                    <>
                        <Divider />

                        <Flex>
                            <VStack
                                align="left"
                                w="25%"
                                alignSelf="flex-start"
                                spacing="6"
                            >
                                <Box>
                                    <Heading
                                        as="h4"
                                        size="md"
                                        fontWeight="light"
                                    >
                                        {t('invitesByValuers.sectionTitle')}
                                    </Heading>

                                    <Text
                                        color="wvwGrey"
                                        paddingTop="1rem"
                                    >
                                        {t('invitesByValuers.blurb')}
                                    </Text>
                                </Box>
                            </VStack>

                            <Spacer />

                            <ClientRecievedClientInvites />
                        </Flex>
                    </>
                </>
            )}

            <ConfirmationModal
                cancelButton={t('button.close', { ns: 'common' })}
                continueButton={t('button.continue', { ns: 'common' })}
                isOpen={isCancelOpen}
                title={t('warning.cancelInvitation.title', { ns: 'common' })}
                content={t('warning.cancelInvitation.prompt', { ns: 'common' })}
                onCancel={onCancelClose}
                onContinue={() => {
                    updateRemoveInvite(selectedInvite);
                    onCancelClose();
                }}
            />

            {openDrawer.isOpen && (
                <PublicEntitySlider
                    isOpen={openDrawer.isOpen}
                    onClose={() => setOpenDrawer({
                        isOpen: false,
                        entityId: 0,
                    })}
                    entityId={openDrawer.entityId}
                />
            )}
        </VStack>
    );
};

export default CompanyValuers;
