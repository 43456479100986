import { HStack, VStack, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import WvwIcon, { WvwIconKeys } from "./WvwIcon";

type PropTypes = {
    icon?: WvwIconKeys;
    label?: string; // The label
    input: ReactNode; // The input, such as a text input or a select input
};

const LabelAndInput = (props: PropTypes) => {
    const { label, input, icon } = props;

    return (
        <VStack
            align="start"
            spacing="2"
            w="100%"
        >
            {label && (
                <HStack>
                    {icon && <WvwIcon icon={icon} />}
                    <Text fontSize={"sm"}>{label}</Text>
                </HStack>
            )}

            {input}
        </VStack>
    );
};

export default LabelAndInput;
