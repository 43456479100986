import { FilterFunctionType, FilterPropsType } from "./Filter";
import { FilterInput } from "./FilterInput";

type PropTypes<T> = FilterPropsType<T> & {
    filterGroupId: string;
    placeholder: string;
    searchFn: (data: T, search: string) => boolean;
};

const BaseFilterInput = <T,>(props: PropTypes<T>) => {
    const {
        filterGroupId,
        placeholder,
        searchFn,
        activateFilter: activateFilterGroup,
        deactivateFilter,
        activeFilters,
    } = props;

    const activateFilter = (
        value: string,
        filter: FilterFunctionType<T>
    ): void => {
        activateFilterGroup({
            id: filterGroupId,
            filter,
            value,
        });
    };

    const activeFilterValue = activeFilters.find(
        ({ id }) => id === filterGroupId
    )?.value;

    return (
        <FilterInput
            placeholder={placeholder}
            value={activeFilterValue}
            onChange={(value) => {
                const search = value.toLowerCase();

                if (!search) return deactivateFilter(filterGroupId);

                return activateFilter(value, (data) =>
                    searchFn(data, search)
                );
            }}
        />
    );
};

export default BaseFilterInput;
