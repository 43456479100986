import { useNavigate } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';
import WvwButton from './WvwButton';
import useSmartTranslation from '../../hooks/useSmartTranslation';

type PropTypes = {
    to?: string;
    onClick?: () => void;
    width?: string;
    block?: boolean
};

const WvwButtonBack = (props: PropTypes) => {
    const {
        to = '../',
        onClick,
        width = '15rem',
        block = false,
    } = props;

    const navigate = useNavigate();
    const t = useSmartTranslation('common');

    return (
        <WvwButton
            icon={<RiArrowGoBackLine />}
            variant="outline"
            width={width}
            block={block}
            onClick={() => {
                if (onClick) {
                    onClick();
                } else {
                    navigate(to);
                }
            }}
            content={t('button.back')}
        />
    );
};

export default WvwButtonBack;
