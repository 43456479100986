import { CompanySetAccessDto } from '../dto';
import { companySetAccess } from '../endpoints';
import useUsersByEntity from './useUsersByEntity';
import useMutationHook from './useMutationHook';
import { useSmartTranslation } from '../../../ui/v2/common/hooks/useSmartTranslation';

type ParamsType = {
    entityId: number | string;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useCompanySetAccess = (params: ParamsType) => {
    const {
        entityId,
        onSuccess,
        onError,
    } = params;

    const t = useSmartTranslation();

    const { refresh: refreshUsers } = useUsersByEntity(Number(entityId));

    const mutation = useMutationHook({
        mutationFunction: (dto: CompanySetAccessDto) => companySetAccess(dto),
        refreshes: [refreshUsers],
        successMessage: t('profile.userInformation.userUpdated'),
        onSuccess,
        onError,
    });

    return mutation;
};

export default useCompanySetAccess;
