import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    VStack
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
    FormikForm,
    FormikInput,
    FormikTextarea,
} from '../../../../../common/forms';
import useToast from '../../../../../ui/v2/common/hooks/useToast';
import { useEmailEmailEnquiryLink } from '../../../../../utils/api/hooks';

const validationSchema = Yup.object().shape({
    to: Yup.string().required('errors.required'),
    name: Yup.string().required('errors.required'),
});

const EnquiryEmailSlider = () => {
    const navigate = useNavigate();

    const { isCompanyOrIndividual = 'company'} = useParams<{ isCompanyOrIndividual?: 'company' | 'individual' }>();

    const { t } = useTranslation(['account', 'common', 'formik']);

    const { createToast } = useToast();

    const { send } = useEmailEmailEnquiryLink({
        onSuccess: () => {
            navigate('../', { replace: true });
            createToast({
                description: t('integration.enquiryEmailSlider.emailSuccess', { ns: 'account' }),
                status: 'success',
            });
        },
        onError: error => {
            createToast({
                description: error.message,
                status: 'error',
            });
        },
    });

    return (
        <Drawer
            isOpen
            placement="right"
            onClose={() => navigate('../', { replace: true })}
            size="md"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader
                    color="wvwGreen"
                >
                    {t('integration.enquiryEmailSlider.title', { ns: 'account' })}
                </DrawerHeader>

                <FormikForm
                    initialValues={{
                        to: '',
                        name: '',
                        message: '',
                    }}
                    onSubmit={values => {
                        send({
                            isCompanyOrIndividual,
                            email: values.to,
                            name: values.name,
                            message: values.message,
                        });
                    }}
                    validationSchema={validationSchema}
                >
                    <DrawerBody>

                        <VStack
                            spacing="3"
                            align="left"
                        >
                            <FormikInput
                                label={t('recipient', { ns: 'common' })}
                                name="to"
                                placeholder={t('to', { ns: 'common' })}
                                type="email"
                            />
                            <FormikInput
                                label={t('from', { ns: 'common' })}
                                name="name"
                                placeholder={t('fieldPlaceholder.name', { ns: 'common' })}
                            />

                            <FormikTextarea
                                label={t('message', { ns: 'common' })}
                                name="message"
                                placeholder={t('message', { ns: 'common' })}
                            />
                        </VStack>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button
                            type="submit"
                            variant="primaryYellow"
                        >
                            {t('button.send', { ns: 'common' })}
                        </Button>
                    </DrawerFooter>
                </FormikForm>
            </DrawerContent>
        </Drawer>
    );
};

export default EnquiryEmailSlider;
