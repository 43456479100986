import { AddIcon } from '@chakra-ui/icons';
import {
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineLocationOn } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import WvwAccordionButton from '../../../../../common/components/display/WvwAccordionButton';
import { WvwButton, WvwLinkButton } from '../../../../../common/components/inputs';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import { WVWTitle } from '../../../../../common/components/typography';
import { useRole } from '../../../../../common/hooks';
import { CaseType, InspectionType, PropertyType } from '../../../../../types';
import { useInspectionCreate, useInspectionRejectAllTentativeDates } from '../../../../../utils/api/hooks';
import InspectionConfirmationModal from './InspectionConfirmationModal';
import InspectionDetailsItem from './InspectionDetailsItem';
import InspectionStatusBadge from './InspectionStatusBadge';

type PropTypes = {
    caseData: CaseType;
    inspectionList: InspectionType[];
    property: PropertyType;
    onClick: (property: PropertyType) => void;
};

const PropertyAccordionItem = (props: PropTypes) => {
    const {
        caseData,
        inspectionList: allInspections,
        property,
        onClick,
    } = props;

    const { t } = useTranslation('inspection');
    const { caseId } = useParams();
    const { userIsValuer, userIsClient, roleLoading } = useRole();

    const [rejectAllOpen, setRejectAllOpen] = useState(false);

    const { update } = useInspectionCreate({ caseId: Number(caseId) });

    const { update: rejectAll } = useInspectionRejectAllTentativeDates({
        caseId: Number(caseId),
        onSuccess: () => setRejectAllOpen(false),
    });

    const inspections = allInspections
        .filter(inspection => inspection.propertyId === property.id)
        .sort((a, b) => b.id - a.id);

    let status;
    const statuses = allInspections.map(i => i.phase);

    const clientPropertyAccordionIsDisabled = inspections.length === 0 && userIsClient;

    if (statuses.includes('PREPARATION')) status = <InspectionStatusBadge status="noDateSuggested" />;
    else if (statuses.includes('NEGOTIATION')) status = <InspectionStatusBadge status="awaitingConfirmation" />;
    else if (statuses.includes('INSPECTION')) status = <InspectionStatusBadge status="scheduled" />;
    else if (statuses.includes('FINISHED')) status = <InspectionStatusBadge status="finished" />;
    else status = <InspectionStatusBadge status="noDateSuggested" />;

    // const tickCircle = useMemo(() => {
    //     if (inspections.length === 0) return null;
    //     if (inspections.some(i => i.phase !== 'FINISHED')) return null;

    //     return (
    //         <Box
    //             ml="1rem"
    //             w="2rem"
    //         >
    //             <CheckCircleIcon
    //                 boxSize="1.2rem"
    //                 color="green"
    //             />
    //         </Box>
    //     );
    // }, [inspections]);

    if (roleLoading) return <LoadingSpinner />;

    const addInspectionButton = (userIsClient || inspections.some(i => i.phase !== 'FINISHED')) ? null : (
        <Box
            paddingLeft="3.2rem"
            paddingBlock=".8rem"
        >
            <WvwLinkButton
                content={t('button.addInspection')}
                icon={<AddIcon />}
                onClick={() => update({
                    propertyId: property.id,
                    target: Number(caseId),
                })}
            />
        </Box>
    );

    const rejectAllButton = (userIsValuer || inspections.every(i => i.phase !== 'NEGOTIATION')) ? null : (
        <Box
            paddingRight="2rem"
        >
            <WvwButton
                content={t('button.rejectAll')}
                onClick={() => setRejectAllOpen(true)}
                size="sm"
                width="max"
                variant="danger"
            />
        </Box>
    );

    return (
        <>
            <AccordionItem
                onClick={() => onClick(property)}
            >
                <WvwAccordionButton
                    actionButton={rejectAllButton}
                    disabled={clientPropertyAccordionIsDisabled}
                    icon={(
                        <MdOutlineLocationOn
                            color="red"
                            size="24"
                        />
                    )}
                    statusBadge={status}
                    title={(
                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            w="100%"
                        >
                            <WVWTitle
                                color="black"
                                content={(
                                    t('addressOneLiner', {
                                        street: property?.street,
                                        city: property?.city,
                                        country: property?.country,
                                        postalCode: property?.postalCode,
                                        ns: 'common',
                                    })
                                )}
                                level="3"
                                normalFontWeight
                            />

                            {/* {tickCircle} */}
                        </Flex>
                    )}
                />

                {!clientPropertyAccordionIsDisabled && (
                    <AccordionPanel paddingInline="0">
                        <VStack align="start">
                            {addInspectionButton}

                            {inspections.map(inspection => (
                                <InspectionDetailsItem
                                    caseData={caseData}
                                    inspection={inspection}
                                    key={inspection.id}
                                />
                            ))}
                        </VStack>
                    </AccordionPanel>
                )}

            </AccordionItem>

            <InspectionConfirmationModal
                isOpen={rejectAllOpen}
                title={t('modal.rejectAppointments.title')}
                content={t('modal.rejectAppointments.content')}
                property={property}
                onConfirm={() => {
                    const inspection = inspections.find(i => i.phase === 'NEGOTIATION');

                    if (inspection) rejectAll(inspection.id);
                }}
                onClose={() => setRejectAllOpen(false)}
            />
        </>
    );
};

export default PropertyAccordionItem;
