import { CopyIcon } from "@chakra-ui/icons";
import {
    Button,
    Flex,
    HStack,
    Text,
    useClipboard,
    VStack
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Outlet, Link as ReactLink } from "react-router-dom";
import { EntityType } from "../../../../../../types";
import { useMyEntity } from "../../../../../../utils/api/hooks";
import { Title } from "../../../../common/components/display";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../common/hooks/useToast";
import { Section } from "../../../../layout";

const IntegrationsScreen = () => {
    const t = useSmartTranslation();

    const { onCopy, setValue, value } = useClipboard("");

    const { createToast } = useToast();

    const { data: entity, isLoading: entityLoading } = useMyEntity();

    useEffect(() => {
        if (!value) return;

        onCopy();
        createToast({
            title: t("account.integration.buttonTitle"),
            status: "success",
            duration: 3000,
        });
    }, [value]);

    if (entityLoading || !entity) {
        return <LoadingSpinner />;
    }

    const setCopiableValue = (clientType: string) => {
        setValue(
            `${window.location.origin}/direct-enquiry-form/${clientType}/${entity.identityToken}`
        );
    };

    // render

    const header = (
        <Title title={t("account.accountSectionLink.integration")} />
    );

    const privateLink = (
        <Section
            title={t("account.integration.customerTypes.private")}
            content={
                <CopyableLink
                    clientType="individual"
                    entity={entity}
                    copyLink={() => {
                        setCopiableValue("individual");
                    }}
                />
            }
        />
    );

    const institutionLink = (
        <Section
            title={t("account.integration.customerTypes.institution")}
            content={
                <CopyableLink
                    clientType="company"
                    entity={entity}
                    copyLink={() => {
                        setCopiableValue("company");
                    }}
                />
            }
        />
    );

    return (
        <VStack
            align={"stretch"}
            gap={4}
        >
            {header}
            {privateLink}
            {institutionLink}

            <Outlet />
        </VStack>
    );
};

type CopyableLinkProps = {
    clientType: string;
    entity: EntityType;
    copyLink: () => void;
};

const CopyableLink = (props: CopyableLinkProps) => {
    const { clientType, entity, copyLink } = props;

    const t = useSmartTranslation();

    return (
        <HStack>
            <Flex
                flexGrow={1}
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                padding="0.5rem 1rem"
                maxW={"calc(100% - 270px)"}
            >
                <Text
                    color="gray.400"
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                >
                    {`${window.location.origin}/direct-enquiry-form/${clientType}/${entity.identityToken}`}
                </Text>
            </Flex>
            <HStack>
                <Button
                    variant="outline"
                    colorScheme="blue"
                    leftIcon={<CopyIcon />}
                    onClick={copyLink}
                >
                    {t("account.integration.button.copyLink")}
                </Button>
                <Button
                    as={ReactLink}
                    to={`enquiry-email-link/${clientType}`}
                    variant="outline"
                    colorScheme="blue"
                >
                    {t("account.integration.button.emailLink")}
                </Button>
            </HStack>
        </HStack>
    );
};

export default IntegrationsScreen;
