import { UserInviteType } from "../../../../../../types";
import DataTable from "../../../../common/components/data-table/DataTable";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { teamColumns } from "./TeamColumns";

type TeamInvitesTableProps = {
    users: UserInviteType[];
    canRemove: boolean | undefined;
};

const TeamInvitesTable = (props: TeamInvitesTableProps) => {
    const { users, canRemove } = props;

    const t = useSmartTranslation();

    return (
        <DataTable
            fullSectionWidth
            data={users}
            columns={[
                teamColumns.user,
                teamColumns.role,
                teamColumns.email,
                teamColumns.invitedAt,
                teamColumns.status,
                ...(canRemove ? [teamColumns.removeInvite] : []),
            ]}
            noDataText={t("account.teamInvites.noPendingInvitesMsg")}
        />
    );
};

export default TeamInvitesTable;
