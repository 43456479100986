import { ChevronDownIcon } from "@chakra-ui/icons";
import {
    Button,
    Link as ChakraLink,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BsClipboard } from "react-icons/bs";
import { MdOutlineLink } from "react-icons/md";
import { Link as ReactLink, useNavigate } from "react-router-dom";
import PageWithTitleLayout from "../../../common/components/display/PageWithTitleLayout";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { useRole } from "../../../common/hooks";
import { useMyEntity } from "../../../utils/api/hooks";
import EnquiryTable from "./EnquiryTable";

const Enquiries = () => {
    const navigate = useNavigate();

    const { t } = useTranslation(["dashboard", "common", "offer"]);

    const { userIsClient, userIsValuer, roleLoading } = useRole();

    const { data: valuer, isLoading: valuerLoading } = useMyEntity();

    if (roleLoading || valuerLoading) return <LoadingSpinner />;

    return (
        <PageWithTitleLayout
            title={t("section.enquiries")}
            extra={
                <>
                    {userIsClient && (
                        <ChakraLink
                            as={ReactLink}
                            to="/create-client-enquiry"
                        >
                            <Button
                                variant="outline1"
                                paddingBlock="1.2rem"
                                borderRadius="10"
                                width="12.8rem"
                            >
                                {t("enquiriesTable.button.createNewEnquiry")}
                            </Button>
                        </ChakraLink>
                    )}

                    {userIsValuer && (
                        <Menu>
                            <MenuButton
                                as={Button}
                                variant="outline1"
                                paddingBlock="1.2rem"
                                borderRadius="10"
                                width="12.8rem"
                                rightIcon={<ChevronDownIcon />}
                            >
                                {t("enquiriesTable.button.createNewEnquiry")}
                            </MenuButton>

                            <MenuList>
                                <MenuItem
                                    paddingBlock=".8rem"
                                    onClick={() =>
                                        navigate(
                                            `/direct-enquiry-form/individual/${valuer.identityToken}`
                                        )
                                    }
                                >
                                    <BsClipboard size="1.1rem" />
                                    <Text paddingInline=".5rem">
                                        {t(
                                            "enquiriesTable.button.privateEndCustomer"
                                        )}
                                    </Text>
                                </MenuItem>

                                <MenuItem
                                    paddingBlock=".8rem"
                                    onClick={() =>
                                        navigate(
                                            `/direct-enquiry-form/company/${valuer.identityToken}`
                                        )
                                    }
                                >
                                    <BsClipboard size="1.1rem" />
                                    <Text paddingInline=".5rem">
                                        {t(
                                            "enquiriesTable.button.institutionalClient"
                                        )}
                                    </Text>
                                </MenuItem>

                                <MenuItem
                                    paddingBlock=".8rem"
                                    onClick={() =>
                                        navigate(
                                            "/dashboard/account/integration"
                                        )
                                    }
                                >
                                    <MdOutlineLink size="1.1rem" />
                                    <Text paddingInline=".5rem">
                                        {t("enquiriesTable.button.sendLink")}
                                    </Text>
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    )}
                </>
            }
        >
            <EnquiryTable />
        </PageWithTitleLayout>
    );
};

export default Enquiries;
