import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Highlight,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from "@chakra-ui/react";
import { DragEvent, useEffect, useRef, useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import * as Yup from "yup";
import { allowedFileSize } from "../../../../../common/vars/file-uploads/file-standards";
import heic2any from "heic2any";
import { getFileTypeBasedOnName } from "../../components/display/File";
import FormikForm from "../../forms/FormikForm";
import FormikInput from "../../forms/FormikInput";
import { useSmartTranslation } from "../../hooks/useSmartTranslation";
import { FileTypeIcon } from "../../icons/FileTypeIcon";

type SupportedFileType =
    | ".csv"
    | ".gif"
    | ".jpeg"
    | ".jpg"
    | ".heic"
    | ".heif"
    | ".pdf"
    | ".png"
    | ".tiff"
    | ".xls"
    | ".xlsx"
    | ".doc"
    | ".docx";

type PropTypes = {
    customUploadButton?: React.ReactNode;
    defaultFileName?: string;
    fileTypes?: SupportedFileType[];
    noRename?: boolean;
    height?: string;
    onChange: (files: File[]) => void;
};

const FileUploadInput = (props: PropTypes) => {
    const t = useSmartTranslation();

    const {
        customUploadButton,
        defaultFileName,
        fileTypes,
        height = "auto",
        noRename = false,
        onChange,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const [dragActive, setDragActive] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [files, setFiles] = useState<File[]>([]);

    const name = Math.random().toString(36).substring(2, 15);

    const checkFile = (f: File) => {
        const fileExtension =
            `.${f.name.split(".").pop()?.toLowerCase()}` || "";

        if (fileTypes && !fileTypes.some((i) => i === fileExtension)) {
            setError(t("fileUpload.fileTypeNotAllowed"));
            setFiles([]);
            return false;
        }

        if (allowedFileSize && f.size > allowedFileSize) {
            setError(t("fileUpload.fileTooLarge"));
            setFiles([]);
            return false;
        }

        setError(undefined);
        return true;
    };

    const handleDrag = (e: DragEvent<HTMLFormElement | HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (!checkFile(e.dataTransfer.files[0])) {
                return;
            }

            const fList = [];

            for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
                fList.push(e.dataTransfer.files[i]);
            }

            setFiles(fList);
        }
    };

    useEffect(() => {
        if (!noRename) return;
        if (files.length === 0) return;

        onChange(files);
    }, [files]);

    useEffect(() => {
        if (inputRef.current) {
            const dataTransfer = new DataTransfer();
            files.forEach((file) => dataTransfer.items.add(file));
            inputRef.current.files = dataTransfer.files;
        }
    }, [files]);

    // render

    const alert = (
        <Alert
            mb={4}
            alignItems={"start"}
        >
            <AlertIcon />
            <VStack
                align={"stretch"}
                spacing={0}
            >
                <AlertTitle>
                    {t("domain.document.ACTIVITIES.edit.alert.label")}
                </AlertTitle>
                <AlertDescription>
                    {t("domain.document.ACTIVITIES.edit.alert.description")}
                </AlertDescription>
            </VStack>
        </Alert>
    );

    return (
        <>
            <div
                style={{ width: customUploadButton ? "auto" : "100%" }}
                onDragEnter={handleDrag}
            >
                <label
                    id={`label-file-upload-${name}`}
                    htmlFor={`input-file-upload-${name}`}
                    style={{
                        cursor: "pointer"
                    }}
                >
                    {customUploadButton || (
                        <Box
                            h={height}
                            border={"1px dashed"}
                            borderColor={dragActive ? "blue.500" : "gray.300"}
                            borderRadius="lg"
                            bg={dragActive ? "gray.50" : "white"}
                            paddingBlock="1rem"
                            w="100%"
                            transition={".25s"}
                            _hover={{
                                cursor: "pointer",
                                bg: "gray.50",
                                borderColor: "blue.500",
                                transition: ".25s",
                            }}
                        >
                            <VStack
                                align="center"
                                justify={"center"}
                                spacing="2"
                                w="100%"
                                h={"100%"}
                            >
                                <Icon
                                    as={MdOutlineFileUpload}
                                    color={"gray.400"}
                                    boxSize={10}
                                />

                                <Text
                                    align="center"
                                    fontSize={"sm"}
                                >
                                    <Highlight
                                        query={t("fileUpload.browseKeyword")}
                                        styles={{
                                            color: "wvwGreen",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("fileUpload.dragDropBrowse")}
                                    </Highlight>
                                </Text>

                                <Text
                                    variant="secondary"
                                    fontSize={"sm"}
                                >
                                    {t("fileUpload.maxFileSize")}
                                </Text>
                            </VStack>
                        </Box>
                    )}

                    {error && <Text color="red">{error}</Text>}

                    <input
                        id={`input-file-upload-${name}`}
                        accept={fileTypes ? fileTypes.join(",") : undefined}
                        ref={inputRef}
                        hidden
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        onChange={async (event) => {
                            event.preventDefault();

                            if (event.target.files && event.target.files[0]) {
                                const fList: File[] = [];

                                for (let i = 0; i < event.target.files.length; i += 1) {
                                    let file = event.target.files[i];

                                    if (file.type === 'image/heic' || file.type === 'image/heif') {
                                        try {
                                            const convertedBlob = await heic2any({
                                                blob: file,
                                                toType: 'image/jpeg',
                                            });

                                            file = new File(
                                                [convertedBlob as Blob],
                                                file.name.replace(/\.heic|\.heif/i, '.jpeg'),
                                                { type: 'image/jpeg' }
                                            );
                                        } catch {
                                            setError(t("common.error.error"));
                                            continue;
                                        }
                                    }

                                    if (i === 0 && defaultFileName !== undefined) {
                                        const extension = `.${file.name.split(".").pop()?.toLowerCase()}`;
                                        file = new File([file as Blob], `${defaultFileName}${extension}`, { type: file.type });
                                    }

                                    if (checkFile(file)) {
                                        fList.push(file);
                                    }
                                }

                                setFiles(fList);
                            }
                        }}
                    />
                </label>

                {dragActive && (
                    <div
                        id="drag-file-element"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            borderRadius: "1rem",
                            top: "0px",
                            right: "0px",
                            bottom: "0px",
                            left: "0px",
                        }}
                    />
                )}
            </div>

            <Modal
                isOpen={!noRename && files.length > 0}
                closeOnOverlayClick={false}
                size={"lg"}
                onClose={() => setFiles([])}
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader
                        color="blue.700"
                        fontWeight="bold"
                    >
                        {t("fileUpload.button.uploadFile")}
                    </ModalHeader>

                    <ModalCloseButton />

                    <FormikForm
                        initialValues={{
                            fileNames: files.map((f) => f.name),
                        }}
                        onSubmit={(values) => {
                            const { fileNames } = values;

                            const fList = files;

                            files.forEach((file, index) => {
                                let formattedFilename = fileNames[index].trim();
                                let uploadFile = file as File;

                                if (formattedFilename !== file?.name) {
                                    const extention =
                                        file?.name
                                            ?.split(".")
                                            ?.pop()
                                            ?.toLowerCase() || "";

                                    if (
                                        formattedFilename
                                            .split(".")
                                            .pop()
                                            .toLowerCase() !== extention
                                    ) {
                                        formattedFilename += `.${extention}`;
                                    }

                                    uploadFile = new File(
                                        [file as Blob],
                                        formattedFilename as string,
                                        {
                                            type: file?.type,
                                        }
                                    );
                                }

                                files[index] = uploadFile;
                            });

                            onChange(fList);

                            setFiles([]);
                        }}
                        validationSchema={Yup.object().shape({
                            fileNames: Yup.array().of(
                                Yup.string().required(
                                    t("fileUpload.fileNameRequired")
                                )
                            ),
                        })}
                    >
                        <ModalBody>
                            {alert}

                            <VStack align={"stretch"}>
                                {files.map((i, index) => (
                                    <HStack key={i.name}>
                                        <Text
                                            color="gray.500"
                                            fontSize={"sm"}
                                        >
                                            {index + 1}
                                        </Text>

                                        <FileTypeIcon
                                            filetype={getFileTypeBasedOnName(
                                                i.name
                                            )}
                                            size={6}
                                        />

                                        <FormikInput
                                            name={`fileNames.${index}`}
                                            rightinternal={
                                                <Text
                                                    fontSize={"sm"}
                                                    color={"gray.400"}
                                                >
                                                    .
                                                    {getFileTypeBasedOnName(
                                                        i.name
                                                    )}
                                                </Text>
                                            }
                                        />
                                    </HStack>
                                ))}
                            </VStack>
                        </ModalBody>

                        <ModalFooter gap={2}>
                            <Button
                                variant="default"
                                onClick={() => setFiles([])}
                            >
                                {t("common.button.cancel")}
                            </Button>

                            <Button
                                type="submit"
                                variant="primary"
                            >
                                {t("fileUpload.button.upload")}
                            </Button>
                        </ModalFooter>
                    </FormikForm>
                </ModalContent>
            </Modal>
        </>
    );
};

export default FileUploadInput;
