import { Circle, Image } from '@chakra-ui/react';

type PropTypes = {
    url: string; // The URL of the image to display
    size?: string; // The size of the profile picture
};

const ProfilePicture = (props: PropTypes) => {
    const { url, size = "3rem" } = props;

    return (
        <Circle
            size={size}
            margin="0"
        >
            <Image
                alt="Profile Picture"
                src={url}
                borderRadius="50%"
                objectFit="cover"
                h="100%"
                w="100%"
            />
        </Circle>
    );
};

export default ProfilePicture;
