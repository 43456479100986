import {
    caseColumns,
    propertyColumns,
} from "./components/data-table/columns/i18n";

import { offerEnrichments } from "./features/enrichment/domain/offer/enrichedOffersI18n";
import { translations as fileUploadTranslations } from "./inputs/fileupload/i18n";

export const common = {
    de: {
        button: {
            add: "Hinzufügen",
            back: "Zurück",
            cancel: "Abbrechen",
            close: "Schließen",
            confirm: "Bestätigen",
            complete: "Vervollständigen",
            done: "Fertig",
            edit: "Bearbeiten",
            next: "Nächste",
            previous: "Vorherige",
            sendLink: "Link senden",
            upload: "Hochladen",
            view: "Anzeigen",
            submit: "Abschicken",
            save: "Speichern",
            remove: "Entfernen",
        },
        components: {
            dataTable: {
                columns: {
                    caseColumns: caseColumns.de,
                    propertyColumns: propertyColumns.de,
                },
            },
            streetView: {
                noStreetViewAvailable: "Kein Street View verfügbar",
            },
        },
        documents: "Dokumente",
        features: {
            enrichment: {
                domain: {
                    offer: offerEnrichments.de,
                },
            },
        },
        inputs: {
            fileUpload: fileUploadTranslations.de,
        },
        email: "E-Mail Adresse",
        error: {
            error: "Fehler",
            weAreSorry: "Es tut uns leid!",
        },
        goToDashboard: "Zum Dashboard",
        loading: "Lädt...",
        "n/a": "N/A",
        noData: "Keine Daten vorhanden",
        password: "Passwort",
        platformAccessRequired: "Zugang zur Plattform erforderlich",
        selectAll: "Alles auswählen",
        showLess: "Weniger anzeigen",
        showMore: "Mehr anzeigen",
        toast: {
            error: "Fehler",
            success: "Erfolg",
        },
        units: {
            days: "Tage",
            km: "km",
            years: "Jahre",
        },
        wvw: "WBW",
        yes: "Ja",
        no: "Nein",
        noLimit: "Keine Begrenzung",
    },
    en: {
        button: {
            add: "Add",
            back: "Back",
            cancel: "Cancel",
            close: "Close",
            confirm: "Confirm",
            complete: "Complete",
            done: "Done",
            edit: "Edit",
            next: "Next",
            previous: "Previous",
            sendLink: "Send Link",
            submit: "Submit",
            upload: "Upload",
            view: "View",
            save: "Save",
            remove: "Remove",
        },
        components: {
            dataTable: {
                columns: {
                    caseColumns: caseColumns.en,
                    propertyColumns: propertyColumns.en,
                },
            },
            streetView: {
                noStreetViewAvailable: "No Street View available",
            },
        },
        documents: "Documents",
        features: {
            enrichment: {
                domain: {
                    offer: offerEnrichments.en,
                },
            },
        },
        inputs: {
            fileUpload: fileUploadTranslations.en,
        },
        email: "Email Address",
        error: {
            error: "Error",
            weAreSorry: "We're sorry!",
        },
        goToDashboard: "Go to Dashboard",
        loading: "Loading...",
        "n/a": "N/A",
        noData: "No data present",
        password: "Password",
        platformAccessRequired: "Platform access required",
        selectAll: "Select all",
        showLess: "Show less",
        showMore: "Show more",
        toast: {
            error: "Error",
            success: "Success",
        },
        units: {
            days: "Days",
            km: "km",
            years: "years",
        },
        wvw: "WVW",
        yes: "Yes",
        no: "No",
        noLimit: "No limit",
    },
};
