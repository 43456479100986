import { Icon, IconProps } from '@chakra-ui/icons';
import { OmitCommonProps } from '@chakra-ui/react';
import { JSX, SVGProps } from 'react';
/* eslint-disable react/jsx-props-no-spreading */

// eslint-disable-next-line react/require-default-props
const PatternElement = (props: JSX.IntrinsicAttributes & OmitCommonProps<SVGProps<SVGSVGElement>, keyof IconProps> & IconProps & { as?: 'svg' | undefined; }) => (
    <Icon viewBox="0 0 82 83" {...props}>
        <path stroke="currentColor" strokeWidth={2} fill="transparent" strokeLinecap="round" strokeLinejoin="round" d="M16.95 45.8c5.2-7.95 10.85-15.62 13.57-24.98 2.46-8.49-2.38-17.05-11.57-19.52C12.22-.5 3.03 6.02 1.36 13.28c-.99 4.32.22 7.78 2 11.56 3.55 7.52 6.99 15.05 13.08 20.96M40.95 1.3c-.5.83-1.64 2.01-1.41 2.45 5.58 10.49 2.67 20.44-2.18 30.01-3.91 7.71-8.47 15.11-12.95 22.52-4.94 8.18-3.23 17.06 4.52 22.53 6.34 4.47 16.43 3.5 21.42-4.07 5.22-7.91 9.78-16.26 14.58-24.45 4.38-7.48 8.58-15.05 13.04-22.48 3.41-5.67 4.91-11.42 1.44-17.5-3.6-6.31-9.2-9.17-16.47-9.04-7.16.12-14.33.03-21.5.03" className="cls-1" />
        <path stroke="currentColor" strokeWidth={2} fill="transparent" strokeLinecap="round" strokeLinejoin="round" d="M14.45 12.3c-3.22 1.14-3.81 4.07-2.79 6.41.72 1.65 3.9 3.73 5.18 3.29 1.9-.65 3.98-3.2 4.36-5.24.38-2.07-1.12-4.59-4.26-4.46-.67.03-1.33 0-2 0" className="cls-1" />
    </Icon>
);

export default PatternElement;
