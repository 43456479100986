import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import ValuerInviteForm from "../../../../../../../common/components/ValuerInviteForm";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { useState } from "react";
import { MISSING_TRANSLATION } from "../../../../../common/MISSING_TRANSLATION";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";

type PropTypes = {
    onSendInvite: () => void;
};

const useValuersInviteDialog = (props: PropTypes) => {
    const { onSendInvite } = props;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [invitationError, setInvitationError] = useState<string>();

    const cannotInviteIndividualClientInfo = invitationError && (
        <ConfirmationModal
            isOpen={invitationError !== undefined}
            blurb={t(`account.customerPanelDetails.${invitationError}`, {
                defaultValue: MISSING_TRANSLATION,
            })}
            title={t("account.customerPanelDetails.inviteAValuer.title")}
            onConfirmation={() => {
                setInvitationError(undefined);
            }}
            onClose={() => {
                setInvitationError(undefined);
            }}
            hideCloseButton
            confirmationButtonVariant="neutral"
            confirmationButtonLabel={t("common.button.close")}
        />
    );

    const onInviteSuccess = (result: any) => {
        if (result.data.error) {
            setInvitationError(result.data.error);
        } else {
            onSendInvite();
            onClose();
        }
    }

    const inviteModal = (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent minWidth="2xl">
                <ModalHeader color="black">
                    {t("account.customerPanelDetails.inviteNewValuer")}
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody pb={4}>
                    {/* // TODO: rebuild it when Jacek creates a moqup */}
                    <ValuerInviteForm
                        onInviteSuccess={onInviteSuccess}
                    />
                </ModalBody>
            </ModalContent>

            {cannotInviteIndividualClientInfo}
        </Modal>
    );

    return {
        inviteModal,
        isOpenInviteModal: isOpen,
        openInviteModal: onOpen,
        closeInviteModal: onClose,
    };
};

export default useValuersInviteDialog;
