import {
    Button,
    Flex,
    HStack,
    IconButton,
    Input,
    InputGroup,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useField } from "formik";
import { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useSmartTranslation } from "../hooks/useSmartTranslation";

type PropTypes = {
    name: string;
    placeholder?: string;
    width?: string;
    onChangeFunction?: () => void;
};

const FormikListInput = (props: PropTypes) => {
    const {
        name,
        placeholder = "",
        width = "100%",
        onChangeFunction = () => {},
    } = props;

    const [field, meta] = useField(props);

    const [inputValue, setInputValue] = useState("");

    const t = useSmartTranslation();

    const submitValues = () => {
        const inputList = [...field.value, inputValue];

        field.onChange({
            target: {
                name,
                value: inputList,
            },
        });

        setInputValue("");
    };

    return (
        <VStack
            spacing={4}
            align={"start"}
        >
            <InputGroup gap={2}>
                <Input
                    bg="white"
                    name={name}
                    value={inputValue}
                    type="text"
                    w={width}
                    placeholder={placeholder}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        if (onChangeFunction) {
                            onChangeFunction();
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();

                            submitValues();
                        }
                    }}
                />

                <Button
                    variant={"outline"}
                    colorScheme={"blue"}
                    bg={"white"}
                    type="button"
                    onClick={() => {
                        if (inputValue === "") return;
                        submitValues();
                    }}
                >
                    {t("common.button.add")}
                </Button>
            </InputGroup>

            <HStack>
                {field.value?.map((inputContent: string) => (
                    <Flex
                        height="2.5rem"
                        bg={"white"}
                        border="1px solid"
                        borderColor="gray.200"
                        borderRadius="lg"
                        padding={".5rem 1rem"}
                        gap={4}
                        alignItems="center"
                        key={inputContent}
                    >
                        <Text fontSize={"sm"}>{inputContent}</Text>

                        <IconButton
                            icon={<RiDeleteBin6Line />}
                            size="sm"
                            variant="ghost"
                            color="gray.400"
                            aria-label="remove"
                            onClick={() => {
                                const newList = field.value.filter(
                                    (i: string) => i !== inputContent
                                );
                                field.onChange({
                                    target: {
                                        name,
                                        value: newList,
                                    },
                                });
                            }}
                        />
                    </Flex>
                ))}
            </HStack>

            {meta.touched && meta.error && (
                <Text
                    color="red"
                    fontSize={"sm"}
                >
                    {t(meta.error, { defaultValue: meta.error })}
                </Text>
            )}
        </VStack>
    );
};

export default FormikListInput;
