import {
    Button,
    CircularProgress,
    CircularProgressLabel,
    Flex,
    HStack,
    Icon,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { IoMdCheckmark } from "react-icons/io";
import { MdOutlineWarning } from "react-icons/md";
import { Link as ReactLink } from "react-router-dom";
import { Section } from "../../../layout";
import { useSmartTranslation } from "../../hooks/useSmartTranslation";

type ImpulseItem = {
    title: string;
    buttonUrl: string;
    completionTime?: string;
    requiredForMatchmaking?: boolean;
    isDone?: boolean;
};

type CompletionImpulseProps = {
    title: string;
    subtitle: string;
    impulses: ImpulseItem[];
};

const CompletionImpulse = (props: CompletionImpulseProps) => {
    const { title, subtitle, impulses } = props;

    const progress =
        (impulses.filter((impulse) => impulse.isDone || false).length /
            impulses.length) *
        100;

    return (
        <Section
            title={title}
            subtitle={subtitle}
            collapsable
            leftSide={<ProgressCircle progress={progress} />}
            content={
                <VStack align={"stretch"}>
                    {impulses.map((impulse, idx) => (
                        <Impulse
                            key={idx}
                            idx={idx + 1}
                            impulse={impulse}
                        />
                    ))}
                </VStack>
            }
        />
    );
};

type ImpulseProps = {
    impulse: ImpulseItem;
    idx: number;
};

const Impulse = (props: ImpulseProps) => {
    const {
        impulse: {
            title,
            buttonUrl,
            completionTime,
            requiredForMatchmaking: required,
            isDone = false,
        },
        idx,
    } = props;

    const t = useSmartTranslation();

    const bgColor = isDone ? "green.50" : required ? "orange.50" : "gray.50";

    // render

    const index = isDone ? (
        <Icon
            as={IoMdCheckmark}
            boxSize={"1.5rem"}
            color={"green.500"}
        />
    ) : (
        <Text
            fontSize={"sm"}
            color={"gray.600"}
            w={"1.5rem"}
            textAlign={"center"}
        >
            {idx}.
        </Text>
    );

    const requiredInfo = !isDone && required && (
        <Flex
            color={"orange.600"}
            bg={"orange.100"}
            fontSize={"xs"}
            fontWeight={"700"}
            align={"center"}
            gap={"2px"}
            px={1}
            borderRadius={"2px"}
        >
            <Icon as={MdOutlineWarning} />
            {t("completion.required")}
        </Flex>
    );

    const header = (
        <HStack spacing={2}>
            <Text
                fontWeight={"bold"}
                color={"gray.900"}
            >
                {title}
            </Text>

            {completionTime && (
                <Text
                    fontSize={"sm"}
                    color={"gray.500"}
                >
                    {completionTime}
                </Text>
            )}

            {requiredInfo}
        </HStack>
    );

    const button = !isDone && (
        <Button
            as={ReactLink}
            to={buttonUrl}
            variant={required ? "primary" : "outline"}
            colorScheme={"blue"}
            w={"240px"}
        >
            {t("completion.start")}
        </Button>
    );

    return (
        <HStack
            h={"4.5rem"}
            p={4}
            spacing={4}
            bg={bgColor}
            borderRadius={"lg"}
        >
            {index}
            {header}
            <Spacer />
            {button}
        </HStack>
    );
};

type PropTypes = {
    progress: number;
};

// TODO: clarify with Jacek progress colors
const ProgressCircle = (props: PropTypes) => {
    const { progress } = props;
    let color = "";

    switch (true) {
        case progress < 20:
            color = "red.500";
            break;
        case progress < 40:
        case progress < 60:
            color = "orange.500";
            break;
        case progress < 80:
        case progress < 100:
            color = "green.500";
            break;
        default:
            color = "green.500";
            break;
    }
    return (
        <>
            <CircularProgress
                value={progress}
                color={color}
                size="3.75rem"
                mr={4}
                thickness={"5px"}
                capIsRound
            >
                <CircularProgressLabel>{`${progress}%`}</CircularProgressLabel>
            </CircularProgress>
        </>
    );
};

export default CompletionImpulse;
