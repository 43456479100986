import { EntityType } from "../../../../../../types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { InformationRowItem } from "../InformationRowItem";
import RenderFieldData from "../RenderFieldData";

type CompanyDetailsBillingProps = {
    entity: EntityType;
};

const CompanyDetailsBilling = (props: CompanyDetailsBillingProps) => {
    const {
        entity: {
            contactFirstName,
            contactLastName,
            street,
            postalCode,
            city,
            country,
            name,
            vatNumber,
        },
    } = props;

    const t = useSmartTranslation();

    // render

    const companyName = (
        <InformationRowItem
            label={t("account.companyDetails.name")}
            content={
                <RenderFieldData
                    field="companyName"
                    data={[name]}
                />
            }
        />
    );

    const contact = (
        <InformationRowItem
            label={t("account.companyDetails.contactPerson")}
            content={
                <RenderFieldData
                    field="contactPerson"
                    data={[contactFirstName, contactLastName]}
                />
            }
        />
    );

    const location = (
        <InformationRowItem
            label={t("account.companyDetails.location")}
            content={
                <RenderFieldData
                    field="location"
                    data={[street, postalCode, city, country]}
                    config={{ joinWith: ", ", required: true }}
                />
            }
        />
    );

    const vat = (
        <InformationRowItem
            label={t("account.companyDetails.vatNumber")}
            content={
                <RenderFieldData
                    field="vatNumber"
                    data={[vatNumber]}
                />
            }
        />
    );

    return (
        <>
            {companyName}
            {contact}
            {location}
            {vat}
        </>
    );
};

export default CompanyDetailsBilling;
