import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Feature } from "../../../app/features/Feature";

type AdminViewContextType = {
    inAdminView: boolean;
    toggleAdminView: (forceState?: boolean) => void;
};

const AdminViewContext = createContext<AdminViewContextType | undefined>(
    undefined
);

export const useAdminView = () => {
    return useContext(AdminViewContext)!;
};

export const AdminViewProvider = ({ children }: { children: ReactNode }) => {
    // default to everything visible to the user if the feature is disabled
    const [inAdminView, setInAdminView] = useState(Feature.isOff("ADMIN_VIEW"));

    useEffect(() => {
        setInAdminView(false);
    }, []);

    const toggleAdminView = (forceState?: boolean) => {
        if (forceState !== undefined) {
            setInAdminView(forceState);
        } else {
            setInAdminView((prev) => !prev);
        }
    };

    return (
        <AdminViewContext.Provider value={{ inAdminView, toggleAdminView }}>
            {children}
        </AdminViewContext.Provider>
    );
};