import { Box, VStack } from "@chakra-ui/react";
import {
    FormikCheckboxGroup,
    FormikCloudSelect,
    FormikConditionalSection,
    FormikListInput,
} from "../../../../common/forms";
import { useSmartTranslation } from "../../../../common/hooks";
import {
    standardSpecialValuationTypes,
    standardValuationTypes,
} from "../../../../common/vars/valuationsAndMembershipTypes";
import FormSectionProfileEdit from "./FormSectionProfileEdit";
import { wvwPalette } from "../../../../theme";
import { WVWLabel, WvwText } from "../../../../common/components/typography";
import { useFormikContext } from "formik";
import { StandardValuationType } from "../../../../common/vars/valuationsAndMembershipTypes/standardValuationTypes";

const ProfileValuationTypesEdit = () => {
    const t = useSmartTranslation("profile");

    const { values } = useFormikContext<{
        valuationTypes?: StandardValuationType[];
    }>();

    const isValuationTypeMissing =
        !values?.valuationTypes || values.valuationTypes.length === 0;

    // console.log(values.valuationTypes);

    return (
        <FormSectionProfileEdit
            header={t("heading.valuationScope")}
            warningIcon={isValuationTypeMissing}
            subHeader={t("subHeading.valuationScopes")}
        >
            <FormikCloudSelect
                name="valuationTypes"
                options={standardValuationTypes.map((type) => ({
                    label: t(type, { ns: "formik", defaultValue: type }),
                    value: type,
                }))}
            />

            <FormikConditionalSection
                formDataTarget="valuationTypes"
                condition={(x) => x.includes("Other Valuation")}
            >
                <VStack
                    align="start"
                    backgroundColor={wvwPalette.wvwBackground.main}
                    borderRadius="8"
                    padding="1rem"
                    spacing="4"
                    w="100%"
                >
                    <Box>
                        <WVWLabel
                            noColor
                            content={t("heading.otherValuationScopes")}
                        />

                        <WvwText>
                            {t("subHeading.otherValuationScopes")}
                        </WvwText>
                    </Box>

                    <FormikListInput
                        name="otherValuationTypes"
                        placeholder={t(
                            "membershipsAndQualifications.edit.addValuationScope"
                        )}
                    />
                </VStack>
            </FormikConditionalSection>

            <VStack
                align="start"
                backgroundColor={wvwPalette.wvwBackground.main}
                borderRadius="8"
                padding="1rem"
                spacing="4"
                w="100%"
            >
                <Box>
                    <WVWLabel
                        noColor
                        content={t("heading.specificValuationScopes")}
                    />

                    <WvwText>{t("subHeading.specificValuationScopes")}</WvwText>
                </Box>

                <FormikCheckboxGroup
                    name="specialValuationTypes"
                    options={standardSpecialValuationTypes.map((type) => ({
                        label: t(type, { ns: "formik", defaultValue: type }),
                        value: type,
                    }))}
                />
            </VStack>
        </FormSectionProfileEdit>
    );
};

export default ProfileValuationTypesEdit;
