import { Button, ButtonProps } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { EnquiryType } from "../../../../types";
import { usePermissions } from "../../app/permissions/usePermissions";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type EditEnquiryProps = ButtonProps & {
    enquiry: EnquiryType;
};

const EditEnquiry = (props: EditEnquiryProps) => {
    const {
        enquiry: { id: enquiryId, status, dormant },
        enquiry,
        ...cssProps
    } = props;

    const t = useSmartTranslation();

    const navigate = useNavigate();

    const { permission: permissionToEdit } = usePermissions({
        datatype: "Enquiry",
        activity: "UpdateEnquiry",
        objects: enquiry,
    });

    const editable = status === "DRAFT" && !dormant;

    const label = t("domain.enquiry.ACTIVITIES.edit.NAME");

    return (
        <Button
            {...cssProps}
            variant={"primary"}
            size={"sm"}
            isDisabled={!(editable && permissionToEdit)}
            onClick={(ev) => {
                ev.stopPropagation();
                navigate(
                    `/create-client-enquiry/enquiry-valuation-info/${enquiryId}`
                );
            }}
        >
            {label}
        </Button>
    );
};

export { EditEnquiry };
