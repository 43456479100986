/* eslint-disable max-len */
import {
    ReactNode,
    createContext,
    useMemo,
    useState,
} from 'react';

type PropTypes = {
    steps: ReactNode[];
    onProcessEnd?: () => void;
    goBackFromStart?: () => void;
};

export const FlowContextStateful = createContext({
    goNext: () => {},
    goBack: () => {},
});

/**
 * @param props.steps - Array of steps to be displayed in the process flow
 * @param props.onProcessEnd - Function to be called when the process ends
 * @param props.goBackFromStart - Function to be called when the user tries to go back from the first step
 */
const ProcessFlowStateful = (props: PropTypes) => {
    const { steps, onProcessEnd = () => {}, goBackFromStart = () => {}} = props;

    const [currentStep, setCurrentStep] = useState(0);

    const goNext = () => {
        if (currentStep === steps.length - 1) {
            onProcessEnd?.();
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const goBack = () => {
        const backStep = currentStep - 1;

        if (backStep === -1) {
            goBackFromStart?.();
        } else {
            setCurrentStep(backStep);
        }
    };

    const contextValue = useMemo(() => ({
        goNext,
        goBack,
    }), [goNext, goBack]);

    return (
        <FlowContextStateful.Provider value={contextValue}>
            {steps[currentStep]}
        </FlowContextStateful.Provider>
    );
};

export default ProcessFlowStateful;
