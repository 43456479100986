import { IconButton } from "@chakra-ui/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { EntityType } from "../../../../../../../../types";
import DataTable, {
    DataTableColumnType,
} from "../../../../../../common/components/data-table/DataTable";
import useValuersRemove from "../useValuersRemove";
import { entityColumns } from "../../../../../../domain/entity/EntityColumns";

type PropTypes = {
    valuers: EntityType[];
    canRemove: boolean | undefined;
    onRowClick: (item: EntityType) => void;
};

const ValuersTable = (props: PropTypes) => {
    const { valuers, canRemove, onRowClick } = props;

    return (
        <DataTable
            fullSectionWidth
            data={valuers}
            onRowClick={onRowClick}
            columns={[
                entityColumns.name,
                entityColumns.address,
                entityColumns.email,
                entityColumns.website,
                entityColumns.invitedAt,
                ...(canRemove ? [removeValuerColumn] : []),
            ]}
        />
    );
};

const removeValuerColumn: DataTableColumnType<EntityType> = {
    render: (i) => {
        const { removeValuerModal, openRemoveValuerModal } = useValuersRemove({
            valuerId: i.id,
        });

        return (
            <>
                <IconButton
                    icon={<RiDeleteBin6Line />}
                    size="sm"
                    variant="ghost"
                    color="gray.400"
                    aria-label="leave"
                    onClick={(e) => {
                        e.stopPropagation();
                        openRemoveValuerModal();
                    }}
                />
                {removeValuerModal}
            </>
        );
    },
};

export default ValuersTable;
