import { useState } from "react";
import { FilterEntryType } from "./Filter";

const useDataFilter = <T,>(initialData: T[]) => {
    const [searchFilter, setSearchFilter] = useState<FilterEntryType<T> | null>(
        null
    );

    const updateSearchFilter = (filter: FilterEntryType<T>) => {
        setSearchFilter(filter);
    };

    const deactivateSearchFilter = () => {
        setSearchFilter(null);
    };

    const filteredData = searchFilter
        ? initialData.filter((item) => searchFilter.filter(item))
        : initialData;

    return {
        searchFilter,
        filteredData,
        updateSearchFilter,
        deactivateSearchFilter,
    };
};

export default useDataFilter;
