import { AddIcon } from "@chakra-ui/icons";
import {
    Button,
    ButtonGroup,
    HStack,
    Select,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RiFolderAddLine } from "react-icons/ri";
import * as Yup from "yup";
import {
    FormikForm,
    FormikInput,
    FormikTextarea,
} from "../../../../../common/forms";
import {
    useDocumentRequestCreateTemplate,
    useDocumentRequestGetFolders,
    useDocumentRequestGetTemplates,
} from "../../../../../utils/api/hooks";
import RequestListCreation from "./RequestListCreation";
import TemplateList from "./TemplateList";

const LIST = "list";
const ADD_TEMPLATE = "add-template";
const CREATE_FROM_FOLDER = "create-from-folder";

type WithoutCaseId = {
    caseId: undefined;
    enableCreationFromFolder: false;
    enableDragAndDrop: false;
};

type WithCaseId = {
    caseId: number;
    enableCreationFromFolder?: boolean;
    enableDragAndDrop?: boolean;
};

type PropTypes = {
    enableCreation: boolean;
    enableEditing: boolean;
} & (WithCaseId | WithoutCaseId);

const validationSchema = Yup.object({
    name: Yup.string().required("errors.required"),
});

const TEMPLATE_TEMPLATE = {
    name: "",
    description: "",
    requests: [] as { name: string; description: string }[],
};

const TemplateCreation = (props: PropTypes) => {
    const {
        enableCreation,
        enableEditing,
        enableCreationFromFolder,
        enableDragAndDrop,
        caseId,
    } = props;

    const { t } = useTranslation(["documentmanagement"]);

    const [viewMode, setViewMode] = useState<
        typeof LIST | typeof ADD_TEMPLATE | typeof CREATE_FROM_FOLDER
    >(LIST);

    const [initialValues, setInitialValues] = useState(TEMPLATE_TEMPLATE);

    const cancelTemplateCreation = () => {
        setViewMode(LIST);
        setInitialValues(TEMPLATE_TEMPLATE);
    };

    const { data: templateList = [] } = useDocumentRequestGetTemplates();

    const { data: folders = [] } = useDocumentRequestGetFolders({
        caseId: Number(caseId),
    });

    const { update: createTemplate } = useDocumentRequestCreateTemplate({
        caseId,
    });

    return (
        <VStack
            w="100%"
            align="left"
            paddingBlock=".5rem"
        >
            {viewMode === LIST && (
                <>
                    <HStack spacing="3">
                        {enableCreation && (
                            <Button
                                leftIcon={<AddIcon />}
                                variant="none"
                                padding="0"
                                fontWeight="normal"
                                color="wvwGreen"
                                onClick={() => setViewMode(ADD_TEMPLATE)}
                            >
                                {t("addTemplate")}
                            </Button>
                        )}

                        {enableCreationFromFolder && (
                            <Button
                                leftIcon={<RiFolderAddLine />}
                                variant="none"
                                padding="0"
                                fontWeight="normal"
                                color="wvwGreen"
                                onClick={() => setViewMode(CREATE_FROM_FOLDER)}
                            >
                                {t("createFromFolder")}
                            </Button>
                        )}
                    </HStack>

                    <TemplateList
                        caseId={Number(caseId)}
                        templateList={templateList}
                        enableEditing={enableEditing}
                        enableDragAndDrop={enableDragAndDrop}
                    />
                </>
            )}

            {viewMode === ADD_TEMPLATE && (
                <FormikForm
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        createTemplate({
                            name: values.name,
                            description: values.description,
                            requests: values.requests,
                        });

                        setViewMode(LIST);
                    }}
                >
                    <VStack align="left">
                        <Text
                            color="wvwGreen"
                            fontWeight="bold"
                        >
                            {t("templateTitle")}
                        </Text>

                        <FormikInput
                            name="name"
                            placeholder={t("templateTitle")}
                        />

                        <Text
                            color="wvwGreen"
                            fontWeight="bold"
                        >
                            {t("description")}
                        </Text>

                        <FormikTextarea
                            name="description"
                            placeholder={t("templateDescription")}
                        />

                        <Text
                            color="wvwGreen"
                            fontWeight="bold"
                        >
                            {t("requests")}
                        </Text>

                        <RequestListCreation name="requests" />

                        <ButtonGroup alignSelf="flex-end">
                            <Button
                                variant="primary"
                                onClick={() => cancelTemplateCreation()}
                            >
                                {t("button.cancel", { ns: "common" })}
                            </Button>

                            <Button
                                variant="primaryYellow"
                                type="submit"
                            >
                                {t("button.save", { ns: "common" })}
                            </Button>
                        </ButtonGroup>
                    </VStack>
                </FormikForm>
            )}

            {viewMode === CREATE_FROM_FOLDER && (
                <VStack align="left">
                    <Text
                        color="wvwGreen"
                        fontWeight="bold"
                    >
                        {t("folder")}
                    </Text>

                    <Select
                        bg="white"
                        borderRadius="8"
                        fontSize="md"
                        h="3rem"
                        placeholder={t("selectFolder")}
                        onChange={(e) => {
                            const folder = folders.find(
                                (f) => f.id === Number(e.target.value)
                            );

                            const requests = folder?.requests?.map(
                                (request) => ({
                                    name: request.name,
                                    description: request.description,
                                })
                            );

                            setInitialValues({
                                name: folder?.name || "",
                                description: "",
                                requests: requests || [],
                            });
                        }}
                    >
                        {folders.map((folder) => (
                            <option
                                key={folder.id}
                                value={folder.id}
                            >
                                {folder.name}
                            </option>
                        ))}
                    </Select>

                    <Text
                        color="wvwGreen"
                        fontWeight="bold"
                    >
                        {t("requests")}
                    </Text>

                    {initialValues.requests.map((request) => (
                        <VStack
                            align="left"
                            bg="wvwGrey05"
                            borderRadius="10"
                            key={request.name}
                            paddingBlock="1rem"
                            paddingInline="1rem"
                            w="100%"
                        >
                            <Text fontWeight="500">{request.name}</Text>

                            {request.description && (
                                <Text>{request.description}</Text>
                            )}
                        </VStack>
                    ))}

                    <ButtonGroup alignSelf="flex-end">
                        <Button
                            variant="primary"
                            onClick={() => cancelTemplateCreation()}
                        >
                            {t("button.cancel", { ns: "common" })}
                        </Button>

                        <Button
                            isDisabled={initialValues.requests.length < 1}
                            variant="primaryYellow"
                            onClick={() => setViewMode(ADD_TEMPLATE)}
                        >
                            {t("button.next", { ns: "common" })}
                        </Button>
                    </ButtonGroup>
                </VStack>
            )}
        </VStack>
    );
};

export default TemplateCreation;
