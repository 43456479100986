import { Heading, VStack } from "@chakra-ui/react";
import { EnquiryType, OfferOnlyType, UserType } from "../../../../../../types";
import { DeviceBreakpoints } from "../../../../common/hooks/useDevice";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Property } from "../../../../domain";
import {
    EnquiryMatchInfo,
    EnquiryMatchInfoWithValuerAndOffer,
} from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";
import { EnquiryRequirements } from "../../../../domain/enquiry/enquiryRequirements/EnquiryRequirements";
import { Section } from "../../../../layout";
import { CompanyOffers } from "./CompanyOffers";

const translationNS = "screens.platform.valuer.valuerEnquiryDetails";

type Props = {
    enquiry: EnquiryType;
    matchInfo: EnquiryMatchInfo;
    device: DeviceBreakpoints | undefined;
    companyMatchInfoList: EnquiryMatchInfoWithValuerAndOffer[];
    myOffer: React.ReactNode;
    offers: OfferOnlyType[];
    usersList: UserType[];
    user: UserType;
    piCoverWarning: React.ReactNode | undefined;
    onUpdate: () => any;
};

const SinglePropertyLayout = (props: Props) => {
    const {
        enquiry,
        matchInfo,
        companyMatchInfoList,
        device,
        myOffer,
        user,
        usersList,
        offers,
        piCoverWarning,
        onUpdate,
    } = props;

    const t = useSmartTranslation();

    const property = enquiry.properties[0];

    const requestedValuation = (
        <Section
            collapsable
            title={t("domain.enquiry.requestedValuationTitle")}
            content={
                <EnquiryRequirements
                    enquiry={enquiry}
                    matchInfo={matchInfo}
                />
            }
        />
    );

    const map = (
        <Section
            content={
                <Property.PropertiesMap
                    properties={enquiry.properties}
                    selectedProperty={property}
                />
            }
        />
    );

    const streetView = (
        <Section
            content={<Property.PropertyStreetView property={property} />}
        />
    );

    const propertyDetails = (
        <Section
            collapsable
            title={t("domain.property.propertyDetails.title")}
            content={
                <>
                    {(device === "mobile" || device === "tablet") && (
                        <Property.PropertiesMap
                            properties={enquiry.properties}
                            selectedProperty={property}
                        />
                    )}

                    <Heading
                        size="md"
                        my=".5em"
                    >
                        {`${property.street}, ${property.postalCode} ${property.city}`}
                    </Heading>
                    <Property.PropertyDetails property={property} />
                </>
            }
        />
    );

    const companyOffers = (
        <Section
            collapsable
            title={t(`${translationNS}.companyOffers.title`)}
            content={
                <CompanyOffers
                    enquiry={enquiry}
                    user={user}
                    companyMatchInfoList={companyMatchInfoList}
                    usersList={usersList}
                    offers={offers}
                    onUpdate={onUpdate}
                />
            }
        />
    );

    return (
        <>
            <VStack>
                {piCoverWarning}
                {requestedValuation}
                {propertyDetails}
            </VStack>

            {device !== "mobile" && device !== "tablet" && (
                <VStack>
                    {map}
                    {streetView}
                </VStack>
            )}

            <VStack>
                {companyOffers}
                {myOffer}
            </VStack>
        </>
    );
};

export default SinglePropertyLayout;
