import { Button, useDisclosure } from "@chakra-ui/react";
import { object, string } from "yup";
import { useRole } from "../../../../common/hooks";
import { UserType } from "../../../../types";
import { useCompanySetAccess } from "../../../../utils/api/hooks";
import ModalDynamic from "../../common/components/ModalDynamic";
import { Forms } from "../../common/forms/Forms";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useToast from "../../common/hooks/useToast";

const validationSchema = object({
    role: string().required("forms.errors.required"),
});

type PropTypes = {
    user: UserType;
    updateSuccess: () => void;
};

const useTeamMemberUpdateRoleAccess = (props: PropTypes) => {
    const {
        user: { id: userId, entityId, role },
        updateSuccess,
    } = props;

    const t = useSmartTranslation();

    const { userIsClient } = useRole();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { createToast } = useToast();

    const { update: updateAccess } = useCompanySetAccess({
        entityId: entityId,
        onSuccess: () => {
            updateSuccess();
            onClose();
        },
        onError: (error) => {
            createToast({
                description: error.message,
                status: "error",
            });
        },
    });

    const initialValues = {
        role,
    };

    // render

    const updateRoleAccessModal = (
        <ModalDynamic
            size="xl"
            isOpen={isOpen}
            header={t("account.teamInvites.role")}
            onClose={onClose}
        >
            <Forms.FormikForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    updateAccess({
                        entityId: entityId,
                        targetUserId: userId,
                        accessType: values.role,
                    });
                }}
            >
                <Forms.FormikRadioGroup
                    name="role"
                    options={[
                        {
                            label: t("domain.user.role.USER"),
                            description: t(
                                "account.teamInvites.roleDescriptions.USER"
                            ),
                            value: "USER",
                        },
                        ...(!userIsClient
                            ? [
                                  {
                                      label: t("domain.user.role.ASSISTANT"),
                                      description: t(
                                          "account.teamInvites.roleDescriptions.ASSISTANT"
                                      ),
                                      value: "ASSISTANT",
                                  },
                              ]
                            : []),
                        {
                            label: t("domain.user.role.ADMIN"),
                            description: t(
                                "account.teamInvites.roleDescriptions.ADMIN"
                            ),
                            value: "ADMIN",
                        },
                    ]}
                />

                <Button
                    variant="primary"
                    w={"calc(50% - .5rem)"}
                    mt={4}
                    type="submit"
                >
                    {t("common.button.confirm")}
                </Button>
            </Forms.FormikForm>
        </ModalDynamic>
    );

    return {
        updateRoleAccessModal,
        openUpdateRoleAccessModal: onOpen,
        closeUpdateRoleAccessModal: onClose,
    };
};

export default useTeamMemberUpdateRoleAccess;
