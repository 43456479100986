import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';

type PropTypes = {
    variant?: string;
    children: ReactNode | ReactNode[];
    isOpen: boolean;
    header: ReactElement | string;
    onClose: () => void;
};

const ModalDynamic = (props: PropTypes) => {
    const {
        variant = 'large',
        children,
        isOpen,
        header,
        onClose,
    } = props;

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose()}
            variant={variant}
        >
            <ModalOverlay />

            <ModalBody>
                <ModalContent>
                    <ModalCloseButton />

                    <ModalHeader
                        color="black"
                        fontWeight="500"
                        fontSize="2xl"
                        paddingBottom="0"
                        paddingInline="1rem"
                    >
                        {header}
                    </ModalHeader>

                    <Box
                        p="1rem"
                        pt="0"
                    >
                        {children}
                    </Box>
                </ModalContent>
            </ModalBody>
        </Modal>
    );
};

export default ModalDynamic;
