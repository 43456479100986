export const translations = {
    de: {
        at: " bei ",
        client: "Kunde",
        companyAdmin: "Firmen-Administration",
        institutionalClient: "Institutioneller Kunde",
        obscuredClient: "Anonymisierter Kunde",
        privateClient: "Privatkunde",
        previewDrawerTitle: "Ansicht des Kunden",
        role: {
            ADMIN: "Administrator",
            ASSISTANT: "Assistent",
            USER: "Normaler Nutzer",
        },
        valuer: "Gutachter",
        valuers: "Gutachter",
        columns: {
            user: "Benutzer",
            createdAt: "Beitritt",
            role: "Rolle",
            email: "E-Mail Adresse",
        },
    },
    en: {
        at: " at ",
        client: "Client",
        companyAdmin: "Company Admin",
        institutionalClient: "Institutional Client",
        obscuredClient: "Obscured Client",
        privateClient: "Private End Customer",
        previewDrawerTitle: "Client Information",
        role: {
            ADMIN: "Admin",
            ASSISTANT: "Assistant",
            USER: "Regular user",
        },
        valuer: "valuer",
        valuers: "valuers",
        columns: {
            user: "User",
            createdAt: "Joined",
            role: "Role",
            email: "E-mail address",
        },
    },
};
