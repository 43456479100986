import { Flex } from "@chakra-ui/layout";
import DocViewer, {
    BMPRenderer,
    FileLoaderFuncProps,
    IDocument,
    JPGRenderer,
    PDFRenderer,
    PNGRenderer,
    TIFFRenderer,
    TXTRenderer,
} from "react-doc-viewer";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/cjs/Page/AnnotationLayer.css";
import "react-pdf/dist/cjs/Page/TextLayer.css";
import { auth } from "../../../../utils/firebase";
import MSDocRenderer from "./MSDocRenderer";

/*
 * Workaround because in plugin core they are trying to load it by relative path and it doesn't work.
 * @source: https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#import-worker-recommended
 */
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "https://unpkg.com/pdfjs-dist@4.3.136/build/pdf.worker.min.mjs"
).toString();

/**
 * Custom file loader with "Bearer" token
 */
type ReaderTypeFunction = "dataURL" | "arrayBuffer" | "binaryString" | "text";

const customFileLoader = async ({
    documentURI,
    signal,
    fileLoaderComplete,
    readerTypeFunction,
}: { readerTypeFunction?: ReaderTypeFunction } & FileLoaderFuncProps) => {
    const token = await auth.currentUser?.getIdToken();
    return fetch(documentURI, {
        signal,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then(async (res) => {
            const blob = await res.blob();

            const fileReader = new FileReader();
            fileReader.addEventListener("loadend", () =>
                fileLoaderComplete(fileReader)
            );

            switch (readerTypeFunction) {
                case "arrayBuffer":
                    fileReader.readAsArrayBuffer(blob);
                    break;
                case "binaryString":
                    fileReader.readAsBinaryString(blob);
                    break;
                case "dataURL":
                    fileReader.readAsDataURL(blob);
                    break;
                case "text":
                    fileReader.readAsText(blob);
                    break;
                default:
                    fileReader.readAsDataURL(blob);
            }
        })
        .catch((e) => {
            return e;
        });
};

PDFRenderer.fileLoader = customFileLoader;
PNGRenderer.fileLoader = customFileLoader;
JPGRenderer.fileLoader = customFileLoader;
MSDocRenderer.fileLoader = customFileLoader;

type DocumentViewerProps = {
    documents: IDocument[];
    height?: string;
};

const DocumentViewer = (props: DocumentViewerProps) => {
    const { documents, height = "100%" } = props;

    return (
        <Flex
            grow={1}
            height={height}
        >
            <DocViewer
                documents={documents}
                pluginRenderers={[
                    BMPRenderer,
                    JPGRenderer,
                    PDFRenderer,
                    PNGRenderer,
                    TIFFRenderer,
                    TXTRenderer,
                    MSDocRenderer,
                ]}
                config={{
                    header: {
                        disableHeader: true,
                    },
                }}
                style={{
                    width: "100%",
                }}
            />
        </Flex>
    );
};

export default DocumentViewer;
