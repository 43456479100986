import { DeleteIcon } from "@chakra-ui/icons";
import {
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Heading,
    HStack,
    Link,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import PublicCompanyProfilePicture from "../../../../../../../common/components/PublicCompanyProfilePicture";
import toUrl from "../../../../../../../common/functions/toUrl";
import { EntityType } from "../../../../../../../types";
import { usePermissions } from "../../../../../app/permissions/usePermissions";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { InformationRowItem } from "../../InformationRowItem";
import RenderFieldData from "../../RenderFieldData";
import useCustomersLeave from "./useCustomersLeave";

type PropTypes = {
    entity: EntityType;
    onCloseDetails: () => void;
};

const CustomersDetailsDrawer = (props: PropTypes) => {
    const {
        entity: { id: entityId, name },
        entity,
        onCloseDetails,
    } = props;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { permission: canRemove } = usePermissions({
        activity: "RemoveMeFromPanel",
        datatype: "Entity",
        objects: entity,
    });

    const { leavePanelModal, openLeavePanelModal } = useCustomersLeave({
        inviteId: entityId,
    });

    useEffect(() => {
        if (!entity) return;

        onOpen();
    }, []);

    // render

    const header = (
        <HStack justify={"space-between"}>
            <HStack spacing="4">
                <PublicCompanyProfilePicture
                    size="2.5rem"
                    companyId={entityId}
                />

                <Heading fontSize={"lg"}>{name}</Heading>
            </HStack>

            {canRemove && (
                <>
                    <Button
                        variant={"outline"}
                        colorScheme={"red"}
                        size={"xs"}
                        leftIcon={<DeleteIcon />}
                        onClick={openLeavePanelModal}
                    >
                        {t("account.customerPanelDetails.leavePanel.title")}
                    </Button>
                    {leavePanelModal}
                </>
            )}
        </HStack>
    );

    const contactInformation = <ContactInformation entity={entity} />;

    return (
        <Drawer
            isOpen={isOpen}
            onClose={() => {
                onClose();
                onCloseDetails();
            }}
            size="lg"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader
                    fontSize={"xl"}
                    color={"blue.700"}
                >
                    {t("account.teamDetails.teamMemberPreview.title")}
                </DrawerHeader>

                <DrawerBody>
                    {header}
                    {contactInformation}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

type ContactInformationProps = {
    entity: EntityType;
};

const ContactInformation = (props: ContactInformationProps) => {
    const {
        entity: {
            websiteAddress = "",
            companyEmail,
            contactFirstName,
            contactLastName,
            contactNumberDialCode,
            contactNumber,
            street,
            postalCode,
            city,
            country,
            accountType,
        },
    } = props;

    const t = useSmartTranslation();

    return (
        <VStack
            align={"stretch"}
            spacing={1}
        >
            <Divider my={4} />

            <Heading
                size="md"
                variant="blue"
                mb={3}
            >
                {t("account.companyDetails.contactInfo")}
            </Heading>
            <InformationRowItem
                label={t("account.companyDetails.contactPerson")}
                content={
                    <RenderFieldData
                        field="contactPerson"
                        data={[contactFirstName, contactLastName]}
                    />
                }
            />
            <InformationRowItem
                label={t("account.companyDetails.email")}
                content={
                    <RenderFieldData
                        field="email"
                        data={[companyEmail]}
                        config={{ required: accountType !== "CLIENT" }}
                    />
                }
            />
            <InformationRowItem
                label={t("account.companyDetails.phoneNumber")}
                content={
                    <RenderFieldData
                        field="phoneNumber"
                        data={[contactNumberDialCode, contactNumber]}
                    />
                }
            />
            <InformationRowItem
                label={t("account.companyDetails.location")}
                content={
                    <RenderFieldData
                        field="location"
                        data={[street, postalCode, city, country]}
                        config={{ joinWith: ", ", required: true }}
                    />
                }
            />
            <InformationRowItem
                label={t("account.companyDetails.website")}
                content={
                    websiteAddress ? (
                        <Link
                            color="blue.500"
                            href={toUrl(websiteAddress)}
                            isExternal
                            textOverflow="elipsis"
                            noOfLines={1}
                        >
                            {websiteAddress}
                        </Link>
                    ) : (
                        <Text
                            fontSize={"sm"}
                            fontWeight={"700"}
                        >
                            {t("profile.fieldNotProvided.website")}
                        </Text>
                    )
                }
            />
        </VStack>
    );
};

export default CustomersDetailsDrawer;
