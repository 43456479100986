/* eslint-disable max-len */
import { useQuery, useQueryClient } from "react-query";
import useSmartTranslation from "../../../common/hooks/useSmartTranslation";
import useToast from "../../../ui/v2/common/hooks/useToast";

type ParamsType<ReturnDataType> = {
    uniqueName: string;
    dependancies: (string | number | boolean | undefined)[];
    requiredParams: (string | number | boolean)[];
    queryFunction: () => Promise<ReturnDataType>;
};

/**
 * Creates a query hook that can be used to fetch data from the server.
 * @template ReturnDataType - The type of the data returned in the data object.
 * @param params.uniqueName - A unique name for the query.
 * @param params.dependancies - Dependancies for the query, if any of these change it will trigger a refetch.
 * @param params.requiredParams - Params that must be truthy for the query to be enabled.
 * @param params.queryFunction - The endpoint function.
 * @returns return.data - The data returned from the query.
 * @returns return.error - The error returned from the query.
 * @returns return.isLoading - A boolean indicating if the query is loading.
 * @returns return.refresh - A function to manually refresh the query.
 */
function useQueryHook<ReturnDataType>(params: ParamsType<ReturnDataType>) {
    const { uniqueName, dependancies, requiredParams, queryFunction } = params;

    const t = useSmartTranslation("errors");
    const { createToast } = useToast();

    const queryClient = useQueryClient();

    const { data, error, isLoading } = useQuery<ReturnDataType, Error>(
        [uniqueName, ...dependancies],
        queryFunction,
        {
            enabled: requiredParams.every((param) => !!param),
            onError: (err: Error) =>
                createToast({
                    description: t(err.message, { defaultValue: err.message }),
                    status: "error",
                }),
        }
    );

    const refresh = () => queryClient.invalidateQueries(uniqueName);

    return {
        data,
        error,
        isLoading,
        refresh,
    };
}

export default useQueryHook;
