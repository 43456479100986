export const completionImpulseTranslations = {
    de: {
        start: "Start",
        required: "ERFORDERLICH FÜR DEN ABGLEICH",
        companyOnboarding: {
            title: "Onboarding Ihres Unternehmens",
            description:
                "Bitte vervollständigen Sie diese wichtigen und empfohlenen Unternehmensinformationen, damit das System Ihnen passende Anfragen zuordnen kann.",
        },
        completeProfile: {
            title: "Mein Profil vervollständigen",
            completionTime: "~1-5 min.",
        },
    },
    en: {
        start: "Start",
        required: "REQUIRED FOR MATCHING",
        companyOnboarding: {
            title: "Company onboarding",
            description:
                "Complete these crucial and recommended company information in order to be effectively matched to incoming enquiries.",
        },
        completeProfile: {
            title: "Complete my profile",
            completionTime: "~1-5 min.",
        },
    },
};
