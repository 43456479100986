import FormikCheckbox from "./FormikCheckbox";
import FormikCloudSelect from "./FormikCloudSelect";
import FormikConditionalSection from "./FormikConditionalSection";
import FormikCountrySelect from "./FormikCountrySelect";
import FormikCurrencyInput from "./FormikCurrencyInput";
import FormikForm from "./FormikForm";
import FormikInput from "./FormikInput";
import FormikListInput from "./FormikListInput";
import FormikNumberInput from "./FormikNumberInput";
import FormikPassword from "./FormikPassword";
import FormikPhoneNumberInput from "./FormikPhoneNumberInput";
import FormikPinInput from "./FormikPinInput";
import FormikRadioGroup from "./FormikRadioGroup";
import FormikSelect from "./FormikSelect";
import FormikSwitch from "./FormikSwitch";
import { FormikWarning } from "./FormikWarning";
import { FormikWarningList } from "./FormikWarningList";
import { translations } from "./i18n";

export const Forms = {
    FormikForm,
    FormikCloudSelect,
    FormikCheckbox,
    FormikConditionalSection,
    FormikCountrySelect,
    FormikCurrencyInput,
    FormikInput,
    FormikListInput,
    FormikNumberInput,
    FormikPassword,
    FormikPinInput,
    FormikPhoneNumberInput,
    FormikRadioGroup,
    FormikSelect,
    FormikSwitch,
    FormikWarning,
    FormikWarningList,
    translations,
};
