import { Flex, VStack, Spacer, Button, Text, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

type customizationListType = {
    customizeList: customizeItemType[];
};

const CustomizeExperience = (props: customizationListType) => {
    const { customizeList } = props;

    return (
        <VStack
            w="100%"
            spacing={0}
            border="1px"
            borderBottom={0}
            borderColor="gray.100"
        >
            {customizeList.map((item) => (
                <CustomizeItem
                    key={item.title}
                    title={item.title}
                    subtext={item.subtext}
                    link={item.link}
                    headingColorRed={item.headingColorRed}
                />
            ))}
        </VStack>
    );
};

// helper
type customizeItemType = {
    title: string;
    subtext: string;
    headingColorRed?: boolean;
    link: {
        label: string;
        nav: string;
    };
};

const CustomizeItem = (props: customizeItemType) => {
    const { title, subtext, link, headingColorRed } = props;

    return (
        <Flex
            w="100%"
            gap="4"
            align="center"
            padding="4"
            borderBottom="1px"
            borderColor="gray.100"
        >
            <VStack
                align="left"
                spacing={0}
            >
                <Heading
                    variant={headingColorRed ? "red" : "grey"}
                    size="sm"
                >
                    {title}
                </Heading>
                <Text
                    variant="secondary"
                    fontSize="sm"
                >
                    {subtext}
                </Text>
            </VStack>

            <Spacer />

            <Link to={link.nav}>
                <Button
                    variant="outline"
                    colorScheme="blue"
                    w="10rem"
                >
                    {link.label}
                </Button>
            </Link>
        </Flex>
    );
};

export default CustomizeExperience;
