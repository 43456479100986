export const translations = {
    de: {
        countries: {
            Germany: "Deutschland",
            "United Kingdom": "Vereinigtes Königreich",
            Switzerland: "Schweiz",
            Austria: "Österreich",
            France: "Frankreich",
            Luxembourg: "Luxemburg",
            Afghanistan: "Afghanistan",
            "Aland Islands": "Ålandinseln",
            Albania: "Albanien",
            Algeria: "Algerien",
            AmericanSamoa: "Amerikanisch-Samoa",
            Andorra: "Andorra",
            Angola: "Angola",
            Anguilla: "Anguilla",
            Antarctica: "Antarktis",
            "Antigua and Barbuda": "Antigua und Barbuda",
            Argentina: "Argentinien",
            Armenia: "Armenien",
            Aruba: "Aruba",
            Australia: "Australien",
            Azerbaijan: "Aserbaidschan",
            Bahamas: "Bahamas",
            Bahrain: "Bahrain",
            Bangladesh: "Bangladesch",
            Barbados: "Barbados",
            Belarus: "Weißrussland",
            Belgium: "Belgien",
            Belize: "Belize",
            Benin: "Benin",
            Bermuda: "Bermuda",
            Bhutan: "Bhutan",
            "Bolivia, Plurinational State of": "Bolivien",
            "Bosnia and Herzegovina": "Bosnien und Herzegowina",
            Botswana: "Botsuana",
            Brazil: "Brasilien",
            "British Indian Ocean Territory": "British Indian Ocean Territory",
            "Brunei Darussalam": "Brunei Darussalam",
            Bulgaria: "Bulgarien",
            "Burkina Faso": "Burkina Faso",
            Burundi: "Burundi",
            Cambodia: "Kambodscha",
            Cameroon: "Kamerun",
            Canada: "Kanada",
            "Cape Verde": "Kap Verde",
            "Cayman Islands": "Caymaninseln",
            "Central African Republic": "Zentralafrikanische Republik",
            Chad: "Tschad",
            Chile: "Chile",
            China: "China",
            "Christmas Island": "Weihnachtsinsel",
            "Cocos (Keeling) Islands": "Kokosinseln",
            Colombia: "Kolumbien",
            Comoros: "Komoren",
            Congo: "Kongo",
            "Congo, The Democratic Republic of the Congo":
                "Demokratische Republik Kongo",
            "Cook Islands": "Cookinseln",
            "Costa Rica": "Costa Rica",
            "Cote d'Ivoire": "Elfenbeinküste",
            Croatia: "Kroatien",
            Cuba: "Kuba",
            Cyprus: "Zypern",
            "Czech Republic": "Tschechien",
            Denmark: "Dänemark",
            Djibouti: "Dschibuti",
            Dominica: "Dominica",
            "Dominican Republic": "Dominikanische Republik",
            Ecuador: "Ecuador",
            Egypt: "Ägypten",
            "El Salvador": "El Salvador",
            "Equatorial Guinea": "Äquatorialguinea",
            Eritrea: "Eritrea",
            Estonia: "Estland",
            Ethiopia: "Äthiopien",
            "Falkland Islands (Malvinas)": "Falklandinseln",
            "Faroe Islands": "Färöer (Färöer-Inseln)",
            Fiji: "Fidschi",
            Finland: "Finnland",
            "French Guiana": "Französisch-Guayana",
            "French Polynesia": "Französisch-Polynesien",
            Gabon: "Gabun",
            Gambia: "Gambia",
            Georgia: "Georgien",
            Ghana: "Ghana",
            Gibraltar: "Gibraltar",
            Greece: "Griechenland",
            Greenland: "Grönland",
            Grenada: "Grenada",
            Guadeloupe: "Guadeloupe",
            Guam: "Guam",
            Guatemala: "Guatemala",
            Guernsey: "Guernsey",
            Guinea: "Guinea",
            "Guinea-Bissau": "Guinea-Bissau",
            Guyana: "Guyana",
            Haiti: "Haiti",
            "Holy See (Vatican City State)": "Holy See (Vatican City State)",
            Honduras: "Honduras",
            "Hong Kong": "Hong Kong",
            Hungary: "Ungarn",
            Iceland: "Island",
            India: "Indien",
            Indonesia: "Indonesien",
            "Iran, Islamic Republic of Persian Gulf": "Iran (Persien)",
            Iraq: "Irak",
            Ireland: "Irland",
            "Isle of Man": "Isle of Man",
            Israel: "Israel",
            Italy: "Italien",
            Jamaica: "Jamaica",
            Japan: "Japan",
            Jersey: "Jersey",
            Jordan: "Jordanien",
            Kazakhstan: "Kasachstan",
            Kenya: "Kenia",
            Kiribati: "Kiribati",
            "Korea, Democratic People's Republic of Korea": "Nordkorea",
            "Korea, Republic of South Korea": "Südkorea (Republik Korea)",
            Kuwait: "Kuwait",
            Kyrgyzstan: "Kirgisistan",
            Laos: "Laos",
            Latvia: "Lettland",
            Lebanon: "Libanon",
            Lesotho: "Lesotho",
            Liberia: "Liberia",
            "Libyan Arab Jamahiriya": "Libyen",
            Liechtenstein: "Liechtenstein",
            Lithuania: "Litauen",
            Macao: "Macao",
            Macedonia: "Nordmazedonien",
            Madagascar: "Madagaskar",
            Malawi: "Malawi",
            Malaysia: "Malaysia",
            Maldives: "Malediven",
            Mali: "Mali",
            Malta: "Malta",
            "Marshall Islands": "Marshallinseln",
            Martinique: "Martinique",
            Mauritania: "Mauritanien",
            Mauritius: "Mauritius",
            Mayotte: "Mayotte",
            Mexico: "Mexico",
            "Micronesia, Federated States of Micronesia":
                "Föderierte Staaten von Mikronesien",
            Moldova: "Moldavien",
            Monaco: "Monaco",
            Mongolia: "Mongolei",
            Montenegro: "Montenegro",
            Montserrat: "Montserrat",
            Morocco: "Marokko",
            Mozambique: "Mosambik",
            Myanmar: "Myanmar",
            Namibia: "Namibia",
            Nauru: "Nauru",
            Nepal: "Nepal",
            Netherlands: "Niederlande",
            "Netherlands Antilles": "Netherlands Antilles",
            "New Caledonia": "Neukaledonien",
            "New Zealand": "Neuseeland",
            Nicaragua: "Nicaragua",
            Niger: "Niger",
            Nigeria: "Nigeria",
            Niue: "Niue",
            "Norfolk Island": "Norfolk Island",
            "Northern Mariana Islands": "Nördliche Marianen",
            Norway: "Norwegen",
            Oman: "Oman",
            Pakistan: "Pakistan",
            Palau: "Palau",
            "Palestinian Territory, Occupied": "Staat Palästina",
            Panama: "Panama",
            "Papua New Guinea": "Papua-Neuguinea",
            Paraguay: "Paraguay",
            Peru: "Peru",
            Philippines: "Philippinen",
            Pitcairn: "Pitcairn",
            Poland: "Polen",
            Portugal: "Portugal",
            "Puerto Rico": "Puerto Rico",
            Qatar: "Katar",
            Romania: "Rumänien",
            Russia: "Russland",
            Rwanda: "Ruanda",
            Reunion: "Reunion",
            "Saint Barthelemy": "Saint Barthelemy",
            "Saint Helena, Ascension and Tristan Da Cunha": "St. Helena",
            "Saint Kitts and Nevis": "St. Kitts und Nevis",
            "Saint Lucia": "Saint Lucia",
            "Saint Martin": "Saint Martin",
            "Saint Pierre and Miquelon": "Saint Pierre und Miquelon",
            "Saint Vincent and the Grenadines":
                "St. Vincent und die Grenadinen",
            Samoa: "Samoa",
            "San Marino": "San Marino",
            "Sao Tome and Principe": "Sao Tome und Principe",
            "Saudi Arabia": "Saudi-Arabien",
            Senegal: "Senegal",
            Serbia: "Serbien",
            Seychelles: "Seychellen",
            "Sierra Leone": "Sierra Leone",
            Singapore: "Singapur",
            Slovakia: "Slowakei",
            Slovenia: "Slovenien",
            "Solomon Islands": "Salomonen",
            Somalia: "Somalia",
            "South Africa": "Südafrika",
            "South Sudan": "Südsudan",
            "South Georgia and the South Sandwich Islands":
                "Südgeorgien und die Südlichen Sandwichinseln",
            Spain: "Spanien",
            "Sri Lanka": "Sri Lanka",
            Sudan: "Sudan",
            Suriname: "Suriname",
            "Svalbard and Jan Mayen": "Svalbard und Jan Mayen",
            Swaziland: "Swasiland",
            Sweden: "Schweden",
            "Syrian Arab Republic": "Syrien",
            Taiwan: "Taiwan",
            Tajikistan: "Tadschikistan",
            "Tanzania, United Republic of Tanzania": "Tansania",
            Thailand: "Thailand",
            "Timor-Leste": "Osttimor",
            Togo: "Togo",
            Tokelau: "Tokelau",
            Tonga: "Tonga",
            "Trinidad and Tobago": "Trinidad und Tobago",
            Tunisia: "Tunesien",
            Turkey: "Türkei",
            Turkmenistan: "Turkmenistan",
            "Turks and Caicos Islands": "Turks- und Caicosinseln",
            Tuvalu: "Tuvalu",
            Uganda: "Uganda",
            Ukraine: "Ukraine",
            "United Arab Emirates": "Vereinigte Arabische Emirate",
            "United States": "Vereinigte Staaten",
            Uruguay: "Uruguay",
            Uzbekistan: "Uzbekistan",
            Vanuatu: "Vanuatu",
            "Venezuela, Bolivarian Republic of Venezuela":
                "Venezuela, Bolivarian Republic of Venezuela",
            Vietnam: "Vietnam",
            "Virgin Islands, British": "Britische Jungferninseln",
            "Virgin Islands, U.S.": "Amerikanische Jungferninseln",
            "Wallis and Futuna": "Wallis und Futuna",
            Yemen: "Jemen",
            Zambia: "Sambia",
            Zimbabwe: "Simbabwe",
            "----------------": "----------------",
        },
        errors: {
            invalidEmail: "Ungültige E-Mail Adresse",
            minLowercase:
                "Das Passwort muss mindestens 1 Kleinbuchstaben enthalten",
            minNumbers: "Das Passwort muss mindestens 1 Zahl enthalten",
            minSymbols:
                "Das Passwort muss mindestens 1 Sonderzeichen enthalten",
            minUppercase:
                "Das Passwort muss mindestens 1 Großbuchstaben enthalten",
            enterActualNumber: "Bitte geben Sie eine Zahl ein",
            enterNumber: "Bitte geben Sie eine Telefonnummer ein",
            required: "Erforderlich",
            selectOption: "Bitte wählen Sie eine Option aus",
            tooLong: "Zu lang",
            tooShort: "Zu kurz",
        },
        fieldPlaceholder: {
            country: "Land",
        },
        warnings: {
            deliveryDateDoesNotMatchEnquiryRequirement: 'Liefertermin entspricht nicht den Anfrageanforderungen.'
        }
    },
    en: {
        countries: {
            Germany: "Germany",
            "United Kingdom": "United Kingdom",
            Switzerland: "Switzerland",
            Austria: "Austria",
            France: "France",
            Luxembourg: "Luxembourg",
            Afghanistan: "Afghanistan",
            "Aland Islands": "Aland Islands",
            Albania: "Albania",
            Algeria: "Algeria",
            AmericanSamoa: "American Samoa",
            Andorra: "Andorra",
            Angola: "Angola",
            Anguilla: "Anguilla",
            Antarctica: "Antarctica",
            "Antigua and Barbuda": "Antigua and Barbuda",
            Argentina: "Argentina",
            Armenia: "Armenia",
            Aruba: "Aruba",
            Australia: "Australia",
            Azerbaijan: "Azerbaijan",
            Bahamas: "Bahamas",
            Bahrain: "Bahrain",
            Bangladesh: "Bangladesh",
            Barbados: "Barbados",
            Belarus: "Belarus",
            Belgium: "Belgium",
            Belize: "Belize",
            Benin: "Benin",
            Bermuda: "Bermuda",
            Bhutan: "Bhutan",
            "Bolivia, Plurinational State of":
                "Bolivia, Plurinational State of",
            "Bosnia and Herzegovina": "Bosnia and Herzegovina",
            Botswana: "Botswana",
            Brazil: "Brazil",
            "British Indian Ocean Territory": "British Indian Ocean Territory",
            "Brunei Darussalam": "Brunei Darussalam",
            Bulgaria: "Bulgaria",
            "Burkina Faso": "Burkina Faso",
            Burundi: "Burundi",
            Cambodia: "Cambodia",
            Cameroon: "Cameroon",
            Canada: "Canada",
            "Cape Verde": "Cape Verde",
            "Cayman Islands": "Cayman Islands",
            "Central African Republic": "Central African Republic",
            Chad: "Chad",
            Chile: "Chile",
            China: "China",
            "Christmas Island": "Christmas Island",
            "Cocos (Keeling) Islands": "Cocos (Keeling) Islands",
            Colombia: "Colombia",
            Comoros: "Comoros",
            Congo: "Congo",
            "Congo, The Democratic Republic of the Congo":
                "Congo, The Democratic Republic of the Congo",
            "Cook Islands": "Cook Islands",
            "Costa Rica": "Costa Rica",
            "Cote d'Ivoire": "Cote d'Ivoire",
            Croatia: "Croatia",
            Cuba: "Cuba",
            Cyprus: "Cyprus",
            "Czech Republic": "Czech Republic",
            Denmark: "Denmark",
            Djibouti: "Djibouti",
            Dominica: "Dominica",
            "Dominican Republic": "Dominican Republic",
            Ecuador: "Ecuador",
            Egypt: "Egypt",
            "El Salvador": "El Salvador",
            "Equatorial Guinea": "Equatorial Guinea",
            Eritrea: "Eritrea",
            Estonia: "Estonia",
            Ethiopia: "Ethiopia",
            "Falkland Islands (Malvinas)": "Falkland Islands (Malvinas)",
            "Faroe Islands": "Faroe Islands",
            Fiji: "Fiji",
            Finland: "Finland",
            "French Guiana": "French Guiana",
            "French Polynesia": "French Polynesia",
            Gabon: "Gabon",
            Gambia: "Gambia",
            Georgia: "Georgia",
            Ghana: "Ghana",
            Gibraltar: "Gibraltar",
            Greece: "Greece",
            Greenland: "Greenland",
            Grenada: "Grenada",
            Guadeloupe: "Guadeloupe",
            Guam: "Guam",
            Guatemala: "Guatemala",
            Guernsey: "Guernsey",
            Guinea: "Guinea",
            "Guinea-Bissau": "Guinea-Bissau",
            Guyana: "Guyana",
            Haiti: "Haiti",
            "Holy See (Vatican City State)": "Holy See (Vatican City State)",
            Honduras: "Honduras",
            "Hong Kong": "Hong Kong",
            Hungary: "Hungary",
            Iceland: "Iceland",
            India: "India",
            Indonesia: "Indonesia",
            "Iran, Islamic Republic of Persian Gulf":
                "Iran, Islamic Republic of Persian Gulf",
            Iraq: "Iraq",
            Ireland: "Ireland",
            "Isle of Man": "Isle of Man",
            Israel: "Israel",
            Italy: "Italy",
            Jamaica: "Jamaica",
            Japan: "Japan",
            Jersey: "Jersey",
            Jordan: "Jordan",
            Kazakhstan: "Kazakhstan",
            Kenya: "Kenya",
            Kiribati: "Kiribati",
            "Korea, Democratic People's Republic of Korea":
                "Korea, Democratic People's Republic of Korea",
            "Korea, Republic of South Korea": "Korea, Republic of South Korea",
            Kuwait: "Kuwait",
            Kyrgyzstan: "Kyrgyzstan",
            Laos: "Laos",
            Latvia: "Latvia",
            Lebanon: "Lebanon",
            Lesotho: "Lesotho",
            Liberia: "Liberia",
            "Libyan Arab Jamahiriya": "Libyan Arab Jamahiriya",
            Liechtenstein: "Liechtenstein",
            Lithuania: "Lithuania",
            Macao: "Macao",
            Macedonia: "Macedonia",
            Madagascar: "Madagascar",
            Malawi: "Malawi",
            Malaysia: "Malaysia",
            Maldives: "Maldives",
            Mali: "Mali",
            Malta: "Malta",
            "Marshall Islands": "Marshall Islands",
            Martinique: "Martinique",
            Mauritania: "Mauritania",
            Mauritius: "Mauritius",
            Mayotte: "Mayotte",
            Mexico: "Mexico",
            "Micronesia, Federated States of Micronesia":
                "Micronesia, Federated States of Micronesia",
            Moldova: "Moldova",
            Monaco: "Monaco",
            Mongolia: "Mongolia",
            Montenegro: "Montenegro",
            Montserrat: "Montserrat",
            Morocco: "Morocco",
            Mozambique: "Mozambique",
            Myanmar: "Myanmar",
            Namibia: "Namibia",
            Nauru: "Nauru",
            Nepal: "Nepal",
            Netherlands: "Netherlands",
            "Netherlands Antilles": "Netherlands Antilles",
            "New Caledonia": "New Caledonia",
            "New Zealand": "New Zealand",
            Nicaragua: "Nicaragua",
            Niger: "Niger",
            Nigeria: "Nigeria",
            Niue: "Niue",
            "Norfolk Island": "Norfolk Island",
            "Northern Mariana Islands": "Northern Mariana Islands",
            Norway: "Norway",
            Oman: "Oman",
            Pakistan: "Pakistan",
            Palau: "Palau",
            "Palestinian Territory, Occupied":
                "Palestinian Territory, Occupied",
            Panama: "Panama",
            "Papua New Guinea": "Papua New Guinea",
            Paraguay: "Paraguay",
            Peru: "Peru",
            Philippines: "Philippines",
            Pitcairn: "Pitcairn",
            Poland: "Poland",
            Portugal: "Portugal",
            "Puerto Rico": "Puerto Rico",
            Qatar: "Qatar",
            Romania: "Romania",
            Russia: "Russia",
            Rwanda: "Rwanda",
            Reunion: "Reunion",
            "Saint Barthelemy": "Saint Barthelemy",
            "Saint Helena, Ascension and Tristan Da Cunha":
                "Saint Helena, Ascension and Tristan Da Cunha",
            "Saint Kitts and Nevis": "Saint Kitts and Nevis",
            "Saint Lucia": "Saint Lucia",
            "Saint Martin": "Saint Martin",
            "Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
            "Saint Vincent and the Grenadines":
                "Saint Vincent and the Grenadines",
            Samoa: "Samoa",
            "San Marino": "San Marino",
            "Sao Tome and Principe": "Sao Tome and Principe",
            "Saudi Arabia": "Saudi Arabia",
            Senegal: "Senegal",
            Serbia: "Serbia",
            Seychelles: "Seychelles",
            "Sierra Leone": "Sierra Leone",
            Singapore: "Singapore",
            Slovakia: "Slovakia",
            Slovenia: "Slovenia",
            "Solomon Islands": "Solomon Islands",
            Somalia: "Somalia",
            "South Africa": "South Africa",
            "South Sudan": "South Sudan",
            "South Georgia and the South Sandwich Islands":
                "South Georgia and the Sandwich Islands",
            Spain: "Spain",
            "Sri Lanka": "Sri Lanka",
            Sudan: "Sudan",
            Suriname: "Suriname",
            "Svalbard and Jan Mayen": "Svalbard and Jan Mayen",
            Swaziland: "Swaziland",
            Sweden: "Sweden",
            "Syrian Arab Republic": "Syrian Arab Republic",
            Taiwan: "Taiwan",
            Tajikistan: "Tajikistan",
            "Tanzania, United Republic of Tanzania":
                "Tanzania, United Republic of Tanzania",
            Thailand: "Thailand",
            "Timor-Leste": "Timor-Leste",
            Togo: "Togo",
            Tokelau: "Tokelau",
            Tonga: "Tonga",
            "Trinidad and Tobago": "Trinidad and Tobago",
            Tunisia: "Tunisia",
            Turkey: "Turkey",
            Turkmenistan: "Turkmenistan",
            "Turks and Caicos Islands": "Turks and Caicos Islands",
            Tuvalu: "Tuvalu",
            Uganda: "Uganda",
            Ukraine: "Ukraine",
            "United Arab Emirates": "United Arab Emirates",
            "United States": "United States",
            Uruguay: "Uruguay",
            Uzbekistan: "Uzbekistan",
            Vanuatu: "Vanuatu",
            "Venezuela, Bolivarian Republic of Venezuela":
                "Venezuela, Bolivarian Republic of Venezuela",
            Vietnam: "Vietnam",
            "Virgin Islands, British": "Virgin Islands, British",
            "Virgin Islands, U.S.": "Virgin Islands, U.S.",
            "Wallis and Futuna": "Wallis and Futuna",
            Yemen: "Yemen",
            Zambia: "Zambia",
            Zimbabwe: "Zimbabwe",
            "----------------": "----------------",
        },
        errors: {
            invalidEmail: "Invalid email address",
            minLowercase: "Password must contain at least 1 lower case letter",
            minNumbers: "Password must contain at least 1 number",
            minSymbols: "Password must contain at least 1 special character",
            minUppercase: "Password must contain at least 1 upper case letter",
            enterActualNumber: "Please enter a number",
            enterNumber: "Please enter a number",
            required: "Required",
            selectOption: "Please select an option",
            tooLong: "Too long",
            tooShort: "Too short",
        },
        fieldPlaceholder: {
            country: "Country",
        },
        warnings: {
            deliveryDateDoesNotMatchEnquiryRequirement: 'Delivery date does not match enquiry requirement.'
        }
    },
};
