import { WarningTwoIcon } from "@chakra-ui/icons";
import { HStack, Text, VStack } from "@chakra-ui/react";
import delimitNumber from "../../../../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../../../../common/functions/displayDateInLocale";
import { EntityType } from "../../../../../../types";
import { FirebaseCertificateType } from "../../../../../../utils/storage/storage";
import { getFileTypeBasedOnName } from "../../../../common/components/display/File";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { NO_DATA } from "../../../../common/NO_DATA";
import { useDocumentViewerModal } from "../../../../domain/document/useDocumentViewerModal";
import { InformationRowItem } from "../InformationRowItem";
import { FileItem } from "../profile/ProfileValuationStandards";

type CompanyDetailsPiCoverProps = {
    entity: EntityType;
    certificates?: FirebaseCertificateType[];
};

const CompanyDetailsPiCover = (props: CompanyDetailsPiCoverProps) => {
    const {
        entity: {
            piCoverCurrency,
            piCoverSpecialArrangementsAvailable,
            piCoverPercentage,
            piCoverPercentageUnlimited,
            piCoverValue,
            piCoverValueUnlimited,
            piCoverValidityDate,
        },
        certificates = [],
    } = props;

    const t = useSmartTranslation();

    const { documentView, viewDocument } = useDocumentViewerModal();

    const openDocument = (file: FirebaseCertificateType) => {
        viewDocument({
            uri: file.url,
            fileType: getFileTypeBasedOnName(file.label),
            label: file.label,
        });
    };

    const currencySymbol = piCoverCurrency || "€";

    const noLimit = t("account.valuationCapabilities.piCover.noLimit");

    let coverValue = NO_DATA;
    let coverPercentage = NO_DATA;

    if (piCoverValueUnlimited) {
        coverValue = `${currencySymbol} (${noLimit})`;
    } else {
        if (piCoverValue) {
            coverValue = `${currencySymbol} ${delimitNumber(
                piCoverValue.toString()
            )}`;
        }
    }

    if (piCoverPercentageUnlimited) {
        coverPercentage = `(${noLimit})%`;
    } else {
        if (piCoverPercentage) {
            coverPercentage = `${t(
                "account.valuationCapabilities.piCover.maximum"
            )} ${piCoverPercentage}${t(
                "account.valuationCapabilities.piCover.p_of_value"
            )}`;
        }
    }

    // render

    const coverConditions = (
        <>
            <InformationRowItem
                label={t(
                    "account.valuationCapabilities.piCover.insuranceCoverConditions"
                )}
                content={
                    <HStack spacing={1}>
                        <Text
                            fontSize={"sm"}
                            fontWeight={"700"}
                        >
                            {!piCoverValidityDate && (
                                <WarningTwoIcon color={"gray.400"} />
                            )}
                            {t("account.valuationCapabilities.piCover.or", {
                                coverValue,
                                coverPercentage,
                                interpolation: { escapeValue: false },
                            })}
                        </Text>
                    </HStack>
                }
            />
            {piCoverValidityDate && (
                <InformationRowItem
                    label={t(
                        "account.valuationCapabilities.piCover.validUntilLabel"
                    )}
                    content={
                        <HStack spacing={1}>
                            <Text
                                fontSize={"sm"}
                                fontWeight={"700"}
                            >
                                {displayDateWithCurrentLocale(
                                    piCoverValidityDate!
                                )}
                            </Text>
                        </HStack>
                    }
                />
            )}
        </>
    );

    const specialArrangements = (
        <InformationRowItem
            label={t(
                "account.valuationCapabilities.piCover.specialArrangementsAvailable"
            )}
            content={
                <Text
                    fontSize={"sm"}
                    fontWeight={"700"}
                >
                    {piCoverSpecialArrangementsAvailable
                        ? t("common.yes")
                        : t("common.no")}
                </Text>
            }
        />
    );

    const filteredCertifites = certificates.filter((cer) => cer.type === "pi");
    const piCoverCertificates = filteredCertifites.length > 0 && (
        <>
            <InformationRowItem
                label={t("account.valuationCapabilities.piCover.certificate")}
                customContent={
                    <VStack
                        spacing={2}
                        align={"stretch"}
                        width={"100%"}
                    >
                        {filteredCertifites.map((file, idx) => (
                            <FileItem
                                key={idx}
                                file={file}
                                viewDocument={() => openDocument(file)}
                            />
                        ))}
                    </VStack>
                }
            />
            {documentView}
        </>
    );

    return (
        <>
            {coverConditions}
            {specialArrangements}
            {piCoverCertificates}
        </>
    );
};

export default CompanyDetailsPiCover;
