import { defineStyle, extendTheme } from "@chakra-ui/react";
import "@fontsource-variable/inter";

export const wvwPalette = {
    wvwBackground: {
        main: "gray.100",
        container: "blackAlpha.50",
        menubarMain: "blue.700",
        menubarSecondary: "blue.600",
    },
    wvwOrange: {
        50: "#FFF8ED",
        100: "#FFE3B2",
        200: "#FFD080",
        300: "#FFB433",
        400: "#FFA100",
        500: "#ff9002",
    },
    wvwGradients: {
        blue: "linear-gradient(90deg, #10528F 25.98%, #3396BF 100%)",
        orange: "linear-gradient(90deg, #FF8F00 0%, #FFB300 100%)",
    },
};

const theme = extendTheme({
    breakpoints: {
        base: "0px",
        sm: "480px",
        md: "768px",
        lg: "1024px",
        xl: "1280px",
        "2xl": "1536px",
        "3xl": "1920px",
    },
    styles: {
        global: {
            body: {
                minHeight: "100vh",
                bg: `${wvwPalette.wvwBackground.main} !important`,
                color: "blackAlpha.800",
            },
        },
    },
    colors: {
        orange: {
            50: wvwPalette.wvwOrange[50],
            100: wvwPalette.wvwOrange[100],
            200: wvwPalette.wvwOrange[200],
            300: wvwPalette.wvwOrange[300],
            400: wvwPalette.wvwOrange[400],
            500: wvwPalette.wvwOrange[500],
        },
    },
    components: {
        Button: {
            variants: {
                primary: {
                    bg: wvwPalette.wvwOrange[400],
                    color: "black",
                    _hover: { bg: wvwPalette.wvwOrange[300] },
                },
                // TODO: temporary for unstyled views in v2
                primaryYellow: {
                    bg: wvwPalette.wvwOrange[400],
                    color: "black",
                    _hover: { bg: wvwPalette.wvwOrange[300] },
                },
                default: {
                    bg: "gray.100",
                    border: "1px solid",
                    borderColor: "gray.100",
                    color: "gray.800",
                    _hover: {
                        bg: "blue.50",
                        color: "blue.600",
                        borderColor: "currentColor",
                    },
                },
                outline: defineStyle((props) => ({
                    color: `${props.colorScheme}.500`,
                    _hover: {
                        bg: `${props.colorScheme}.50`,
                        color: `${props.colorScheme}.600`,
                    },
                })),
                solid: defineStyle((props) => ({
                    bg: `${props.colorScheme}.500`,
                    color: "white",
                    _hover: {
                        bg: `${props.colorScheme}.600`,
                    },
                })),
                // TODO: temporary for Profile redesign
                cloudSelect: {
                    color: "black",
                    bg: "none",
                    border: "1px solid #ebebeb",
                },
                cloudSelectActive: {
                    bgColor: "blue.50",
                    borderColor: "blue.50",
                    color: "blue.700",
                },
            },
        },
        Input: {
            baseStyle: {
                field: {
                    _readOnly: {
                        bg: "gray.50",
                    },
                },
                element: {
                    h: "full",
                    mr: ".5em",
                },
            },
            variants: {
                Price: {
                    field: {
                        border: "1px solid",
                        color: "green.500",
                        borderColor: "gray.200",
                        fontWeight: "bold",
                    },
                    element: {
                        color: "green.500",
                        fontWeight: "bold",
                    },
                },
            },
            sizes: {
                md: {
                    field: {
                        fontSize: "sm",
                    },
                },
            },
        },
        Select: {
            baseStyle: {
                field: {
                    background: "white",
                },
            },
            sizes: {
                md: {
                    field: {
                        fontSize: "sm",
                    },
                },
            },
        },
        Checkbox: {
            baseStyle: {
                label: {
                    flexGrow: 1,
                },
            },
            sizes: {
                md: {
                    label: {
                        fontSize: "sm",
                        lineHeight: "1.2",
                    },
                },
                lg: {
                    label: {
                        fontSize: "md",
                        lineHeight: "1.3",
                    },
                },
            },
        },
        Radio: {
            variants: {
                outline: {
                    container: {
                        width: "100%",
                        border: "1px solid",
                        borderColor: "gray.200",
                        borderRadius: "8",
                        padding: "1rem",
                        _checked: {
                            bgColor: "gray.100",
                            borderColor: "gray.100",
                        },
                    },
                },
            },
        },
        Heading: {
            variants: {
                blue: {
                    color: "blue.700",
                },
                grey: {
                    color: "gray.600",
                },
                red: {
                    color: "red.500",
                },
            },
        },
        Table: {
            baseStyle: {
                table: {
                    borderCollapse: "collapse !important",
                    maxWidth: "100%",
                },
                th: {
                    background: "gray.50",
                    fontWeight: "normal",
                    height: "1.8em",
                    textTransform: "none",
                },
                tr: {
                    bg: "white",
                    _selected: {
                        bg: "blue.50",
                    },
                },
                td: {
                    borderBottomWidth: "0 !important",
                    borderTopWidth: "1px",
                },
                tfoot: {
                    background: "gray.50",
                },
            },
            variants: {
                hoverable: {
                    tr: {
                        _hover: {
                            bg: "blue.50",
                        },
                    },
                },
                AcceptedOfferVariant: {
                    tr: {
                        _selected: {
                            bg: "green.50",
                        },
                    },
                },
            },
        },
        Text: {
            baseStyle: {
                fontSize: "initial",
            },
            variants: {
                main: {
                    color: "black",
                },
                secondary: {
                    color: "gray.500",
                },
            },
        },
        Tabs: {
            variants: {
                rounded: {
                    tab: {
                        fontSize: "sm",
                        fontWeight: "normal",
                        bg: "gray.50",
                        _selected: {
                            bg: "gray.100",
                        },
                    },
                    tablist: {
                        borderRadius: ".5rem",
                        overflow: "hidden",
                    },
                    tabpanel: {
                        px: "0",
                    },
                },
            },
        },
        Tooltip: {
            baseStyle: {
                fontSize: "sm",
                py: "1",
                borderRadius: "4",
                fontWeight: "normal",
                color: "white",
            },
        },
        Alert: {
            variants: {
                subtle: defineStyle((props) => ({
                    container: {
                        bg: `${props.colorScheme}.50`,
                        borderRadius: "lg",
                    },
                    title: {
                        fontSize: "sm",
                        color: "gray.900",
                    },
                    description: {
                        fontSize: "sm",
                        lineHeight: "1.25rem",
                        color: "gray.600",
                    },
                    icon: {
                        color: `${props.colorScheme}.500`,
                    },
                })),
            },
        },
    },
    fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
    },
});

export default theme;
