import { Icon, IconProps } from '@chakra-ui/icons';
import { OmitCommonProps } from '@chakra-ui/react';
import { JSX, SVGProps } from 'react';
// import securityShield from '../securityShield.svg';

type PropTypes = JSX.IntrinsicAttributes & OmitCommonProps<SVGProps<SVGSVGElement>, keyof IconProps> & IconProps & { as?: 'svg' | undefined; };

const WvwSecurityShield = (props: PropTypes) => (
    <Icon
        viewBox="0 0 20 26"
        h="auto"
        // eslint-disable-next-line react/jsx-props-no-spreading
        as={'svg'}
        {...props}
    >
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C9.68333 21.4167 7.77083 20.0875 6.2625 18.0125C4.75417 15.9375 4 13.6333 4 11.1V5L12 2L20 5V11.1C20 13.6333 19.2458 15.9375 17.7375 18.0125C16.2292 20.0875 14.3167 21.4167 12 22ZM10 16H14C14.2833 16 14.5208 15.9042 14.7125 15.7125C14.9042 15.5208 15 15.2833 15 15V12C15 11.7167 14.9042 11.4792 14.7125 11.2875C14.5208 11.0958 14.2833 11 14 11V10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10V11C9.71667 11 9.47917 11.0958 9.2875 11.2875C9.09583 11.4792 9 11.7167 9 12V15C9 15.2833 9.09583 15.5208 9.2875 15.7125C9.47917 15.9042 9.71667 16 10 16ZM11 11V10C11 9.71667 11.0958 9.47917 11.2875 9.2875C11.4792 9.09583 11.7167 9 12 9C12.2833 9 12.5208 9.09583 12.7125 9.2875C12.9042 9.47917 13 9.71667 13 10V11H11Z" fill="#38A169" />
        </svg>

    </Icon>
);

export default WvwSecurityShield;
