import { HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";

type WarningMessageProps = {
    label: string;
    caption?: string;
};

const WarningMessage = (props: WarningMessageProps) => {
    const { label, caption } = props;

    return (
        <HStack
            borderRadius={8}
            bg={"orange.50"}
            w={"100%"}
            spacing={2}
            p={4}
            align={"start"}
        >
            <Icon
                as={FiAlertTriangle}
                boxSize={5}
                color={"orange.500"}
            />
            <VStack
                gap={1}
                align={"stretch"}
            >
                <Text
                    fontSize={"sm"}
                    fontWeight={"700"}
                >
                    {label}
                </Text>
                {caption && (
                    <Text
                        fontSize={"sm"}
                        color={"gray.500"}
                    >
                        {caption}
                    </Text>
                )}
            </VStack>
        </HStack>
    );
};

export default WarningMessage;
