import { useDisclosure } from "@chakra-ui/react";
import { EnquiryType, OfferType } from "../../../../types";
import { OfferRespondDto } from "../../../../utils/api/dto";
import { offerRespond } from "../../../../utils/api/endpoints";
import useMutationHook from "../../../../utils/api/hooks/useMutationHook";
import { usePermissions } from "../../app/permissions/usePermissions";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

type ParamsType = {
    onSuccess?: (data?: any) => void;
    onError?: (error: Error) => void;
    refresh?: () => void;
    offer: OfferType;
    enquiry: EnquiryType | undefined;
};

const useRejectOffer = (params: ParamsType) => {
    const {
        enquiry,
        offer: { id },
        offer,
        onSuccess,
        onError,
        refresh,
    } = params;

    const t = useSmartTranslation();

    const { permission: hasPermissions } = usePermissions({
        activity: "RespondToOffer",
        datatype: "Offer",
        objects: offer,
    });

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { update: rejectOffer } = useMutationHook({
        mutationFunction: (dto: OfferRespondDto) => offerRespond(dto),
        refreshes: refresh ? [refresh] : [],
        onSuccess,
        onError,
    });

    const onConfirmCancellation = () => {
        onClose();
        rejectOffer({
            offerId: id,
            status: "REJECTED",
        });
    };

    const confirmationModal = (
        <ConfirmationModal
            isOpen={isOpen}
            onConfirmation={onConfirmCancellation}
            onClose={onClose}
            title={t("domain.offer.ACTIVITIES.reject.confirmationTitle")}
            blurb={t("domain.offer.ACTIVITIES.reject.confirmationBlurb")}
            confirmationButtonLabel={t("domain.offer.ACTIVITIES.reject.NAME")}
            confirmationButtonVariant={"negative"}
        />
    );

    return {
        rejectOffer: onOpen,
        confirmationModal,
        hasPermissions,
    };
};

export default useRejectOffer;
