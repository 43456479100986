export type StandardCompanyDocumentType =
    (typeof standardCompanyDocumentTypes)[number];

export type StandardCompanyDocumentWithValuationSamplesType = StandardCompanyDocumentType | 'sampleValuations'

export const standardCompanyDocumentTypes = [
    "marketing",
    "offer",
    "pi",
] as const;
