import { PropertyType } from "../standards/property-type/PropertyType";

export const documentTranslations = {
    de: {
        ACTIVITIES: {
            delete: {
                NAME: "Dokument löschen",
                confirmationBlurb:
                    "Sind Sie sicher, dass Sie dieses Dokument löschen möchten?",
            },
            edit: {
                NAME: "Dateinamen bearbeiten",
                alert: {
                    label: "Hinweise",
                    description:
                        "Bitte benennen Sie Ihre Dateien eindeutig. Zur einfacheren Zuordnung stellen Sie Idealerweise Ihren Firmennamen voran.",
                },
            },
        },
        columns: {
            name: "Name",
            size: "Dateigröße",
            uploadDate: "Upload Datum",
            category: "Kategorie",
        },
        category: {
            Documents: "Dokumente",
            Marketing: "Verkaufsförderung",
            Offer: "Notwendige Vertragsunterlagen",
            Pi: "Nachweisdokumente zur Haftpflichtsumme",
            SampleValuations: "Beispiel-Wertgutachten",
            ...PropertyType.translations.de,
        },
    },
    en: {
        ACTIVITIES: {
            delete: {
                NAME: "Delete Document",
                confirmationBlurb:
                    "Are you sure you want to delete this document?",
            },
            edit: {
                NAME: "Edit name",
                alert: {
                    label: "Recommendations",
                    description:
                        "Name files clearly, ideally starting with your company name for quick recognition.",
                },
            },
        },
        columns: {
            name: "Name",
            size: "Size",
            uploadDate: "Upload Date",
            category: "Category",
        },
        category: {
            Documents: "Documents",
            Marketing: "Marketing",
            Offer: "Offer Documents",
            Pi: "PI Cover Documents",
            SampleValuations: "Valuation Samples",
            ...PropertyType.translations.en,
        },
    },
};
