import { UploadedContractWithCaseAndClientAndValuerType } from "../../../../../../../types";
import BaseFilterInput from "../../BaseFilterInput";
import { FilterPropsType } from "../../Filter";

const ByContractDataFilter = (
    props: FilterPropsType<UploadedContractWithCaseAndClientAndValuerType>
) => {
    return (
        <BaseFilterInput
            {...props}
            filterGroupId="BY_CONTRACT_DATA"
            placeholder="Search by Contracts"
            searchFn={({ case: caseData }, search) => {
                const clientName = caseData?.clientUser
                    ? `${caseData?.clientUser.firstName} ${caseData?.clientUser.lastName}`
                          .toLowerCase()
                          .includes(search)
                    : false;

                const clientEntity = caseData?.clientEntity
                    ? caseData?.clientEntity.name.toLowerCase().includes(search)
                    : false;

                const valuerName = caseData?.valuerUser
                    ? `${caseData?.valuerUser.firstName} ${caseData?.valuerUser.lastName}`
                          .toLowerCase()
                          .includes(search)
                    : false;

                const valuerEntity = caseData?.valuerEntity
                    ? caseData?.valuerEntity.name.toLowerCase().includes(search)
                    : false;

                return clientName || clientEntity || valuerName || valuerEntity;
            }}
        />
    );
};

export { ByContractDataFilter };
