import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AddTentativeDateDto } from '../../dto/inspection/addTentativeDate.dto';
import useInspectionGetCaseInspections from './useInspectionGetCaseInspections';
import { addTentativeDate } from '../../endpoints';
import useToast from '../../../../ui/v2/common/hooks/useToast';

type ParamsType = {
    caseId: number;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useInspectionAddTentativeDate = (params: ParamsType) => {
    const {
        caseId: target,
        onSuccess,
        onError,
    } = params;

    const { refresh: refreshInspections } = useInspectionGetCaseInspections(target);

    const { t } = useTranslation();

    const { createToast } = useToast();

    const { mutate: update } = useMutation(
        (dto: AddTentativeDateDto) => addTentativeDate(dto),
        {
            onSuccess: () => {
                refreshInspections();
                onSuccess?.();
            },
            onError: (error: Error) => {
                createToast({
                    status: "error",
                    description: t(error.message, { defaultValue: error.message })
                });
                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useInspectionAddTentativeDate;
