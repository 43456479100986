import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { useClientInvitesCreate } from "../../../../../../../utils/api/hooks";
import FormikForm from "../../../../../common/forms/FormikForm";
import FormikInput from "../../../../../common/forms/FormikInput";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../../common/hooks/useToast";
import { useState } from "react";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import { MISSING_TRANSLATION } from "../../../../../common/MISSING_TRANSLATION";

const validationSchema = Yup.object({
    clientCompanyName: Yup.string().required("forms.errors.required"),
    clientName: Yup.string().required("forms.errors.required"),
    clientEmail: Yup.string().required("forms.errors.required"),
});

type PropTypes = {
    onSendInvite: () => void;
};

const useCustomersInviteDialog = (props: PropTypes) => {
    const { onSendInvite } = props;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { createToast } = useToast();

    const [invitationError, setInvitationError] = useState<string>();

    const cannotInviteIndividualClientInfo = invitationError && (
        <ConfirmationModal
            isOpen={invitationError !== undefined}
            blurb={t(`account.customerPanelDetails.${invitationError}`, {
                defaultValue: MISSING_TRANSLATION,
            })}
            title={t("account.customerPanelDetails.inviteAClient.title")}
            onConfirmation={() => {
                setInvitationError(undefined);
            }}
            onClose={() => {
                setInvitationError(undefined);
            }}
            hideCloseButton
            confirmationButtonVariant="neutral"
            confirmationButtonLabel={t("common.button.close")}
        />
    );

    const { update } = useClientInvitesCreate({
        onSuccess: (result) => {
            if (result.data.error) {
                setInvitationError(result.data.error);
            } else {
                onSendInvite();
                onClose();
            }
        },
        onError: (error) => {
            createToast({
                description: error.message,
                status: "error",
            });
        },
    });

    const inviteModal = (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent minWidth="2xl">
                <VStack
                    align="stretch"
                    paddingBottom="1rem"
                    spacing={0}
                >
                    <ModalHeader color="black">
                        {t("account.customerPanelDetails.inviteCustomer")}
                    </ModalHeader>

                    <Text
                        px={6}
                        color="gray.500"
                        fontSize={"sm"}
                    >
                        {t(
                            "account.customerPanelDetails.customerExplainerText"
                        )}
                    </Text>
                </VStack>

                <ModalCloseButton />

                <FormikForm
                    initialValues={{
                        clientCompanyName: "",
                        clientEmail: "",
                        clientName: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        update({
                            clientCompanyName: values.clientCompanyName,
                            clientEmail: values.clientEmail,
                            clientName: values.clientName,
                        });
                    }}
                >
                    <ModalBody>
                        <VStack
                            align="stretch"
                            spacing={4}
                        >
                            <Flex gap={2}>
                                <FormikInput
                                    placeholder={t(
                                        "account.customerPanelDetails.name"
                                    )}
                                    name="clientName"
                                    type="text"
                                    label={t(
                                        "account.customerPanelDetails.name"
                                    )}
                                />

                                <FormikInput
                                    placeholder={t(
                                        "account.customerPanelDetails.email"
                                    )}
                                    name="clientEmail"
                                    type="email"
                                    label={t(
                                        "account.customerPanelDetails.email"
                                    )}
                                />
                            </Flex>

                            <FormikInput
                                placeholder={t(
                                    "account.customerPanelDetails.customerCompanyName"
                                )}
                                name="clientCompanyName"
                                type="text"
                                label={t(
                                    "account.customerPanelDetails.customerCompanyName"
                                )}
                            />
                        </VStack>
                    </ModalBody>

                    <ModalFooter
                        gap={2}
                        justifyContent={"end"}
                    >
                        <Button
                            variant="default"
                            onClick={onClose}
                        >
                            {t("common.button.cancel")}
                        </Button>

                        <Button
                            variant="primary"
                            alignSelf="flex-end"
                            type="submit"
                        >
                            {t("common.button.add")}
                        </Button>
                    </ModalFooter>
                </FormikForm>
            </ModalContent>

            {cannotInviteIndividualClientInfo}
        </Modal>
    );

    return {
        inviteModal,
        isOpenInviteModal: isOpen,
        openInviteModal: onOpen,
        closeInviteModal: onClose,
    };
};

export default useCustomersInviteDialog;
