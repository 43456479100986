import { Button, HStack, Text } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import PublicUserProfilePicture from "../../../../../common/components/PublicUserProfilePicture";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import { displayTimeInCurrentLocale } from "../../../../../common/functions/displayTime";
import { UserInviteType, UserType } from "../../../../../types";
import { DataTableColumnType } from "../../../common/components/data-table/DataTable";
import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";

type Column = DataTableColumnType<UserType | UserInviteType>;

const translationNameSpace = "domain.user.columns";

const user: Column = {
    title: (t) => t(`${translationNameSpace}.user`),
    render: (i) => {
        return (
            <HStack>
                <PublicUserProfilePicture
                    size="2.5rem"
                    userId={i.id}
                    entityId={i.entityId}
                />
                <Text
                    fontSize={"sm"}
                    fontWeight={"700"}
                >
                    {i.firstName} {i.lastName}
                </Text>
            </HStack>
        );
    },
};

const role: Column = {
    title: (t) => t(`${translationNameSpace}.role`),
    render: (i, t) => {
        return (
            <Text
                fontSize={"sm"}
                textTransform={"capitalize"}
            >
                {t(`domain.user.role.${i.role}`)}
            </Text>
        );
    },
};

const email: Column = {
    title: (t) => t(`${translationNameSpace}.email`),
    render: (i) => {
        return (
            <Button
                as={ReactLink}
                to={`mailto:${i.email}`}
                variant={"link"}
                size={"sm"}
                colorScheme={"blue"}
                fontWeight={"400"}
                onClick={(e) => e.stopPropagation()}
            >
                {i.email}
            </Button>
        );
    },
};

const createdAt: Column = {
    title: (t) => t(`${translationNameSpace}.createdAt`),
    render: (i) => {
        const date = displayDateWithCurrentLocale(i.createdAt);
        const time = displayTimeInCurrentLocale(new Date(i.createdAt));

        return (
            <Text fontSize={"sm"}>
                {date}, {time}
            </Text>
        );
    },
};

export const userColumns = {
    user,
    role,
    email,
    createdAt,
};
