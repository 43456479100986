import {
    Box,
    SimpleGrid,
    SimpleGridProps,
    Text,
    VStack,
} from "@chakra-ui/react";
import { UserType } from "../../../../../../types";
import {
    GoogleMapV2,
    MapMarkerType,
} from "../../../../common/components/display";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../../common/MISSING_TRANSLATION";
import { InformationRowItem } from "../InformationRowItem";
import RenderFieldData from "../RenderFieldData";

type PropsType = SimpleGridProps & {
    user: UserType;
    userIsValuer: boolean;
};

const ProfileOfficeDetails = (props: PropsType) => {
    const {
        user: {
            id: userId,
            latitude,
            longitude,
            street,
            postalCode,
            city,
            country,
            officeTelDialCode,
            officeTel,
            officeFaxDialCode,
            officeFax,
            maxOperatingDistance,
            doesValuations,
        },
        userIsValuer,
        ...cssProps
    } = props;

    const t = useSmartTranslation();

    const markers: MapMarkerType[] = [
        {
            id: userId,
            lat: +latitude,
            lng: +longitude,
        },
    ];

    const operationArea = userIsValuer && doesValuations && (
        <InformationRowItem
            label={t("profile.heading.operationalArea")}
            content={
                <Text
                    fontWeight={"700"}
                    fontSize={"sm"}
                >
                    {maxOperatingDistance !== null
                        ? t(
                              `domain.standards.maxOperatingDistance.${maxOperatingDistance}`,
                              { defaultValue: MISSING_TRANSLATION }
                          )
                        : t(
                              "profile.fieldNotProvided.valuerMaxOperationDistance"
                          )}
                </Text>
            }
        />
    );

    const map = userIsValuer && (
        <Box
            h={"auto"}
            minH={"220px"}
        >
            <GoogleMapV2
                markers={markers}
                height="100%"
            />
        </Box>
    );

    return (
        <SimpleGrid
            spacing={4}
            gridTemplateColumns={
                userIsValuer
                    ? "minmax(0, 50%) minmax(0, 50%)"
                    : "minmax(0, 100%)"
            }
            {...cssProps}
        >
            <VStack
                spacing={1}
                align={"stretch"}
            >
                <InformationRowItem
                    label={t("profile.profileDetails.location")}
                    content={
                        <RenderFieldData
                            field="location"
                            data={[street, postalCode, city, country]}
                            config={{ joinWith: ", ", required: true }}
                        />
                    }
                />

                <InformationRowItem
                    label={t("profile.profileDetails.officeTelNumber")}
                    content={
                        <RenderFieldData
                            field="officeTelNumber"
                            data={[officeTelDialCode, officeTel]}
                        />
                    }
                />

                <InformationRowItem
                    label={t("profile.profileDetails.officeFaxNumber")}
                    content={
                        <RenderFieldData
                            field="officeFaxNumber"
                            data={[officeFaxDialCode, officeFax]}
                        />
                    }
                />
                {operationArea}
            </VStack>

            {map}
        </SimpleGrid>
    );
};

export default ProfileOfficeDetails;
