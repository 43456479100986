export type Feature = keyof typeof FEATURES;

export const FEATURES = {
    CASE: "CASE",
    CONTRACT: "CONTRACT",
    DMS: "DMS",
    INSPECTION: "INSPECTION",
    VALUATION_REPORT: "VALUATION_REPORT",
    ADMIN_VIEW: "ADMIN_VIEW",
    ACCOUNT_TYPE_DISPLAY: "ACCOUNT_TYPE_DISPLAY",
    ONBOARDING_IMPULSES: "ONBOARDING_IMPULSES",
} as const;
