import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    Flex,
    Heading,
    HStack,
    Spacer,
    Text,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CommentsThread from "../../../../../common/components/CommentsThread";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import PublicUserProfilePicture from "../../../../../common/components/PublicUserProfilePicture";
import StatusBadge from "../../../../../common/components/StatusBadge";
import Toggle from "../../../../../common/components/Toggle";
import PublicUserSlider from "../../../../../common/components/user-details/PublicUserSlider";
import delimitNumber from "../../../../../common/functions/delimitNumber";
import { OfferType } from "../../../../../types";
import useToast from "../../../../../ui/v2/common/hooks/useToast";
import {
    useEnquiry,
    useEntityPublicEntityGetById,
    useMe,
    useMyValuerPanels,
    useOfferCommentsCreate,
    useOfferCommentsMarkAsRead,
    useOfferGetById,
    useOfferGetCompanyOffers,
    useOfferWithdraw,
} from "../../../../../utils/api/hooks";
import ValuerOfferForm from "./ValuerOfferForm";
import { MdOutlineArrowBackIos } from "react-icons/md";

const offerTitles = [
    "Price",
    "PI Cover",
    "Timeline",
    "Availability",
    "Properties",
];

const ValuerOffer = () => {
    const { t } = useTranslation(["offer", "common"]);

    const { createToast } = useToast();

    const { enquiryId = "", offerId = "" } = useParams();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const {
        isOpen: isCancelOpen,
        onOpen: onCancelOpen,
        onClose: onCancelClose,
    } = useDisclosure();

    const [editOffer, setEditOffer] = useState(false);

    const navigate = useNavigate();

    const { data: user, isLoading: userLoading } = useMe();

    const { enquiry: enquiryData, isLoading: enquiryLoading } = useEnquiry({
        enquiryId,
    });

    const {
        data: offer = {} as OfferType,
        isLoading: offerLoading,
        refresh: refreshOffer,
        error: offerError,
    } = useOfferGetById(offerId);

    const { data: offerList = [] as OfferType[] } =
        useOfferGetCompanyOffers(enquiryId);

    const offerIndex = offerList.findIndex(
        (x: { id: number }) => x.id === Number(offerId)
    );

    const closeEditor = () => {
        refreshOffer();
        setEditOffer(false);
    };

    const { withdraw } = useOfferWithdraw({
        onSuccess: () => {
            refreshOffer();
        },
        onError: () => {
            createToast({
                description: t("error.offer.withdrawingFailed", {
                    ns: "common",
                }),
                status: "error",
            });
        },
    });

    const {
        data: valuerCompany,
        isLoading: valuerCompanyLoading,
        error: valuerCompanyError,
    } = useEntityPublicEntityGetById(offer?.fromEntityId || "");

    const { update: createOfferComment } = useOfferCommentsCreate({
        onSuccess: () => {
            refreshOffer();
        },
    });

    const { update: markAsRead } = useOfferCommentsMarkAsRead({
        onSuccess: () => {
            refreshOffer();
        },
        onError: () => {},
    });

    const { data: valuerPanels = [], isLoading: valuerPanelsLoading } =
        useMyValuerPanels();

    if (
        !user ||
        offerLoading ||
        enquiryLoading ||
        valuerCompanyLoading ||
        userLoading ||
        valuerPanelsLoading
    )
        return <LoadingSpinner />;

    const isPanelClient = valuerPanels.some(
        (panel) => panel.id === enquiryData?.fromEntityId
    );

    const obscureClient = !isPanelClient;

    return (
        <VStack
            w="100%"
            align="left"
            paddingBottom="4rem"
        >
            <Box
                w="100%"
                bg="white"
                borderRadius="10"
            >
                {editOffer ? (
                    <ValuerOfferForm
                        closeEditor={closeEditor}
                        onSuccess={() => refreshOffer()}
                    />
                ) : (
                    <>
                        <Flex
                            paddingInline="1.5rem"
                            paddingBlock="1rem"
                        >
                            <Button
                                variant="none"
                                width="5rem"
                                color="wvwGreen"
                                marginLeft=".5rem"
                                marginTop=".5rem"
                                onClick={() =>
                                    navigate(
                                        `/dashboard/enquiry/${enquiryId}/offer`
                                    )
                                }
                            >
                                <HStack>
                                    <MdOutlineArrowBackIos />

                                    <Text>
                                        {t("button.back", { ns: "common" })}
                                    </Text>
                                </HStack>
                            </Button>

                            <Spacer />

                            <Toggle
                                index={offerIndex}
                                itemId={offerId}
                                toggleList={offerList}
                                path={`dashboard/enquiry/${enquiryId}/offer`}
                            />
                        </Flex>

                        <VStack
                            paddingInline="2.5rem"
                            paddingBottom="1rem"
                            align="left"
                        >
                            <>
                                <Flex alignItems="center">
                                    <HStack
                                        spacing="4"
                                        alignItems="flex-start"
                                    >
                                        <>
                                            <PublicUserProfilePicture
                                                entityId={offer?.fromEntityId}
                                                userId={offer?.fromValuerId}
                                                size="4rem"
                                            />

                                            {valuerCompanyError && (
                                                <Heading
                                                    as="h4"
                                                    size="md"
                                                    color="wvwGrey"
                                                    paddingBlock=".8rem"
                                                >
                                                    {t(
                                                        "error.nameUnavailable",
                                                        { ns: "common" }
                                                    )}
                                                </Heading>
                                            )}

                                            {!valuerCompanyError && (
                                                <VStack
                                                    spacing="0"
                                                    align="left"
                                                    paddingBlock=".8rem"
                                                >
                                                    <Heading
                                                        as="h4"
                                                        fontSize="1.5rem"
                                                        color="wvwGreen"
                                                    >
                                                        {offer.fromValuerId ===
                                                        user.id
                                                            ? t("yourOffer", {
                                                                  ns: "offer",
                                                                  defaultValue:
                                                                      "Your Offer",
                                                              })
                                                            : `${offer?.fromValuer.firstName} ${offer?.fromValuer.lastName}`}
                                                    </Heading>

                                                    {offer.fromValuer.id !==
                                                        user.id && (
                                                        <>
                                                            <Heading
                                                                as="h4"
                                                                size="6rem"
                                                                color="wvwGreen80"
                                                            >
                                                                {t(
                                                                    "offerDetails.fromCompany",
                                                                    {
                                                                        company:
                                                                            valuerCompany?.name,
                                                                    }
                                                                )}
                                                            </Heading>

                                                            <Button
                                                                variant="none"
                                                                size="max-content"
                                                                w="5.4rem"
                                                                color="wvwYellow"
                                                                fontWeight="400"
                                                                onClick={() => {
                                                                    setIsDrawerOpen(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                {t(
                                                                    "button.viewValuer",
                                                                    {
                                                                        ns: "common",
                                                                    }
                                                                )}
                                                            </Button>
                                                        </>
                                                    )}
                                                </VStack>
                                            )}
                                        </>
                                    </HStack>

                                    <Spacer />

                                    <StatusBadge status={offer.status} />
                                </Flex>

                                <Divider />

                                {offerLoading && <LoadingSpinner />}

                                {offerError && (
                                    <Text>
                                        {t("error.offer.loadingFailed", {
                                            ns: "common",
                                        })}
                                    </Text>
                                )}

                                {offer && (
                                    <VStack
                                        align="left"
                                        paddingBottom="1rem"
                                    >
                                        {offerTitles.map((title) => (
                                            <Fragment key={title}>
                                                <Flex paddingInline="1rem">
                                                    <HStack w="17rem">
                                                        <Text>
                                                            {t(
                                                                `offerTitle.${title}`,
                                                                {
                                                                    defaultValue:
                                                                        title,
                                                                }
                                                            )}
                                                        </Text>
                                                    </HStack>
                                                    <Text w="20rem">
                                                        <>
                                                            {title ===
                                                                "Price" &&
                                                                `${t(
                                                                    "unitSymbol.currency",
                                                                    {
                                                                        ns: "common",
                                                                    }
                                                                )} ${delimitNumber(
                                                                    offer.price
                                                                )}`}
                                                            {title ===
                                                                "PI Cover" &&
                                                                `${t(
                                                                    "piCover_or_statement",
                                                                    {
                                                                        ns: "common",
                                                                        coverValue:
                                                                            offer?.piCoverValue ===
                                                                                0 ||
                                                                            null
                                                                                ? "-"
                                                                                : delimitNumber(
                                                                                      offer?.piCoverValue?.toString()
                                                                                  ),
                                                                        coverPercentage:
                                                                            offer?.piCoverPercentage ===
                                                                                0 ||
                                                                            null
                                                                                ? "-"
                                                                                : offer?.piCoverPercentage,
                                                                    }
                                                                )}`}
                                                            {title ===
                                                                "Timeline" &&
                                                                `${
                                                                    offer.timeline
                                                                } ${t(
                                                                    "offerForm.timeline.days"
                                                                )}`}
                                                            {title ===
                                                                "Availability" &&
                                                                offer.availability}
                                                            {title ===
                                                                "Properties" &&
                                                                enquiryData?.properties?.map(
                                                                    (
                                                                        property
                                                                    ) => (
                                                                        <Fragment
                                                                            key={
                                                                                property.id
                                                                            }
                                                                        >
                                                                            {`${property.street}, ${property.city}`}
                                                                            <br />
                                                                        </Fragment>
                                                                    )
                                                                )}
                                                        </>
                                                    </Text>
                                                </Flex>
                                                <Divider />
                                            </Fragment>
                                        ))}

                                        <VStack
                                            bg="wvwGrey05"
                                            borderRadius="10"
                                            align="left"
                                            spacing="4"
                                            padding="1rem"
                                        >
                                            <Heading
                                                as="h4"
                                                size="sm"
                                            >
                                                {t("offerDetails.sectionTitle")}
                                            </Heading>

                                            <Text
                                                color={
                                                    offer.comments
                                                        ? ""
                                                        : "wvwGrey40"
                                                }
                                            >
                                                {offer.comments
                                                    ? offer.comments
                                                    : t("comments.noDetails")}
                                            </Text>
                                        </VStack>

                                        {offer.fromValuerId === user.id && (
                                            <ButtonGroup
                                                alignSelf="flex-end"
                                                marginTop=".5rem"
                                            >
                                                {offer.status === "PENDING" && (
                                                    <Button
                                                        maxW="min-content"
                                                        variant="danger"
                                                        onClick={() => {
                                                            onCancelOpen();
                                                        }}
                                                    >
                                                        {t(
                                                            "button.withdrawOffer",
                                                            { ns: "common" }
                                                        )}
                                                    </Button>
                                                )}

                                                {(offer.status === "PENDING" ||
                                                    "WITHDRAWN") &&
                                                    offer.status !==
                                                        "ACCEPTED" && (
                                                        <Button
                                                            maxW="min-content"
                                                            variant="primary"
                                                            onClick={() =>
                                                                setEditOffer(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            {offer.status ===
                                                            "PENDING"
                                                                ? t(
                                                                      "button.editOffer",
                                                                      {
                                                                          ns: "common",
                                                                      }
                                                                  )
                                                                : t(
                                                                      "button.reSubmitOffer",
                                                                      {
                                                                          ns: "common",
                                                                      }
                                                                  )}
                                                        </Button>
                                                    )}
                                            </ButtonGroup>
                                        )}

                                        {offer.fromValuerId === user.id && (
                                            <CommentsThread
                                                obscureDetails={obscureClient}
                                                commentsList={
                                                    offer.offerComments?.map(
                                                        (comment) => ({
                                                            id: comment.id,
                                                            createdAt:
                                                                comment.createdAt,
                                                            updatedAt:
                                                                comment.updatedAt,
                                                            fromUserId:
                                                                comment.fromId,
                                                            readBy: comment.readBy,
                                                            message:
                                                                comment.message,
                                                        })
                                                    ) || []
                                                }
                                                onSend={(values) => {
                                                    createOfferComment({
                                                        offerId: offer.id,
                                                        message: values.comment,
                                                    });
                                                }}
                                                markAsRead={() => {
                                                    markAsRead({
                                                        commentIds:
                                                            offer.offerComments?.map(
                                                                (comment) =>
                                                                    comment.id
                                                            ) || [],
                                                    });
                                                }}
                                            />
                                        )}
                                    </VStack>
                                )}
                            </>
                        </VStack>
                    </>
                )}
            </Box>

            <ConfirmationModal
                cancelButton={t("button.cancel", { ns: "common" })}
                isOpen={isCancelOpen}
                onCancel={onCancelClose}
                content={t("warning.withdrawOffer.prompt", { ns: "common" })}
                title={t("warning.withdrawOffer.title", { ns: "common" })}
                onContinue={() => {
                    withdraw(offer.id);
                    onCancelClose();
                }}
            />

            <PublicUserSlider
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                userId={offer?.fromValuer.id}
                viewMode="ENQUIRY"
            />
        </VStack>
    );
};

export default ValuerOffer;
