import { HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";
import ProfilePictureEditor from "../../../../../common/components/ProfilePictureEditor";
import { Forms } from "../../../../../common/forms/Forms";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../../layout";

type PropTypes = {
    minimumDetails?: boolean;
};

const ProfileContactDetailsEdit = (props: PropTypes) => {
    const t = useSmartTranslation();

    const { minimumDetails = false } = props;

    const allDetails = minimumDetails === false;

    const basicInfoEdit = (
        <>
            <HStack spacing={4}>
                <Forms.FormikInput
                    noSubmitOnEnter
                    name="firstName"
                    label={t("profile.profileDetails.firstName")}
                    placeholder={t("profile.profileDetails.firstName")}
                />

                <Forms.FormikInput
                    noSubmitOnEnter
                    name="lastName"
                    label={t("profile.profileDetails.lastName")}
                    placeholder={t("profile.profileDetails.lastName")}
                />
            </HStack>

            <Forms.FormikInput
                noSubmitOnEnter
                name="orgPosition"
                label={t("profile.profileDetails.roleInCompany")}
                placeholder={t("profile.profileDetails.roleInCompany")}
            />

            <Forms.FormikInput
                disabled
                noSubmitOnEnter
                name="email"
                label={t("profile.profileDetails.email")}
                placeholder={t("profile.profileDetails.email")}
            />

            <HStack
                spacing={4}
                align={"start"}
            >
                <Forms.FormikPhoneNumberInput
                    dialCodeLabel={t("profile.profileDetails.mobileNumber")}
                    dialCodeName="mobileDialCode"
                    phoneNumberPlaceholder={t(
                        "profile.profileDetails.mobileNumber"
                    )}
                    phoneNumberName="mobile"
                />
            </HStack>
        </>
    );

    const allDataEdit = allDetails && (
        <>
            <HStack
                spacing={4}
                align={"start"}
            >
                <Forms.FormikPhoneNumberInput
                    dialCodeLabel={t("profile.profileDetails.telNumber")}
                    dialCodeName="telDialCode"
                    phoneNumberPlaceholder={t(
                        "profile.profileDetails.telNumber"
                    )}
                    phoneNumberName="tel"
                />
            </HStack>
            <HStack
                spacing={4}
                align={"start"}
            >
                <Forms.FormikPhoneNumberInput
                    dialCodeLabel={t("profile.profileDetails.faxNumber")}
                    dialCodeName="faxDialCode"
                    phoneNumberPlaceholder={t(
                        "profile.profileDetails.faxNumber"
                    )}
                    phoneNumberName="fax"
                />
            </HStack>
        </>
    );

    const logoEdit = (
        <>
            <Text fontSize={"sm"}>
                {t("profile.profileDetails.profilePicture")}
            </Text>

            <ProfilePictureEditor />

            <HStack
                spacing={1}
                align={"start"}
            >
                <Icon
                    as={MdInfoOutline}
                    color={"gray.400"}
                />
                <Text
                    fontSize={"xs"}
                    lineHeight={4}
                    color={"gray.500"}
                >
                    {t("profile.profileDetails.profilePictureEditDescription")}
                </Text>
            </HStack>
        </>
    );

    return (
        <Section
            title={t("profile.heading.contactDetails")}
            content={
                <HStack
                    w="100%"
                    spacing={4}
                    align={"stretch"}
                >
                    <VStack
                        width="100%"
                        align={"stretch"}
                        spacing={4}
                    >
                        {basicInfoEdit}
                        {allDataEdit}
                    </VStack>

                    <VStack
                        spacing={2}
                        w={"100%"}
                        align={"stretch"}
                    >
                        {logoEdit}
                    </VStack>
                </HStack>
            }
        />
    );
};

export default ProfileContactDetailsEdit;
