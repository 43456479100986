import { CheckIcon } from '@chakra-ui/icons';
import {
    Box,
    Grid,
    GridItem,
    IconButton,
} from '@chakra-ui/react';
import { useState } from 'react';
import { DragSourceMonitor, useDrag } from 'react-dnd';
import { HiOutlineDocumentText } from 'react-icons/hi';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';
import ConfirmationModal from '../../../../../../common/components/ConfirmationModal';
import WvwHStack from '../../../../../../common/components/display/WvwHStack';
import WvwIcon from '../../../../../../common/components/display/WvwIcon';
import { WvwButton, WvwLink, WvwLinkButton } from '../../../../../../common/components/inputs';
import StatusBadge from '../../../../../../common/components/StatusBadge';
import { WvwText } from '../../../../../../common/components/typography';
import WvwCancelIconButton from '../../../../../../common/components/WvwCancelIconButton';
import WvwDeleteIconButton from '../../../../../../common/components/WvwDeleteIconButton';
import { FormikForm, FormikInput } from '../../../../../../common/forms';
import { displayDateWithCurrentLocale } from '../../../../../../common/functions/displayDateInLocale';
import { useRole, useSmartTranslation } from '../../../../../../common/hooks';
import { DocumentRequestDocumentType, RequestDropTargetType, dragTargetTypes } from '../../../../../../types';
import { useDocumentRequestRemoveDocumentFromRequest, useDocumentRequestUpdateDocument } from '../../../../../../utils/api/hooks';

type PropTypes = {
    document: DocumentRequestDocumentType;
    inEditMode: boolean;
};

const DocumentOverviewItem = (props: PropTypes) => {
    const {
        document,
        inEditMode,
    } = props;

    const {
        id: documentId,
        deleted = false,
        status,
        name,
        documentRequestId,
        updatedBy,
        updatedAt,
    } = document;

    const urlParams = useParams();

    const caseId = Number(urlParams.caseId);

    const t = useSmartTranslation('common');
    const navigate = useNavigate();
    const { userIsClient } = useRole();

    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const {
        update: updateDocument,
        busy: documentUpdating,
    } = useDocumentRequestUpdateDocument({
        caseId: Number(caseId),
        onSuccess: () => setIsEditing(false),
    });

    const {
        update: removeDocumentFromRequest,
    } = useDocumentRequestRemoveDocumentFromRequest({
        caseId,
        onSuccess: () => setIsDeleting(false),
    });

    const [{ isDragging }, drag] = useDrag(() => ({
        type: dragTargetTypes.DOCUMENT_MOVE,
        item: {
            type: dragTargetTypes.DOCUMENT_FROM_ACTION_AREA,
            documentId: document.id,
            documentName: document.name,
        },
        canDrag: () => !inEditMode,
        end(item, monitor) {
            const dropResult = monitor.getDropResult() as RequestDropTargetType;

            if (item && dropResult) {
                updateDocument({
                    documentId: item.documentId,
                    documentRequestId: Number(dropResult.requestId),
                });
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [inEditMode]);

    if (isDragging) {
        return null;
    }

    const statusBadgeStatus = deleted ? 'DELETED' : status || 'PENDING';
    const updatedByText = updatedBy ? `${updatedBy.firstName} ${updatedBy.lastName}` : '-';
    const updatedAtText = displayDateWithCurrentLocale(updatedAt);
    const linkDisabled = deleted || status === 'UNAVAILABLE';
    const linkIconColor = linkDisabled ? 'wvwGrey60' : 'wvwGreen';

    return (
        <>
            <Grid
                alignItems="center"
                key={documentId}
                maxW="100%"
                paddingBlock=".5rem"
                ref={node => {
                    drag(node);
                }}
                templateColumns="repeat(4, 1fr)"
                w="100%"
            >
                <GridItem
                    alignSelf="left"
                    w="18rem"
                    pl="2.4rem"
                >
                    {inEditMode && (
                        <>
                            {isEditing && (
                                <FormikForm
                                    submitting={documentUpdating}
                                    initialValues={{ name }}
                                    onSubmit={values => updateDocument({
                                        documentId,
                                        documentRequestId,
                                        name: values.name,
                                    })}
                                >
                                    <WvwHStack>
                                        <FormikInput
                                            name="name"
                                            width="25rem"
                                        />

                                        <IconButton
                                            aria-label="Save"
                                            icon={<CheckIcon />}
                                            size="xs"
                                            borderRadius="30"
                                            variant="primary"
                                            type="submit"
                                        />

                                        <WvwCancelIconButton
                                            onClick={e => {
                                                setIsEditing(false);
                                                e.stopPropagation();
                                            }}
                                            color="wvwGreen"
                                        />
                                    </WvwHStack>
                                </FormikForm>
                            )}

                            {!isEditing && (
                                <WvwLinkButton
                                    content={name}
                                    icon={<HiOutlineDocumentText />}
                                    onClick={() => setIsEditing(true)}
                                />
                            )}
                        </>
                    )}

                    {!inEditMode && (
                        <WvwHStack>
                            <WvwIcon
                                icon="document"
                                color={linkIconColor}
                            />

                            <WvwLink
                                label={name}
                                isDisabled={linkDisabled}
                                to={`document-preview/${documentId}`}
                                noWrap
                            />
                        </WvwHStack>
                    )}
                </GridItem>

                {!inEditMode && (
                    <>
                        <GridItem
                            justifySelf="center"
                            w="10rem"
                        >
                            <StatusBadge status={statusBadgeStatus} />
                        </GridItem>

                        <GridItem pl="1rem">
                            <WvwHStack>
                                <Box maxW="4.5rem">
                                    <WvwText noWrap>
                                        {updatedByText}
                                    </WvwText>
                                </Box>

                                <WvwText>
                                    {updatedAtText}
                                </WvwText>
                            </WvwHStack>
                        </GridItem>

                        {userIsClient && (
                            <GridItem justifySelf="flex-end">
                                {status === 'REJECTED' && (
                                    <WvwButton
                                        size="sm"
                                        variant="primary"
                                        content={t('replace', { ns: 'documentmanagement' })}
                                        onClick={() => navigate(`replace-document/${documentId}`)}
                                    />
                                )}

                                {status === 'PENDING' && (
                                    <WvwDeleteIconButton
                                        onClick={e => {
                                            e.stopPropagation();
                                            setIsDeleting(true);
                                        }}
                                        color="black"
                                    />
                                )}
                            </GridItem>
                        )}
                    </>
                )}
            </Grid>

            <ConfirmationModal
                isOpen={isDeleting}
                onCancel={() => setIsDeleting(false)}
                onContinue={() => removeDocumentFromRequest(documentId)}
                title={t('warning.deleteDocument.title')}
                content={t('warning.deleteDocument.prompt')}
            />
        </>
    );
};

export default DocumentOverviewItem;
