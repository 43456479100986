import { Button, Flex } from "@chakra-ui/react";
import { ReactElement, JSXElementConstructor } from "react";
import { useMediaQuery } from "@chakra-ui/react";

type MenuToggleButtonProps = {
    label: string;
    icon?: ReactElement<any, string | JSXElementConstructor<any>>;
    isSelected: boolean;
    onClick: () => void;
};

const MenuToggleButton = (props: MenuToggleButtonProps) => {
    const { label, icon, isSelected, onClick } = props;
    const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");

    return (
        <Button
            minW={isLargerThan1280 ? "120px" : "50px"}
            bg={isSelected ? "blue.700" : "blue.900"}
            color={isSelected ? "white" : "gray.400"}
            border="none"
            _hover={{ color: !isSelected && "white" }}
            _focus={{
                boxShadow: "none",
                bg: isSelected ? "blue.700" : "initial",
            }}
            borderRadius="md !important"
            transition="all 0.2s ease-in-out"
            onClick={onClick}
        >
            <Flex
                gap={2}
                align="center"
                justify="center"
            >
                {icon}
                {isLargerThan1280 && label}
            </Flex>
        </Button>
    );
};

export default MenuToggleButton;
