import { OfferOnlyType } from "../../../../types";
import { OfferStatus } from "./offerStatus";

export const calculateDeliveryDate = ({
    availability,
    timeline,
}: Pick<OfferOnlyType, "availability" | "timeline">) => {
    const deliveryDate = new Date(availability);
    deliveryDate.setDate(deliveryDate.getDate() + Number(timeline));

    return deliveryDate;
};

const VAT_PERCENT = 0.19;
const SERVICE_FEE = 0;

export const calculateVat = (price: number) => {
    if (price == null) return "";

    const vat = (price + SERVICE_FEE) * VAT_PERCENT;
    return vat.toFixed(2);
};

export const calculateGrossPrice = (price: number) => {
    if (price == null) return "";

    const vat = (price + SERVICE_FEE) * VAT_PERCENT;
    return (price + SERVICE_FEE + vat).toFixed(2);
};

export const filterActiveOffers = (offers: OfferOnlyType[]) => {
    const activeOffers = offers.filter(
        (offer) => offer.status === "PENDING"
    ).length;

    return activeOffers;
};

export const filterRejectedeOffers = (offers: OfferOnlyType[]) => {
    const rejectedOffers = offers.filter(
        (offer) => offer.status === "REJECTED"
    ).length;

    return rejectedOffers;
};
