import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import WvwButton from './WvwButton';

type PropsType = {
    content: string;
    to: string;
    variant?: 'danger' | 'default' | 'link' | 'primary' | 'success' | 'outline';
};

const WvwButtonAsReactLink = (props: PropsType) => {
    const { content, to, variant } = props;

    return (
        <ChakraLink
            as={ReactLink}
            to={to}
        >
            <WvwButton
                content={content}
                variant={variant}
            />
        </ChakraLink>
    );
};

export default WvwButtonAsReactLink;
