import { Icon } from "@chakra-ui/icons";
import { Tag, TagLabel, Tooltip } from "@chakra-ui/react";
import { ElementType } from "react";

type MatchmakingInfoTagProps = {
    label: string;
    icon: ElementType;
    iconVariant?: boolean;
};

const MatchmakingInfoTag = (props: MatchmakingInfoTagProps) => {
    const { label, icon, iconVariant } = props;

    return (
        <>
            {!iconVariant ? (
                <Tag
                    size={"lg"}
                    bg={"gray.100"}
                    borderRadius={"lg"}
                >
                    <Icon
                        as={icon}
                        boxSize={"16px"}
                        color={"gray.600"}
                        me={1}
                    />
                    <TagLabel
                        fontSize={"sm"}
                        color={"gray.600"}
                    >
                        {label}
                    </TagLabel>
                </Tag>
            ) : (
                <Tooltip
                    label={label}
                    aria-label={label}
                >
                    <span>
                        <Icon
                            as={icon}
                            boxSize={"16px"}
                            color={"gray.600"}
                            me={1}
                        />
                    </span>
                </Tooltip>
            )}
        </>
    );
};

export { MatchmakingInfoTag };
