import { IconButton } from "@chakra-ui/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ValuerInviteType } from "../../../../../../../../types";
import DataTable, {
    DataTableColumnType,
} from "../../../../../../common/components/data-table/DataTable";
import useCancelInvitation from "../useCancelInvitation";
import { valuerInviteColumns } from "../../../../../../domain/user/valuer/ValuerInviteColumns";

type PropTypes = {
    invites: ValuerInviteType[];
};

const ValuersSentInvitesTable = (props: PropTypes) => {
    const { invites } = props;

    return (
        <DataTable
            fullSectionWidth
            data={invites}
            columns={[
                valuerInviteColumns.valuerName,
                valuerInviteColumns.email,
                valuerInviteColumns.receivedAt,
                cancelInvitation,
            ]}
        />
    );
};

const cancelInvitation: DataTableColumnType<ValuerInviteType> = {
    render: (i) => {
        const { cancelInvitationModal, openCancelInvitationModal } =
            useCancelInvitation({
                inviteId: i.id,
            });

        return (
            <>
                <IconButton
                    icon={<RiDeleteBin6Line />}
                    size="sm"
                    variant="ghost"
                    color="gray.400"
                    aria-label="cancel"
                    onClick={(e) => {
                        e.stopPropagation();
                        openCancelInvitationModal();
                    }}
                />
                {cancelInvitationModal}
            </>
        );
    },
};

export default ValuersSentInvitesTable;
