import { HStack, VStack } from "@chakra-ui/react";
import FormikFieldAutoFillCheckBox from "../../../../../../../common/forms/FormikFieldAutoFIllCheckbox";
import FormikMapsAutocomplete from "../../../../../../../common/forms/FormikMapsAutocomplete";
import { standardValuerMaxOperatingDistance } from "../../../../../../../common/vars/valuationsAndMembershipTypes";
import { useMyEntity } from "../../../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { Forms } from "../../../../../common/forms/Forms";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../../../common/MISSING_TRANSLATION";
import { Section } from "../../../../../layout";

type PropsType = {
    userIsValuer: boolean;
    userIsCompanyAdmin: boolean;
    userDoesValuations?: boolean;
    streetName: string;
    postalCodeName: string;
    cityName: string;
    countryName: string;
    latName: string;
    lngName: string;
    officeTelDialCodeName: string;
    officeTelName: string;
    officeFaxDialCodeName: string;
    officeFaxName: string;
};

const ProfileOfficeDetailsEdit = (props: PropsType) => {
    const {
        userIsValuer,
        userIsCompanyAdmin,
        streetName,
        postalCodeName,
        cityName,
        countryName,
        latName,
        lngName,
        officeTelDialCodeName,
        officeTelName,
        officeFaxDialCodeName,
        officeFaxName,
        userDoesValuations = false,
    } = props;

    const t = useSmartTranslation();

    const { data: entity, isLoading: entityLoading } = useMyEntity();

    if (entityLoading) return <LoadingSpinner />;

    // render

    const basicDataEdit = (
        <VStack
            align={"stretch"}
            width={"100%"}
            spacing={4}
        >
            {userIsValuer && userDoesValuations && (
                <Forms.FormikSelect
                    name="maxOperatingDistance"
                    placeholder={t(
                        "profile.profileDetails.valuerMaxOperationDistance"
                    )}
                    subtext={t("profile.subHeading.operationalArea")}
                    options={standardValuerMaxOperatingDistance.map(
                        (distance) => ({
                            label: t(
                                `domain.standards.maxOperatingDistance.${distance.value}`,
                                {
                                    defaultValue: MISSING_TRANSLATION,
                                }
                            ),
                            value: distance.value,
                        })
                    )}
                />
            )}

            <HStack
                spacing={4}
                align={"start"}
            >
                <Forms.FormikPhoneNumberInput
                    dialCodeLabel={t("profile.profileDetails.officeTelNumber")}
                    dialCodeName={officeTelDialCodeName}
                    phoneNumberPlaceholder={t(
                        "profile.profileDetails.officeTelNumber"
                    )}
                    phoneNumberName={officeTelName}
                />
            </HStack>

            <HStack
                spacing={4}
                align={"start"}
            >
                <Forms.FormikPhoneNumberInput
                    dialCodeLabel={t("profile.profileDetails.officeFaxNumber")}
                    dialCodeName={officeFaxDialCodeName}
                    phoneNumberPlaceholder={t(
                        "profile.profileDetails.officeFaxNumber"
                    )}
                    phoneNumberName={officeFaxName}
                />
            </HStack>
        </VStack>
    );

    const addressFields = userIsValuer ? (
        <FormikMapsAutocomplete
            cityName={cityName}
            countryName={countryName}
            streetName={streetName}
            postalCodeName={postalCodeName}
            latName={latName}
            lngName={lngName}
            placeholder={t("profile.profileDetails.address")}
        />
    ) : (
        <>
            <HStack
                alignItems="top"
                spacing="4"
                w="100%"
                flexWrap={{
                    base: "wrap",
                    md: "nowrap",
                }}
            >
                <Forms.FormikInput
                    noSubmitOnEnter
                    name={streetName}
                    label={t("profile.profileDetails.street")}
                    placeholder={t("profile.profileDetails.street")}
                />
                <Forms.FormikInput
                    noSubmitOnEnter
                    name={postalCodeName}
                    label={t("profile.profileDetails.postalCode")}
                    placeholder={t("profile.profileDetails.postalCode")}
                />
            </HStack>
            <HStack
                alignItems="top"
                spacing="4"
                w="100%"
                flexWrap={{
                    base: "wrap",
                    md: "nowrap",
                }}
            >
                <Forms.FormikInput
                    noSubmitOnEnter
                    name={cityName}
                    label={t("profile.profileDetails.city")}
                    placeholder={t("profile.profileDetails.city")}
                />

                <Forms.FormikCountrySelect
                    name={countryName}
                    label={t("profile.profileDetails.country")}
                    width="100%"
                />
            </HStack>
        </>
    );

    const addressDataEdit = (
        <VStack
            align={"stretch"}
            width={"100%"}
            spacing={4}
        >
            {userIsCompanyAdmin && !userIsValuer && (
                <FormikFieldAutoFillCheckBox
                    name="useCompanyAddress"
                    label={t(
                        "profile.profileCompletionWizard.useCompanyDetails"
                    )}
                    formFields={[
                        {
                            name: streetName,
                            value: entity.street,
                        },
                        {
                            name: postalCodeName,
                            value: entity.postalCode,
                        },
                        {
                            name: cityName,
                            value: entity.city,
                        },
                        {
                            name: countryName,
                            value: entity.country,
                        },
                    ]}
                />
            )}
            {addressFields}
        </VStack>
    );

    return (
        <Section
            title={t("profile.heading.officeLocation")}
            subtitle={t("profile.subHeading.workLocation")}
            content={
                <HStack
                    width="100%"
                    align={"stretch"}
                    spacing={4}
                >
                    {basicDataEdit}
                    {addressDataEdit}
                </HStack>
            }
        />
    );
};

export default ProfileOfficeDetailsEdit;
