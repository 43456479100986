import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { FormikForm } from "../../../common/forms";
import useToast from "../../../ui/v2/common/hooks/useToast";
import { useEnquiry } from "../../../utils/api/hooks";
import EnquiryFormBox from "../common/EnquiryFormBox";
import useEnquirySteps from "../common/hooks/useEnquirySteps";
import Properties from "./property-forms/Properties";
import { Property } from "./PropertyFormPropertyType";

const validationSchema = Yup.object({
    properties: Yup.array().min(1, "errors.required"),
});

const EnquiryPropertyInfo = () => {
    const { t } = useTranslation([
        "enquiryform",
        "common",
        "enquiries",
        "formik",
    ]);
    const { createToast } = useToast();

    const { onContinue, onBack } = useEnquirySteps();

    const { enquiryId = "" } = useParams();

    const {
        enquiry,
        update: updateEnquiry,
        isLoading,
    } = useEnquiry({
        enquiryId,
        onUpdateSuccess: () => {
            onContinue();
        },
        onUpdateError: (error) =>
            createToast({
                description: error.message,
                status: "error",
            }),
    });

    const { update: updateProperties } = useEnquiry({
        enquiryId,
        onUpdateSuccess: () => {},
        onUpdateError: (error) =>
            createToast({
                description: error.message,
                status: "error",
            }),
    });

    const saveProperties = (properties: Property[], goNext: boolean) => {
        const data = {
            properties,
            id: Number(enquiryId),
        };

        if (goNext) {
            updateEnquiry(data);
        } else {
            updateProperties(data);
        }
    };

    if (isLoading || !enquiry) {
        return <LoadingSpinner />;
    }

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                properties: enquiry?.properties || [],
            }}
            onSubmit={(values) => saveProperties(values.properties, true)}
        >
            <EnquiryFormBox
                onBack={onBack}
                content={[
                    {
                        key: "propertyDetails",
                        header: t("newEnquiry.propertyDetailsSection.title", {
                            ns: "enquiryform",
                        }),
                        subHeader: t(
                            "newEnquiry.propertyDetailsSection.subTitle",
                            { ns: "enquiryform" }
                        ),
                        element: (
                            <Properties
                                renderUseMyDetailsCheckbox
                                onUpdate={(newProperties) =>
                                    saveProperties(newProperties, false)
                                }
                            />
                        ),
                    },
                ]}
            />
        </FormikForm>
    );
};

export default EnquiryPropertyInfo;
