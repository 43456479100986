import { Formik, useFormikContext } from "formik";
import FormikCountrySelect from "./FormikCountrySelect";
import FormikInput from "./FormikInput";
import { ReactElement, ReactNode } from "react";

type PropsType = {
    dialCodeLabel?: string;
    dialCodeName: string;
    phoneNumberLabel?: string;
    phoneNumberPlaceholder: string;
    phoneNumberName: string;
    fieldWrapper?: (field: ReactElement) => ReactNode;
};

const FormikPhoneNumberInput = (props: PropsType) => {
    const {
        dialCodeLabel,
        dialCodeName,
        phoneNumberLabel,
        phoneNumberPlaceholder,
        phoneNumberName,
        fieldWrapper = (field: ReactElement) => <>{field}</>,
    } = props;

    const { setFieldTouched, validateForm } = useFormikContext();

    return (
        <>
            {fieldWrapper(
                <FormikCountrySelect
                    name={dialCodeName}
                    label={dialCodeLabel}
                    selectDialCode
                    w="100%"
                    onChange={async () => {
                        await setFieldTouched(phoneNumberName, true);
                        validateForm();
                    }}
                />
            )}

            {fieldWrapper(
                <FormikInput
                    name={phoneNumberName}
                    label={phoneNumberLabel}
                    placeholder={phoneNumberPlaceholder}
                    onChange={async () => {
                        await setFieldTouched(dialCodeName, true);
                        validateForm();
                    }}
                />
            )}
        </>
    );
};

export default FormikPhoneNumberInput;
