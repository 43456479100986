import { HStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import DataTable from "../../../common/components/datatable/DataTable";
import PageWithTitleLayout from "../../../common/components/display/PageWithTitleLayout";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { EntityType } from "../../../types";
import { ByEntityDataFilter } from "../../../ui/v2/common/features/filters/domain/entity/ByEntityDataFilter";
import useDataFilter from "../../../ui/v2/common/features/filters/useDataFIlter";
import { useAdminEntities } from "../../../utils/api/hooks";

const AdminEntities = () => {
    const { t } = useTranslation("admin");

    const { data: cases, isLoading } = useAdminEntities({});

    const {
        filteredData,
        searchFilter,
        updateSearchFilter,
        deactivateSearchFilter,
    } = useDataFilter<EntityType>(cases);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const headers = [
        {
            title: "#",
            sorter: (a: EntityType, b: EntityType) => a.id - b.id,
            render: (i: EntityType) => i.id,
        },
        {
            title: t("tableHeading.companyName"),
            sorter: (a: EntityType, b: EntityType) =>
                a.name.localeCompare(b.name),
            render: (i: EntityType) => i.name,
        },
        {
            title: t("tableHeading.entityType"),
            sorter: (a: EntityType, b: EntityType) =>
                a.accountType.localeCompare(b.accountType),
            render: (i: EntityType) => i.accountType,
        },
        {
            title: t("tableHeading.createdAt"),
            sorter: (a: EntityType, b: EntityType) =>
                a.createdAt.localeCompare(b.createdAt),
            render: (i: EntityType) =>
                new Date(i.createdAt).toLocaleDateString(),
        },
    ];

    const entitiesFilters = [
        {
            filterName: "Entity Type",
            placeholder: t("filters.entities.entityType.entityType", {
                ns: "admin",
            }),
            options: [
                {
                    label: t("filters.entities.entityType.client", {
                        ns: "admin",
                    }),
                    value: "CLIENT",
                },
                {
                    label: t("filters.entities.entityType.valuer", {
                        ns: "admin",
                    }),
                    value: "VALUER",
                },
            ],
            filterFunction: (i: EntityType, filterValue?: string) =>
                i.accountType === filterValue,
        },
    ];

    return (
        <PageWithTitleLayout title="Admin Entities">
            <HStack justify={"end"}>
                <ByEntityDataFilter
                    activateFilter={updateSearchFilter}
                    deactivateFilter={deactivateSearchFilter}
                    activeFilters={searchFilter ? [searchFilter] : []}
                />
            </HStack>

            <DataTable
                columns={headers}
                data={filteredData}
                filters={entitiesFilters}
            />
        </PageWithTitleLayout>
    );
};

export default AdminEntities;
