import { Button, HStack, Text } from "@chakra-ui/react";
import useSupportModal from "../../../utils/api/hooks/useSupportModal";
import { useLegalModal } from "../common/hooks/useLegalModal";
import { useSmartTranslation } from "../common/hooks/useSmartTranslation";

type PropTypes = {
    invertColor?: boolean;
};

const Footer = (props: PropTypes) => {
    const { invertColor = false } = props;

    const { modal: supportModal, openModal } = useSupportModal();

    const t = useSmartTranslation();

    const { legalModal, openLegalModal, setLegalType } = useLegalModal();

    const textColor = invertColor ? "white" : "black";

    const footerButton = (modalToOpen: any, text: string, divider: boolean) => (
        <HStack>
            <Button
                color={textColor}
                fontSize="0.8rem"
                fontWeight="normal"
                onClick={() => modalToOpen()}
                variant="link"
            >
                <Text color={textColor}>{text}</Text>
            </Button>

            {divider && <Text color={textColor}>{" | "}</Text>}
        </HStack>
    );

    return (
        <>
            <HStack
                fontSize="0.8rem"
                justifyContent="center"
                paddingBlock="0.5rem"
                w="100%"
                flexWrap="wrap"
            >
                {footerButton(
                    openModal,
                    t("screen.footer.reportAProblem"),
                    true
                )}
                {footerButton(
                    () => {
                        setLegalType("privacy");
                        openLegalModal();
                    },
                    t("legal.privacy"),
                    true
                )}
                {footerButton(
                    () => {
                        setLegalType("terms");
                        openLegalModal();
                    },
                    t("legal.terms"),
                    true
                )}
                {footerButton(
                    () => {
                        setLegalType("imprint");
                        openLegalModal();
                    },
                    t("legal.imprint"),
                    false
                )}
            </HStack>

            {supportModal}

            {legalModal}
        </>
    );
};

export default Footer;
