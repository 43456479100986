import { IconButton } from "@chakra-ui/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { EntityType } from "../../../../../../../../types";
import DataTable, {
    DataTableColumnType,
} from "../../../../../../common/components/data-table/DataTable";
import useCustomersLeave from "../useCustomersLeave";
import { entityColumns } from "../../../../../../domain/entity/EntityColumns";
import { usePermissions } from "../../../../../../app/permissions/usePermissions";

type PropTypes = {
    customers: EntityType[];
    onRowClick: (item: EntityType) => void;
};

const CustomersTable = (props: PropTypes) => {
    const { customers, onRowClick } = props;

    return (
        <DataTable
            fullSectionWidth
            data={customers}
            onRowClick={onRowClick}
            columns={[
                entityColumns.name,
                entityColumns.address,
                entityColumns.email,
                entityColumns.website,
                entityColumns.invitedAt,
                leaveCompanyColumn,
            ]}
        />
    );
};

const leaveCompanyColumn: DataTableColumnType<EntityType> = {
    render: (i) => {
        const { leavePanelModal, openLeavePanelModal } = useCustomersLeave({
            inviteId: i.id,
        });

        const { permission: canRemove } =
            usePermissions({
                activity: "RemoveMeFromPanel",
                datatype: "Entity",
                objects: i,
            });

        return (
            <>
                <IconButton
                    icon={<RiDeleteBin6Line />}
                    size="sm"
                    variant="ghost"
                    color="gray.400"
                    aria-label="leave"
                    isDisabled={!canRemove}
                    onClick={(e) => {
                        e.stopPropagation();
                        openLeavePanelModal();
                    }}
                />
                {leavePanelModal}
            </>
        );
    },
};

export default CustomersTable;
