import {
    Button,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
} from "@chakra-ui/react";
import { FormikValues } from "formik";
import { useNavigate } from "react-router-dom";
import { number, object, string } from "yup";
import parseDelimitedNumber from "../../../../../../../common/functions/parseDelimitedNumber";
import { useRole } from "../../../../../../../common/hooks";
import { useMyEntity } from "../../../../../../../utils/api/hooks";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import Logo from "../../../../../common/components/Logo";
import { Forms } from "../../../../../common/forms/Forms";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import CompanyDetailsBillingEdit from "./CompanyDetailsBillingEdit";
import CompanyDetailsContactEdit from "./CompanyDetailsContactEdit";
import CompanyDetailsPiCoverEdit from "./CompanyDetailsPiCoverEdit";

const basevalidationFields = {
    name: string().required("forms.errors.required"),
    street: string().required("forms.errors.required"),
    city: string().required("forms.errors.required"),
    country: string().required("forms.errors.required"),
    postalCode: string().required("forms.errors.required"),
    companyEmail: string()
        .email("forms.errors.invalidEmail")
        .required("forms.errors.required"),
    contactNumberDialCode: string().test(
        "contactNumber",
        "forms.errors.required",
        function (value) {
            if (value === undefined) {
                if (this.parent.contactNumber === undefined) return true;

                return false;
            }

            return true;
        }
    ),
    contactNumber: number()
        .typeError("forms.errors.enterNumber")
        .test("contactNumber", "forms.errors.required", function (value) {
            if (value === undefined) {
                if (this.parent.contactNumberDialCode === undefined)
                    return true;

                return false;
            }

            return true;
        }),
};

const valuerValidationFields = {
    valuerYearsOfExperience: string().required("forms.errors.required"),
    valuerNumberOfPartners: number().required("forms.errors.required"),
    piCoverValue: string().required("forms.errors.required"),
    piCoverPercentage: string().required("forms.errors.required"),
    piCoverValidityDate: string().required("forms.errors.required"),
};

const CompanyDetailsEdit = () => {
    const t = useSmartTranslation();

    const navigate = useNavigate();

    const { userIsValuer, userIsCompanyAdmin } = useRole();

    const {
        data: entity,
        isLoading: entityLoading,
        updateEntity,
    } = useMyEntity({
        onUpdateSuccess: () => {
            onComplete();
        },
    });

    if (entityLoading || !entity) {
        return <LoadingSpinner />;
    }

    const onComplete = () => {
        return navigate("../company");
    };

    const onFormSubmit = (values: FormikValues) => {
        const {
            valuerNumberOfPartners,
            valuerYearsOfExperience,
            piCoverValue,
            piCoverValueUnlimited,
            piCoverPercentage,
            piCoverPercentageUnlimited,
            piCoverValidityDate,
            piCoverSpecialArrangementsAvailable,
            ...rest
        } = values;

        let update = {
            ...rest,
        };

        if (userIsValuer) {
            const valuerNumberOfPartnersAsString =
                valuerNumberOfPartners.toString();

            const piCoverValueasNumber = Number(
                parseDelimitedNumber(piCoverValue.toString())
            );

            const piCoverPercentageAsNumber = Number(
                parseDelimitedNumber(piCoverPercentage.toString())
            );

            update = {
                ...update,
                valuerNumberOfPartners: valuerNumberOfPartnersAsString,
                piCoverValue: piCoverValueasNumber,
                piCoverValueUnlimited,
                piCoverPercentage: piCoverPercentageAsNumber,
                piCoverPercentageUnlimited,
                valuerYearsOfExperience,
                piCoverValidityDate,
                piCoverSpecialArrangementsAvailable,
            };
        }

        return updateEntity(update);
    };

    const validationSchema = object().shape({
        ...basevalidationFields,
        ...(userIsValuer ? valuerValidationFields : {}),
    });

    // render

    const header = (
        <HStack spacing="4">
            <Logo
                size="2.5rem"
                withColor
            />

            <Heading size={"sm"}>
                {t("account.companyDetails.editCompanyDetails")}
            </Heading>
        </HStack>
    );

    const piCoverEdit = userIsValuer && userIsCompanyAdmin && (
        <CompanyDetailsPiCoverEdit />
    );

    const body = (
        <Forms.FormikForm
            validationSchema={validationSchema}
            initialValues={{
                ...entity,
                valuerNumberOfPartners:
                    Number(entity.valuerNumberOfPartners) || 0,
            }}
            onSubmit={onFormSubmit}
        >
            <VStack
                align={"stretch"}
                maxW={"1520px"}
                m={"0 auto"}
                spacing={4}
            >
                <CompanyDetailsContactEdit />
                <CompanyDetailsBillingEdit />
                {piCoverEdit}

                <HStack
                    justify={"end"}
                    mt={4}
                >
                    <Button
                        variant={"primary"}
                        w={240}
                        type="submit"
                    >
                        {t("common.button.save")}
                    </Button>
                </HStack>
            </VStack>
        </Forms.FormikForm>
    );

    return (
        <Modal
            isOpen
            size="full"
            closeOnOverlayClick={false}
            scrollBehavior={"inside"}
            onClose={onComplete}
        >
            <ModalOverlay />

            <ModalContent bg={"gray.50"}>
                <ModalHeader
                    bg={"white"}
                    p={".75rem 1rem"}
                >
                    {header}
                </ModalHeader>

                <ModalCloseButton />

                <ModalBody
                    py={4}
                    px={6}
                >
                    {body}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CompanyDetailsEdit;
