import { useDisclosure } from "@chakra-ui/react";
import { useMyEntity } from "../../../../utils/api/hooks";
import storage, {
    FirebaseCertificateType,
} from "../../../../utils/storage/storage";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useToast from "../../common/hooks/useToast";

type PropTypes = {
    file: FirebaseCertificateType;
};

const useDocumentRemove = (props: PropTypes) => {
    const {
        file: { url },
    } = props;

    const t = useSmartTranslation();

    const { createToast } = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { entityCertificatesRefresh, entitySampleValuationsRefresh } =
        useMyEntity();

    const removeFile = async () => {
        if (!url) return;

        try {
            await storage.deleteEntityFile(url);

            entityCertificatesRefresh();
            entitySampleValuationsRefresh();

            onClose();
        } catch (err) {
            createToast({ status: "error" });
        }
    };

    const removeDocumentModal = (
        <ConfirmationModal
            confirmationButtonLabel={t("common.button.confirm")}
            confirmationButtonVariant="negative"
            isOpen={isOpen}
            title={t("domain.document.ACTIVITIES.delete.NAME")}
            blurb={t("domain.document.ACTIVITIES.delete.confirmationBlurb")}
            onClose={onClose}
            onConfirmation={removeFile}
        />
    );

    return {
        removeDocumentModal,
        isOpenRemoveDocumentModal: isOpen,
        openRemoveDocumentModal: onOpen,
    };
};

export default useDocumentRemove;
