import { CaseType } from "../../../../../../../types";
import { caseColumns } from "../../../../../common/components/data-table/columns/CaseColumns";
import { DataTableColumnType } from "../../../../../common/components/data-table/DataTable";
import { EnquiryWithRecipientInfo } from "../../../../../domain/enquiry/Title";

// columns
export const columns: DataTableColumnType<CaseType>[] = [
    caseColumns.caseIdWithProperties,
    caseColumns.caseClient,
    caseColumns.valuationType,
    caseColumns.offerPiCover,
    caseColumns.expectedDelivery,
    caseColumns.estPortfolioValue,
    caseColumns.viewCaseButton,
];

export const caseEnquiryColumns: DataTableColumnType<CaseType>[] = [
    caseColumns.enquiryIdWithProperties,
    caseColumns.estPortfolioValue,
    caseColumns.valuationType,
    caseColumns.expectedDelivery,
    caseColumns.offerPiCover,
    caseColumns.caseClient,
    caseColumns.viewCaseButton,
];

export const acceptedEnquiryColumns: DataTableColumnType<
    CaseType & {
        enquiry: EnquiryWithRecipientInfo;
    }
>[] = [
    caseColumns.enquiryIdWithProperties,
    caseColumns.acceptedEnquirySource,
    caseColumns.enquiryCreated,
    caseColumns.created,
    caseColumns.caseClient,
    caseColumns.valuationType,
    caseColumns.expectedDelivery,
    caseColumns.estPortfolioValue,
];
