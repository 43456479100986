import { fromAddress } from "react-geocode";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import useToast from "../../../ui/v2/common/hooks/useToast";

type ParamsType = {
    onSuccess?: (data: any) => void;
    onError?: (error: Error) => void;
};

type DtoType = {
    street: string;
    city: string;
    country: string;
    postalCode: string;
};

const useGoogleGeocode = (params: ParamsType) => {
    const { onSuccess, onError } = params;

    const { t } = useTranslation("common");
    const { createToast } = useToast();

    const { mutate: update } = useMutation(
        async (dto: DtoType) => {
            const { street, city, country, postalCode } = dto;

            if (!street) throw new Error("No street");
            if (!city) throw new Error("No city");
            if (!country) throw new Error("No country");
            if (!postalCode) throw new Error("No postal code");

            // let address = '';

            // if (i18n.language === 'de') {
            //     address = `${street}, ${postalCode}, ${city}, ${country}`;
            // } else {
            //     address = `${street}, ${city}, ${country}, ${postalCode}`;
            // }

            const address = `${street}, ${postalCode}, ${city}, ${country}`;

            return fromAddress(address);
        },
        {
            onSuccess: (data: any) => onSuccess?.(data),
            onError: (error: Error) => {
                createToast({
                    status: "error",
                    description: t(error.message, {
                        defaultValue: error.message,
                    }),
                });

                onError?.(error);
            },
        }
    );

    return {
        update,
    };
};

export default useGoogleGeocode;
