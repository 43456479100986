import { useDisclosure } from "@chakra-ui/react";
import {
    useUserInvitesDelete,
    useUserInvitesPending,
} from "../../../../../../utils/api/hooks";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../common/hooks/useToast";

type TeamInviteRemoveProps = {
    inviteId: number;
};

const useTeamInviteRemove = (props: TeamInviteRemoveProps) => {
    const { inviteId } = props;

    const t = useSmartTranslation();

    const { createToast } = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { refresh: refreshInvites } = useUserInvitesPending();

    const { update: updateDeleteInvite } = useUserInvitesDelete({
        onSuccess: () => {
            refreshInvites();
        },
        onError: (error) => {
            createToast({
                description: error.message,
                status: "error",
            });
        },
    });

    const removeInviteModal = (
        <ConfirmationModal
            confirmationButtonLabel={t("common.button.confirm")}
            confirmationButtonVariant="negative"
            isOpen={isOpen}
            title={t("account.teamInvites.deleteInvitation.title")}
            blurb={t("account.teamInvites.deleteInvitation.prompt")}
            onClose={onClose}
            onConfirmation={() => {
                updateDeleteInvite(inviteId);
                onClose();
            }}
        />
    );

    return {
        removeInviteModal,
        isOpenRemoveInviteModal: isOpen,
        openRemoveInviteModal: onOpen,
    };
};

export default useTeamInviteRemove;
