import { Search2Icon } from "@chakra-ui/icons";
import {
    Input,
    InputGroup,
    InputLeftElement,
    Text,
    VStack,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";

const DEBOUNCE_TIME = 300;

type FilterInputPropsType = {
    label?: string;
    placeholder?: string;
    value?: string;
    delay?: number;
    onChange: (value: string) => void;
};

const FilterInput = (props: FilterInputPropsType) => {
    const {
        label,
        placeholder,
        value = "",
        delay = DEBOUNCE_TIME,
        onChange,
    } = props;

    const [internalValue, setInternalValue] = useState(value);

    const debouncedOnChange = useCallback(
        debounce((value: string) => {
            onChange(value);
        }, delay),
        []
    );

    const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = target.value;
        setInternalValue(newValue);
        debouncedOnChange(newValue);
    };

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    useEffect(() => {
        return () => {
            debouncedOnChange.cancel();
        };
    }, [debouncedOnChange]);

    // render

    const display = (
        <InputGroup>
            <InputLeftElement pointerEvents="none">
                <Search2Icon color="gray.400" />
            </InputLeftElement>
            <Input
                type="search"
                variant="outline"
                placeholder={placeholder}
                value={internalValue}
                onChange={handleChange}
            />
        </InputGroup>
    );

    return (
        <VStack align={"start"}>
            {label && <Text>{label}</Text>}
            {display}
        </VStack>
    );
};

export { FilterInput };
