import { Box, Flex, Spacer } from '@chakra-ui/react';
import { ReactElement, ReactNode, useMemo } from 'react';
import { ContentBox, EventDateTime, ListItemLayout } from '../../../../../common/components/display';
import { InspectionType } from '../../../../../types';

type PropTypes = {
    actionButton?: ReactNode;
    color: 'green' | 'orange' | 'wvwGreen' | 'wvwGrey05' | 'wvwYellow10';
    customHeading?: ReactElement;
    greyText?: boolean;
    inspection: InspectionType;
    statusBadge?: ReactNode;
};

const InspectionLayout = (props: PropTypes) => {
    const {
        actionButton = null,
        color,
        customHeading = null,
        greyText = false,
        inspection,
        statusBadge = null,
    } = props;

    const { cause } = inspection;

    const [event] = cause.calendarEntries;

    const heading = useMemo(() => {
        if (customHeading) return customHeading;

        return (
            <EventDateTime
                color={greyText ? 'wvwGrey60' : 'white'}
                date={new Date(event.start)}
                duration={event.duration}
            />
        );
    }, []);

    return (
        <ContentBox color={color}>
            <Box ml="2rem">
                <ListItemLayout
                    heading={heading}
                    extra={(
                        <Flex
                            ml="3rem"
                            w="100%"
                        >
                            {statusBadge}

                            <Spacer />

                            {actionButton}
                        </Flex>
                    )}
                />
            </Box>
        </ContentBox>
    );
};

export default InspectionLayout;
