import { Button, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { EntityType } from "../../../../../../../types";
import {
    useClientInvitesGetSent,
    useMyEntity,
    useMyValuerPanels,
    useValuerInvitesReceived,
} from "../../../../../../../utils/api/hooks";
import { usePermissions } from "../../../../../app/permissions/usePermissions";
import { Title } from "../../../../../common/components/display";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../../layout";
import CustomersDetailsDrawer from "./CustomersDetailsDrawer";
import CustomersReceivedInvitesTable from "./data-table/CustomersReceivedInvitesTable";
import CustomersSentInvitesTable from "./data-table/CustomersSentInvitesTable";
import CustomersTable from "./data-table/CustomersTable";
import useCustomersInviteDialog from "./useCustomersInviteDialog";
import { useRole } from "../../../../../../../common/hooks";

const CustomersScreen = () => {
    const t = useSmartTranslation();

    const [viewEntity, setViewEntity] = useState<EntityType>();

    const { data: entity, isLoading: entityLoading } = useMyEntity();
    
    const { userIsCompanyAdmin } = useRole();

    const { permission: canInviteCustomers } = usePermissions({
        activity: "InviteAClient",
        datatype: "Entity",
        objects: entity,
    });

    const { data: valuerPanels = [], isLoading: valuerPanelsLoading } =
        useMyValuerPanels();

    const {
        data: pendingInvitesList = [],
        isLoading: pendingInvitesListIsLoading,
        refresh: clientInvitesListRefresh,
    } = useClientInvitesGetSent();

    const { data: receivedInvites = [], isLoading: receivedInvitesLoading } =
        useValuerInvitesReceived();

    const { inviteModal, openInviteModal } = useCustomersInviteDialog({
        onSendInvite: () => {
            clientInvitesListRefresh();
        },
    });

    if (
        entityLoading ||
        valuerPanelsLoading ||
        pendingInvitesListIsLoading ||
        receivedInvitesLoading
    ) {
        return <LoadingSpinner />;
    }

    // render

    const header = (
        <Title
            title={t("account.accountSectionLink.customers")}
            rightSide={
                canInviteCustomers && (
                    <Button
                        variant={"outline"}
                        colorScheme={"blue"}
                        leftIcon={<MdAdd />}
                        onClick={openInviteModal}
                    >
                        {t("account.customerPanelDetails.button.addCustomer")}
                    </Button>
                )
            }
        />
    );

    const customersTable = (
        <Section
            title={t("account.customerPanelDetails.customersList")}
            content={
                <CustomersTable
                    customers={valuerPanels}
                    onRowClick={setViewEntity}
                />
            }
        />
    );

    const invitationsSentTable = userIsCompanyAdmin && (
        <Section
            title={t("account.customerPanelDetails.invitationsSentList")}
            content={<CustomersSentInvitesTable invites={pendingInvitesList} />}
        />
    );

    const invitationsReceivedTable = userIsCompanyAdmin && (
        <Section
            title={t("account.customerPanelDetails.invitationsReceivedList")}
            content={
                <CustomersReceivedInvitesTable invites={receivedInvites} />
            }
        />
    );

    const entityDetails = viewEntity && (
        <CustomersDetailsDrawer
            entity={viewEntity}
            onCloseDetails={() => {
                setViewEntity(undefined);
            }}
        />
    );

    return (
        <VStack
            align={"stretch"}
            gap={4}
        >
            {header}
            {customersTable}
            {invitationsSentTable}
            {invitationsReceivedTable}

            {inviteModal}
            {entityDetails}
        </VStack>
    );
};

export default CustomersScreen;
