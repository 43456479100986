import { Checkbox } from "@chakra-ui/react";

const calcTitleState = (nChecked: number, nTotal: number) => {
    if (nChecked === 0)
        return {
            isIndeterminate: false,
            isChecked: false,
        };
    if (nChecked < nTotal)
        return {
            isIndeterminate: true,
            isChecked: false,
        };
    return {
        isIndeterminate: false,
        isChecked: true,
    };
};

const CheckedColumn = {
    title: (nChecked: number, nTotal: number) => {
        const { isIndeterminate, isChecked } = calcTitleState(nChecked, nTotal);

        return (
            <Checkbox
                color={"blue.500"}
                iconSize={"12px"}
                isChecked={isChecked}
                isIndeterminate={isIndeterminate}
            />
        );
    },
    render: (isChecked: boolean, onToggle: (newChecked: boolean) => void) => {
        const onChange = () => onToggle(!isChecked);

        return (
            <Checkbox
                color={"blue.500"}
                iconSize={"12px"}
                isChecked={isChecked}
                onChange={onChange}
            />
        );
    },
};

export { CheckedColumn };
