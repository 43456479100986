import { Box, Flex, Spacer, VStack } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import MenuBar from "./menu-bar/MenuBar";
import Footer from "../../Footer";

export const PlatformScreen = () => {
    return (
        <Flex
            w={"100%"}
            minH={"100vh"}
            gap={0}
            direction={"column"}
        >
            <MenuBar />
            <Outlet />
            <Spacer />
            <Footer />
        </Flex>
    );
};
