import {
    Box,
    ButtonGroup,
    Flex,
    Grid,
    GridItem,
    Select,
    VStack,
} from "@chakra-ui/react";
import { createContext, useState } from "react";
import { HiOutlineFolderAdd } from "react-icons/hi";
import { MdOutlineClose, MdOutlineEdit, MdOutlineFilterAlt } from "react-icons/md";
import { Outlet, useParams } from "react-router-dom";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import { WvwButton } from "../../../../../common/components/inputs";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { WvwText } from "../../../../../common/components/typography";
import {
    useRole,
    useSmartTranslation,
} from "../../../../../common/hooks";
import { DocumentRequestType } from "../../../../../types";
import {
    useDocumentRequestGetFolders,
    useDocumentRequestSubmitAllRequests,
} from "../../../../../utils/api/hooks";
import Documents from "../documents/Documents";
import Folders from "../folders/Folders";
import Requests from "../requests/Requests";
import {
    DOCUMENT_FILTER,
    DocumentFiltersType,
    REQUEST_FILTER,
    RequestFiltersType,
    VIEW_FILTER,
    ViewFiltersType,
} from "./documentManagementAreaTypes";
import NewFolderBox from "./NewFolderBox";
import useToast from "../../../../../ui/v2/common/hooks/useToast";

const FOLDER_LEVEL_LIST = [
    {
        labelTranslationKey: "folders",
        level: VIEW_FILTER.FOLDERS,
    },
    {
        labelTranslationKey: "requests",
        level: VIEW_FILTER.REQUESTS,
    },
    {
        labelTranslationKey: "documents",
        level: VIEW_FILTER.DOCUMENTS,
    },
];

const REQUEST_FILTER_OPTIONS = [
    {
        labelTranslationKey: "allRequests",
        value: REQUEST_FILTER.ALL,
    },
    {
        labelTranslationKey: "statuses.approved",
        value: REQUEST_FILTER.APPROVED,
    },
    {
        labelTranslationKey: "statuses.rejected",
        value: REQUEST_FILTER.REJECTED,
    },
    {
        labelTranslationKey: "statuses.submitted",
        value: REQUEST_FILTER.SUBMITTED,
    },
    {
        labelTranslationKey: "statuses.pending",
        value: REQUEST_FILTER.PENDING,
    },
];

const DOCUMENT_FILTER_OPTIONS = [
    {
        labelTranslationKey: "allDocuments",
        value: DOCUMENT_FILTER.ALL,
    },
    {
        labelTranslationKey: "statuses.accepted",
        value: DOCUMENT_FILTER.ACCEPTED,
    },
    {
        labelTranslationKey: "statuses.rejected",
        value: DOCUMENT_FILTER.REJECTED,
    },
    {
        labelTranslationKey: "statuses.submitted",
        value: DOCUMENT_FILTER.SUBMITTED,
    },
    {
        labelTranslationKey: "statuses.pending",
        value: DOCUMENT_FILTER.PENDING,
    },
];

export const ViewFilterContext = createContext<ViewFiltersType>(
    VIEW_FILTER.FOLDERS
);

const DocumentManagementArea = () => {
    const { caseId = "" } = useParams<{ caseId: string }>();

    const t = useSmartTranslation("documentmanagement");
    const { createToast } = useToast();
    const { userIsClient, userIsValuer } = useRole();

    const [viewLevel, setViewLevel] = useState<ViewFiltersType>(
        VIEW_FILTER.FOLDERS
    );
    const [requestFilter, setRequestFilter] = useState<RequestFiltersType>(
        REQUEST_FILTER.ALL
    );
    const [documentFilter, setDocumentFilter] = useState<DocumentFiltersType>(
        DOCUMENT_FILTER.ALL
    );

    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const [openFolderCreate, setOpenFolderCreate] = useState<boolean>(false);
    const [submitWarning, setSubmitWarning] = useState(false);

    const { data: folders = [], isLoading: foldersLoading } =
        useDocumentRequestGetFolders({
            caseId: Number(caseId),
            requestStatus: requestFilter === "ALL" ? undefined : requestFilter,
            documentStatus:
                documentFilter === "ALL" ? undefined : documentFilter,
        });

    const { update: submitAllRequests } = useDocumentRequestSubmitAllRequests({
        caseId: Number(caseId),
        onSuccess: () => {
            setSubmitWarning(false);
        },
        onError: (error) => createToast({
            status: "error",
            description: error.message
        }),
    });

    const renderView = () => {
        switch (viewLevel) {
            case VIEW_FILTER.FOLDERS:
                return (
                    <Folders
                        inEditMode={inEditMode}
                        documentFilter={documentFilter}
                        requestFilter={requestFilter}
                    />
                );
            case VIEW_FILTER.REQUESTS:
                return (
                    <Requests
                        inEditMode={inEditMode}
                        documentFilter={documentFilter}
                        requestFilter={requestFilter}
                    />
                );
            case VIEW_FILTER.DOCUMENTS:
                return (
                    <Documents
                        inEditMode={inEditMode}
                        documentFilter={documentFilter}
                    />
                );
            default:
                return null;
        }
    };

    if (foldersLoading) return <LoadingSpinner />;

    const allRequests = folders.reduce(
        (acc, folder) => acc.concat(folder.requests),
        [] as DocumentRequestType[]
    );

    const isClientAndHasPending =
        userIsClient &&
        allRequests.filter((r) => r.status === "PENDING").length > 0;

    return (
        <ViewFilterContext.Provider value={viewLevel}>
            <VStack
                align="stretch"
                w="70%"
                spacing="0"
                paddingLeft="1.5rem"
            >
                <Flex
                    w="100%"
                    align="center"
                    mb=".5rem"
                >
                    <MdOutlineFilterAlt
                        color="#016064"
                        size="1.3rem"
                    />

                    <Select
                        marginLeft=".5rem"
                        maxW="fit-content"
                        defaultValue={viewLevel}
                        onChange={(e) =>
                            setViewLevel(VIEW_FILTER[e.target.value])
                        }
                    >
                        {FOLDER_LEVEL_LIST.map((i) => (
                            <option
                                key={i.level}
                                value={i.level}
                            >
                                {t(i.labelTranslationKey, {
                                    ns: "documentmanagement",
                                    defaultValue: i.labelTranslationKey,
                                })}
                            </option>
                        ))}
                    </Select>

                    <Select
                        maxW="fit-content"
                        pl="1rem"
                        value={requestFilter}
                        onChange={(e) =>
                            setRequestFilter(REQUEST_FILTER[e.target.value])
                        }
                    >
                        {REQUEST_FILTER_OPTIONS.map((i) => (
                            <option
                                key={i.value}
                                value={i.value}
                            >
                                {t(i.labelTranslationKey, {
                                    ns: "documentmanagement",
                                    defaultValue: i.labelTranslationKey,
                                })}
                            </option>
                        ))}
                    </Select>

                    <Select
                        maxW="fit-content"
                        pl="1rem"
                        value={documentFilter}
                        onChange={(e) =>
                            setDocumentFilter(DOCUMENT_FILTER[e.target.value])
                        }
                    >
                        {DOCUMENT_FILTER_OPTIONS.map((i) => (
                            <option
                                key={i.value}
                                value={i.value}
                            >
                                {t(i.labelTranslationKey, {
                                    ns: "documentmanagement",
                                    defaultValue: i.labelTranslationKey,
                                })}
                            </option>
                        ))}
                    </Select>

                    {userIsValuer && (
                        <ButtonGroup
                            spacing="1rem"
                            ml="auto"
                        >
                            <WvwButton
                                content={
                                    inEditMode
                                        ? t("endEditMode")
                                        : t("button.edit", { ns: "common" })
                                }
                                icon={inEditMode ? <MdOutlineClose /> : <MdOutlineEdit />}
                                onClick={() => setInEditMode(!inEditMode)}
                                variant="link"
                            />

                            <WvwButton
                                content={t("newFolder")}
                                icon={<HiOutlineFolderAdd />}
                                onClick={() => setOpenFolderCreate(true)}
                                variant="link"
                            />
                        </ButtonGroup>
                    )}
                </Flex>

                <Grid
                    bg="wvwGreen"
                    color="white"
                    borderRadius="10"
                    fontWeight="500"
                    pl="2.7rem"
                    alignItems="center"
                    maxW="100%"
                    paddingBlock=".5rem"
                    templateColumns="repeat(4, 1fr)"
                    w="100%"
                >
                    <GridItem minW="18rem">
                        <WvwText color="white">
                            {t("title", { ns: "documentmanagement" })}
                        </WvwText>
                    </GridItem>

                    <GridItem
                        justifySelf="center"
                        w="9.5rem"
                    >
                        <WvwText color="white">
                            {t("status", { ns: "documentmanagement" })}
                        </WvwText>
                    </GridItem>

                    <GridItem w="11rem">
                        <WvwText color="white">{t("lastUpdated")}</WvwText>
                    </GridItem>
                </Grid>

                {openFolderCreate && (
                    <NewFolderBox
                        closeEditor={() => setOpenFolderCreate(false)}
                    />
                )}

                <Box
                    w="100%"
                    height={window.innerHeight - 60}
                    overflow="auto"
                >
                    {renderView()}
                </Box>

                {isClientAndHasPending && (
                    <Flex justifyContent="flex-end">
                        <WvwButton
                            content={t("button.submit", { ns: "common" })}
                            onClick={() => setSubmitWarning(true)}
                        />
                    </Flex>
                )}

                <ConfirmationModal
                    isOpen={submitWarning}
                    onCancel={() => setSubmitWarning(false)}
                    title={t("modal.title.submitDocuments")}
                    content={t("modal.message.submitDocuments")}
                    onContinue={() => submitAllRequests(caseId)}
                />
            </VStack>

            <Outlet />
        </ViewFilterContext.Provider>
    );
};

export default DocumentManagementArea;
