import { EnquiryWithFromUserAndFromEntityType } from "../../../../../../types";
import {
    useCaseActiveCases,
    useEnquiryGetAll,
    useEnquiryGetDormant,
    useOfferGetAllMyOffers,
} from "../../../../../../utils/api/hooks";
import { useAuthedUser } from "../../../../app/useMeV2";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Enquiry } from "../../../../domain";
import { clientPictureAndName } from "../../../../domain/enquiry/columns/EnquiryColumns";
import { EnquiryWithSenderAndOffersType } from "../../enquiry-list/EnquiryList";
import { ByOutlet } from "../../../../common/features/filters/domain/enquiry/ByOutlet";
import { ByScope } from "../../../../common/features/filters/domain/enquiry/ByScope";
import { ByStandard } from "../../../../common/features/filters/domain/enquiry/ByStandard";
import { ByStatus } from "../../../../common/features/filters/domain/enquiry/ByStatus";
import { PlatformScreens } from "../../PlatformScreens";

type EnquiryListPrefilter = "ACTIVE" | "ARCHIVED";

type EnquiryListLoaderProps = {
    prefilter: EnquiryListPrefilter;
};

// TODO split into components in order to not load all of the user's enquiries everywhere
const EnquiryListLoader = (props: EnquiryListLoaderProps) => {
    const { prefilter } = props;

    const t = useSmartTranslation();

    // PENDING / CANCELLED
    const { data: enquiries, isLoading: isLoadingEnquiries } =
        useEnquiryGetAll();

    // ACCEPTED
    const { data: cases, isLoading: isLoadingCases } = useCaseActiveCases();

    const { data: archivedEnquiries, isLoading: isLoadingArchivedEnquiries } =
        useEnquiryGetDormant();

    const { data: offers = [], isLoading: isLoadingOffers } =
        useOfferGetAllMyOffers();

    const user = useAuthedUser();

    if (
        !enquiries ||
        !archivedEnquiries ||
        !cases ||
        !offers ||
        isLoadingEnquiries ||
        isLoadingArchivedEnquiries ||
        isLoadingCases ||
        isLoadingOffers
    )
        return <LoadingSpinner />;

    let prefilteredEnquiries: EnquiryWithFromUserAndFromEntityType[];
    switch (prefilter) {
        case "ACTIVE": {
            prefilteredEnquiries = cases
                .map(
                    ({
                        enquiry,
                        clientEntity: fromEntity,
                        clientUser: fromUser,
                    }) => ({
                        ...enquiry,
                        fromEntity,
                        fromUser: { ...fromUser, entity: fromEntity },
                    })
                )
                .concat(enquiries.map(Enquiry.includeEntityInUser));
            break;
        }
        case "ARCHIVED": {
            prefilteredEnquiries = archivedEnquiries.map(
                Enquiry.includeEntityInUser
            );
            break;
        }
    }

    const enquiriesWithOffers: EnquiryWithSenderAndOffersType[] =
        prefilteredEnquiries.map((enquiry) => ({
            ...enquiry,
            offers: offers.filter(
                (offer) =>
                    offer.enquiryId === enquiry.id &&
                    offer.fromValuerId === user.id
            ),
        }));

    const title = t(`screens.platform.enquiryList.title.${prefilter}`, {
        count: enquiriesWithOffers.length,
    });

    const filters = [ByOutlet, ByStandard, ByStatus, ByScope];

    return (
        <PlatformScreens.EnquiryList
            enquiries={enquiriesWithOffers}
            columns={columns}
            title={title}
            filters={filters}
        />
    );
};

const columns = [
    Enquiry.TableColumns.Enquiry.enquiryIdWithProperties,
    Enquiry.TableColumns.Enquiry.createdAt,
    clientPictureAndName,
    Enquiry.TableColumns.Enquiry.valuationScopeAndStandard,
    Enquiry.TableColumns.Enquiry.expectedDeliveryDate,
    Enquiry.TableColumns.Enquiry.estPortfolioValueEnquiry,
    Enquiry.TableColumns.EnquiryWithOffer.valuerOfferStatus,
];

export { EnquiryListLoader as ValuerEnquiryList };
