import { PropertyType } from "../../../../../../../types";
import { useAuthedUser } from "../../../../../app/useMeV2";
import { useSmartTranslation } from "../../../../hooks/useSmartTranslation";
import {
    EnquiryFilterFunctionType,
    EnquiryFilterPropsType,
} from "../../../../../screens/platform/enquiry-list/filters/EnquiryFilter";
import { FilterInput } from "../../FilterInput";

const FILTERGROUP_ID = "BY_PROPERTY_AND_CLIENT";

const ByPropertyAndClient = (props: EnquiryFilterPropsType) => {
    const {
        activateFilter: activateFilterGroup,
        deactivateFilter,
        activeFilters,
    } = props;

    const t = useSmartTranslation();

    const user = useAuthedUser();
    const role = user.entity.accountType;

    const activateFilter = (
        value: string,
        filter: EnquiryFilterFunctionType
    ): void => {
        activateFilterGroup({
            id: FILTERGROUP_ID,
            filter,
            value,
            displayedData: {
                filterLabel: t(
                    `screens.platform.enquiryList.filters.byPropertyAndClient.label`
                ),
                valueLabel: value,
            },
        });
    };

    const activeFilterValue = activeFilters.find(
        ({ id }) => id === FILTERGROUP_ID
    )?.value;

    return (
        <FilterInput
            placeholder={t(
                `screens.platform.enquiryList.filters.byPropertyAndClient.${role}.placeholder`
            )}
            value={activeFilterValue}
            onChange={(value) => {
                const search = value.toLowerCase();

                if (!search) return deactivateFilter(FILTERGROUP_ID);

                return activateFilter(
                    value,
                    ({ properties, fromEntity, fromUser }) => {
                        const compareProperties = properties.some(
                            (property: PropertyType) =>
                                `${property.city} ${property.street} ${property.postalCode}`
                                    .toLowerCase()
                                    .includes(search)
                        );

                        const compareEntity = fromEntity
                            ? fromEntity.name.toLowerCase().includes(search)
                            : false;

                        const compareUser = fromUser
                            ? `${fromUser.firstName} ${fromUser.lastName}`
                                  .toLowerCase()
                                  .includes(search)
                            : false;

                        return (
                            compareProperties || compareEntity || compareUser
                        );
                    }
                );
            }}
        />
    );
};

export { ByPropertyAndClient };
