import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { object, string } from "yup";
import { EntityType } from "../../../../../../types";
import { useUserInvitesCreate } from "../../../../../../utils/api/hooks";
import { Forms } from "../../../../common/forms/Forms";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../common/hooks/useToast";
import FormikRadioGroup from "../../../../common/forms/FormikRadioGroup";

const validationSchema = object({
    firstName: string().required("forms.errors.required"),
    lastName: string().required("forms.errors.required"),
    email: string().required("forms.errors.required"),
    role: string().required("forms.errors.required"),
});

const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: "USER",
    doesValuations: "true",
};

type TeamInviteModalProps = {
    entity: EntityType;
    onSendInvite: () => void;
};

const useValuerInviteModal = (props: TeamInviteModalProps) => {
    const { entity, onSendInvite } = props;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { createToast } = useToast();

    const { update: sendInvite } = useUserInvitesCreate({
        onSuccess: () => {
            onClose();
            onSendInvite();
        },
        onError: (error) => {
            createToast({
                description: error.message,
                status: "error",
            });
        },
    });

    const modal = (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />

            <ModalContent minWidth="2xl">
                <ModalHeader color="black">
                    {t("account.teamInvites.addTeamMember")}
                </ModalHeader>

                <ModalCloseButton />

                <Forms.FormikForm
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        sendInvite({
                            entityId: entity.id,
                            email: values.email,
                            firstName: values.firstName,
                            lastName: values.lastName,
                            role: values.role,
                            doesValuations: values.doesValuations === true,
                        });
                    }}
                >
                    <ModalBody>
                        <VStack
                            spacing={4}
                            align={"stretch"}
                        >
                            <HStack spacing={4}>
                                <Forms.FormikInput
                                    label={t(
                                        "account.teamInvites.fieldPlaceholder.firstName"
                                    )}
                                    placeholder={t(
                                        "account.teamInvites.fieldPlaceholder.firstName"
                                    )}
                                    name="firstName"
                                />

                                <Forms.FormikInput
                                    label={t(
                                        "account.teamInvites.fieldPlaceholder.lastName"
                                    )}
                                    placeholder={t(
                                        "account.teamInvites.fieldPlaceholder.lastName"
                                    )}
                                    name="lastName"
                                />
                            </HStack>

                            <Forms.FormikInput
                                label={t(
                                    "account.teamInvites.fieldPlaceholder.email"
                                )}
                                placeholder={t(
                                    "account.teamInvites.fieldPlaceholder.email"
                                )}
                                name="email"
                                type="email"
                            />
                        </VStack>

                        <Text
                            fontSize={"sm"}
                            mt={4}
                            mb={2}
                        >
                            {t("account.teamInvites.role")}
                        </Text>

                        <Forms.FormikRadioGroup
                            name="role"
                            options={[
                                {
                                    label: t("domain.user.role.USER"),
                                    description: t(
                                        "account.teamInvites.roleDescriptions.USER"
                                    ),
                                    value: "USER",
                                },
                                {
                                    label: t("domain.user.role.ASSISTANT"),
                                    description: t(
                                        "account.teamInvites.roleDescriptions.ASSISTANT"
                                    ),
                                    value: "ASSISTANT",
                                },
                                {
                                    label: t("domain.user.role.ADMIN"),
                                    description: t(
                                        "account.teamInvites.roleDescriptions.ADMIN"
                                    ),
                                    value: "ADMIN",
                                },
                            ]}
                        />

                        <VStack
                            width="100%"
                            align="left"
                        >
                            <Text
                                fontSize={"sm"}
                                mt={4}
                                mb={2}
                            >
                                {t('account.teamInvites.doesValuations.title')}
                            </Text>

                            <FormikRadioGroup
                                name="doesValuations"
                                options={[
                                    {
                                        label: t("common.yes"),
                                        value: "true",
                                    },
                                    {
                                        label: t("common.no"),
                                        value: "false",
                                    },
                                ]}
                            />
                        </VStack>
                    </ModalBody>

                    <ModalFooter justifyContent={"start"}>
                        <Button
                            variant="primary"
                            w={"calc(50% - .5rem)"}
                            type="submit"
                        >
                            {t("common.button.confirm")}
                        </Button>
                    </ModalFooter>
                </Forms.FormikForm>
            </ModalContent>
        </Modal>
    );

    return {
        valuerInviteModal: modal,
        openValuerInviteModal: onOpen,
        closeValuerInviteModal: onClose,
    };
};

export default useValuerInviteModal;
