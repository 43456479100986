import { EnquiryType, PropertyType } from "../../../../../../../types";
import BaseFilterInput from "../../BaseFilterInput";
import { FilterPropsType } from "../../Filter";

const ByEnquiryDataFilter = (props: FilterPropsType<EnquiryType>) => {
    return (
        <BaseFilterInput
            {...props}
            filterGroupId="BY_ENQUIRY_DATA"
            placeholder="Search by Enquiries"
            searchFn={(
                { invoiceLName, invoiceFName, properties, valuationType },
                search
            ) => {
                const compareProperties = properties.some(
                    (property: PropertyType) =>
                        `${property.city} ${property.street} ${property.postalCode}`
                            .toLowerCase()
                            .includes(search)
                );

                const name = invoiceFName
                    ? `${invoiceFName} ${invoiceLName}`
                          .toLowerCase()
                          .includes(search)
                    : false;

                const valuation = valuationType
                    ? valuationType.toLowerCase().includes(search)
                    : false;

                return compareProperties || name || valuation;
            }}
        />
    );
};

export { ByEnquiryDataFilter };
