import { Box, HStack, VStack } from "@chakra-ui/react";
import { WvwText, WVWTitle } from "../../../../common/components/typography";
import WvwIcon, {
    WvwIconKeys,
} from "../../../../common/components/display/WvwIcon";

type PropsType = {
    variant?: "white" | "none";
    icon?: WvwIconKeys;
    header: string | undefined;
    subHeader?: string;
    element: React.ReactNode;
};

/**
 * A form box section with a header and optional subheader.
 */
const FormSection = (props: PropsType) => {
    const { variant = 'none', icon, header, subHeader, element } = props;

    return (
        <VStack
            w="100%"
            bg={variant === "white" ? "white" : "transparent"}
            borderRadius="10"
            padding="1rem"
            align="left"
        >
            <Box>
                {header !== undefined && (
                    <WVWTitle
                        content={header}
                        color="black"
                        level="1"
                    />
                )}

                <HStack>
                    {icon && <WvwIcon icon={icon} />}

                    {subHeader !== undefined && <WvwText>{subHeader}</WvwText>}
                </HStack>
            </Box>

            {element}
        </VStack>
    );
};

export default FormSection;
