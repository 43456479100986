import { Box, Button, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { FiUpload } from "react-icons/fi";
import { MdCloudOff } from "react-icons/md";
import { StandardSystemStandardsOffered } from "../../../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";
import { UserType } from "../../../../../../types";
import { FirebaseCertificateType } from "../../../../../../utils/storage/storage";
import {
    File,
    getFileTypeBasedOnName,
} from "../../../../common/components/display/File";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../../common/MISSING_TRANSLATION";
import { useDocumentViewerModal } from "../../../../domain/document/useDocumentViewerModal";
import { InformationRowItem } from "../InformationRowItem";

type PropsType = {
    user: UserType;
    certificates?: FirebaseCertificateType[];
    hideUploadButton?: boolean;
    uploadCertificates?: (
        standard: string | StandardSystemStandardsOffered
    ) => void;
};

const ProfileValuationStandards = (props: PropsType) => {
    const {
        user: { systemStandardsOffered = [], otherStandardsOffered = [] },
        certificates = [],
        hideUploadButton = false,
        uploadCertificates,
    } = props;

    const t = useSmartTranslation();

    const { documentView, viewDocument } = useDocumentViewerModal();

    const openDocument = (file: FirebaseCertificateType) => {
        viewDocument({
            uri: file.url,
            fileType: getFileTypeBasedOnName(file.label),
            label: file.label,
        });
    };

    // render

    const systemStandards = systemStandardsOffered.length > 0 && (
        <StandardsList
            standards={systemStandardsOffered}
            certificates={certificates}
            viewDocument={openDocument}
            uploadDocument={uploadCertificates!}
            hideUploadButton={hideUploadButton}
            hasTranslations
        />
    );

    const otherStandards = otherStandardsOffered.length > 0 && (
        <StandardsList
            standards={otherStandardsOffered}
            certificates={certificates}
            viewDocument={openDocument}
            uploadDocument={uploadCertificates!}
            hideUploadButton={hideUploadButton}
        />
    );

    const emptyData = !systemStandardsOffered.length &&
        !otherStandardsOffered.length && (
            <Text
                color="gray.500"
                fontSize={"sm"}
            >
                {t("common.noData")}
            </Text>
        );

    return (
        <VStack
            align={"stretch"}
            spacing={1}
        >
            {systemStandards}
            {otherStandards}
            {documentView}

            {emptyData}
        </VStack>
    );
};

type StandardsListProps = {
    standards: string[] | StandardSystemStandardsOffered[];
    certificates: FirebaseCertificateType[];
    hasTranslations?: boolean;
    hideUploadButton?: boolean;
    viewDocument: (data: FirebaseCertificateType) => void;
    uploadDocument: (data: string | StandardSystemStandardsOffered) => void;
};

const StandardsList = (props: StandardsListProps) => {
    const {
        certificates,
        standards,
        hasTranslations = false,
        hideUploadButton,
        viewDocument,
        uploadDocument,
    } = props;

    const t = useSmartTranslation();

    return (
        <>
            {standards.length === 0 && (
                <Text
                    color="gray.500"
                    fontSize={"sm"}
                >
                    {t("common.noData")}
                </Text>
            )}

            {standards?.sort()?.map((i, idx) => {
                const files = certificates.filter(
                    (certificate) =>
                        certificate.type.toLowerCase() === i.toLowerCase()
                );

                return (
                    <InformationRowItem
                        key={idx}
                        label={
                            hasTranslations
                                ? t(`domain.standards.systemStandard.${i}`, {
                                      defaultValue: MISSING_TRANSLATION,
                                  })
                                : i
                        }
                        customContent={
                            <VStack
                                spacing={2}
                                align={"stretch"}
                                width={"100%"}
                            >
                                {files.map((file, idx) => (
                                    <FileItem
                                        key={idx}
                                        file={file}
                                        viewDocument={() => viewDocument(file)}
                                    />
                                ))}

                                {files.length === 0 && (
                                    <EmptyFilesList
                                        hideUploadButton={hideUploadButton}
                                        uploadDocument={() => uploadDocument(i)}
                                    />
                                )}
                            </VStack>
                        }
                    />
                );
            })}
        </>
    );
};

type FileItemProps = {
    file: FirebaseCertificateType;
    viewDocument: () => void;
};

const FileItem = (props: FileItemProps) => {
    const { file, viewDocument } = props;

    return (
        <Box
            padding=".5rem 1rem"
            bg={"gray.50"}
            transition={".25s"}
            color="gray.900"
            borderRadius={"lg"}
            _hover={{
                bg: "blue.50",
                color: "blue.500",
            }}
        >
            <File
                documentItem={{
                    ...file,
                    name: file.label,
                    size: file.meta.size,
                }}
                viewDocument={viewDocument}
                downloadDocument
            />
        </Box>
    );
};

type EmptyFilesListProps = {
    hideUploadButton?: boolean;
    uploadDocument: () => void;
};

const EmptyFilesList = (props: EmptyFilesListProps) => {
    const { hideUploadButton, uploadDocument } = props;

    const t = useSmartTranslation();

    const uploadButton = !hideUploadButton && (
        <Button
            size={"sm"}
            colorScheme="blue"
            leftIcon={<FiUpload />}
            onClick={uploadDocument}
        >
            <Text fontSize={"sm"}>
                {t("profile.membershipsAndQualifications.uploadCertificate")}
            </Text>
        </Button>
    );

    return (
        <HStack
            spacing={4}
            p={".6rem 1rem"}
            border={"1px solid"}
            borderColor={"gray.200"}
            borderRadius={"lg"}
            justify={"space-between"}
        >
            <HStack spacing={4}>
                <Icon
                    as={MdCloudOff}
                    boxSize={"1.5rem"}
                    color={"gray.400"}
                />
                <Text
                    fontSize={"sm"}
                    color={"gray.500"}
                >
                    {t("profile.membershipsAndQualifications.noCertificate")}
                </Text>
            </HStack>

            {uploadButton}
        </HStack>
    );
};

export { FileItem, ProfileValuationStandards };
