import { useContext } from "react";
import WarningMessage from "../components/display/WarningMessage";
import {
    FormikWarning as FormikWarningType,
    FormikWarningsContext,
    hasFormikWarning,
} from "./FormikForm";

type Props =
    | {
          warning: FormikWarningType;
          fieldName?: undefined;
      }
    | {
          warning?: undefined;
          fieldName: string;
      };

export const FormikWarning = (props: Props) => {
    const { warning: providedWarning, fieldName } = props;

    const warnings = useContext(FormikWarningsContext);

    let warning = providedWarning;

    if (fieldName) {
        warning = hasFormikWarning(fieldName, warnings);
    }

    if (!warning) return null;

    return <WarningMessage label={warning.message} />;
};
