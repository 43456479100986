import { VStack } from "@chakra-ui/layout";
import { UserType } from "../../../../../../types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { InformationRowItem } from "../InformationRowItem";
import RenderFieldData from "../RenderFieldData";

type PropsType = {
    user: UserType;
};

const ProfileContactDetails = (props: PropsType) => {
    const {
        user: {
            email,
            mobileDialCode,
            mobile,
            telDialCode,
            tel,
            faxDialCode,
            fax,
            orgPosition,
        },
    } = props;

    const t = useSmartTranslation();

    return (
        <VStack
            spacing={1}
            width={"100%"}
            align={"stretch"}
        >
            <InformationRowItem
                label={t("profile.profileDetails.email")}
                content={
                    <RenderFieldData
                        field="email"
                        data={[email]}
                        config={{ required: true }}
                    />
                }
            />

            <InformationRowItem
                label={t("profile.profileDetails.mobileNumber")}
                content={
                    <RenderFieldData
                        field="mobileNumber"
                        data={[mobileDialCode, mobile]}
                        config={{ required: true }}
                    />
                }
            />

            <InformationRowItem
                label={t("profile.profileDetails.telNumber")}
                content={
                    <RenderFieldData
                        field="telNumber"
                        data={[telDialCode, tel]}
                    />
                }
            />

            <InformationRowItem
                label={t("profile.profileDetails.faxNumber")}
                content={
                    <RenderFieldData
                        field="faxNumber"
                        data={[faxDialCode, fax]}
                    />
                }
            />

            <InformationRowItem
                label={t("profile.profileDetails.roleInCompany")}
                content={
                    <RenderFieldData
                        field="roleInCompany"
                        data={[orgPosition]}
                    />
                }
            />
        </VStack>
    );
};

export default ProfileContactDetails;
