import WVWLogoColor from "../../../../assets/icons/WVWLogoColor";
import WVWLogoWhite from "../../../../assets/icons/WVWLogoWhite";

const Logo = (props: { size?: string, withColor?: boolean }) => {
    const { size = '4rem', withColor = false } = props;

    if (withColor) {
        return (
            <WVWLogoColor
                width={size}
            />
        );
    }

    return (
        <WVWLogoWhite
            width={size}
        />
    );
};

export default Logo;
