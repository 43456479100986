import {
    Box,
    Button,
    Highlight,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from "@chakra-ui/react";
import { DragEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsCloudUpload } from "react-icons/bs";
import heic2any from "heic2any";
import * as Yup from "yup";
import FormikForm from "../forms/FormikForm";
import FormikInput from "../forms/FormikInput";
import { allowedFileSize } from "../vars/file-uploads/file-standards";

type SupportedFileType =
    | ".csv"
    | ".gif"
    | ".jpeg"
    | ".jpg"
    | ".heic"
    | ".heif"
    | ".pdf"
    | ".png"
    | ".xls"
    | ".xlsx";

type PropTypes = {
    customUploadButton?: React.ReactNode;
    defaultFileName?: string;
    fileTypes?: SupportedFileType[];
    noRename?: boolean;
    onChange: (files: File[]) => void;
};

const FileUploadInput = (props: PropTypes) => {
    const { t } = useTranslation(["formik", "common"]);

    const {
        customUploadButton,
        defaultFileName,
        fileTypes,
        noRename = false,
        onChange,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const [dragActive, setDragActive] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [files, setFiles] = useState<File[]>([]);

    const name = Math.random().toString(36).substring(2, 15);

    const checkFile = (f: File) => {
        const fileExtension =
            `.${f.name.split(".").pop()?.toLowerCase()}` || "";

        if (fileTypes && !fileTypes.some((i) => i === fileExtension)) {
            setError(t("fileUpload.fileTypeNotAllowed", { ns: "errors" }));
            setFiles([]);
            return false;
        }

        if (allowedFileSize && f.size > allowedFileSize) {
            setError(t("fileUpload.fileTooLarge", { ns: "errors" }));
            setFiles([]);
            return false;
        }

        setError(undefined);
        return true;
    };

    const handleDrag = (e: DragEvent<HTMLFormElement | HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (!checkFile(e.dataTransfer.files[0])) {
                return;
            }

            const fList = [];

            for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
                fList.push(e.dataTransfer.files[i]);
            }

            setFiles(fList);
        }
    };

    useEffect(() => {
        if (!noRename) return;
        if (files.length === 0) return;

        onChange(files);
    }, [files]);

    useEffect(() => {
        if (inputRef.current) {
            const dataTransfer = new DataTransfer();
            files.forEach((file) => dataTransfer.items.add(file));
            inputRef.current.files = dataTransfer.files;
        }
    }, [files]);

    return (
        <>
            <div
                style={{ width: customUploadButton ? "auto" : "100%" }}
                onDragEnter={handleDrag}
            >
                <label
                    id={`label-file-upload-${name}`}
                    htmlFor={`input-file-upload-${name}`}
                >
                    {customUploadButton || (
                        <Box
                            border={`${dragActive ? 2 : 1}px dashed`}
                            borderColor="wvwGreen"
                            borderRadius="4"
                            marginBlock={dragActive ? "1px" : "2px"}
                            paddingBlock="1rem"
                            w="100%"
                            _hover={{
                                borderWidth: "2px",
                                cursor: "pointer",
                                marginBlock: "1px",
                            }}
                        >
                            <VStack
                                align="center"
                                spacing="2"
                                w="100%"
                            >
                                <BsCloudUpload />

                                <Text align="center">
                                    <Highlight
                                        query={t("fileUpload.browseKeyword", {
                                            ns: "common",
                                        })}
                                        styles={{
                                            color: "wvwGreen",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("fileUpload.dragDropBrowse", {
                                            ns: "common",
                                        })}
                                    </Highlight>
                                </Text>

                                <Text variant="secondary">
                                    {t("fileUpload.maxFileSize", {
                                        ns: "common",
                                    })}
                                </Text>
                            </VStack>
                        </Box>
                    )}

                    {error && <Text color="red">{error}</Text>}

                    <input
                        id={`input-file-upload-${name}`}
                        accept={fileTypes ? fileTypes.join(",") : undefined}
                        ref={inputRef}
                        hidden
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        onChange={async (event) => {
                            event.preventDefault();

                            if (event.target.files && event.target.files[0]) {
                                const fList: File[] = [];

                                for (let i = 0; i < event.target.files.length; i += 1) {
                                    let file = event.target.files[i];

                                    if (file.type === 'image/heic' || file.type === 'image/heif') {
                                        try {
                                            const convertedBlob = await heic2any({
                                                blob: file,
                                                toType: 'image/jpeg',
                                            });

                                            file = new File(
                                                [convertedBlob as Blob],
                                                file.name.replace(/\.heic|\.heif/i, '.jpeg'),
                                                { type: 'image/jpeg' }
                                            );
                                        } catch (error) {
                                            setError(t("error.error", { ns: "common" }));
                                            continue;
                                        }
                                    }

                                    if (checkFile(file)) {
                                        fList.push(file);
                                    }
                                }

                                setFiles(fList);
                            }
                        }}
                    />
                </label>

                {dragActive && (
                    <div
                        id="drag-file-element"
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            borderRadius: "1rem",
                            top: "0px",
                            right: "0px",
                            bottom: "0px",
                            left: "0px",
                        }}
                    />
                )}
            </div>

            <Modal
                isOpen={!noRename && files.length > 0}
                onClose={() => setFiles([])}
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>
                        {t("button.uploadFile", { ns: "common" })}
                    </ModalHeader>

                    <FormikForm
                        initialValues={{
                            fileNames: files.map((f) => f.name),
                        }}
                        onSubmit={(values) => {
                            const { fileNames } = values;

                            const fList = files;

                            files.forEach((file, index) => {
                                let formattedFilename = fileNames[index].trim();
                                let uploadFile = file as File;

                                if (formattedFilename !== file?.name) {
                                    const extention =
                                        file?.name
                                            ?.split(".")
                                            ?.pop()
                                            ?.toLowerCase() || "";

                                    if (
                                        formattedFilename
                                            .split(".")
                                            .pop()
                                            .toLowerCase() !== extention
                                    ) {
                                        formattedFilename += `.${extention}`;
                                    }

                                    uploadFile = new File(
                                        [file as Blob],
                                        formattedFilename as string,
                                        {
                                            type: file?.type,
                                        }
                                    );
                                }

                                files[index] = uploadFile;
                            });

                            onChange(fList);

                            setFiles([]);
                        }}
                        validationSchema={Yup.object().shape({
                            fileNames: Yup.array().of(
                                Yup.string().required(
                                    t("common:fileNameRequired")
                                )
                            ),
                        })}
                    >
                        <ModalBody>
                            <VStack>
                                {files.map((i, index) => (
                                    <VStack
                                        key={i.name}
                                        w="100%"
                                    >
                                        <Text
                                            color="wvwGreen"
                                            w="100%"
                                        >
                                            {t("file", { ns: "common" })}
                                            {` #${index + 1}`}
                                        </Text>

                                        <FormikInput
                                            name={`fileNames.${index}`}
                                        />
                                    </VStack>
                                ))}
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                mr={3}
                                variant="ghost"
                                onClick={() => setFiles([])}
                            >
                                {t("button.cancel", { ns: "common" })}
                            </Button>

                            <Button
                                colorScheme="wvwGreen"
                                type="submit"
                                variant="primary"
                            >
                                {t("button.upload", { ns: "common" })}
                            </Button>
                        </ModalFooter>
                    </FormikForm>
                </ModalContent>
            </Modal>
        </>
    );
};

export default FileUploadInput;
