import {
    Box,
    Flex,
    Heading,
    HStack,
    IconButton,
    Link,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { RiSendPlane2Line } from 'react-icons/ri';
import LoadingSpinner from '../../../../common/components/LoadingSpinner';
import PublicUserProfilePicture from '../../../../common/components/PublicUserProfilePicture';
import { useMyEntity, useUsersByEntity } from '../../../../utils/api/hooks';

const ProfileTeam = () => {
    const { t } = useTranslation(['profile', 'account', 'common']);

    const {
        data: entity,
        isLoading: entityLoading,
        error: entityError,
    } = useMyEntity();

    const {
        usersList = [],
        isLoading: usersLoading,
        error: usersError,
    } = useUsersByEntity(entity?.id);

    if (entityLoading) {
        return (
            <LoadingSpinner />
        );
    }

    if (entityError !== null) {
        return (
            <VStack>
                <Heading
                    size="sm"
                >
                    {t('error.weAreSorry', { ns: 'common' })}
                </Heading>

                <Text>
                    {t('valuerListError')}
                </Text>
            </VStack>
        );
    }

    return (
        <Flex
            paddingInline=".5rem"
            paddingBlock="2rem"
        >
            <VStack>
                <Box>
                    <Heading
                        as="h4"
                        size="md"
                        fontWeight="light"
                    >
                        {t('teamDetails.sectionTitle')}
                    </Heading>

                    <Text
                        color="wvwGrey"
                        paddingTop="1rem"
                        paddingBottom="2rem"
                    >
                        {t('teamDetails.blurb')}
                    </Text>
                </Box>
            </VStack>

            <Spacer />

            <VStack
                w="70%"
                paddingTop="1.5rem"
            >
                {usersList.map(user => (
                    <Flex
                        alignItems="center"
                        w="100%"
                        height="3.5rem"
                        border="1px solid"
                        borderColor="wvwGrey40"
                        bg="wvwGrey05"
                        borderRadius="8"
                        paddingInline="1rem"
                        key={user.email}
                    >
                        <HStack spacing="8">
                            <PublicUserProfilePicture
                                size="2.5rem"
                                userId={user.id}
                                entityId={user.entityId}
                            />

                            <Box paddingBlock="1rem">
                                <Text
                                    color="wvwGrey80"
                                    fontWeight="medium"
                                >
                                    {`${user.firstName} ${user.lastName}`}
                                </Text>
                            </Box>

                            {user.role === 'ADMIN' && (
                                <Box
                                    bg="wvwGreen"
                                    paddingBlock=".2rem"
                                    paddingInline=".6rem"
                                    borderRadius="15"
                                >
                                    <Text
                                        color="white"
                                        fontWeight="medium"
                                    >
                                        {t('admin', { ns: 'account' })}
                                    </Text>
                                </Box>
                            )}
                        </HStack>

                        <Spacer />

                        <Link
                            href={`mailto:${user.email}`}
                            isExternal
                        >
                            <IconButton
                                aria-label="email"
                                bg="none"
                                icon={<RiSendPlane2Line />}
                            />
                        </Link>
                    </Flex>
                ))}

                {usersLoading && (
                    <LoadingSpinner />
                )}

                {usersError !== null && (
                    <VStack>
                        <Heading
                            size="sm"
                        >
                            {t('error.weAreSorry', { ns: 'common' })}
                        </Heading>

                        <Text>
                            {t('valuerListError')}
                        </Text>
                    </VStack>
                )}
            </VStack>
        </Flex>
    );
};

export default ProfileTeam;
