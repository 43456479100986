import { useFormikContext } from "formik";
import { ReactElement, ReactNode } from "react";
import FormikCountrySelect from "./FormikCountrySelect";
import FormikInput from "./FormikInput";
import { Box } from "@chakra-ui/react";

type PropsType = {
    dialCodeLabel?: string;
    dialCodeName: string;
    phoneNumberPlaceholder: string;
    phoneNumberName: string;
    fieldWrapper?: (field: ReactElement) => ReactNode;
};

const FormikPhoneNumberInput = (props: PropsType) => {
    const {
        dialCodeLabel,
        dialCodeName,
        phoneNumberPlaceholder,
        phoneNumberName,
        fieldWrapper = (field: ReactElement) => <>{field}</>,
    } = props;

    const { setFieldTouched, validateForm } = useFormikContext();

    return (
        <>
            {fieldWrapper(
                <FormikCountrySelect
                    name={dialCodeName}
                    label={dialCodeLabel}
                    selectDialCode
                    width="100%"
                    onChange={async () => {
                        await setFieldTouched(phoneNumberName, true);
                        validateForm();
                    }}
                />
            )}

            {fieldWrapper(
                <Box
                    pt={7}
                    width={"100%"}
                >
                    <FormikInput
                        name={phoneNumberName}
                        placeholder={phoneNumberPlaceholder}
                        onChange={async () => {
                            await setFieldTouched(dialCodeName, true);
                            validateForm();
                        }}
                    />
                </Box>
            )}
        </>
    );
};

export default FormikPhoneNumberInput;
