import { useState } from "react";
import { IDocument } from "react-doc-viewer";
import ModalDynamic from "../../common/components/ModalDynamic";
import DocumentViewer from "./DocumentViewer";

export type ViewedDocument = IDocument & {
    label: string;
};

const useDocumentViewerModal = () => {
    const [document, setDocument] = useState<ViewedDocument>();

    const setViewedDocument = (doc: ViewedDocument) => {
        if (!doc) return;

        setDocument(doc);
    };

    const onClose = () => {
        setDocument(undefined);
    };

    const documentView = document && (
        <ModalDynamic
            size="full"
            isOpen
            header={document.label}
            onClose={onClose}
        >
            <DocumentViewer
                documents={[document]}
                height={"calc(100vh - 70px)"}
            />
        </ModalDynamic>
    );

    return {
        documentView,
        viewDocument: setViewedDocument,
    };
};

export { useDocumentViewerModal };
