export type StandardSystemStandardsOffered =
    (typeof standardSystemStandardsOffered)[number];

export type StandardSystemStandardsOfferedWithOther =
    (typeof standardSystemStandardsOfferedWithOtherAndNone)[number];

/*
These are the values the field in the Enquiry can have.
To be used eg. as choices for Clients when they create an Enquiry.

If the client doesn't get to see a choice for an Enquiry Standard,
eg. in a Direct Enquiry, it remains the empty string.

"Other" is an option in the UI to indicate that a non-system-standard
is requested, which is entered manually into a different field
*/
export const standardSystemStandardsOfferedWithOtherAndNone = [
    'Öffentlich Bestellt',
    'DIA',
    "DIN 17024",
    'Sprengnetter',
    'HypZert',
    'RICS - Red Book',
    'BIIS',
    'BVS',
    "Tegova - Blue Book",
    "IVSC - White Book",
    "ValueV",
    "BDSF",
    "DESAG",
    "TAF",
    "AI",
    "ASA",
    "Other",
    "",
] as const;

/*
These are the actual standards in the system.
To be used eg. as choices for Valuers when they edit their Account.
*/
const standardSystemStandardsOffered = [
    'Öffentlich Bestellt',
    'DIA',
    "DIN 17024",
    'Sprengnetter',
    'HypZert',
    'RICS - Red Book',
    'BIIS',
    'BVS',
    "Tegova - Blue Book",
    "IVSC - White Book",
    "ValueV",
    "BDSF",
    "DESAG",
    "TAF",
    "AI",
    "ASA",
] as const;

type SystemStandardTooltips = {
    [key in StandardSystemStandardsOfferedWithOther | "More" as string]: string;
};

export const systemStandardTooltips: SystemStandardTooltips = {
    "Öffentlich Bestellt": "(IHK)",
    Sprengnetter: "S (Standardimmobilien) / WG (Gewerbe) / AI (alle Immobilienarten)",
    BIIS: "Bundesverband der Immobilien-Investment-Sachverständigen e.V.",
    BVS: "Bundesverband öffentlich bestellter und vereidigter sowie qualifizierter Sachverständiger e.V.",
    HypZert: "(S) / F (alle in DT)  / MLV (international)",
    "": "",
    DIA: "",
    "DIN 17024": "",
    "RICS - Red Book": "",
    "Tegova - Blue Book": "",
    "IVSC - White Book": "",
    ValueV: "",
    BDSF: "",
    DESAG: "",
    TAF: "",
    AI: "",
    ASA: "",
    More: "",
    Other: ""
};

export default standardSystemStandardsOffered;
