import { EntityType } from "../../../../../../../types";
import BaseFilterInput from "../../BaseFilterInput";
import { FilterPropsType } from "../../Filter";

const ByEntityDataFilter = (props: FilterPropsType<EntityType>) => {
    return (
        <BaseFilterInput
            {...props}
            filterGroupId="BY_ENTITIES_DATA"
            placeholder="Search by Entities data"
            searchFn={({ name, street, city }, search) => {
                const address = street
                    ? `${street} ${city}`.toLowerCase().includes(search)
                    : false;

                const entityName = name
                    ? name.toLowerCase().includes(search)
                    : false;

                return address || entityName;
            }}
        />
    );
};

export { ByEntityDataFilter };
