import { Button, Heading, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { MdInfoOutline, MdOutlineModeEditOutline } from "react-icons/md";
import { Link as ReactLink } from "react-router-dom";
import { useRole } from "../../../../../../common/hooks";
import {
    StandardSystemStandardsOffered,
    StandardSystemStandardsOfferedWithOther,
} from "../../../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";
import { EntityType, UserType } from "../../../../../../types";
import { useMyEntity } from "../../../../../../utils/api/hooks";
import storage from "../../../../../../utils/storage/storage";
import useMe from "../../../../app/useMeV2";
import { Title } from "../../../../common/components/display";
import ProfilePicture from "../../../../common/components/display/ProfilePicture";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../common/hooks/useToast";
import { useFileUploadModal } from "../../../../common/inputs/fileupload/useFileUploadModal";
import { Section } from "../../../../layout";
import ProfileContactDetails from "./ProfileContactDetails";
import ProfileOfficeDetails from "./ProfileOfficeDetails";
import { ProfileValuationDetails } from "./ProfileValuationDetails";
import { ProfileValuationStandards } from "./ProfileValuationStandards";

const ProfileDetailsScreen = () => {
    const t = useSmartTranslation();

    const { userIsValuer, userIsCompany, roleLoading } = useRole();

    const { createToast } = useToast();

    const {
        data: user,
        isLoading: userLoading,
        myCertificates = [],
        myCertificatesIsLoading,
        profilePictureUrl,
        myCertificatesRefresh,
    } = useMe();

    const { data: entity, isLoading: entityLoading } = useMyEntity();

    const [selectedStandard, setSelectedStandard] = useState<
        string | StandardSystemStandardsOffered
    >();

    const { fileUploadModal, openFileUpload } = useFileUploadModal({
        onUpload: (files) => {
            if (!selectedStandard) return;

            uploadCertificates(files, selectedStandard);
        },
    });

    if (
        !user ||
        userLoading ||
        roleLoading ||
        entityLoading ||
        myCertificatesIsLoading
    ) {
        return <LoadingSpinner />;
    }

    const uploadCertificates = async (
        files: File[],
        standard: StandardSystemStandardsOfferedWithOther | string
    ) => {
        if (!files.length) return;

        try {
            await Promise.all(
                files.map((file) =>
                    storage.uploadUserCertificate(user.id, file, standard)
                )
            );

            myCertificatesRefresh();
        } catch {
            createToast({
                status: "error",
            });
        }
    };

    // render

    const header = (
        <Title
            title={t("account.accountSectionLink.profile")}
            rightSide={
                <Button
                    as={ReactLink}
                    to={"edit"}
                    variant="outline"
                    colorScheme="blue"
                    leftIcon={<MdOutlineModeEditOutline />}
                >
                    {t("common.button.edit")}
                </Button>
            }
        />
    );

    const contactDetails = (
        <Section
            title={t("profile.heading.contactDetails")}
            content={
                <HStack
                    spacing={4}
                    align={"stretch"}
                >
                    <ProfileContactDetails user={user} />

                    <UserDetails
                        entity={entity}
                        user={user}
                        userIsCompany={userIsCompany}
                        profilePictureUrl={profilePictureUrl}
                    />
                </HStack>
            }
        />
    );

    const officeDetails = (
        <Section
            title={t("profile.heading.officeLocation")}
            content={
                <ProfileOfficeDetails
                    user={user}
                    userIsValuer={userIsValuer}
                />
            }
        />
    );

    const valuationStandards = (
        <Section
            title={t("profile.heading.valuationStandards")}
            content={
                <ProfileValuationStandards
                    user={user}
                    certificates={myCertificates}
                    uploadCertificates={(standard) => {
                        setSelectedStandard(standard);
                        openFileUpload();
                    }}
                />
            }
        />
    );

    const valuationDetails = (
        <Section
            title={t("profile.heading.valuationDetails")}
            content={<ProfileValuationDetails user={user} />}
        />
    );

    const doesValuations = userIsValuer && user.doesValuations && (
        <>
            {valuationStandards}
            {valuationDetails}
        </>
    );

    return (
        <VStack
            gap={4}
            align={"stretch"}
        >
            {header}
            {contactDetails}
            {officeDetails}
            {doesValuations}

            {fileUploadModal}
        </VStack>
    );
};

type UserDetailsProps = {
    user: UserType;
    entity: EntityType;
    userIsCompany: boolean;
    profilePictureUrl?: string;
};

const UserDetails = (props: UserDetailsProps) => {
    const {
        user: { firstName, lastName, orgPosition },
        entity: { name: companyName },
        userIsCompany,
        profilePictureUrl,
    } = props;

    const t = useSmartTranslation();

    return (
        <VStack
            spacing={2}
            width={"100%"}
            align={"stretch"}
        >
            <VStack
                w={"100%"}
                justify={"center"}
                spacing={4}
                p={"2rem 1.5rem"}
                border={"1px solid"}
                borderColor={"gray.200"}
                borderRadius={"lg"}
            >
                <ProfilePicture
                    url={profilePictureUrl}
                    size={"10rem"}
                />

                <VStack spacing={2}>
                    <Heading
                        fontSize={"1.5rem"}
                    >{`${firstName} ${lastName}`}</Heading>

                    {userIsCompany && (
                        <Text color={"gray.500"}>
                            {orgPosition &&
                                t("profile.position.at", {
                                    position: orgPosition,
                                    company: companyName,
                                    interpolation: {
                                        escapeValue: false,
                                    },
                                })}
                        </Text>
                    )}
                </VStack>
            </VStack>

            <HStack
                spacing={1}
                align={"start"}
            >
                <Icon
                    as={MdInfoOutline}
                    color={"gray.400"}
                />
                <Text
                    fontSize={"xs"}
                    lineHeight={4}
                    color={"gray.500"}
                >
                    {t("profile.profileDetails.profilePictureDescription")}
                </Text>
            </HStack>
        </VStack>
    );
};

export default ProfileDetailsScreen;
