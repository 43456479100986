import { UseToastOptions, useToast as chakraToast } from "@chakra-ui/react";
import { useSmartTranslation } from "./useSmartTranslation";

const useToast = () => {
    const t = useSmartTranslation();
    const toast = chakraToast();

    const createToast = (props: UseToastOptions) => {
        const title = t(
            props.status === "error"
                ? "common.toast.error"
                : "common.toast.success"
        );

        toast({
            title,
            duration: 5000,
            isClosable: true,
            position: "top-right",
            ...props,
        });
    };

    return {
        createToast,
    };
};

export default useToast;
