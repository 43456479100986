import { Box, Checkbox, Text } from "@chakra-ui/react";
import { FieldInputProps } from "formik";
import { useSmartTranslation } from "../hooks/useSmartTranslation";
import { useFormikMeta } from "./useFormikMeta";

type PropTypes = {
    name: string;
    onChange?: (value: boolean, field?: FieldInputProps<any>) => void;
    label: React.ReactNode | string;
    size?: string;
};

const FormikCheckbox = (props: PropTypes) => {
    const t = useSmartTranslation();

    const { name, label, size = "md", onChange } = props;

    const { isRequired, isInvalid, field, meta } = useFormikMeta(name);

    return (
        <Box width="100%">
            <Checkbox
                alignItems="center"
                isChecked={field.value}
                isInvalid={isInvalid}
                size={size}
                {...props}
                onChange={(e) => {
                    field.onChange({
                        target: {
                            name,
                            value: !field.value,
                        },
                    });

                    onChange?.(e.target.checked, field);
                }}
            >
                {label && `${label}${isRequired ? " *" : ""}`}
            </Checkbox>

            {meta.touched && meta.error && (
                <Text
                    fontSize={"sm"}
                    color="red"
                >
                    {t(meta.error, { defaultValue: meta.error })}
                </Text>
            )}
        </Box>
    );
};

export default FormikCheckbox;
