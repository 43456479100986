import { FormLabel, Switch, Text, VStack } from "@chakra-ui/react";
import { FieldInputProps } from "formik";
import { useSmartTranslation } from "../hooks/useSmartTranslation";
import { useFormikMeta } from "./useFormikMeta";

type PropTypes = {
    name: string;
    onChange?: (value: boolean, field?: FieldInputProps<any>) => void;
    label: React.ReactNode | string;
    subtext?: React.ReactNode | string;
    size?: string;
};

const FormikSwitch = (props: PropTypes) => {
    const t = useSmartTranslation();

    const { name, label, subtext, size = "md", onChange } = props;

    const { isRequired, isInvalid, field, meta } = useFormikMeta(name);

    return (
        <FormLabel
            width="100%"
            display={"flex"}
            alignItems={"center"}
            border={"1px solid"}
            borderColor={"gray.200"}
            borderRadius={"lg"}
            p={".75rem"}
            gap={".75rem"}
            cursor={"pointer"}
            htmlFor={name}
        >
            <Switch
                id={name}
                isChecked={field.value}
                isInvalid={isInvalid}
                size={size}
                onChange={(e) => {
                    field.onChange({
                        target: {
                            name,
                            value: !field.value,
                        },
                    });

                    onChange?.(e.target.checked, field);
                }}
            />

            <VStack
                align={"stretch"}
                spacing={0}
            >
                <Text fontSize={"sm"}>
                    {label && `${label}${isRequired ? " *" : ""}`}
                </Text>

                {subtext && (
                    <Text
                        fontSize={"xs"}
                        color={"gray.500"}
                    >
                        {subtext}
                    </Text>
                )}
            </VStack>

            {meta.touched && meta.error && (
                <Text
                    fontSize={"sm"}
                    color="red"
                >
                    {t(meta.error, { defaultValue: meta.error })}
                </Text>
            )}
        </FormLabel>
    );
};

export default FormikSwitch;
