import { DeleteIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    Image,
    Spacer,
    Text,
    useDisclosure,
    VStack
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';
import LoadingSpinner from '../../../../../common/components/LoadingSpinner';
import { useRole } from '../../../../../common/hooks';
import useToast from '../../../../../ui/v2/common/hooks/useToast';
import { useUserInvitesDelete, useUserInvitesPending } from '../../../../../utils/api/hooks';

const TeamPendingInvites = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { t } = useTranslation(['account', 'common']);

    const { userIsValuer } = useRole();

    const [selectedInvite, setSelectedInvite] = useState<string | number>('');

    const { createToast } = useToast();

    const {
        data: invites,
        isLoading: invitesLoading,
        error: invitesError,
        refresh,
    } = useUserInvitesPending();

    const {
        update: updateDeleteInvite,
    } = useUserInvitesDelete({
        onSuccess: () => { refresh(); },
        onError: error => {
            createToast({
                description: error.message,
                status: 'error',
            });
        },
    });

    return (
        <Flex>
            <VStack
                align="left"
                alignSelf="flex-start"
                spacing="6"
                w="25%"
            >
                <Box>
                    <Heading
                        as="h4"
                        size="md"
                        fontWeight="light"
                    >
                        {t('teamInvites.sectionTitle')}
                    </Heading>

                    <Text
                        color="wvwGrey"
                        paddingTop="1rem"
                    >
                        {t('teamInvites.blurb')}
                    </Text>
                </Box>
            </VStack>

            <Spacer />

            <VStack
                paddingTop="1.5rem"
                w="70%"
            >
                {invitesLoading && (
                    <LoadingSpinner />
                )}

                {invitesError !== null && (
                    <VStack>
                        <Heading size="sm">
                            {t("common:error.weAreSorry")}
                        </Heading>

                        <Text>
                            {t('pvListError')}
                        </Text>
                    </VStack>
                )}

                {invites?.length === 0 && (
                    <Text>
                        {t('teamInvites.noPendingInvitesMsg')}
                    </Text>
                )}

                {invites?.map(i => (
                    <Flex
                        alignItems="center"
                        height="3.5rem"
                        bg="wvwGrey05"
                        border="1px solid"
                        borderColor="wvwGrey40"
                        borderRadius="8"
                        key={i.id}
                        paddingInline="1rem"
                        w="100%"
                    >
                        <HStack spacing="8">
                            <Image
                                alt={`${i.firstName} ${i.lastName}`}
                                borderRadius="full"
                                boxSize="2.5rem"
                                objectFit="cover"
                                src={userIsValuer ? '/images/AvatarValuer.png' : '/images/AvatarendCust.png'}
                            />

                            <Box paddingBlock="1rem">
                                <Text
                                    color="wvwGrey80"
                                    fontWeight="medium"
                                >
                                    {`${i.firstName} ${i.lastName}`}
                                </Text>
                            </Box>

                            { i.role === 'ADMIN' && (
                                <Box
                                    bg="wvwGreen"
                                    borderRadius="15"
                                    paddingBlock=".2rem"
                                    paddingInline=".6rem"
                                >
                                    <Text
                                        color="white"
                                        fontWeight="medium"
                                    >
                                        {t('admin')}
                                    </Text>
                                </Box>
                            ) }
                        </HStack>

                        <Spacer />

                        <Button
                            onClick={() => {
                                onOpen();
                                setSelectedInvite(i.id);
                            }}
                            variant="none"
                        >
                            <DeleteIcon />
                        </Button>
                    </Flex>
                ))}
            </VStack>

            <ConfirmationModal
                cancelButton={t('button.cancel', { ns: 'common' })}
                continueButton={t('button.delete', { ns: 'common' })}
                isOpen={isOpen}
                title={t('warning.deleteInvitation.title', { ns: 'common' })}
                content={t('warning.deleteInvitation.prompt', { ns: 'common' })}
                onCancel={onClose}
                onContinue={() => {
                    updateDeleteInvite(selectedInvite);
                    onClose();
                }}
            />
        </Flex>
    );
};

export default TeamPendingInvites;
