import { IconButton } from '@chakra-ui/react';
import { MouseEvent } from 'react';
import WvwIcon, { WvwIconKeys } from '../display/WvwIcon';

type PropTypes = {
    ariaLabel: string;
    color? : 'wvwGreen' | 'wvwGrey60' | 'black' | 'white';
    icon: WvwIconKeys;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
};

export type IconButtonPropType = Omit<PropTypes, 'icon' | 'ariaLabel'>;

const WvwIconButton = (props: PropTypes) => {
    const {
        ariaLabel,
        color = 'black',
        icon,
        onClick = () => {},
    } = props;

    return (
        <IconButton
            aria-label={ariaLabel}
            icon={<WvwIcon icon={icon} color={color} />}
            onClick={onClick}
            variant="none"
        />
    );
};

export default WvwIconButton;
