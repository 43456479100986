import { HStack, Icon, Select, Text } from "@chakra-ui/react";
import { LabelAndInput } from "../components/display";
import { useSmartTranslation } from "../hooks/useSmartTranslation";
import { useFormikMeta } from "./useFormikMeta";
import { MdInfoOutline } from "react-icons/md";

type PropTypes = {
    name: string;
    label?: string;
    width?: string;
    options: {
        disabled?: boolean;
        label: string;
        value: string | number;
    }[];
    placeholder?: string;
    size?: string;
    subtext?: string;
};

const FormikSelect = (props: PropTypes) => {
    const t = useSmartTranslation();

    const {
        name,
        label,
        placeholder,
        options,
        width = "100%",
        size = "md",
        subtext,
    } = props;

    const { errorBorderColor, isRequired, isInvalid, field, meta } =
        useFormikMeta(name);

    return (
        <LabelAndInput
            label={label && `${label}${isRequired ? " *" : ""}`}
            input={
                <>
                    <Select
                        h="2.5rem"
                        borderRadius="lg"
                        size={size}
                        w={width}
                        isInvalid={!!meta.touched && !!meta.error}
                        placeholder={placeholder}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                    >
                        {options.map((option) => (
                            <option
                                key={`${option.label} ${option.value}`}
                                disabled={option.disabled}
                                value={option.value}
                            >
                                {option.label}
                            </option>
                        ))}
                    </Select>

                    {subtext && (
                        <HStack
                            spacing={1}
                            align={"start"}
                        >
                            <Icon
                                as={MdInfoOutline}
                                color={"gray.400"}
                            />
                            <Text
                                fontSize={"xs"}
                                color="gray.500"
                            >
                                {subtext}
                            </Text>
                        </HStack>
                    )}

                    {meta.touched && meta.error && (
                        <Text
                            color="red"
                            fontSize={"sm"}
                        >
                            {t(meta.error, { defaultValue: meta.error })}
                        </Text>
                    )}
                </>
            }
        />
    );
};

export default FormikSelect;
