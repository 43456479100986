import { FirebaseCertificateType } from "../../../../../../utils/storage/storage";
import DataTable from "../../../../common/components/data-table/DataTable";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Documents } from "../../../../domain/document/Document";

type TeamTableProps = {
    files: FirebaseCertificateType[];
    checkedDocuments: FirebaseCertificateType[];
    userIsAdmin: boolean;
    footerContent?: React.ReactNode | React.ReactNode[];
    toggleFileChecked: (
        file: FirebaseCertificateType,
        checked: boolean
    ) => void;
};

const DocumentsTable = (props: TeamTableProps) => {
    const {
        files,
        checkedDocuments,
        userIsAdmin,
        footerContent,
        toggleFileChecked,
    } = props;

    const t = useSmartTranslation();

    return (
        <DataTable
            data={files}
            checkedRows={checkedDocuments}
            isChecked={(i) =>
                checkedDocuments.find((o) => o.url === i.url) !== undefined
            }
            columns={[
                Documents.TableColumns.name,
                Documents.TableColumns.category,
                Documents.TableColumns.createdAt,
                Documents.TableColumns.size,
                ...(userIsAdmin
                    ? [
                          Documents.TableColumns.edit,
                          Documents.TableColumns.remove,
                      ]
                    : []),
            ]}
            noDataText={t("account.sharedDocuments.noDocs")}
            onToggleRowChecked={toggleFileChecked}
            footerContent={footerContent}
        />
    );
};

export default DocumentsTable;
