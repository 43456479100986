import _ from "lodash";
import { useEffect, useState } from "react";
import { permissionGetOnDemand } from "../../../../utils/api/endpoints/permissions-get-on-demand";
import { WvwActivity } from "./activityTypes";
import { WvwSubjectName, WvwSubjectType } from "./subjectTypes";

type usePermissionProps = {
    datatype: WvwSubjectName;
    activity: WvwActivity;
    objects?: WvwSubjectType | WvwSubjectType[];
};

export const usePermissions = (props: usePermissionProps) => {
    const { datatype, activity, objects } = props;

    const [permission, setPermission] = useState<boolean | undefined>();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const getPermission = async () => {
            const hasPermission = await permissionGetOnDemand(
                datatype,
                activity,
                objects
            );

            setPermission(hasPermission);
            setLoading(false);
        };

        getPermission();
    }, []);

    console.log(permissionToString({ ...props, permission }));

    return { permission, loading };
};

const permissionToString = (
    props: usePermissionProps & { permission: boolean | undefined }
) => {
    const { datatype, activity, objects, permission: granted } = props;
    const ids = objects && _.castArray(objects).map(({ id }) => id);
    const grantedPart =
        granted !== undefined
            ? granted
                ? "GRANTED"
                : "NOT GRANTED"
            : "INDETERMINED";

    return `permission '${datatype}.${activity}${
        objects ? `(${ids})` : ""
    }: ${grantedPart}`;
};
