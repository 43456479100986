import { DeleteIcon } from "@chakra-ui/icons";
import {
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Heading,
    HStack,
    Link,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import PublicCompanyProfilePicture from "../../../../../../../common/components/PublicCompanyProfilePicture";
import toUrl from "../../../../../../../common/functions/toUrl";
import { useStandards } from "../../../../../../../common/hooks";
import { EntityType } from "../../../../../../../types";
import {
    useEntityPublicEntityGetById,
    useMyEntity,
} from "../../../../../../../utils/api/hooks";
import { usePermissions } from "../../../../../app/permissions/usePermissions";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import CompanyDetailsPiCover from "../../company-details/CompanyDetailsPiCover";
import { InformationRowItem } from "../../InformationRowItem";
import RenderFieldData from "../../RenderFieldData";
import useValuersRemove from "./useValuersRemove";
import toTitleCase from "../../../../../../../common/functions/toTitleCase";
import { standardPropertyTypes } from "../../../../../../../common/vars/valuationsAndMembershipTypes";
import { ValuationStandard } from "../../profile/ProfileValuationDetails";
import { MISSING_TRANSLATION } from "../../../../../common/MISSING_TRANSLATION";
import CompanyDetailsBilling from "../../company-details/CompanyDetailsBilling";

type PropTypes = {
    entity: EntityType;
    onCloseDetails: () => void;
};

const ValuersDetailsDrawer = (props: PropTypes) => {
    const {
        entity: {
            id: entityId,
            name,
            billingStreet,
            billingPostalCode,
            billingCity,
            billingCountry,
            vatNumber,
            valuationTypes,
        },
        entity,
        onCloseDetails,
    } = props;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { data: myEntity } = useMyEntity();

    const { permission: canRemove } = usePermissions({
        activity: "RemovePanelValuer",
        datatype: "Entity",
        objects: myEntity,
    });

    const { entityCertificates = [] } = useEntityPublicEntityGetById(entityId);

    const { removeValuerModal, openRemoveValuerModal } = useValuersRemove({
        valuerId: entityId,
    });

    useEffect(() => {
        if (!entity) return;

        onOpen();
    }, []);

    // render

    const header = (
        <HStack justify={"space-between"}>
            <HStack spacing="4">
                <PublicCompanyProfilePicture
                    size="2.5rem"
                    companyId={entityId}
                />

                <Heading fontSize={"lg"}>{name}</Heading>
            </HStack>

            {canRemove && (
                <>
                    <Button
                        variant={"outline"}
                        colorScheme={"red"}
                        size={"xs"}
                        leftIcon={<DeleteIcon />}
                        onClick={openRemoveValuerModal}
                    >
                        {t("common.button.remove")}
                    </Button>
                    {removeValuerModal}
                </>
            )}
        </HStack>
    );

    const contactInformation = <ContactInformation entity={entity} />;

    const billingAddress = (
        <VStack
            align={"stretch"}
            spacing={1}
        >
            <Divider my={4} />
            <Heading
                size="md"
                variant="blue"
                mb={3}
            >
                {t("account.companyDetails.billingAddress")}
            </Heading>

            <CompanyDetailsBilling entity={entity} />
        </VStack>
    );

    const piCoverDetails = (
        <VStack
            align={"stretch"}
            spacing={1}
        >
            <Divider my={4} />
            <Heading
                size="md"
                variant="blue"
                mb={3}
            >
                {t("account.valuationCapabilities.sectionTitle")}
            </Heading>

            <CompanyDetailsPiCover
                entity={entity}
                certificates={entityCertificates}
            />
        </VStack>
    );

    const propertyTypesToTitleCase = valuationTypes.map((i: string) =>
        toTitleCase(i)
    );
    const propertyTypes = (
        <InformationRowItem
            label={t("profile.valuationCompetence.propertyTypes")}
            customContent={
                <HStack
                    spacing={1}
                    flexWrap={"wrap"}
                    mt={1}
                >
                    {standardPropertyTypes.map((valuation) => (
                        <ValuationStandard
                            key={valuation}
                            label={t(
                                `domain.standards.propertyType.${valuation}`
                            )}
                            checked={propertyTypesToTitleCase.includes(
                                valuation
                            )}
                        />
                    ))}
                </HStack>
            }
        />
    );

    return (
        <Drawer
            isOpen={isOpen}
            onClose={() => {
                onClose();
                onCloseDetails();
            }}
            size="lg"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader
                    fontSize={"xl"}
                    color={"blue.700"}
                >
                    {t("account.teamDetails.teamMemberPreview.title")}
                </DrawerHeader>

                <DrawerBody>
                    {header}
                    {contactInformation}
                    {billingAddress}
                    {piCoverDetails}
                    {propertyTypes}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

type ContactInformationProps = {
    entity: EntityType;
};

const ContactInformation = (props: ContactInformationProps) => {
    const {
        entity: {
            valuerNumberOfPartners,
            valuerYearsOfExperience,
            websiteAddress = "",
            companyEmail,
            contactFirstName,
            contactLastName,
            contactNumberDialCode,
            contactNumber,
            street,
            postalCode,
            city,
            country,
            accountType,
        },
    } = props;

    const t = useSmartTranslation();

    const { yearsOfExperience } = useStandards();

    return (
        <VStack
            align={"stretch"}
            spacing={1}
        >
            <Divider my={4} />

            <Heading
                size="md"
                variant="blue"
                mb={3}
            >
                {t("account.companyDetails.contactInfo")}
            </Heading>
            <InformationRowItem
                label={t("account.companyDetails.email")}
                content={
                    <RenderFieldData
                        field="email"
                        data={[companyEmail]}
                        config={{ required: accountType !== "CLIENT" }}
                    />
                }
            />
            <InformationRowItem
                label={t("account.companyDetails.phoneNumber")}
                content={
                    <RenderFieldData
                        field="phoneNumber"
                        data={[contactNumberDialCode, contactNumber]}
                    />
                }
            />
            <InformationRowItem
                label={t("account.companyDetails.website")}
                content={
                    websiteAddress ? (
                        <Link
                            color="blue.500"
                            href={toUrl(websiteAddress)}
                            isExternal
                            textOverflow="elipsis"
                            noOfLines={1}
                        >
                            {websiteAddress}
                        </Link>
                    ) : (
                        <Text
                            fontSize={"sm"}
                            fontWeight={"700"}
                        >
                            {t("profile.fieldNotProvided.website")}
                        </Text>
                    )
                }
            />
            <InformationRowItem
                label={t("account.companyDetails.numberOfValuers")}
                content={
                    <RenderFieldData
                        field="numberOfPartners"
                        data={[valuerNumberOfPartners]}
                        config={{ required: true }}
                    />
                }
            />
            <InformationRowItem
                label={t("account.companyDetails.yearsOfExperience")}
                content={
                    <RenderFieldData
                        field="yearsOfExperience"
                        data={[
                            yearsOfExperience.find(
                                (i) => i.value === valuerYearsOfExperience
                            )?.label,
                        ]}
                        config={{ required: true }}
                    />
                }
            />
        </VStack>
    );
};

export default ValuersDetailsDrawer;
