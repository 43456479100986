import {
    Box,
    ButtonGroup,
    Flex,
    Heading,
    HStack,
    IconButton,
    Text,
} from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/fa";
import { Link as ReactLink } from "react-router-dom";
import delimitNumber from "../../../../../../common/functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../../../../../../common/functions/displayDateInLocale";
import { CaseType } from "../../../../../../types";
import { Case } from "../../../../domain";
import { enquirySource } from "../../../../domain/enquiry/columns/EnquiryColumns";
import { TranslationFunction } from "../../../hooks/useSmartTranslation";
import PanelRelationshipMarker from "../../display/ProfileBookmarkIcon";
import PublicUserProfilePictureWithEntityLogo from "../../PublicUserProfilePictureWithEntityLogo";
import { DataTableColumnType } from "../DataTable";
import { estimatePortfolioValue } from "../TableDataCalculations";

const caseTableNameSpace = "common.components.dataTable.columns.caseColumns";

type Column = DataTableColumnType<CaseType>;

export const caseIdWithProperties: Column = {
    title: (t) => t(`${caseTableNameSpace}.case`),
    render: (i, t) => {
        const firstProperty = `${i.enquiry.properties[0].city}, ${i.enquiry.properties[0].street}`;
        const noOfProperties = i.enquiry.properties.length;

        return (
            <Flex
                direction="column"
                gap={0}
            >
                <Heading size={"xs"}>{i.id}</Heading>
                <Text
                    color="gray.500"
                    fontSize={"xs"}
                >
                    {firstProperty}
                </Text>
                {noOfProperties > 1 ? (
                    <Text
                        color="gray.500"
                        fontSize={"xs"}
                    >
                        {t(`${caseTableNameSpace}.moreText`, {
                            count: noOfProperties - 1,
                        })}
                    </Text>
                ) : (
                    ""
                )}
            </Flex>
        );
    },
    sorter: (a, b) => a.id - b.id,
};

export const enquiryIdWithProperties: Column = {
    title: (t) => t("domain.enquiry.enquiry"),
    render: (i, t) => {
        const firstProperty = `${i.enquiry.properties[0].city}, ${i.enquiry.properties[0].street}`;
        const noOfProperties = i.enquiry.properties.length;

        return (
            <Flex
                direction="column"
                gap={0}
            >
                <Heading size={"xs"}>{i.id}</Heading>
                <Text
                    color="gray.500"
                    fontSize={"xs"}
                >
                    {firstProperty}
                </Text>
                {noOfProperties > 1 ? (
                    <Text
                        color="gray.500"
                        fontSize={"xs"}
                    >
                        {t(`${caseTableNameSpace}.moreText`, {
                            count: noOfProperties - 1,
                        })}
                    </Text>
                ) : (
                    ""
                )}
            </Flex>
        );
    },
    sorter: (a, b) => a.id - b.id,
};

export const acceptedEnquirySource: Column = {
    render: (i, t, idx) => {
        const { enquiry } = i;

        return enquirySource.render!(enquiry, t, idx);
    },
};

//column for enquiry acceptance date, hence title
export const created: Column = {
    title: (t) => t(`${caseTableNameSpace}.accepted`),
    render: (i) => {
        const enquiryAccepted = displayDateWithCurrentLocale(i.createdAt);

        return (
            <Heading
                size={"xs"}
                fontWeight={"400"}
            >
                {enquiryAccepted}
            </Heading>
        );
    },
};

export const enquiryCreated: Column = {
    title: (t) => t(`${caseTableNameSpace}.created`),
    render: (i) => {
        const created = displayDateWithCurrentLocale(i.enquiry.createdAt);

        return (
            <Heading
                size={"xs"}
                fontWeight={"400"}
            >
                {created}
            </Heading>
        );
    },
};

export const caseClient: Column = {
    title: (t) => t(`${caseTableNameSpace}.client`),
    render: (i, t) => {
        const {
            id: clientId,
            entityId,
            firstName,
            lastName,
            orgPosition,
            workingForCompanyName,
        } = i.clientUser;

        const clientName = `${firstName} ${lastName}`;

        return (
            <HStack spacing="3">
                <PublicUserProfilePictureWithEntityLogo
                    entityId={entityId}
                    userId={clientId}
                    profileLogoSize={2.5}
                    companyLogoSize={1.5}
                />

                <Flex direction="column">
                    <Heading size="xs">{clientName}</Heading>

                    <Text
                        variant="secondary"
                        fontSize={"xs"}
                    >
                        {orgPosition &&
                            t(`${caseTableNameSpace}.position.at`, {
                                position: orgPosition,
                                company: workingForCompanyName,
                                interpolation: { escapeValue: false },
                            })}

                        {!orgPosition && workingForCompanyName}
                    </Text>
                </Flex>
            </HStack>
        );
    },
    sorter: (a, b) => {
        const aName = `${a.clientUser.firstName} ${a.clientUser.lastName}`;
        const bName = `${b.clientUser.firstName} ${b.clientUser.lastName}`;

        return aName.localeCompare(bName);
    },
};

export const valuationType: Column = {
    title: (t) => t("domain.enquiry.columns.scopeAndStandard"),
    render: (i) => {
        const { valuationType, valuationStandards } = i.enquiry;
        return (
            <Flex direction="column">
                <Heading size="xs">{valuationType}</Heading>
                <Text
                    variant="secondary"
                    fontSize={"xs"}
                >
                    {valuationStandards}
                </Text>
            </Flex>
        );
    },
};

export const offerPiCover: Column = {
    title: (t) => t(`${caseTableNameSpace}.piCover`),
    render: (i) => {
        const { piCoverValue, piCoverPercentage } = i.acceptedOffer;

        const coverValue = `€${delimitNumber(piCoverValue)}`;

        return (
            <Flex direction="column">
                <Heading size="xs">{coverValue}</Heading>
                <Text
                    variant="secondary"
                    fontSize={"xs"}
                >{`${piCoverPercentage}%`}</Text>
            </Flex>
        );
    },
};

export const expectedDelivery: Column = {
    title: (t) => t(`${caseTableNameSpace}.expectedDelivery`),
    render: (caseData, t) => {
        const { deliveryDate, daysToDeliveryDateText } =
            Case.getAgreedDeliveryDate(caseData, t);

        return (
            <Flex direction="column">
                <Heading size="xs">{deliveryDate}</Heading>
                <Text
                    variant="secondary"
                    fontSize={"xs"}
                >
                    {daysToDeliveryDateText}
                </Text>
            </Flex>
        );
    },
    sorter: (a, b) =>
        new Date(a.enquiry.completionDate).getTime() -
        new Date(b.enquiry.completionDate).getTime(),
};

export const estPortfolioValue: Column = {
    alignment: "right",
    title: (t) => t(`${caseTableNameSpace}.portfolioValue`),
    tooltip: (t: TranslationFunction) =>
        t(`${caseTableNameSpace}.portfolioValueTooltip`),
    render: (i) => {
        const { properties } = i.enquiry;
        const estPortfolioValue = estimatePortfolioValue(properties);

        const delimitedValue = `€${delimitNumber(estPortfolioValue)}`;

        return (
            <Heading
                size="xs"
                color="cyan.500"
            >
                {delimitedValue}
            </Heading>
        );
    },
    sorter: (a, b) => {
        const aEstPortfolioValue = estimatePortfolioValue(a.enquiry.properties);
        const bEstPortfolioValue = estimatePortfolioValue(b.enquiry.properties);

        return aEstPortfolioValue - bEstPortfolioValue;
    },
};

export const viewCaseButton: Column = {
    alignment: "right",
    render: (i) => (
        <ButtonGroup>
            <IconButton
                as={ReactLink}
                to={`/dashboard/case/${i.id}`}
                onClick={(e) => e.stopPropagation()}
                variant="ghost"
                icon={<FaChevronRight />}
                aria-label="arrow"
            />
        </ButtonGroup>
    ),
};

export const caseValuer: Column = {
    title: (t) => t(`${caseTableNameSpace}.selectedValuer`),
    render: (i, t) => {
        const {
            id: valuerId,
            entityId,
            firstName,
            lastName,
            orgPosition,
            workingForCompanyName,
        } = i.valuerUser;

        const { panelValuersIds } = i.clientEntity;

        const valuerName = `${firstName} ${lastName}`;

        const showPanelBadge = panelValuersIds.includes(valuerId);

        return (
            <HStack spacing="3">
                <Box position={"relative"}>
                    {showPanelBadge && <PanelRelationshipMarker />}
                    <PublicUserProfilePictureWithEntityLogo
                        entityId={entityId}
                        userId={valuerId}
                        profileLogoSize={2.5}
                        companyLogoSize={1.5}
                    />
                </Box>

                <Flex direction="column">
                    <Heading size="xs">{valuerName}</Heading>

                    <Text
                        variant="secondary"
                        fontSize={"xs"}
                    >
                        {orgPosition &&
                            t(`${caseTableNameSpace}.position.at`, {
                                position: orgPosition,
                                company: workingForCompanyName,
                                interpolation: { escapeValue: false },
                            })}

                        {!orgPosition && workingForCompanyName}
                    </Text>
                </Flex>
            </HStack>
        );
    },
    sorter: (a, b) => {
        const aName = `${a.valuerUser.firstName} ${a.valuerUser.lastName}`;
        const bName = `${b.valuerUser.firstName} ${b.valuerUser.lastName}`;

        return aName.localeCompare(bName);
    },
};

export const caseColumns = {
    acceptedEnquirySource,
    caseClient,
    caseIdWithProperties,
    caseValuer,
    enquiryCreated,
    created,
    enquiryIdWithProperties,
    estPortfolioValue,
    expectedDelivery,
    offerPiCover,
    valuationType,
    viewCaseButton,
};
