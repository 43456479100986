import {
    MenuButton,
    Button,
    MenuList,
    MenuItem,
    Menu,
    HStack,
    Text,
    Link as ChakraLink,
    Tooltip,
    Icon,
} from "@chakra-ui/react";
import { ElementType, JSX, MouseEventHandler, ReactNode } from "react";
import { Link as ReactLink } from "react-router-dom";

type PropTypes = {
    active?: string;
    menu: {
        menuTitle?: string;
        rightIcon?: JSX.Element;
        leftIcon?: JSX.Element;
        size?: string;
        buttonVariant?: string;
        menuList: {
            onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
            key: string;
            nav?: string;
            element?: ReactNode;
            icon?: ElementType;
            isDisabled?: boolean;
            name?: string;
            tooltip?: string;
        }[];
    };
};

const DropdownMenu = (props: PropTypes) => {
    const { active = "", menu } = props;

    const menuActiveState = () => {
        if (active === menu.menuTitle) {
            return "var(--action-disabledBackground, #2B6CB0)";
        }
        return "transparent";
    };

    return (
        <Menu variant="none">
            <MenuButton
                as={Button}
                rightIcon={menu.rightIcon}
                leftIcon={menu.leftIcon}
                size={menu.size}
                color="white"
                bgColor={menuActiveState()}
                borderRadius="8"
                paddingInline=".8rem"
                paddingBlock=".4rem"
                h="min-content"
                _hover={{
                    bgColor: "var(--action-disabledBackground, #2B6CB0)",
                }}
                _active={{
                    bgColor: "var(--action-disabledBackground, #2B6CB0)",
                }}
            >
                {menu.menuTitle}
            </MenuButton>

            <MenuList
                borderRadius="lg"
                p={2}
                zIndex={2}
            >
                {menu.menuList.map((listItem) => (
                    <Tooltip
                        key={listItem.key}
                        label={listItem.tooltip}
                    >
                        <ChakraLink
                            role="group"
                            as={ReactLink}
                            to={listItem.nav}
                            onClick={listItem.onClick}
                            _hover={{
                                textDecoration: "none",
                            }}
                        >
                            <MenuItem
                                p={".75rem 1rem"}
                                isDisabled={listItem.isDisabled}
                                borderRadius={"base"}
                                _hover={{
                                    bg: "blue.50",
                                    color: "blue.500",
                                }}
                            >
                                <HStack spacing="4">
                                    <Icon
                                        as={listItem.icon}
                                        color={"gray.400"}
                                        boxSize={6}
                                        _groupHover={{
                                            color: "blue.500",
                                        }}
                                    />

                                    <Text
                                        fontSize={"sm"}
                                        fontWeight={"500"}
                                    >
                                        {listItem.name}
                                    </Text>
                                </HStack>
                            </MenuItem>
                        </ChakraLink>
                    </Tooltip>
                ))}
            </MenuList>
        </Menu>
    );
};

export default DropdownMenu;
