import { VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../../../../../common/hooks";
import {
    useCaseActiveCases,
    useEnquiriesByStatus,
    useEnquiryGetEnquiriesWithAssignments,
    useMyEntity,
    useOfferGetAllMyOffers,
} from "../../../../../../utils/api/hooks";
import useMe from "../../../../app/useMeV2";
import DataTable from "../../../../common/components/data-table/DataTable";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import AwaitingAccessBanner from "../../../../common/features/awaiting-access/AwaitingAccessBanner";
import Statistics from "../../../../common/features/statistics/Statistics";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../layout/Section";
import ProfileCompletionBanner from "../../profile-completion/ProfileCompletionBanner";
import {
    allActiveCases,
    allLostEnquiriesStatistic,
    allMatchingEnquiriesStatistic,
    allOffersSentStatistic,
    allPendingOffersStatistic,
    allRejectedOffersStatistic,
    caseConversionRate,
} from "../dashboard/EnquiryAndCaseStatistics";
import { EntityType, UserType } from "../../../../../../types";
import ValuerExperienceCustomization from "../dashboard/experience-customization/ValuerExperienceCustomizationList";
import {
    createEnquiryAwaitingOffersData,
    enquiryAwaitingOfferColumns,
} from "../dashboard/data-table/EnquiryTable";
import { acceptedEnquiryColumns } from "../dashboard/data-table/CasesTable";

// TODO functions for profile completion checks. Business need to clarify on assistant's functionality better
const isOfficeAddressComplete = (user: UserType): boolean => {
    return !!(user.street && user.postalCode && user.city && user.country);
};

const hasValuerRequiredMatchmakingFields = (user: UserType): boolean => {
    return !!(
        user.valuationTypes &&
        user.systemStandardsOffered &&
        user.valuationOccasions &&
        user.valuationPropertyTypes &&
        user.latitude &&
        user.longitude &&
        isOfficeAddressComplete(user)
    );
};

const hasCompanyRequiredMatchmakingFields = (entity: EntityType): boolean => {
    return !!(
        entity?.valuerNumberOfPartners &&
        entity.valuerYearsOfExperience &&
        ((entity.piCoverPercentage && entity.piCoverPercentage > 0) ||
            entity.piCoverPercentageUnlimited ||
            (entity.piCoverValue && entity.piCoverValue > 0) ||
            entity.piCoverValueUnlimited)
    );
};

const isProfileComplete = (
    user: UserType | undefined,
    entity: EntityType,
    isCompanyAdmin: boolean
): boolean => {
    if (!user) return false;
    if (!user.doesValuations) return false;

    return isCompanyAdmin
        ? hasCompanyRequiredMatchmakingFields(entity) &&
              hasValuerRequiredMatchmakingFields(user)
        : hasValuerRequiredMatchmakingFields(user);
};

const AssistantDashboard = () => {
    const t = useSmartTranslation();

    const {
        data: allPendingCompanyEnquiries = [],
        isLoading: allPendingCompanyEnquiriesLoading,
    } = useEnquiryGetEnquiriesWithAssignments();

    const {
        enquiryList: acceptedEnquiries = [],
        isLoading: acceptedEnquiriesLoading,
    } = useEnquiriesByStatus("ACCEPTED");

    const { data: offers = [], isLoading: offersLoading } =
        useOfferGetAllMyOffers();

    const {
        data: companyAcceptedEnquiriesData = [],
        isLoading: companyAcceptedEnquiryLoading,
    } = useCaseActiveCases();

    const {
        data: entity,
        isLoading: entityLoading,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
    } = useMyEntity();

    const {
        data: user,
        isLoading: userLoading,
        myCertificates = [],
        myCertificatesIsLoading,
    } = useMe();

    const { userIsValuer, userIsClient, userIsCompanyAdmin, roleLoading } =
        useRole();

    const navigate = useNavigate();

    const awaitingAccess = user?.accessPending === true;

    const matchmakingInfoCustomiseExperienceCompleted = isProfileComplete(
        user,
        entity,
        userIsCompanyAdmin
    );

    if (userLoading || roleLoading) return <LoadingSpinner />;

    if (
        !user ||
        acceptedEnquiriesLoading ||
        allPendingCompanyEnquiriesLoading ||
        offersLoading ||
        companyAcceptedEnquiryLoading ||
        entityLoading ||
        userLoading ||
        myCertificatesIsLoading ||
        entitySampleValuationsIsLoading
    )
        return <LoadingSpinner />;

    // profile completion
    const profileCompletionBanner = (
        <ProfileCompletionBanner
            user={user}
            entity={entity}
            userCertificates={myCertificates}
            sampleValuations={entitySampleValuations}
            userRoles={{
                userIsClient,
                userIsCompanyAdmin,
                userIsValuer,
            }}
        />
    );

    // statistics
    // const companyOffers = offers;

    const numPendingOffers = allPendingOffersStatistic(offers);
    const numMatchingEnquiries = allMatchingEnquiriesStatistic(
        allPendingCompanyEnquiries
    );

    const numAllOffersSent = allOffersSentStatistic(
        offers,
        allPendingCompanyEnquiries
    );

    const numAllRejectedOffers = allRejectedOffersStatistic(
        offers,
        allPendingCompanyEnquiries
    );

    const numLostEnquiries = allLostEnquiriesStatistic(
        offers,
        acceptedEnquiries
    );
    const numAcceptedEnquiries = allActiveCases(
        companyAcceptedEnquiriesData,
        allPendingCompanyEnquiries
    );
    const percentageOfCompletedCases = caseConversionRate(
        companyAcceptedEnquiriesData,
        allPendingCompanyEnquiries
    );
    const companyEnquiryStatsData = [
        numPendingOffers,
        numMatchingEnquiries,
        numAllOffersSent,
        numAllRejectedOffers,
        numLostEnquiries,
        numAcceptedEnquiries,
        percentageOfCompletedCases,
    ];

    // statistics
    const companyEnquiryStats = (
        <Section
            title={t("domain.enquiry.companyEnquiries")}
            content={<Statistics stats={companyEnquiryStatsData} />}
        />
    );

    const awaitingOffersdata = createEnquiryAwaitingOffersData({
        enquiries: allPendingCompanyEnquiries,
        offers: offers,
    });

    // awaiting offers
    const awaitingOffers = (
        <Section
            collapsable
            title={t("valuerDashboard.awaitingOffer.title")}
            subtitle={t("valuerDashboard.awaitingOffer.subtext")}
            content={
                <DataTable
                    fullSectionWidth
                    data={awaitingOffersdata}
                    columns={enquiryAwaitingOfferColumns}
                    noDataText={t("valuerDashboard.noAwaitingOffers")}
                    onRowClick={(enquiry) => {
                        navigate(`/dashboard/enquiry/${enquiry.id}`);
                    }}
                />
            }
        />
    );

    //accepted enquiries
    const companyAcceptedEnquiries = (
        <Section
            collapsable
            title={t("valuerDashboard.companysAcceptedEnquiries.title")}
            subtitle={t("valuerDashboard.companysAcceptedEnquiries.subtitle")}
            content={
                <DataTable
                    fullSectionWidth
                    data={companyAcceptedEnquiriesData}
                    columns={acceptedEnquiryColumns}
                    noDataText={t("valuerDashboard.noAcceptedEnquiries")}
                    // TODO: check if new paths is needed
                    onRowClick={(cases) => {
                        navigate(`/dashboard/case/${cases.id}`);
                    }}
                />
            }
        />
    );

    // account and profile
    const companyEnquiryTablesAndStats = (
        <VStack
            spacing={4}
            w="100%"
        >
            {companyEnquiryStats}
            {awaitingOffers}
            {companyAcceptedEnquiries}
        </VStack>
    );

    return (
        <VStack
            w="100%"
            p={4}
            spacing={4}
        >
            {profileCompletionBanner}
            {awaitingAccess && <AwaitingAccessBanner />}
            {companyEnquiryTablesAndStats}
            <ValuerExperienceCustomization user={user} />
        </VStack>
    );
};

export default AssistantDashboard;
