import { Button, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { MdAdd } from "react-icons/md";
import { useRole } from "../../../../../../../common/hooks";
import { EntityType } from "../../../../../../../types";
import {
    useClientInvitesGetReceived,
    useEntityPanelValuers,
    useMyEntity,
    useValuerInvitesSent,
} from "../../../../../../../utils/api/hooks";
import { usePermissions } from "../../../../../app/permissions/usePermissions";
import { Title } from "../../../../../common/components/display";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../../layout";
import ValuersReceivedInvitesTable from "./data-table/ValuersReceivedInvitesTable";
import ValuersSentInvitesTable from "./data-table/ValuersSentInvitesTable";
import ValuersTable from "./data-table/ValuersTable";
import useValuersInviteDialog from "./useValuersInviteDialog";
import EntityDetailsDrawer from "./ValuersDetailsDrawer";

const ValuersScreen = () => {
    const t = useSmartTranslation();

    const [viewEntity, setViewEntity] = useState<EntityType>();

    const { data: entity, isLoading: entityLoading } = useMyEntity();

    const { userIsCompanyAdmin } = useRole();

    const { permission: canInviteValuers } = usePermissions({
        activity: "InviteAValuer",
        datatype: "Entity",
        objects: entity,
    });

    const { permission: canRemoveValuers, loading: permissionsIsLoading } =
        usePermissions({
            activity: "RemovePanelValuer",
            datatype: "Entity",
            objects: entity,
        });

    const { valuerList = [], isLoading: valuersListLoading } =
        useEntityPanelValuers();

    const {
        data: pendingInvites = [],
        isLoading: pendingInvitesLoading,
        refresh: pendingInvitesRefresh,
    } = useValuerInvitesSent();

    const { data: receivedInvites = [], isLoading: receievedInvitesLoading } =
        useClientInvitesGetReceived();

    const { inviteModal, openInviteModal } = useValuersInviteDialog({
        onSendInvite: () => {
            pendingInvitesRefresh();
        },
    });

    if (
        entityLoading ||
        valuersListLoading ||
        pendingInvitesLoading ||
        receievedInvitesLoading
    ) {
        return <LoadingSpinner />;
    }

    // render

    const header = (
        <Title
            title={t("account.accountSectionLink.panelValuers")}
            rightSide={
                canInviteValuers && (
                    <Button
                        variant={"outline"}
                        colorScheme={"blue"}
                        leftIcon={<MdAdd />}
                        onClick={openInviteModal}
                    >
                        {t("account.customerPanelDetails.button.addValuer")}
                    </Button>
                )
            }
        />
    );

    const valuersTable = (
        <Section
            title={t("account.customerPanelDetails.valuersList")}
            content={
                !permissionsIsLoading && (
                    <ValuersTable
                        valuers={valuerList}
                        canRemove={canRemoveValuers}
                        onRowClick={setViewEntity}
                    />
                )
            }
        />
    );

    const invitationsSentTable = userIsCompanyAdmin && (
        <Section
            title={t("account.customerPanelDetails.invitationsSentList")}
            content={<ValuersSentInvitesTable invites={pendingInvites} />}
        />
    );

    const invitationsReceivedTable = userIsCompanyAdmin && (
        <Section
            title={t("account.customerPanelDetails.invitationsReceivedList")}
            content={<ValuersReceivedInvitesTable invites={receivedInvites} />}
        />
    );

    const entityDetails = viewEntity && (
        <EntityDetailsDrawer
            entity={viewEntity}
            onCloseDetails={() => {
                setViewEntity(undefined);
            }}
        />
    );

    return (
        <VStack
            align={"stretch"}
            gap={4}
        >
            {header}
            {valuersTable}
            {invitationsSentTable}
            {invitationsReceivedTable}

            {inviteModal}
            {entityDetails}
        </VStack>
    );
};

export default ValuersScreen;
