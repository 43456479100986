import {
    Box,
    HStack,
    Select,
    SimpleGrid,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../../../../../common/hooks";
import {
    useCaseActiveCases,
    useEnquiryGetAll,
    useMe,
    useMyEntity,
    useOfferGetAllMyOffers,
} from "../../../../../../utils/api/hooks";
import { Feature } from "../../../../app/features/Feature";
import DataTable from "../../../../common/components/data-table/DataTable";
import { GoogleMapV2 } from "../../../../common/components/display";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import Statistics from "../../../../common/features/statistics/Statistics";
import { useDevice } from "../../../../common/hooks/useDevice";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../layout";
import ProfileCompletionBanner from "../../profile-completion/ProfileCompletionBanner";
import { clientCustomizations } from "../customize-experience/customizations";
import CustomizeExperience from "../customize-experience/CustomizeExperience";
import { columns as caseColumns, caseEnquiryColumns } from "./CaseTableData";
import {
    activeCasesStatistic,
    activeEnquiriesStatistic,
    averageSelectionTimeStatistic,
    clientAverageOffersStatistic,
    completedCasesStatistic,
} from "./EnquiryAndCaseStatistics";
import {
    createEnquiriesTableData,
    columns as enquiryColumns,
} from "./EnquiryTableData";

const Dashboard = () => {
    const { data: enquiries = [], isLoading: enquiriesLoading } =
        useEnquiryGetAll();
    const { data: offers, isLoading: offersLoading } = useOfferGetAllMyOffers();
    const { data: cases = [], isLoading: casesLoading } = useCaseActiveCases();
    const {
        data: entity,
        isLoading: entityLoading,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
    } = useMyEntity();
    const {
        data: user,
        isLoading: userLoading,
        myCertificates = [],
        myCertificatesIsLoading,
    } = useMe();
    const { userIsValuer, userIsClient, userIsCompanyAdmin, roleLoading } =
        useRole();

    const enquiriesWithCancellationsExcluded = enquiries.filter(
        (enquiry) => enquiry.status !== "CANCELLED"
    );

    const navigate = useNavigate();

    const [noOfEnquiriesToShow, setNoOfEnquiriesToShow] = useState(
        noOfRecentEnquiriesToShowOptions[5]
    );

    const allEnquiryPropertyLocations =
        enquiriesWithCancellationsExcluded.flatMap((enquiry) =>
            enquiry.properties.map((property) => ({
                id: property.id,
                lat: Number(property.latitude) || 0,
                lng: Number(property.longitude) || 0,
            }))
        );

    const t = useSmartTranslation();

    const device = useDevice();

    if (
        !user ||
        enquiriesLoading ||
        offersLoading ||
        casesLoading ||
        entityLoading ||
        userLoading ||
        roleLoading ||
        myCertificatesIsLoading ||
        entitySampleValuationsIsLoading
    )
        return <LoadingSpinner />;

    // profile completion
    const profileCompletionBanner = (
        <ProfileCompletionBanner
            user={user}
            entity={entity}
            userCertificates={myCertificates}
            sampleValuations={entitySampleValuations}
            userRoles={{
                userIsClient,
                userIsCompanyAdmin,
                userIsValuer,
            }}
        />
    );

    if (enquiriesLoading || offersLoading || casesLoading)
        return <LoadingSpinner />;

    // statistics
    const numActiveEnquiries = activeEnquiriesStatistic(enquiries);
    const numAvgOffers = clientAverageOffersStatistic(enquiries, offers);
    const avgOfferSelectiontime = averageSelectionTimeStatistic(
        t,
        cases,
        offers
    );
    const enquiryStatsData = [
        numActiveEnquiries,
        numAvgOffers,
        avgOfferSelectiontime,
    ];
    const numActiveCases = activeCasesStatistic(cases);
    const numCompletedCases = completedCasesStatistic(cases);
    const caseStatsData = [numActiveCases, numCompletedCases];

    const enquiryStats = (
        <Section
            title={t("domain.enquiry.enquiries")}
            content={<Statistics stats={enquiryStatsData} />}
        />
    );

    const caseStats = (
        <Section
            title={t("domain.case.cases")}
            content={<Statistics stats={caseStatsData} />}
        />
    );

    // enquiries
    const enquiriesTableData = createEnquiriesTableData({
        enquiries: enquiriesWithCancellationsExcluded,
        offers,
    });

    const recentEnquiriesFooter = (
        <HStack>
            <Text fontSize={"sm"}>{t("clientDashboard.noOfEntries")}</Text>
            <Select
                value={noOfEnquiriesToShow}
                size={"sm"}
                onChange={(e) =>
                    setNoOfEnquiriesToShow(
                        noOfRecentEnquiriesToShowOptions[e.target.value]
                    )
                }
            >
                {createOptions(noOfRecentEnquiriesToShowOptions)}
            </Select>
        </HStack>
    );

    const recentEnquiries = (
        <Section
            collapsable
            title={t("clientDashboard.recentEnquiriesWithCount", {
                count: enquiriesTableData.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={enquiriesTableData}
                    columns={enquiryColumns}
                    noDataText={t("clientDashboard.noDataPlaceholder")}
                    noOfEntriesToShow={noOfEnquiriesToShow}
                    footerContent={recentEnquiriesFooter}
                    onRowClick={(enquiry) => {
                        navigate(`/dashboard/enquiry/${enquiry.id}`);
                    }}
                />
            }
        />
    );

    // cases
    const ongoingCases = (
        <Section
            collapsable
            title={t("clientDashboard.ongoingCasesWithCount", {
                count: cases.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={cases}
                    columns={caseColumns}
                    noDataText={t("clientDashboard.noDataPlaceholder")}
                    onRowClick={(cases) => {
                        navigate(`/dashboard/case/${cases.id}`);
                    }}
                />
            }
        />
    );

    const caseEnquiries = (
        <Section
            collapsable
            title={t("clientDashboard.caseEnquiries", {
                count: cases.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={cases}
                    columns={caseEnquiryColumns}
                    noDataText={t("clientDashboard.noCaseEnquiries")}
                    onRowClick={(cases) => {
                        navigate(`/dashboard/case/${cases.id}`);
                    }}
                />
            }
        />
    );

    // account and profile
    const experienceCustomization = (
        <Section
            collapsable
            title={t(
                "clientDashboard.customizeExperience.customizeYourExperience"
            )}
            content={
                <CustomizeExperience
                    customizeList={[
                        clientCustomizations.completeProfile(t),
                        ...(userIsCompanyAdmin
                            ? [clientCustomizations.teamMembers(t)]
                            : []),
                    ]}
                />
            }
        />
    );
    const caseDependentTablesAndStats = (
        <>
            <SimpleGrid
                columnGap={4}
                w="100%"
                gridTemplateColumns={
                    device === "mobile"
                        ? "100%"
                        : "minmax(0, 50%) minmax(0, 50%)"
                }
            >
                <VStack spacing={4}>
                    {enquiryStats}
                    {recentEnquiries}
                </VStack>

                <Feature.Off name={Feature.FEATURES.CASE}>
                    <Box
                        marginTop={{
                            base: "1rem",
                            xl: "0",
                        }}
                        bgColor="white"
                        padding="1rem"
                        borderRadius={8}
                        minH="12rem"
                        w="100%"
                    >
                        <GoogleMapV2
                            height="100%"
                            width="100%"
                            markers={allEnquiryPropertyLocations}
                        />
                    </Box>
                </Feature.Off>

                <Feature.On name={Feature.FEATURES.CASE}>
                    <VStack spacing={4}>
                        {caseStats}
                        {ongoingCases}
                    </VStack>
                </Feature.On>
            </SimpleGrid>

            <Feature.Off name={Feature.FEATURES.CASE}>
                {caseEnquiries}
            </Feature.Off>
        </>
    );

    return (
        <VStack
            w="100%"
            p={4}
            spacing={4}
        >
            {profileCompletionBanner}
            {caseDependentTablesAndStats}
            {experienceCustomization}
        </VStack>
    );
};

const createOptions = (options: Record<any, any>) => {
    return Object.entries(options).map(([label, value]) => (
        <option
            key={label}
            value={value}
        >
            {label}
        </option>
    ));
};

const noOfRecentEnquiriesToShowOptions: Record<string, number> = {
    "5": 5,
    "10": 10,
    "20": 20,
};

export default Dashboard;
