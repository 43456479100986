import {
    Button,
    Checkbox,
    CheckboxGroup,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    name: string;
    options: {
        icon?: ReactNode;
        label: string;
        value: string;
    }[];
    optionsExpanded: {
        label: string;
        value: string;
    }[];
    onChange?: (field?: FieldInputProps<any>, value?: string[],) => void,
};

const FormikCloudSelectExpandable = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const {
        name,
        options,
        optionsExpanded,
        onChange = () => { },
    } = props;

    const { values } = useFormikContext<{ [index: string]: any }>();

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const fieldValues = values[name] || [];

    const initalExpansion = optionsExpanded.some(option => fieldValues.includes(option.value));

    const initialSelectedMainOptions = fieldValues.filter(
        (i: string) => !optionsExpanded.map(o => o.value).includes(i),
    );

    const initialSelectedExpandedOptions = fieldValues.filter(
        (i: string) => optionsExpanded.map(o => o.value).includes(i),
    );

    const [
        isExpanded,
        setIsExpanded,
    ] = useState(initalExpansion);

    const [
        selectedMainOptions,
        setSelectedMainOptions,
    ] = useState<string[]>(initialSelectedMainOptions);

    const [
        selectedExpandedOptions,
        setSelectedExpandedOptions,
    ] = useState<string[]>(initialSelectedExpandedOptions);

    return (
        <VStack
            w="100%"
        >
            <SimpleGrid
                width="100%"
                minChildWidth="13rem"
                spacing="0.2rem"
                alignItems="top"
            >
                {options.map(option => {
                    if (option.icon) {
                        return (
                            <Button
                                key={`${field.name}[${option.value}]`}
                                variant="cloudSelect"
                                maxWidth="20rem"
                                name={`${field.name}[${option.value}]`}
                                w="100%"
                            >
                                <VStack
                                    align="center"
                                    spacing="1"
                                    w="7em"
                                >
                                    {option.icon}

                                    <Text
                                        align="center"
                                    >
                                        {option.label}
                                    </Text>
                                </VStack>
                            </Button>
                        );
                    }

                    return (
                        <Checkbox
                            key={option.value}
                            minW="12rem"
                            fontSize={option.label.length > 20 ? '0.8rem' : '1rem'}
                            name={`${field.name}[${option.value}]`}
                            isChecked={field.value.includes(option.value)}
                            variant="outline"
                            paddingBlock=".3rem"
                            width="100%"
                            onChange={() => {
                                const wasSelected = selectedMainOptions.includes(option.value);

                                let newSelectedMainOptions = [];

                                if (wasSelected) {
                                    newSelectedMainOptions = selectedMainOptions.filter(
                                        (value: string) => value !== option.value,
                                    );
                                } else {
                                    newSelectedMainOptions = [...selectedMainOptions, option.value];
                                }

                                setSelectedMainOptions(newSelectedMainOptions);

                                const newFieldValue = [
                                    ...newSelectedMainOptions,
                                    ...selectedExpandedOptions,
                                ];

                                field.onChange({
                                    target: {
                                        name,
                                        value: newFieldValue,
                                    },
                                });

                                onChange?.(field, newFieldValue);
                            }}
                        >
                            {option.label}
                        </Checkbox>
                    );
                })}

                <Checkbox
                    fontSize="1rem"
                    paddingBlock=".3rem"
                    variant="outline"
                    width="100%"
                    isChecked={isExpanded}
                    onChange={() => {
                        if (selectedExpandedOptions.length === 0) {
                            setIsExpanded(!isExpanded);
                        }
                    }}
                >
                    {t('more')}
                </Checkbox>
            </SimpleGrid>

            {meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                )}

            {isExpanded && (

                <CheckboxGroup
                    defaultValue={selectedExpandedOptions}
                    onChange={(value: string[]) => {
                        const newExpandedOptions = value;

                        setSelectedExpandedOptions(newExpandedOptions);

                        const newFieldValue = [
                            ...selectedMainOptions,
                            ...newExpandedOptions,
                        ];

                        field.onChange({ target: { name, value: newFieldValue } });

                        onChange?.(field, newFieldValue);
                    }}
                >
                    <SimpleGrid
                        backgroundColor="wvwGrey05"
                        borderRadius="8"
                        minChildWidth="14rem"
                        spacing={2}
                        width="100%"
                        padding="1rem"
                    >
                        {optionsExpanded.map(({ value, label }) => (
                            <Checkbox
                                key={value}
                                value={value}
                                paddingBlock=".3rem"
                                variant="outline"
                                width="100%"
                            >
                                {label}
                            </Checkbox>
                        ))}
                    </SimpleGrid>

                </CheckboxGroup>
            )}
        </VStack>
    );
};

export default FormikCloudSelectExpandable;
