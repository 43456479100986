import _ from "lodash";
import { WvwActivity } from "../../../ui/v2/app/permissions/activityTypes";
import {
    WvwSubjectName,
    WvwSubjectType,
} from "../../../ui/v2/app/permissions/subjectTypes";
import { get } from "../request";

export const permissionGetOnDemand = async (
    subjectType: WvwSubjectName,
    activity: WvwActivity,
    objects?: WvwSubjectType | WvwSubjectType[]
): Promise<boolean> => {
    const ids = objects && _.castArray(objects).map(({ id }) => id);

    return get(`/user/canI/${subjectType}/${activity}${ids ? `/${ids}` : ""}`);
};
