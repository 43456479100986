import { Box, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import ContentBox from './ContentBox';
import WvwIcon from './WvwIcon';
import { WvwText } from '../typography';
import WvwHStack from './WvwHStack';
import WvwDeleteIconButton from '../WvwDeleteIconButton';
import { WvwButton, WvwIconButton, WvwInput } from '../inputs';
import useSmartTranslation from '../../hooks/useSmartTranslation';
import WvwVStack from './WvwVStack';

type PropTypes = {
    data: {
        file: File;
        comment: string;
    };
    onDelete?: () => void;
    onEdit?: (newName: string, newComment?: string) => void;
    withComment?: boolean;
};

const FileUploadUploadedFileListItem = (props: PropTypes) => {
    const {
        data,
        onDelete,
        onEdit,
        withComment = false,
    } = props;

    const { file, comment } = data;

    const t = useSmartTranslation('documentmanagement');

    const [editing, setEditing] = useState(false);
    const [newName, setNewName] = useState(file.name);
    const [newComment, setNewComment] = useState(comment);

    const fileExtension = `.${file.name.split('.').pop()}`;

    let editingError = false;

    if (newName === '') editingError = true;
    if (newName === fileExtension) editingError = true;

    return (
        <ContentBox key={file.name}>
            <Flex justifyContent="space-between" w="100%">
                <WvwHStack w="100%">
                    <WvwIcon icon="documentToUpload" />

                    {editing && (
                        <WvwVStack>
                            <Flex
                                justifyContent="space-between"
                                w="100%"
                            >
                                <Box
                                    paddingRight="1rem"
                                    w="100%"
                                >
                                    <WvwVStack>
                                        <WvwInput
                                            value={newName}
                                            placeholder={t('fieldPlaceholder.name', { ns: 'common' })}
                                            onChange={e => setNewName(e.target.value)}
                                        />

                                        {withComment && (
                                            <WvwInput
                                                value={newComment}
                                                placeholder={t('submissionNote')}
                                                onChange={e => setNewComment(e.target.value)}
                                            />
                                        )}
                                    </WvwVStack>
                                </Box>

                                <WvwHStack>
                                    <WvwButton
                                        content={t('button.cancel', { ns: 'common' })}
                                        onClick={() => {
                                            setNewName(file.name);
                                            setNewComment(comment);
                                            setEditing(false);
                                        }}
                                    />

                                    <WvwButton
                                        variant="primary"
                                        content={t('button.save', { ns: 'common' })}
                                        onClick={() => {
                                            if (editingError) return;

                                            let newFileName = newName;

                                            if (!newName.endsWith(fileExtension)) {
                                                newFileName = `${newName}${fileExtension}`;
                                            }

                                            onEdit?.(newFileName, newComment);

                                            setEditing(false);
                                        }}
                                    />
                                </WvwHStack>
                            </Flex>

                            {editingError && (
                                <WvwText color="red">
                                    {t('errors.required', { ns: 'formik' })}
                                </WvwText>
                            )}
                        </WvwVStack>
                    )}

                    {!editing && (
                        <WvwVStack>
                            <WvwText>
                                {file.name}
                            </WvwText>

                            {withComment && (
                                <WvwText color="wvwGrey60">
                                    {`${t('submissionNote')}: ${comment || t('noSubmissionNote')}`}
                                </WvwText>
                            )}
                        </WvwVStack>
                    )}
                </WvwHStack>

                {!editing && (
                    <WvwHStack>
                        {onEdit && (
                            <WvwIconButton
                                ariaLabel={t('button.edit', { ns: 'common' })}
                                icon="edit"
                                onClick={() => setEditing(true)}
                                color="black"
                            />
                        )}

                        {onDelete && (
                            <WvwDeleteIconButton
                                onClick={() => onDelete()}
                                color="black"
                            />
                        )}
                    </WvwHStack>
                )}
            </Flex>
        </ContentBox>
    );
};

export default FileUploadUploadedFileListItem;
