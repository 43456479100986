import { WvwIconButton } from './inputs';
import { IconButtonPropType } from './inputs/WvwIconButton';

const WvwCancelIconButton = (props: IconButtonPropType) => {
    const {
        color = 'wvwGreen',
        onClick = () => {},
    } = props;

    return (
        <WvwIconButton
            ariaLabel="Cancel"
            color={color}
            icon="cancel"
            onClick={onClick}
        />
    );
};

export default WvwCancelIconButton;
