import { HStack } from "@chakra-ui/react";
import { ValuerInviteType } from "../../../../../../../../types";
import DataTable, {
    DataTableColumnType,
} from "../../../../../../common/components/data-table/DataTable";
import AcceptInvite from "../panel-invite/AcceptInvite";
import DeclineInvite from "../panel-invite/DeclineInvite";
import { valuerInviteColumns } from "../../../../../../domain/user/valuer/ValuerInviteColumns";

type PropTypes = {
    invites: ValuerInviteType[];
};

const CustomersReceivedInvitesTable = (props: PropTypes) => {
    const { invites } = props;

    return (
        <DataTable
            fullSectionWidth
            data={invites}
            columns={[
                valuerInviteColumns.entityName,
                valuerInviteColumns.receivedAt,
                actions,
            ]}
        />
    );
};

const actions: DataTableColumnType<ValuerInviteType> = {
    alignment: "right",
    render: (i) => {
        return (
            <HStack>
                <AcceptInvite invite={i} />
                <DeclineInvite invite={i} />
            </HStack>
        )
    },
};

export default CustomersReceivedInvitesTable;
