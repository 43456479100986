import { WarningTwoIcon } from "@chakra-ui/icons";
import { HStack, Text } from "@chakra-ui/react";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../common/MISSING_TRANSLATION";

type RenderFieldDataProps = {
    field: string;
    data: (string | number | undefined)[];
    config?: {
        required?: boolean;
        joinWith?: string;
    };
};

const RenderFieldData = (props: RenderFieldDataProps) => {
    const { field, config, data } = props;

    const t = useSmartTranslation();

    const { required, joinWith = " " } = config || {};

    if (data.every((item) => item)) {
        return (
            <Text
                fontSize={"sm"}
                fontWeight={"700"}
                wordBreak={"break-word"}
            >
                {data.join(joinWith)}
            </Text>
        );
    }

    return (
        <HStack>
            {required && <WarningTwoIcon color={"gray.400"} />}
            <Text
                color={"gray.500"}
                fontSize={"sm"}
                fontWeight={"500"}
            >
                {t(`profile.fieldNotProvided.${field}`, {
                    defaultValue: MISSING_TRANSLATION,
                })}
            </Text>
        </HStack>
    );
};

export default RenderFieldData;
