import { Heading, VStack } from "@chakra-ui/react";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../../layout";
import { standardValuationOccasions } from "../../../../../../../common/vars/valuationsAndMembershipTypes";
import { Forms } from "../../../../../common/forms/Forms";
import { FormikCheckboxGroup } from "../../../../../../../common/forms";

const ProfileValuationOccasionsEdit = () => {
    const t = useSmartTranslation();

    const standardsEdit = (
        <FormikCheckboxGroup
            name="valuationOccasions"
            selectAll
            variant="outline"
            size="lg"
            options={standardValuationOccasions.map((type) => ({
                label: t(`domain.standards.valuationOccasion.${type}`),
                value: type,
            }))}
        />
    );

    const otherStandardsEdit = (
        <Forms.FormikConditionalSection
            condition={(value) => value.valuationOccasions.includes("Other")}
        >
            <VStack
                align={"start"}
                bg={"gray.50"}
                borderRadius="lg"
                padding={4}
                spacing={4}
                mt={2}
            >
                <Heading
                    size={"sm"}
                    color={"blue.700"}
                >
                    {t("profile.heading.otherValuationOccasions")}
                </Heading>

                <Forms.FormikListInput
                    name="otherValuationOccasions"
                    width="15rem"
                    placeholder={t(
                        "profile.membershipsAndQualifications.edit.addOtherValuationOccasion"
                    )}
                />
            </VStack>
        </Forms.FormikConditionalSection>
    );

    return (
        <Section
            title={t("profile.heading.valuationOccasions")}
            subtitle={t("profile.subHeading.valuationOccasions")}
            content={
                <>
                    {standardsEdit}
                    {otherStandardsEdit}
                </>
            }
        />
    );
};

export default ProfileValuationOccasionsEdit;
