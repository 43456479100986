import { IconButton, Text } from "@chakra-ui/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { displayDateWithCurrentLocale } from "../../../../../../common/functions/displayDateInLocale";
import { displayTimeInCurrentLocale } from "../../../../../../common/functions/displayTime";
import { UserInviteType } from "../../../../../../types";
import { DataTableColumnType } from "../../../../common/components/data-table/DataTable";
import { userColumns } from "../../../../domain/user/columns/UserColumns";
import InviteStatus from "./InviteStatus";
import useTeamInviteRemove from "./useTeamInviteRemove";

type Column = DataTableColumnType<UserInviteType>;

const invitedAt: Column = {
    title: (t) => t("account.teamInvites.invited"),
    render: (i) => {
        const date = displayDateWithCurrentLocale(i.createdAt);
        const time = displayTimeInCurrentLocale(new Date(i.createdAt));

        return (
            <Text fontSize={"sm"}>
                {date}, {time}
            </Text>
        );
    },
};

const status: Column = {
    title: (t) => t("account.teamInvites.statusLabel"),
    render: ({ status }) => {
        return <InviteStatus status={status} />;
    },
};

const removeInvite: Column = {
    render: (i) => {
        const { removeInviteModal, openRemoveInviteModal } =
            useTeamInviteRemove({ inviteId: i.id });

        return (
            <>
                <IconButton
                    icon={<RiDeleteBin6Line />}
                    size="sm"
                    variant="ghost"
                    color="gray.400"
                    aria-label="remove"
                    onClick={openRemoveInviteModal}
                />
                {removeInviteModal}
            </>
        );
    },
};

export const teamColumns = {
    ...userColumns,
    removeInvite,
    status,
    invitedAt,
};
