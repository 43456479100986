import { useDisclosure } from "@chakra-ui/react";
import { useMe } from "../../../../utils/api/hooks";
import storage, {
    FirebaseCertificateType,
} from "../../../../utils/storage/storage";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useToast from "../../common/hooks/useToast";
import { useState } from "react";

type PropTypes = {
    file: FirebaseCertificateType;
};

const useCertificateRemove = () => {
    const t = useSmartTranslation();

    const { createToast } = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [removedCertificate, setRemovedCertificate] = useState<FirebaseCertificateType>();

    const {
        myCertificatesRefresh,
    } = useMe();

    const removeFile = async () => {
        if (!removedCertificate) return;

        try {
            await storage.deleteUserFile(removedCertificate.url);

            myCertificatesRefresh();
            onClose();
        } catch {
            createToast({
                status: "error"
            });
        }
    };

    const removeCertificateModal = removedCertificate && (
        <ConfirmationModal
            confirmationButtonLabel={t("common.button.confirm")}
            confirmationButtonVariant="negative"
            isOpen={isOpen}
            title={t("domain.document.ACTIVITIES.delete.NAME")}
            blurb={t("domain.document.ACTIVITIES.delete.confirmationBlurb")}
            onClose={onClose}
            onConfirmation={removeFile}
        />
    );

    return {
        removeCertificateModal,
        setRemovedCertificate,
        openRemoveCertificateModal: onOpen,
    };
};

export default useCertificateRemove;
