import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type PropTypes = {
    status: string;
    margin?: string;
};

const StatusBadge = (props: PropTypes) => {
    const { status, margin = '8rem'} = props;
    const { t } = useTranslation('documentmanagement');

    const statusColor = (s: string) => {
        switch (s) {
            case 'APPROVED':
                return {
                    color: 'green',
                    bg: 'green10',
                };
            case 'ACCEPTED':
                return {
                    color: 'green',
                    bg: 'green10',
                };
            case 'NEW':
                return {
                    color: 'wvwGreen',
                    bg: 'wvwGreen10',
                };
            case 'REJECTED':
                return {
                    color: 'red',
                    bg: 'red10',
                };
            case 'UNAVAILABLE':
                return {
                    color: 'orange',
                    bg: 'orange10',
                };
            case 'SCHEDULED':
                return {
                    color: 'orange',
                    bg: 'orange10',
                };
            case 'SUBMITTED':
                return {
                    color: 'purple',
                    bg: 'purple10',
                };
            case 'CANCELLED':
                return {
                    color: 'red',
                    bg: 'red10',
                };
            case 'DELETED':
                return {
                    color: 'wvwGrey60',
                    bg: 'wvwGrey05',
                };
            case 'AWAITING DATES':
                return {
                    color: 'wvwYellow',
                    bg: 'wvwYellow10',
                };
            default:
                return {
                    color: 'wvwYellow',
                    bg: 'wvwYellow10',
                };
        }
    };

    if (!status) return null;

    return (
        <Box
            bgColor="white"
            width={status.length > 10 ? '10rem' : '7rem'}
            borderRadius="10"
        >
            <Text
                bg={statusColor(status).bg}
                borderRadius="10"
                h="1.6rem"
                color={statusColor(status).color}
                fontWeight="bold"
                pt=".2rem"
                fontSize=".8rem"
                textAlign="center"
                w={status.length > 10 ? '10rem' : '7rem'}
                marginRight={margin}
            >
                {t(status, { ns: 'documentmanagement', defaultValue: status })}
            </Text>
        </Box>
    );
};

export default StatusBadge;
