import { IconButton } from "@chakra-ui/react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ClientInviteType } from "../../../../../../../../types";
import DataTable, {
    DataTableColumnType,
} from "../../../../../../common/components/data-table/DataTable";
import { clientInviteColumns } from "../../../../../../domain/user/client/ClientInviteColumns";
import useCancelInvitation from "../useCancelInvitation";

type PropTypes = {
    invites: ClientInviteType[];
};

const CustomersSentInvitesTable = (props: PropTypes) => {
    const { invites } = props;

    return (
        <DataTable
            fullSectionWidth
            data={invites}
            columns={[
                clientInviteColumns.clientName,
                clientInviteColumns.email,
                clientInviteColumns.sentAt,
                cancelInvitation,
            ]}
        />
    );
};

const cancelInvitation: DataTableColumnType<ClientInviteType> = {
    render: (i) => {
        const { cancelInvitationModal, openCancelInvitationModal } =
            useCancelInvitation({
                inviteId: i.id,
            });

        return (
            <>
                <IconButton
                    icon={<RiDeleteBin6Line />}
                    size="sm"
                    variant="ghost"
                    color="gray.400"
                    aria-label="cancel"
                    onClick={(e) => {
                        e.stopPropagation();
                        openCancelInvitationModal();
                    }}
                />
                {cancelInvitationModal}
            </>
        );
    },
};

export default CustomersSentInvitesTable;
