import {
    createContext,
    useMemo,
} from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import getCurrentStep from '../../functions/proccess-flow-controls/getCurrentStep';

type PropTypes = {
    steps: string[];
    onProcessEnd?: () => void;
};

export const FlowContext = createContext({
    currentStep: '',
    finalStep: false,
    paths: [] as string[],
    goNext: () => {},
    goBack: () => {},
});

const ProcessFlowRouted = (props: PropTypes) => {
    const { steps, onProcessEnd = () => {}} = props;

    const navigate = useNavigate();

    const url = useLocation().pathname.split('/').splice(1, 1).join('');

    const currentStep = getCurrentStep({ steps });

    const paths = steps;

    const currentStepIndex = paths.indexOf(currentStep);

    const finalStepIndex = paths.length - 1;

    const finalStep = currentStep === paths[finalStepIndex];

    const goToStep = (stepName: string) => navigate(`/${url}/${stepName}`);

    const goNext = () => goToStep(steps[currentStepIndex + 1]);

    const goBack = () => goToStep(steps[currentStepIndex - 1]);

    if (currentStep === paths[paths.length] && onProcessEnd) {
        onProcessEnd();
    }

    const contextValue = useMemo(() => ({
        currentStep,
        finalStep,
        paths,
        goNext,
        goBack,
    }), [currentStep, finalStep, paths, goNext, goBack]);

    return (
        <FlowContext.Provider value={contextValue}>
            <Outlet />
        </FlowContext.Provider>
    );
};

export default ProcessFlowRouted;
