import {
    Flex,
    Radio,
    RadioGroup,
    Text,
    Tooltip,
    VStack,
} from "@chakra-ui/react";
import { FieldInputProps, useField } from "formik";
import { useSmartTranslation } from "../hooks/useSmartTranslation";

type PropTypes = {
    name: string;
    width?: string;
    onChange?: (value: string, field: FieldInputProps<any>) => void;
    options: {
        label: string;
        description?: string;
        value: string;
        tooltip?: string;
    }[];
};

const FormikRadioGroup = (props: PropTypes) => {
    const t = useSmartTranslation();

    const { name, width, options, onChange } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    return (
        <RadioGroup
            variant="filled"
            {...field}
            onChange={(value) => {
                field.onChange({ target: { name, value } });

                onChange?.(value, field);
            }}
            value={field.value}
            width="100%"
        >
            <Flex
                {...field}
                width="100%"
                flexWrap="wrap"
                gap={2}
                justifyContent={"space-between"}
            >
                {options.map(({ label, description, value, tooltip }) => (
                    <Radio
                        key={value}
                        width={width}
                        minWidth="10rem"
                        value={value}
                        fontWeight={value}
                        variant="outline"
                        alignItems={"start"}
                    >
                        <Tooltip
                            key={value}
                            label={tooltip}
                            aria-label={label}
                        >
                            <VStack
                                align="left"
                                spacing="0"
                            >
                                <Text
                                    fontWeight={"bold"}
                                    lineHeight={"1"}
                                >
                                    {label}
                                </Text>

                                {description && (
                                    <Text
                                        fontSize={"xs"}
                                        lineHeight={4}
                                        color={"gray.500"}
                                        mt={1}
                                    >
                                        {description}
                                    </Text>
                                )}
                            </VStack>
                        </Tooltip>
                    </Radio>
                ))}
            </Flex>

            {meta.touched && meta.error && (
                <Text
                    color="red"
                    fontSize={"sm"}
                >
                    {t(meta.error, { defaultValue: meta.error })}
                </Text>
            )}
        </RadioGroup>
    );
};

export default FormikRadioGroup;
