import { OfferType } from "../../../../../../../types";
import BaseFilterInput from "../../BaseFilterInput";
import { FilterPropsType } from "../../Filter";

const ByOfferDataFilter = (props: FilterPropsType<OfferType>) => {
    return (
        <BaseFilterInput
            {...props}
            filterGroupId="BY_OFFER_DATA"
            placeholder="Search by Offer data"
            searchFn={({ fromValuer, fromEntity }, search) => {
                const compareEntity = fromEntity
                    ? fromEntity.name.toLowerCase().includes(search)
                    : false;

                const compareAddress = fromEntity
                    ? `${fromEntity.city} ${fromEntity.street} ${fromEntity.postalCode}`
                          .toLowerCase()
                          .includes(search)
                    : false;

                const valuerEmail = fromValuer
                    ? fromValuer.email.toLowerCase().includes(search)
                    : false;

                const valuerName = fromValuer
                    ? `${fromValuer.firstName} ${fromValuer.lastName}`
                          .toLowerCase()
                          .includes(search)
                    : false;

                return (
                    compareEntity || valuerEmail || valuerName || compareAddress
                );
            }}
        />
    );
};

export { ByOfferDataFilter };
