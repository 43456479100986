import { Flex, Icon, Text, VStack } from "@chakra-ui/react";
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { useEffect, useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { TbMapSearch } from "react-icons/tb";
import { useSmartTranslation } from "../../hooks";
import GoogleMap from "../GoogleMap";
import { WvwText, WVWTitle } from "../typography";
import i18n from "../../../i18n";
import useToast from "../../../ui/v2/common/hooks/useToast";

type AddressType = {
    street: string;
    postalCode: string;
    city: string;
    country: string;
    lat: number | undefined;
    lng: number | undefined;
};

type PropsType = {
    onChange: (value: AddressType) => void;
    placeholder: string;
    value: AddressType;
};

const { REACT_APP_GOOGLEMAPS_API_KEY } = process.env;

const MapsAutocomplete = (props: PropsType) => {
    const {
        onChange,
        placeholder,
        value = {
            street: "",
            postalCode: "",
            city: "",
            country: "",
            lat: undefined,
            lng: undefined,
        },
    } = props;

    const t = useSmartTranslation("errors");
    const { createToast } = useToast();

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: REACT_APP_GOOGLEMAPS_API_KEY,
        debounce: 300,
        options: {
            input: "",
            types: ["address"],
            language: i18n.language,
        },
    });

    const initalPlace = [
        value.street,
        value.postalCode,
        value.city,
        value.country,
    ]
        .filter((i) => i)
        .join(", ");

    const [selectedPlace, setSelectedPlace] = useState("");

    const hasLocation = value.lat && value.lng;

    useEffect(() => {
        if (!selectedPlace) return;

        const placeId = placePredictions.find(
            (i) => i.description === selectedPlace
        )?.place_id;

        if (!placeId) {
            createToast({
                status: "error",
                description: t("mapsAutoComplete.noPlace"),
            });
            return;
        }

        placesService?.getDetails({ placeId }, (place) => {
            if (
                !place ||
                !place.address_components ||
                !place.geometry?.location
            ) {
                createToast({
                    description: t("mapsAutoComplete.noPlace"),
                    status: "error",
                });
                return;
            }

            const addressComponents = place.address_components;

            const streetNumber =
                addressComponents.find((i) => i.types.includes("street_number"))
                    ?.long_name || "";
            const streetName =
                addressComponents.find((i) => i.types.includes("route"))
                    ?.long_name || "";
            const postalCode =
                addressComponents.find((i) => i.types.includes("postal_code"))
                    ?.long_name || "";
            const city =
                addressComponents.find((i) => i.types.includes("locality"))
                    ?.long_name || "";
            const country =
                addressComponents.find((i) => i.types.includes("country"))
                    ?.long_name || "";
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();

            let street = `${streetNumber} ${streetName}`.trim();

            if (i18n.language === "de") {
                street = `${streetName} ${streetNumber}`.trim();
            }

            onChange({
                street,
                postalCode,
                city,
                country,
                lat,
                lng,
            });
        });
    }, [selectedPlace]);

    return (
        <VStack w="100%">
            <AutoComplete
                onSelectOption={(event) => setSelectedPlace(event.item.value)}
                isLoading={isPlacePredictionsLoading}
                disableFilter
                defaultValue={initalPlace}
                emptyState={
                    <Flex justifyContent="center">
                        <WvwText>
                            {t("noAddressFound", { ns: "common" })}
                        </WvwText>
                    </Flex>
                }
            >
                <AutoCompleteInput
                    onChange={(event) => {
                        const { value: eValue } = event.target;

                        if (eValue === "") {
                            setSelectedPlace("");

                            onChange({
                                street: "",
                                postalCode: "",
                                city: "",
                                country: "",
                                lat: undefined,
                                lng: undefined,
                            });
                        }

                        getPlacePredictions({ input: eValue });
                    }}
                    placeholder={placeholder}
                />

                <AutoCompleteList>
                    {placePredictions.map(({ description }) => (
                        <AutoCompleteItem
                            key={description}
                            value={description}
                        >
                            {description}
                        </AutoCompleteItem>
                    ))}
                </AutoCompleteList>
            </AutoComplete>

            {hasLocation && (
                <GoogleMap
                    latitude={value.lat}
                    longitude={value.lng}
                />
            )}

            {!hasLocation && (
                <VStack
                    w={"100%"}
                    paddingBlock="5rem"
                    border="1px solid"
                    borderColor={"gray.300"}
                    borderRadius={"lg"}
                >
                    <Icon
                        as={TbMapSearch}
                        color="gray.400"
                        boxSize="8"
                    />

                    <WVWTitle
                        level="3"
                        color="black"
                        content={t("noAddressSelected", { ns: "common" })}
                    />

                    <Text
                        color="gray.500"
                        fontSize={"sm"}
                    >
                        {t("pleaseSelectAddress", { ns: "common" })}
                    </Text>
                </VStack>
            )}
        </VStack>
    );
};

export default MapsAutocomplete;
