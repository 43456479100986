import {
    Button,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdAdd, MdOutlinePersonAddAlt, MdUploadFile } from "react-icons/md";
import { useRole } from "../../../../../../common/hooks";
import { UserType } from "../../../../../../types";
import {
    useMyEntity,
    useUserInvitesPending,
    useUsersByEntity,
} from "../../../../../../utils/api/hooks";
import { usePermissions } from "../../../../app/permissions/usePermissions";
import { Title } from "../../../../common/components/display";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../layout";
import TeamInvitesTable from "./TeamInvitesTable";
import TeamMemberDetails from "./TeamMemberDetails";
import TeamTable from "./TeamTable";
import useClientInviteModal from "./useClientInviteModal";
import useTeamGroupInviteModal from "./useTeamGroupInviteModal";
import useValuerInviteModal from "./useValuerInviteModal";

const TeamScreen = () => {
    const t = useSmartTranslation();

    const { data: entity, isLoading: entityLoading } = useMyEntity();

    const {
        usersList = [],
        isLoading: usersLoading,
        refresh: refreshUsers,
    } = useUsersByEntity(entity?.id);

    const {
        data: invites,
        isLoading: invitesLoading,
        refresh: refreshInvites,
    } = useUserInvitesPending();

    const onUpdate = () => {
        refreshInvites();
        refreshUsers();
    };

    const { userIsClient } = useRole();

    const { permission: canInvite, loading: permissionLoading } =
        usePermissions({
            activity: "InviteATeamMember",
            datatype: "Entity",
            objects: entity,
        });

    const [viewTeamMember, setViewTeamMember] = useState<UserType>();

    const { valuerInviteModal, openValuerInviteModal } = useValuerInviteModal({
        entity,
        onSendInvite: onUpdate,
    });

    const { clientInviteModal, openClientInviteModal } = useClientInviteModal({
        entity,
        onSendInvite: onUpdate,
    });

    const { groupInviteModal, openGroupInviteModal } = useTeamGroupInviteModal({
        entity,
        onSendInvite: onUpdate,
    });

    if (entityLoading || invitesLoading || usersLoading || !entity) {
        return <LoadingSpinner />;
    }

    // render

    const header = (
        <Title
            title={t("account.accountSectionLink.team")}
            rightSide={
                canInvite && (
                    <HeaderMenu
                        openInviteModal={() =>
                            userIsClient
                                ? openClientInviteModal()
                                : openValuerInviteModal()
                        }
                        openGroupInviteModal={openGroupInviteModal}
                    />
                )
            }
        />
    );

    const teamTable = (
        <Section
            title={t("account.teamDetails.sectionTitle")}
            content={
                <TeamTable
                    users={usersList}
                    onRowClick={(user) => {
                        setViewTeamMember(user);
                    }}
                />
            }
        />
    );

    const teamInvitesTable = (
        <Section
            title={t("account.teamInvites.sectionTitle")}
            content={
                !permissionLoading && (
                    <TeamInvitesTable
                        users={invites || []}
                        canRemove={canInvite}
                    />
                )
            }
        />
    );

    const inviteModal = userIsClient ? clientInviteModal : valuerInviteModal;

    const teamMemberDetails = viewTeamMember && (
        <TeamMemberDetails
            user={viewTeamMember}
            closeDetails={() => {
                setViewTeamMember(undefined);
            }}
        />
    );

    return (
        <VStack
            align={"stretch"}
            gap={4}
        >
            {header}

            {teamTable}
            {teamInvitesTable}

            {inviteModal}
            {groupInviteModal}

            {teamMemberDetails}
        </VStack>
    );
};

type HeaderMenuProps = {
openInviteModal: () => void;
    openGroupInviteModal: () => void;
};

const HeaderMenu = (props: HeaderMenuProps) => {
    const { openGroupInviteModal, openInviteModal } = props;

    const t = useSmartTranslation();

    return (
        <Menu>
            <MenuButton
                as={Button}
                variant={"outline"}
                colorScheme="blue"
                leftIcon={<MdAdd />}
            >
                {t("account.teamDetails.button.inviteMembers")}
            </MenuButton>
            <MenuList>
                <MenuItem
                    gap={4}
                    fontSize={"sm"}
                    py={2}
                    onClick={openInviteModal}
                >
                    <Icon
                        as={MdOutlinePersonAddAlt}
                        boxSize={5}
                        color={"gray.400"}
                    />
                    {t("account.teamDetails.button.inviteMembers")}
                </MenuItem>
                <MenuItem
                    gap={4}
                    fontSize={"sm"}
                    py={2}
                    onClick={openGroupInviteModal}
                >
                    <Icon
                        as={MdUploadFile}
                        boxSize={5}
                        color={"gray.400"}
                    />
                    {t("account.teamDetails.button.excelUpload")}
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default TeamScreen;
