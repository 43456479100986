import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    Flex,
    Heading,
    HStack,
    Image,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIosNew } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import CommentsThread from "../../../../../common/components/CommentsThread";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import StatusBadge from "../../../../../common/components/StatusBadge";
import Toggle from "../../../../../common/components/Toggle";
import { WvwButton } from "../../../../../common/components/inputs";
import PublicUserSlider from "../../../../../common/components/user-details/PublicUserSlider";
import delimitNumber from "../../../../../common/functions/delimitNumber";
import { OfferType } from "../../../../../types";
import {
    useEnquiry,
    useEntityPublicEntityGetById,
    useOfferCommentsCreate,
    useOfferCommentsMarkAsRead,
    useOfferGetByEnquiryId,
    useOfferGetById,
    useOfferRespond,
    usePublicUserProfilePicture,
} from "../../../../../utils/api/hooks";
import { Feature } from "../../../../../ui/v2/app/features/Feature";

const offerTitles = [
    "Price",
    "PI Cover",
    "Timeline",
    "Availability",
    "Properties",
];

const ClientOffer = () => {
    const { t } = useTranslation(["offer", "common"]);

    const { enquiryId = "", offerId = "" } = useParams();

    const navigate = useNavigate();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [isRejectOpen, setIsRejectOpen] = useState(false);

    const [isAcceptOpen, setIsAcceptOpen] = useState(false);

    const { enquiry: enquiryData, isLoading: enquiryLoading } = useEnquiry({
        enquiryId,
    });

    const {
        data: offer = {} as OfferType,
        isLoading: offerLoading,
        refresh: refreshOffer,
        error: offerError,
    } = useOfferGetById(offerId);

    const { data: offerList = [] as OfferType[] } =
        useOfferGetByEnquiryId(enquiryId);

    const offerIndex = offerList.findIndex(
        (x: { id: number }) => x.id === Number(offerId)
    );

    const { update: updateOfferRespond } = useOfferRespond({
        offerId: Number(offerId),
        onSuccess: (response) => {
            setIsAcceptOpen(false);
            setIsRejectOpen(false);
            Feature.isOn(Feature.FEATURES.CASE) && response.data.caseId
                ? navigate(`/dashboard/case/${response.data.caseId}`)
                : navigate(
                      `/dashboard/enquiry/${response.data.enquiryId}/overview`
                  );
        },
    });

    const { update: createOfferComment } = useOfferCommentsCreate({
        onSuccess: () => {
            refreshOffer();
        },
    });

    const { update: markAsRead } = useOfferCommentsMarkAsRead({
        onSuccess: () => {
            refreshOffer();
        },
        onError: () => {},
    });

    const {
        data: valuerCompany,
        isLoading: valuerCompanyLoading,
        error: valuerCompanyError,
    } = useEntityPublicEntityGetById(offer.fromEntityId);

    const {
        data: valuerProfilePicture,
        isLoading: valuerProfilePictureLoading,
        error: valuerProfilePictureError,
    } = usePublicUserProfilePicture(offer.fromValuerId);

    if (
        valuerProfilePictureLoading ||
        valuerCompanyLoading ||
        offerLoading ||
        enquiryLoading
    )
        return <LoadingSpinner />;

    return (
        <VStack
            w="100%"
            align="left"
            paddingBottom="4rem"
        >
            {offerList?.length === 0 && (
                <VStack
                    w="100%"
                    bg="white"
                    borderRadius="10"
                    paddingBlock="1rem"
                    paddingInline="2.5rem"
                >
                    <Text
                        w="70%"
                        textAlign="center"
                        paddingBlock="2rem"
                    >
                        {t("msg.noOffers")}
                    </Text>
                </VStack>
            )}

            <Box
                w="100%"
                bg="white"
                borderRadius="10"
            >
                <Flex
                    paddingInline="1.5rem"
                    paddingBlock="1rem"
                >
                    <Button
                        variant="none"
                        width="5rem"
                        color="wvwGreen"
                        marginLeft=".5rem"
                        marginTop=".5rem"
                        onClick={() =>
                            navigate(`/dashboard/enquiry/${enquiryId}/offer`)
                        }
                    >
                        <HStack>
                            <MdArrowBackIosNew />

                            <Text>{t("button.back", { ns: "common" })}</Text>
                        </HStack>
                    </Button>

                    <Spacer />

                    <Toggle
                        index={offerIndex}
                        itemId={offerId}
                        toggleList={offerList}
                        path={`dashboard/enquiry/${enquiryId}/offer`}
                    />
                </Flex>

                <VStack
                    paddingInline="2.5rem"
                    paddingBottom="1rem"
                    align="left"
                >
                    <>
                        <Flex alignItems="center">
                            <HStack
                                spacing="4"
                                alignItems="flex-start"
                            >
                                <>
                                    {(!valuerProfilePicture ||
                                        valuerProfilePictureError) && (
                                        <Image
                                            borderRadius="full"
                                            marginTop="1rem"
                                            boxSize="4rem"
                                            src="/images/AvatarValuer.png"
                                            alt={t(
                                                "altMessage.noValuerProfilePicture",
                                                { ns: "common" }
                                            )}
                                        />
                                    )}

                                    {valuerProfilePicture &&
                                        !valuerProfilePictureError && (
                                            <Image
                                                borderRadius="full"
                                                marginTop="1rem"
                                                boxSize="4rem"
                                                src={valuerProfilePicture}
                                                alt={t(
                                                    "altMessage.valuerProfilePicture",
                                                    { ns: "common" }
                                                )}
                                            />
                                        )}

                                    {valuerCompanyError && (
                                        <Heading
                                            as="h4"
                                            size="md"
                                            color="wvwGrey"
                                            paddingBlock=".8rem"
                                        >
                                            {t("error.nameUnavailable", {
                                                ns: "common",
                                            })}
                                        </Heading>
                                    )}

                                    {!valuerCompanyError &&
                                        !valuerCompanyLoading && (
                                            <VStack
                                                spacing="0"
                                                align="left"
                                                paddingBlock=".8rem"
                                            >
                                                <Heading
                                                    as="h4"
                                                    fontSize="1.5rem"
                                                    color="wvwGreen"
                                                >
                                                    {`${offer?.fromValuer.firstName} ${offer?.fromValuer.lastName}`}
                                                </Heading>

                                                <Heading
                                                    as="h4"
                                                    size="6rem"
                                                    color="wvwGreen80"
                                                >
                                                    {t(
                                                        "offerDetails.fromCompany",
                                                        {
                                                            company:
                                                                valuerCompany?.name,
                                                        }
                                                    )}
                                                </Heading>

                                                <Button
                                                    variant="none"
                                                    size="max-content"
                                                    color="wvwYellow"
                                                    alignSelf="flex-start"
                                                    fontWeight="400"
                                                    onClick={() =>
                                                        setIsDrawerOpen(true)
                                                    }
                                                >
                                                    {t("button.viewValuer", {
                                                        ns: "common",
                                                    })}
                                                </Button>
                                            </VStack>
                                        )}
                                </>
                            </HStack>

                            <Spacer />

                            <StatusBadge status={offer?.status} />
                        </Flex>

                        <Divider />

                        {offerError && (
                            <Text>
                                {t("error.offer.loadingFailed", {
                                    ns: "common",
                                })}
                            </Text>
                        )}

                        {offer && (
                            <VStack
                                align="left"
                                paddingBottom="1rem"
                            >
                                {offerTitles.map((title) => (
                                    <Fragment key={title}>
                                        <Flex paddingInline="1rem">
                                            <HStack w="17rem">
                                                <Text>
                                                    {t(`offerTitle.${title}`, {
                                                        defaultValue: title,
                                                    })}
                                                </Text>
                                            </HStack>

                                            <Text w="20rem">
                                                <>
                                                    {title === "Price" &&
                                                        `${t(
                                                            "unitSymbol.currency",
                                                            { ns: "common" }
                                                        )} ${delimitNumber(
                                                            offer.price
                                                        )}`}
                                                    {title === "PI Cover" &&
                                                        `${t(
                                                            "piCover_or_statement",
                                                            {
                                                                ns: "common",
                                                                coverValue:
                                                                    offer?.piCoverValue ===
                                                                        0 ||
                                                                    null
                                                                        ? "-"
                                                                        : delimitNumber(
                                                                              offer?.piCoverValue?.toString()
                                                                          ),
                                                                coverPercentage:
                                                                    offer?.piCoverPercentage ===
                                                                        0 ||
                                                                    null
                                                                        ? "-"
                                                                        : offer?.piCoverPercentage,
                                                            }
                                                        )}`}
                                                    {title === "Timeline" &&
                                                        `${offer.timeline} ${t(
                                                            "offerForm.timeline.days"
                                                        )}`}
                                                    {title === "Availability" &&
                                                        offer.availability}
                                                    {title === "Properties" &&
                                                        enquiryData?.properties?.map(
                                                            (property) => (
                                                                <Fragment
                                                                    key={
                                                                        property.id
                                                                    }
                                                                >
                                                                    {`${property.street}, ${property.city}`}
                                                                    <br />
                                                                </Fragment>
                                                            )
                                                        )}
                                                </>
                                            </Text>
                                        </Flex>

                                        <Divider />
                                    </Fragment>
                                ))}

                                <VStack
                                    bg="wvwGrey05"
                                    borderRadius="10"
                                    align="left"
                                    spacing="4"
                                    padding="1rem"
                                >
                                    <Heading
                                        as="h4"
                                        size="sm"
                                    >
                                        {t("offerDetails.sectionTitle")}
                                    </Heading>

                                    <Text
                                        color={
                                            offer.comments ? "" : "wvwGrey40"
                                        }
                                    >
                                        {offer.comments
                                            ? offer.comments
                                            : t("comments.noDetails")}
                                    </Text>
                                </VStack>

                                {offer.status === "PENDING" && (
                                    <ButtonGroup
                                        spacing="4"
                                        justifyContent="flex-end"
                                        marginTop=".5rem"
                                    >
                                        <WvwButton
                                            variant="danger"
                                            onClick={() => {
                                                setIsRejectOpen(true);
                                            }}
                                            content={t("button.reject", {
                                                ns: "common",
                                            })}
                                        />

                                        <WvwButton
                                            variant="success"
                                            onClick={() => {
                                                setIsAcceptOpen(true);
                                            }}
                                            content={t("button.accept", {
                                                ns: "common",
                                            })}
                                        />
                                    </ButtonGroup>
                                )}

                                <CommentsThread
                                    commentsList={
                                        offer.offerComments?.map((comment) => ({
                                            id: comment.id,
                                            createdAt: comment.createdAt,
                                            updatedAt: comment.updatedAt,
                                            fromUserId: comment.fromId,
                                            readBy: comment.readBy,
                                            message: comment.message,
                                        })) || []
                                    }
                                    onSend={(values) => {
                                        createOfferComment({
                                            offerId: offer.id,
                                            message: values.comment,
                                        });
                                    }}
                                    markAsRead={() => {
                                        markAsRead({
                                            commentIds:
                                                offer.offerComments?.map(
                                                    (comment) => comment.id
                                                ) || [],
                                        });
                                    }}
                                />
                            </VStack>
                        )}
                    </>
                </VStack>
            </Box>

            <ConfirmationModal
                cancelButton={t("button.cancel", { ns: "common" })}
                continueButton={t("button.reject", { ns: "common" })}
                isOpen={isRejectOpen}
                content={t("warning.rejectOffer.prompt", { ns: "common" })}
                title={t("warning.rejectOffer.title", { ns: "common" })}
                onCancel={() => setIsRejectOpen(false)}
                onContinue={() => {
                    updateOfferRespond({
                        offerId: offer.id,
                        status: "REJECTED",
                    });
                }}
            />

            <ConfirmationModal
                cancelButton={t("button.cancel", { ns: "common" })}
                continueButton={t("button.accept", { ns: "common" })}
                isOpen={isAcceptOpen}
                content={t("warning.acceptOffer.prompt", { ns: "common" })}
                title={t("warning.acceptOffer.title", { ns: "common" })}
                onCancel={() => setIsAcceptOpen(false)}
                onContinue={() => {
                    updateOfferRespond({
                        offerId: offer.id,
                        status: "ACCEPTED",
                    });
                }}
            />

            <PublicUserSlider
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                userId={offer?.fromValuer.id}
                viewMode="ENQUIRY"
            />
        </VStack>
    );
};

export default ClientOffer;
