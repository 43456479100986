import { Box, HStack, VStack } from "@chakra-ui/layout";
import {
    Button,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Select,
    Text,
} from "@chakra-ui/react";
import { ElementType, useState } from "react";
import {
    MdOutlineAccountBalance,
    MdOutlineAgriculture,
    MdOutlineCellTower,
    MdOutlineFileUpload,
    MdOutlineFoundation,
    MdOutlineHomeWork,
    MdOutlineNaturePeople,
    MdOutlineStorefront,
    MdOutlineVerifiedUser,
} from "react-icons/md";
import { RiFolderOpenLine } from "react-icons/ri";
import toTitleCase from "../../../../../../common/functions/toTitleCase";
import { useRole } from "../../../../../../common/hooks";
import { StandardCompanyDocumentType } from "../../../../../../common/vars/valuationsAndMembershipTypes/standardCompanyDocumentTypes";
import { StandardPropertyType } from "../../../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";
import { useMyEntity } from "../../../../../../utils/api/hooks";
import { FirebaseCertificateType } from "../../../../../../utils/storage/storage";
import { Title } from "../../../../common/components/display";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../../common/MISSING_TRANSLATION";
import DocumentsTable from "./DocumentsTable";
import DocumentsUploadDrawer from "./DocumentsUploadDrawer";

const DocumentsScreen = () => {
    const t = useSmartTranslation();

    const { userIsCompanyAdmin } = useRole();

    const [checkedDocuments, setCheckedDocuments] = useState<
        FirebaseCertificateType[]
    >([]);

    const [openUploaderType, setOpenUploaderType] = useState<
        StandardCompanyDocumentType | StandardPropertyType | undefined
    >(undefined);

    const {
        entityCertificates = [],
        entityCertificatesIsLoading,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
    } = useMyEntity();

    if (entityCertificatesIsLoading || entitySampleValuationsIsLoading)
        return <LoadingSpinner />;

    const toggleFileChecked = (
        document: FirebaseCertificateType,
        checked: boolean
    ) => {
        if (!checked) {
            const idx = checkedDocuments.findIndex(
                (o) => o.url === document.url
            );
            if (idx !== -1) {
                const newChecked = [...checkedDocuments];
                newChecked.splice(idx, 1);
                setCheckedDocuments(newChecked);
            }
        } else {
            if (checkedDocuments.find((o) => o.url === document.url)) return;
            setCheckedDocuments([...checkedDocuments, document]);
        }
    };

    // render

    const header = (
        <Title
            title={t("account.accountSectionLink.sharedDocs")}
            rightSide={
                userIsCompanyAdmin && (
                    <HeaderMenu openUploadModal={setOpenUploaderType} />
                )
            }
        />
    );

    // TODO: connect to the email sending modal
    const documentsFooter = checkedDocuments.length && (
        <HStack>
            <Text fontSize={"sm"}>
                {t(
                    "screens.platform.client.enquiryDetails.activeOffers.footer.selected",
                    {
                        count: checkedDocuments.length,
                    }
                )}
            </Text>
            <Select
                width={"140px"}
                size={"sm"}
            >
                <option value={"email"}>
                    {t("account.sharedDocuments.sendEmail")}
                </option>
            </Select>
            <Button
                variant={"outline"}
                colorScheme={"blue"}
                size={"sm"}
            >
                {t("common.button.confirm")}
            </Button>
        </HStack>
    );

    const files = [...entityCertificates, ...entitySampleValuations];

    const documentsTable = (
        <Box
            borderRadius={"lg"}
            overflow={"hidden"}
        >
            <DocumentsTable
                files={files}
                checkedDocuments={checkedDocuments}
                userIsAdmin={userIsCompanyAdmin}
                toggleFileChecked={toggleFileChecked}
                footerContent={documentsFooter}
            />
        </Box>
    );

    const uploader = openUploaderType && (
        <DocumentsUploadDrawer
            onClose={() => setOpenUploaderType(undefined)}
            type={openUploaderType}
        />
    );

    return (
        <VStack
            align={"stretch"}
            spacing={4}
        >
            {header}
            {documentsTable}
            {uploader}
        </VStack>
    );
};

type HeaderMenuItemProps = {
    icon: ElementType;
    type: StandardCompanyDocumentType | StandardPropertyType;
};

const DEFAULT_MENU_ITEMS: HeaderMenuItemProps[] = [
    {
        icon: RiFolderOpenLine,
        type: "offer",
    },
    {
        icon: MdOutlineVerifiedUser,
        type: "pi",
    },
    {
        icon: MdOutlineCellTower,
        type: "marketing",
    },
];

const VALUATION_MENU_ITEMS: HeaderMenuItemProps[] = [
    {
        icon: MdOutlineHomeWork,
        type: "Residential",
    },
    {
        icon: MdOutlineStorefront,
        type: "Commercial",
    },
    {
        icon: MdOutlineNaturePeople,
        type: "Land",
    },
    {
        icon: MdOutlineAgriculture,
        type: "Agricultural",
    },
    {
        icon: MdOutlineFoundation,
        type: "Development",
    },
    {
        icon: MdOutlineAccountBalance,
        type: "Special",
    },
];

type HeaderMenuProps = {
    openUploadModal: (
        type: StandardCompanyDocumentType | StandardPropertyType
    ) => void;
};

const HeaderMenu = (props: HeaderMenuProps) => {
    const { openUploadModal } = props;

    const t = useSmartTranslation();

    return (
        <Menu>
            <MenuButton
                as={Button}
                variant={"outline"}
                colorScheme="blue"
                leftIcon={<MdOutlineFileUpload />}
            >
                {t("common.button.upload")}
            </MenuButton>
            <MenuList>
                <Text
                    variant={"secondary"}
                    fontSize={"xs"}
                    px={2}
                    mb={1}
                >
                    {t("domain.document.category.Documents")}
                </Text>
                {DEFAULT_MENU_ITEMS.map(({ icon, type }) => (
                    <MenuItem
                        key={type}
                        gap={4}
                        fontSize={"sm"}
                        py={2}
                        onClick={() => openUploadModal(type)}
                    >
                        <Icon
                            as={icon}
                            boxSize={5}
                            color={"gray.400"}
                        />
                        {t(`domain.document.category.${toTitleCase(type)}`, {
                            defaultValue: MISSING_TRANSLATION,
                        })}
                    </MenuItem>
                ))}

                <Text
                    variant={"secondary"}
                    fontSize={"xs"}
                    px={2}
                    mt={3}
                    mb={1}
                >
                    {t("domain.document.category.SampleValuations")}
                </Text>
                {VALUATION_MENU_ITEMS.map(({ icon, type }) => (
                    <MenuItem
                        key={type}
                        gap={4}
                        fontSize={"sm"}
                        py={2}
                        onClick={() => openUploadModal(type)}
                    >
                        <Icon
                            as={icon}
                            boxSize={5}
                            color={"gray.400"}
                        />
                        {t(`domain.document.category.${type}`, {
                            defaultValue: MISSING_TRANSLATION,
                        })}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export default DocumentsScreen;
