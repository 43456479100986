import {
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    InputRightAddon,
    InputRightElement,
    Text,
} from "@chakra-ui/react";
import { FieldInputProps, useField } from "formik";
import { JSX, useContext } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { LabelAndInput } from "../components/display";
import { FormikValidationContext } from "./FormikForm";

type PropTypes = {
    name: string;
    disabled?: boolean;
    warningIcon?: boolean;
    label?: string;
    min?: string;
    placeholder?: string;
    prefix?: string;
    suffix?: string;
    type?: string;
    leftinternal?: string | JSX.Element;
    rightinternal?: string | JSX.Element;
    width?: string;
    maxW?: string;
    onChange?: (value: string, field?: FieldInputProps<any>) => void;
    noSubmitOnEnter?: boolean;
};

const FormikInput = (props: PropTypes) => {
    const { t } = useTranslation("formik");

    const {
        name,
        prefix = null,
        disabled = false,
        warningIcon,
        label = "",
        min,
        placeholder = '',
        suffix = null,
        leftinternal = null,
        maxW,
        rightinternal = null,
        width = '100%',
        onChange = () => { },
        noSubmitOnEnter = false,
        type = 'text',
    } = props;

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const isFieldRequired = useContext(FormikValidationContext);

    const isRequired = isFieldRequired(name);

    return (
        <LabelAndInput
            icon={warningIcon ? "warning" : undefined}
            label={label && `${label}${isRequired ? " *" : ""}`}
            input={
                <>
                    <InputGroup>
                        {prefix && (
                            <InputLeftAddon
                                h="2.5rem"
                                minW="4rem"
                            >
                                {prefix}
                            </InputLeftAddon>
                        )}

                        {leftinternal && (
                            <InputLeftElement>{leftinternal}</InputLeftElement>
                        )}

                        <Input
                            min={min}
                            placeholder={placeholder}
                            borderRadius="8"
                            bg="white"
                            disabled={disabled}
                            position="relative"
                            h="2.5rem"
                            isInvalid={!!meta.touched && !!meta.error}
                            maxW={maxW}
                            w={width}
                            lang={i18n.language}
                            type={type}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            onClick={(e) => e.stopPropagation()}
                            onKeyDown={(e) => {
                                e.stopPropagation();

                                if (noSubmitOnEnter && e.key === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                field.onChange(e);

                                onChange?.(e.target.value, field);
                            }}
                        />

                        {rightinternal && (
                            <InputRightElement>
                                {rightinternal}
                            </InputRightElement>
                        )}

                        {suffix && (
                            <InputRightAddon
                                h="3rem"
                                minW="4rem"
                            >
                                {suffix}
                            </InputRightAddon>
                        )}
                    </InputGroup>

                    {meta.touched && meta.error && (
                        <Text color="red">
                            {t(meta.error, { defaultValue: meta.error })}
                        </Text>
                    )}
                </>
            }
        />
    );
};

export default FormikInput;
