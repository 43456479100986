import { Box, Heading, Text, VStack } from "@chakra-ui/react";
import { FormikCheckboxGroup } from "../../../../../../../common/forms";
import {
    standardSpecialValuationTypes,
    standardValuationTypes,
} from "../../../../../../../common/vars/valuationsAndMembershipTypes";
import { Forms } from "../../../../../common/forms/Forms";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../../layout";

const ProfileValuationTypesEdit = () => {
    const t = useSmartTranslation();

    const standardsEdit = (
        <Forms.FormikCloudSelect
            name="valuationTypes"
            options={standardValuationTypes.map((type) => ({
                label: t(`domain.standards.valuationType.${type}`),
                value: type,
            }))}
        />
    );

    const otherStandardsEdit = (
        <Forms.FormikConditionalSection
            condition={(value) =>
                value.valuationTypes.includes("Other Valuation")
            }
        >
            <VStack
                align={"start"}
                bg={"gray.50"}
                borderRadius="lg"
                padding={4}
                spacing={4}
                my={2}
            >
                <Box>
                    <Heading
                        size={"sm"}
                        color={"blue.700"}
                    >
                        {t("profile.heading.otherValuationScopes")}
                    </Heading>

                    <Text
                        fontSize={"sm"}
                        color={"gray.500"}
                    >
                        {t("profile.subHeading.otherValuationScopes")}
                    </Text>
                </Box>

                <Forms.FormikListInput
                    name="otherValuationTypes"
                    width="15rem"
                    placeholder={t(
                        "profile.membershipsAndQualifications.edit.addValuationScope"
                    )}
                />
            </VStack>
        </Forms.FormikConditionalSection>
    );

    const specificStandards = (
        <VStack
            align={"start"}
            bg={"gray.50"}
            borderRadius="lg"
            padding={4}
            spacing={4}
        >
            <Box>
                <Heading
                    size={"sm"}
                    color={"blue.700"}
                >
                    {t("profile.heading.specificValuationScopes")}
                </Heading>

                <Text
                    fontSize={"sm"}
                    color={"gray.500"}
                >
                    {t("profile.subHeading.specificValuationScopes")}
                </Text>
            </Box>

            <FormikCheckboxGroup
                name="specialValuationTypes"
                size="lg"
                options={standardSpecialValuationTypes.map((type) => ({
                    label: t(`domain.standards.specialValuationType.${type}`),
                    value: type,
                }))}
            />
        </VStack>
    );

    return (
        <Section
            title={t("profile.heading.valuationScope")}
            subtitle={t("profile.subHeading.valuationScopes")}
            content={
                <>
                    {standardsEdit}
                    {otherStandardsEdit}
                    {specificStandards}
                </>
            }
        />
    );
};

export default ProfileValuationTypesEdit;
