export const translations = {
    de: {
        browseKeyword: "durchsuchen",
        dragDropBrowse: "Drag & Drop oder lokale Dateien durchsuchen",
        file: "Datei",
        fileNameRequired: "Dateiname ist erforderlich",
        fileTooLarge: "Datei ist zu groß",
        fileTypeNotAllowed: "Dateityp wird nicht unterstützt",
        maxFileSize: "Maximale Dateigröße ist 25 MB",
        button: {
            upload: "Hochladen",
            uploadFile: "Datei Hochladen",
            loader: "Hochladen von Dateien...",
        },
        uploaded: {
            label: "Hochgeladen!",
            description: "Die Dateien wurden erfolgreich hochgeladen und gespeichert.",
        }
    },
    en: {
        browseKeyword: "browse",
        dragDropBrowse: "Drag and drop files, or browse",
        file: "File",
        fileNameRequired: "File name is required",
        fileTooLarge: "File is too large",
        fileTypeNotAllowed: "File type is not allowed",
        maxFileSize: "Max file size 25 MB",
        button: {
            upload: "Upload",
            uploadFile: "Upload File",
            loader: "Uploading files...",
        },
        uploaded: {
            label: "Uploaded!",
            description: "Files uploaded and saved successfully.",
        }
    },
};
