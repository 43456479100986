import { Center, Spinner, Text, VStack } from "@chakra-ui/react";
import { useSmartTranslation } from "../hooks/useSmartTranslation";

type PropTypes = {
    noText?: boolean;
    customText?: string;
};

const LoadingSpinner = (props: PropTypes) => {
    const { noText = false, customText } = props;

    const t = useSmartTranslation();

    const color = "blue.700";
    const size = noText ? "xl" : "lg";
    const thickness = noText ? "4px" : undefined;

    const text = !noText && (
        <Text
            fontSize={"sm"}
            color={"gray.500"}
        >
            {customText || t("common.loading")}
        </Text>
    );

    return (
        <Center
            w="100%"
            h="100%"
        >
            <VStack
                spacing="4"
                w="100%"
            >
                <Spinner
                    color={color}
                    size={size}
                    thickness={thickness}
                />

                {text}
            </VStack>
        </Center>
    );
};

export default LoadingSpinner;
