import React from "react";
import { Feature } from "./FEATURES";
import { isFeatureOn } from "./isFeatureOn";

export type WithFeatureProps = {
    name: Feature;
    off?: boolean;
    children: React.ReactNode;
};

export const WithFeature = (props: WithFeatureProps) => {
    const { name, off = false, children } = props;

    const enabled = isFeatureOn(name);

    return off !== enabled ? children : null;
};

export const WithFeatureOn = (props: Omit<WithFeatureProps, "off">) => (
    <WithFeature name={props.name}>{props.children}</WithFeature>
);

export const WithFeatureOff = (props: Omit<WithFeatureProps, "off">) => (
    <WithFeature
        name={props.name}
        off
    >
        {props.children}
    </WithFeature>
);
