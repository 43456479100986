import { CheckIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { ClientInviteType } from "../../../../../../../../types";
import {
    useClientInvitesGetReceived,
    useClientInvitesRespond,
    useEntityPanelValuers,
} from "../../../../../../../../utils/api/hooks";
import { useSmartTranslation } from "../../../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../../../common/hooks/useToast";

type AcceptInviteProps = {
    invite: ClientInviteType;
};

const AcceptInvite = (props: AcceptInviteProps) => {
    const { invite } = props;
    const t = useSmartTranslation();
    const { createToast } = useToast();

    const { refresh: refreshValuers } = useEntityPanelValuers();
    const { refresh: pendingInvitesRefresh } = useClientInvitesGetReceived();

    const { update } = useClientInvitesRespond({
        onSuccess: () => {
            pendingInvitesRefresh();
            refreshValuers();
        },
        onError: (err) => {
            createToast({
                description: err.message,
                status: "error",
            });
        },
    });

    return (
        <Button
            variant="solid"
            colorScheme="green"
            size="sm"
            leftIcon={<CheckIcon />}
            onClick={() =>
                update({ inviteId: invite.id, response: "ACCEPTED" })
            }
        >
            {t("account.customerPanelDetails.button.accept")}
        </Button>
    );
};

export default AcceptInvite;
