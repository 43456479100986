import { standardValuationTypes } from "../../../../../../../common/vars/valuationsAndMembershipTypes";
import { StandardValuationType } from "../../../../../../../common/vars/valuationsAndMembershipTypes/standardValuationTypes";
import { useSmartTranslation } from "../../../../hooks/useSmartTranslation";
import {
    EnquiryFilterFunctionType,
    EnquiryFilterPropsType,
} from "../../../../../screens/platform/enquiry-list/filters/EnquiryFilter";
import { FilterOptionType, FilterSelect } from "../../FilterSelect";

const FILTERGROUP_ID = "BY_VALUATION_TYPE";

const ByScope = (props: EnquiryFilterPropsType) => {
    const {
        activateFilter: activateFilterGroup,
        deactivateFilter,
        activeFilters,
    } = props;

    const t = useSmartTranslation();

    const activateFilter = (
        value: StandardValuationType,
        filter: EnquiryFilterFunctionType
    ): void => {
        activateFilterGroup({
            id: FILTERGROUP_ID,
            filter,
            value,
            displayedData: {
                filterLabel: t(
                    "screens.platform.enquiryList.filters.byValuationType.label"
                ),
                valueLabel: t(`domain.standards.valuationType.${value}`),
            },
        });
    };

    const activeFilterValue = activeFilters.find(
        ({ id }) => id === FILTERGROUP_ID
    )?.value;

    const options: FilterOptionType[] = [
        {
            id: "allValutationTypes",
            label: t(
                "screens.platform.enquiryList.filters.byValuationType.options.allValuationTypes"
            ),
            onSelect: () => deactivateFilter(FILTERGROUP_ID),
        },
        ...standardValuationTypes.map((scope) => {
            const id = scope;

            return {
                id,
                label: t(`domain.standards.valuationType.${scope}`),
                onSelect: () =>
                    activateFilter(
                        id,
                        ({ valuationType }) => valuationType === scope
                    ),
            };
        }),
    ];

    return (
        <FilterSelect
            label={t(
                "screens.platform.enquiryList.filters.byValuationType.label"
            )}
            filterOptions={options}
            value={activeFilterValue}
        />
    );
};

export { ByScope };
