import { DeleteIcon } from "@chakra-ui/icons";
import { Button, useDisclosure } from "@chakra-ui/react";
import { ClientInviteType } from "../../../../../../../../types";
import {
    useClientInvitesGetReceived,
    useClientInvitesRespond,
    useEntityPanelValuers,
} from "../../../../../../../../utils/api/hooks";
import ConfirmationModal from "../../../../../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../../../common/hooks/useToast";

type DeclineInviteProps = {
    invite: ClientInviteType;
};

const DeclineInvite = (props: DeclineInviteProps) => {
    const { invite } = props;
    const t = useSmartTranslation();
    const { createToast } = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const { refresh: refreshValuers } = useEntityPanelValuers();
    const { refresh: pendingInvitesRefresh } = useClientInvitesGetReceived();

    const { update } = useClientInvitesRespond({
        onSuccess: () => {
            pendingInvitesRefresh();
            refreshValuers();
        },
        onError: (err) => {
            createToast({
                description: err.message,
                status: "error",
            });
        },
    });

    return (
        <>
            <Button
                variant="solid"
                colorScheme="red"
                size="sm"
                leftIcon={<DeleteIcon />}
                onClick={onOpen}
            >
                {t("account.customerPanelDetails.button.reject")}
            </Button>

            <ConfirmationModal
                confirmationButtonLabel={t("common.button.confirm")}
                confirmationButtonVariant="negative"
                isOpen={isOpen}
                title={t(
                    "account.customerPanelDetails.declineInvitation.title"
                )}
                blurb={t(
                    "account.customerPanelDetails.declineInvitation.prompt"
                )}
                onClose={onClose}
                onConfirmation={() => {
                    update({ inviteId: invite.id, response: "DECLINED" });
                    onClose();
                }}
            />
        </>
    );
};

export default DeclineInvite;
