import {
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Text,
} from "@chakra-ui/react";
import { LabelAndInput } from "../components/display";
import { useSmartTranslation } from "../hooks/useSmartTranslation";
import { useFormikMeta } from "./useFormikMeta";

type PropTypes = {
    name: string;
    label?: string;
    min?: number;
    max?: number;
    w?: string;
    maxW?: string;
    placeholder?: string;
};

const FormikNumberInput = (props: PropTypes) => {
    const t = useSmartTranslation();

    const {
        name,
        min,
        max,
        label,
        maxW = "100%",
        w = "100%",
        placeholder,
    } = props;

    const { errorBorderColor, isRequired, isInvalid, field, meta } =
        useFormikMeta(name);

    return (
        <LabelAndInput
            label={label && `${label}${isRequired ? " *" : ""}`}
            input={
                <>
                    <NumberInput
                        borderRadius="8"
                        bg="white"
                        isInvalid={!!meta.touched && !!meta.error}
                        min={min}
                        max={max}
                        width={w}
                        maxW={maxW}
                        {...field}
                        {...props}
                        onChange={(_sValue, value) => {
                            field.onChange({ target: { name, value } });
                        }}
                    >
                        <NumberInputField
                            h="2.5rem"
                            placeholder={placeholder}
                            min={min}
                        />
                        <NumberInputStepper>
                            <NumberIncrementStepper
                                children={"+"}
                                color={"gray.400"}
                                fontSize={"md"}
                                lineHeight={"1.2"}
                            />
                            <NumberDecrementStepper
                                children={"-"}
                                color={"gray.400"}
                                fontSize={"md"}
                                lineHeight={"1.2"}
                            />
                        </NumberInputStepper>
                    </NumberInput>

                    {meta.touched && meta.error && (
                        <Text
                            fontSize={"sm"}
                            color="red"
                        >
                            {t(meta.error, { defaultValue: meta.error })}
                        </Text>
                    )}
                </>
            }
        />
    );
};

export default FormikNumberInput;
