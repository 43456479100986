/* eslint-disable react/jsx-props-no-spreading */
import {
    AccordionButton,
    AccordionIcon,
    Box,
    Flex,
    HStack,
    Spacer,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import ListItemLayout from './ListItemLayout';
import { WVWTitle } from '../typography';

type PropTypes = {
    disabled?: boolean;
    actionButton?: ReactNode;
    icon?: ReactNode;
    statusBadge: ReactNode;
    title: string | ReactNode;
};

const WvwAccordionButton = (props: PropTypes) => {
    const {
        disabled = false,
        actionButton = null,
        icon = null,
        statusBadge,
        title,
    } = props;

    return (
        <h2>
            <AccordionButton
                _hover={{
                    bgColor: disabled ? '' : 'wvwGrey10',
                }}
                bgColor="wvwGrey05"
            >
                <AccordionIcon visibility={disabled ? 'hidden' : 'visible'} />

                <Box
                    ml="0.8rem"
                    w="100%"
                >
                    <ListItemLayout
                        heading={(
                            <HStack
                                spacing="3"
                                mr="3rem"
                            >
                                <Box>
                                    {icon}
                                </Box>

                                {typeof title === 'string' && (
                                    <Box
                                        w="100%"
                                    >
                                        <WVWTitle
                                            color="black"
                                            content={title}
                                            level="3"
                                            normalFontWeight
                                        />
                                    </Box>
                                )}

                                {typeof title !== 'string' && title}
                            </HStack>
                        )}
                        extra={(
                            <Flex
                                ml="2rem"
                                w="100%"
                                alignItems="center"
                            >
                                {statusBadge}

                                <Spacer />

                                {actionButton}
                            </Flex>
                        )}
                    />
                </Box>
            </AccordionButton>
        </h2>
    );
};

export default WvwAccordionButton;
