export const databaseDateStringToDate = (dateString: string) => {
    const [year, month, day] = dateString.split("-").map((i) => Number(i));

    const date = new Date();
    date.setHours(0,0,0,0);

    date.setFullYear(year);
    date.setMonth(month - 1);
    date.setDate(day);

    return date;
};

export const localeDateStringToDate = (dateString: string): Date => {
    const dateParts = dateString.split(/[^0-9]/g).map((i) => Number(i));

    const locale = localStorage.getItem("i18nextLng") || "";

    if (locale === "") throw new Error("No locale provided");

    const date = new Date();
    date.setHours(0,0,0,0);

    if (locale === "de") {
        const [day, month, year] = dateParts;

        date.setFullYear(year);
        date.setMonth(month - 1);
        date.setDate(day);
    }

    if (locale === "en") {
        const [month, day, year] = dateParts;

        date.setFullYear(year);
        date.setMonth(month - 1);
        date.setDate(day);
    }

    return date;
};
