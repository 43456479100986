import { Box, Flex } from '@chakra-ui/react';

export type StatusBadgeColor = 'blue10' | 'green' | 'green10' | 'wvwGrey10' | 'orange10' | 'purple10' | 'red10' | 'white' | 'wvwGreen10' | 'wvwGrey05' | 'wvwYellow10';
export type StatusBadgeTextColor = 'blue' | 'green' | 'wvwGrey80' | 'orange' | 'purple' | 'red' | 'wvwGreen' | 'wvwGrey60' | 'wvwYellow';

type PropTypes = {
    color: StatusBadgeColor; // The background color of the badge
    status: string; // The text of the badge
    textColor: StatusBadgeTextColor; // The text color of the badge
    noPadding?: boolean; // Whether to remove padding from the badge
};

const StatusBadge = (props: PropTypes) => {
    const {
        color,
        status,
        textColor,
        noPadding = false,
    } = props;

    const badge = (
        <Box
            bg={color}
            borderRadius="10"
            color={textColor}
            fontWeight="bold"
            paddingBlock="0.2rem"
            paddingInline="1rem"
            fontSize=".8rem"
            textAlign="center"
        >
            {status}
        </Box>
    );

    if (noPadding) {
        return (
            <Flex alignItems="flex-start">
                {badge}
            </Flex>
        );
    }

    return (
        <Flex
            alignItems="center"
            justifyContent="center"
        >
            {badge}
        </Flex>
    );
};

export default StatusBadge;
