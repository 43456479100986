import { HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";
import { useRole, useStandards } from "../../../../../../../common/hooks";
import useMe from "../../../../../app/useMeV2";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import ProfilePictureEditor from "../../../../../common/components/ProfilePictureEditor";
import { Forms } from "../../../../../common/forms/Forms";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../../layout";

const CompanyDetailsContactEdit = () => {
    const t = useSmartTranslation();

    const { data: user, isLoading: userLoading } = useMe();

    const { userIsValuer, userIsCompanyAdmin } = useRole();

    const { yearsOfExperience } = useStandards();

    if (!user || userLoading) return <LoadingSpinner />;

    // render

    const basicInfoEdit = (
        <>
            <Forms.FormikInput
                name="companyEmail"
                label={t("account.companyDetails.email")}
                placeholder={t("account.fieldPlaceholder.email")}
                type="email"
            />

            <Forms.FormikInput
                name="websiteAddress"
                label={t("account.companyDetails.website")}
                placeholder={t("account.fieldPlaceholder.url")}
            />

            <HStack
                spacing={4}
                align={"start"}
            >
                <Forms.FormikPhoneNumberInput
                    dialCodeName="contactNumberDialCode"
                    dialCodeLabel={t("account.companyDetails.phoneNumber")}
                    phoneNumberName="contactNumber"
                    phoneNumberPlaceholder={t(
                        "account.fieldPlaceholder.phoneNumber"
                    )}
                />
            </HStack>
        </>
    );

    const valuersInfoEdit = userIsValuer && userIsCompanyAdmin && (
        <HStack
            spacing={4}
            align={"start"}
        >
            <Forms.FormikNumberInput
                label={t("account.companyDetails.numberOfValuers")}
                name="valuerNumberOfPartners"
                placeholder={t("account.fieldPlaceholder.numberOfValuers")}
                min={0}
            />

            <Forms.FormikSelect
                name="valuerYearsOfExperience"
                label={t("account.companyDetails.yearsOfExperience")}
                placeholder={t("account.fieldPlaceholder.numberOfYears")}
                options={yearsOfExperience}
            />
        </HStack>
    );

    const logoEdit = (
        <>
            <Text fontSize={"sm"}>
                {t("account.companyDetails.companyLogo")}
            </Text>

            <ProfilePictureEditor forEntity />

            <HStack
                spacing={1}
                align={"start"}
            >
                <Icon
                    as={MdInfoOutline}
                    color={"gray.400"}
                />
                <Text
                    fontSize={"xs"}
                    lineHeight={4}
                    color={"gray.500"}
                >
                    {t("account.companyDetails.companyLogoDescription")}
                </Text>
            </HStack>
        </>
    );

    return (
        <Section
            title={t("account.companyDetails.contactInfo")}
            subtitle={t("account.companyDetails.contactInfoDescription")}
            content={
                <HStack
                    w="100%"
                    spacing={4}
                    align={"stretch"}
                >
                    <VStack
                        width="100%"
                        align={"stretch"}
                        spacing={4}
                    >
                        {basicInfoEdit}
                        {valuersInfoEdit}
                    </VStack>

                    <VStack
                        spacing={2}
                        w={"100%"}
                        align={"stretch"}
                    >
                        {logoEdit}
                    </VStack>
                </HStack>
            }
        />
    );
};

export default CompanyDetailsContactEdit;
