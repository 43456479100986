import { Icon } from "@chakra-ui/react";
import { BsCloudUpload, BsDot, BsDownload, BsMap, BsTrash } from "react-icons/bs";
import { FiClock, FiMenu } from "react-icons/fi";
import { HiPlus } from "react-icons/hi";
import { MdArrowBackIos, MdDocumentScanner, MdOutlineEdit, MdOutlineLocationOn, MdOutlineWarningAmber } from "react-icons/md";
import { RiCloseCircleLine } from "react-icons/ri";

const icons = {
    add: HiPlus,
    back: MdArrowBackIos,
    burgerMenu: FiMenu,
    cancel: RiCloseCircleLine,
    delete: BsTrash,
    document: MdDocumentScanner,
    documentRequest: MdDocumentScanner,
    documentToUpload: BsCloudUpload,
    documentToDownload: BsDownload,
    edit: MdOutlineEdit,
    location: MdOutlineLocationOn,
    map: BsMap,
    pending: FiClock,
    success: BsDot,
    warning: MdOutlineWarningAmber,
};

export type WvwIconKeys = keyof typeof icons;

type PropTypes = {
    icon: WvwIconKeys;
    color?: "wvwGreen" | "wvwGrey60" | "black" | "white";
    size?: "md" | "lg" | "xl";
};

const WvwIcon = (props: PropTypes) => {
    const { icon, color = "wvwGreen", size = "md"} = props;

    let boxSize = "6";

    if (size === "lg") {
        boxSize = "12";
    }

    if (size === "xl") {
        boxSize = "20";
    }

    return (
        <Icon
            as={icons[icon]}
            color={color}
            boxSize={boxSize}
        />
    );
};

export default WvwIcon;
