import { useDisclosure } from "@chakra-ui/react";
import {
    useClientInvitesDelete,
    useClientInvitesGetSent,
} from "../../../../../../../utils/api/hooks";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../../common/hooks/useToast";

type PropTypes = {
    inviteId: number;
};

const useCancelInvitation = (props: PropTypes) => {
    const { inviteId } = props;

    const t = useSmartTranslation();

    const { createToast } = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { refresh: pendingInvitesListRefresh } = useClientInvitesGetSent();

    const { update: deleteInvite } = useClientInvitesDelete({
        onSuccess: () => {
            pendingInvitesListRefresh();
        },
        onError: (error) => {
            createToast({
                description: error.message,
                status: "error",
            });
        },
    });

    const cancelInvitationModal = (
        <ConfirmationModal
            confirmationButtonLabel={t("common.button.confirm")}
            confirmationButtonVariant="negative"
            isOpen={isOpen}
            title={t("account.customerPanelDetails.cancelInvitation.title")}
            blurb={t("account.customerPanelDetails.cancelInvitation.prompt")}
            onClose={onClose}
            onConfirmation={() => {
                deleteInvite(inviteId);
                onClose();
            }}
        />
    );

    return {
        cancelInvitationModal,
        isOpenCancelInvitationModal: isOpen,
        openCancelInvitationModal: onOpen,
    };
};

export default useCancelInvitation;
