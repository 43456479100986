import { VStack } from "@chakra-ui/layout";
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { EnquiryType, OfferOnlyType, UserType } from "../../../../../../types";
import DataTable from "../../../../common/components/data-table/DataTable";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { EnquiryMatchInfoWithValuerAndOffer } from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";
import AssignCompanyValuerToEnquiry from "./AssignCompanyValuerToEnquiry";
import { companyOfferColumns } from "./CompanyOfferColumns";

type Props = {
    offers: OfferOnlyType[];
    usersList: UserType[];
    companyMatchInfoList: EnquiryMatchInfoWithValuerAndOffer[];
    enquiry: EnquiryType;
    user: UserType;
    onUpdate: () => any;
};

const columns = [
    companyOfferColumns.valuerName,
    companyOfferColumns.delivery,
    companyOfferColumns.offerPrice,
    companyOfferColumns.valuerOfferStatus,
];
export const CompanyOffers = (props: Props) => {
    const { offers, usersList, companyMatchInfoList, enquiry, user, onUpdate } =
        props;

    const t = useSmartTranslation();

    const { isOpen: assignOpen, onOpen, onClose } = useDisclosure();

    const setAssignOpen = (open: boolean) => {
        if (open) onOpen();
        else onClose();
    };

    const assignEnquiry = user.role === "ADMIN" && (
        <>
            <Button
                size={"sm"}
                onClick={() => setAssignOpen(true)}
                alignSelf={"end"}
                variant={"outline"}
                colorScheme={"blue"}
            >
                {t("domain.enquiry.ACTIVITIES.assign.NAME")}
            </Button>

            <Drawer
                isOpen={assignOpen}
                size="xl"
                placement="right"
                onClose={() => setAssignOpen(false)}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />

                    <DrawerHeader color="wvwGreen">
                        {t("domain.enquiry.ACTIVITIES.assign.modal.title")}
                    </DrawerHeader>

                    <DrawerBody>
                        <AssignCompanyValuerToEnquiry
                            user={user}
                            offers={offers}
                            usersList={usersList}
                            companyMatchInfoList={companyMatchInfoList}
                            enquiry={enquiry}
                            onComplete={() => setAssignOpen(false)}
                            onUpdate={onUpdate}
                        />
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );

    const userListWithValuationCapabilities = usersList.filter(
        (valuer) =>
            valuer.doesValuations
    );

    // List of team that the enquiry was sent to
    const teamEnquirySentTo = enquiry.toUserIds
        .map((userId) =>
            userListWithValuationCapabilities.find((user) => user.id === userId)
        )
        .filter((user) => user !== undefined);

    // Offers on this Enquiry
    const offersOnThisEnquiry = offers.filter(
        (offer) => offer.enquiryId === enquiry.id
    );

    // List of Company Offers Filtered by latest
    const valuerCompanyOffers = teamEnquirySentTo.map((team) => {
        if (!team) return { user: null, offers: [] };
        const offersByTeam = offersOnThisEnquiry
            .filter((offer) => offer.fromValuerId === team.id)
            .sort(
                (a, b) =>
                    new Date(b.updatedAt).getTime() -
                    new Date(a.updatedAt).getTime()
            );
        const latestOffer = offersByTeam[0];
        return {
            user: team,
            offers: latestOffer ? [latestOffer] : [],
        };
    });

    const columnData = valuerCompanyOffers.map((offerGroup) => ({
        users: offerGroup.user ? [offerGroup.user] : [],
        offers: offerGroup.offers,
        enquiry,
    }));

    return (
        <VStack>
            {assignEnquiry}
            <DataTable
                fullSectionWidth
                data={columnData}
                columns={columns}
                noDataText={t(
                    "screens.platform.valuer.valuerEnquiryDetails.companyOffers.noData"
                )}
            />
        </VStack>
    );
};
