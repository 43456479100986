import {
    Box,
    Button,
    Heading,
    HStack,
    SlideFade,
    Tag,
    TagCloseButton,
    TagLabel,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    EnquiryWithFromUserAndFromEntityType,
    OfferOnlyType,
} from "../../../../../types";
import DataTable, {
    DataTableColumnType,
} from "../../../common/components/data-table/DataTable";
import { Title } from "../../../common/components/display";
import { Breadcrumb } from "../../../common/features/breadcrumbs/Breadcrumb";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { ByPropertyAndClient } from "../../../common/features/filters/domain/enquiry/ByPropertyAndClient";
import {
    EnquiryFilterElement,
    EnquiryFilterEntryType,
} from "./filters/EnquiryFilter";
import { Filters } from "./filters/Filters";

export type EnquiryWithSenderAndOffersType =
    EnquiryWithFromUserAndFromEntityType & {
        offers: OfferOnlyType[];
    };

type EnquiryListProps<T extends EnquiryWithFromUserAndFromEntityType> = {
    enquiries: T[];
    columns: DataTableColumnType<T>[];
    title: string;
    filters: EnquiryFilterElement[];
};

const EnquiryList = <T extends EnquiryWithFromUserAndFromEntityType>(
    props: EnquiryListProps<T>
) => {
    const { enquiries, columns, filters, title: titleString } = props;

    const navigate = useNavigate();

    const [searchFilter, setSearchFilter] =
        useState<EnquiryFilterEntryType | null>(null);

    const [activeFilters, setActiveFilters] = useState<
        EnquiryFilterEntryType[]
    >([]);

    const updateSearchFilter = (filter: EnquiryFilterEntryType) => {
        setSearchFilter(filter);
    };

    const deactivateSearchFilter = () => {
        setSearchFilter(null);
    };

    const updateFilters = (filter: EnquiryFilterEntryType) => {
        setActiveFilters(
            activeFilters
                .filter(({ id: existingId }) => existingId !== filter.id)
                .concat(filter)
        );
    };

    const deactivateFilter = (deactivatedId: string) => {
        setActiveFilters((filters) =>
            filters.filter((filter) => filter.id !== deactivatedId)
        );
    };

    const clearAllFilters = () => {
        setActiveFilters([]);
        setSearchFilter(null);
    };

    // render

    const breadcrumbs = <Breadcrumb.Breadcrumbs />;

    const title = (
        <Title
            title={titleString}
            rightSide={
                <HStack>
                    <Box w={"18rem"}>
                        <ByPropertyAndClient
                            activateFilter={updateSearchFilter}
                            deactivateFilter={deactivateSearchFilter}
                            activeFilters={searchFilter ? [searchFilter] : []}
                        />
                    </Box>
                    <Filters
                        activateFilter={updateFilters}
                        deactivateFilter={deactivateFilter}
                        filters={filters}
                        activeFilters={activeFilters}
                        clearAllFilters={clearAllFilters}
                    />
                </HStack>
            }
        />
    );

    const combinedFilters = activeFilters.concat(
        searchFilter ? [searchFilter] : []
    );

    const filteredEnquiries = enquiries.filter((enquiry) =>
        combinedFilters.every((filter) => filter.filter(enquiry))
    );

    const activeFiltersSection = combinedFilters.length && (
        <ActiveFiltersContainer
            filteredCount={filteredEnquiries.length}
            activeFilters={combinedFilters}
            clearFilter={(id) => {
                if (searchFilter?.id === id) {
                    deactivateSearchFilter();
                } else {
                    deactivateFilter(id);
                }
            }}
            clearAllFilters={clearAllFilters}
        />
    );

    const table = (
        <DataTable
            tableWithRadius
            data={filteredEnquiries}
            columns={columns}
            onRowClick={(enquiry) => {
                navigate(`/dashboard/enquiry/${enquiry.id}`);
            }}
        />
    );

    return (
        <VStack
            w="100%"
            p={4}
            gap={4}
            align={"stretch"}
        >
            {breadcrumbs}
            {title}
            {activeFiltersSection}
            {table}
        </VStack>
    );
};

type ActiveFiltersContainerProps = {
    filteredCount: number;
    activeFilters: EnquiryFilterEntryType[];
    clearFilter: (id: string) => void;
    clearAllFilters: () => void;
};

const ActiveFiltersContainer = (props: ActiveFiltersContainerProps) => {
    const { filteredCount, activeFilters, clearFilter, clearAllFilters } =
        props;

    const t = useSmartTranslation();

    return (
        <SlideFade in={true}>
            <HStack
                spacing={0}
                justify={"space-between"}
            >
                <Heading
                    size={"sm"}
                    color={"blue.700"}
                >
                    {t("screens.platform.enquiryList.filters.results", {
                        count: filteredCount,
                    })}
                </Heading>
                <HStack
                    spacing={2}
                    justifyContent={"end"}
                >
                    {activeFilters.map((filter) => (
                        <ActiveFilter
                            key={filter.id}
                            filter={filter}
                            clearFilter={clearFilter}
                        />
                    ))}

                    <Button
                        variant={"link"}
                        colorScheme={"blue"}
                        size={"xs"}
                        ms={4}
                        fontWeight={"400"}
                        onClick={clearAllFilters}
                    >
                        {t(
                            "screens.platform.enquiryList.filters.ACTIVITIES.clearAll.NAME"
                        )}
                    </Button>
                </HStack>
            </HStack>
        </SlideFade>
    );
};

type ActiveFilterProps = {
    filter: EnquiryFilterEntryType;
    clearFilter: (id: string) => void;
};

const ActiveFilter = (props: ActiveFilterProps) => {
    const {
        filter: { id, displayedData },
        clearFilter,
    } = props;

    return (
        <Tag
            key={id}
            bg={"gray.200"}
            borderRadius={"lg"}
        >
            <Text
                color={"gray.500"}
                fontSize={"xs"}
                me={1}
            >
                {displayedData.filterLabel}
            </Text>
            <TagLabel fontSize={"xs"}>{displayedData.valueLabel}</TagLabel>
            <TagCloseButton
                color={"gray.500"}
                onClick={() => clearFilter(id)}
            />
        </Tag>
    );
};

export { EnquiryList };
