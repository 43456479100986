import { Box, Button, Icon, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import ImageCropModal from "../../../../common/components/ImageCropModal";
import PublicCompanyProfilePicture from "../../../../common/components/PublicCompanyProfilePicture";
import PublicUserProfilePicture from "../../../../common/components/PublicUserProfilePicture";
import readFileToDataUrl from "../../../../common/functions/readFileToDataUrl";
import {
    useMyEntity,
    usePublicEntityProfilePicture,
    usePublicUserProfilePicture,
} from "../../../../utils/api/hooks";
import storage from "../../../../utils/storage/storage";
import useMe from "../../app/useMeV2";
import { useSmartTranslation } from "../hooks/useSmartTranslation";
import useToast from "../hooks/useToast";
import { FileUploadInput } from "../inputs";
import { MISSING_TRANSLATION } from "../MISSING_TRANSLATION";
import LoadingSpinner from "./LoadingSpinner";

type PropsTypes = {
    changeButtonText?: string;
    forEntity?: boolean;
};

const ProfilePictureEditor = (props: PropsTypes) => {
    const { changeButtonText = "common.button.upload", forEntity = false } =
        props;

    const t = useSmartTranslation();

    const { createToast } = useToast();

    const [image, setImage] = useState<string | undefined>(undefined);

    const {
        data: entity,
        isLoading: entityIsLoading,
        entityProfilePictureRefresh,
    } = useMyEntity();

    const {
        data: user,
        isLoading: userIsLoading,
        profilePictureRefresh: userProfilePictureRefresh,
    } = useMe();

    const { refresh: publicEntityprofilePictureRefresh } =
        usePublicEntityProfilePicture(entity.id);

    const { refresh: publicUserprofilePictureRefresh } =
        usePublicUserProfilePicture(Number(user?.id));

    const isLoading = forEntity ? entityIsLoading : userIsLoading;

    if (!user || isLoading) {
        return <LoadingSpinner />;
    }

    const saveImage = async (img: File) => {
        if (!img) return;

        try {
            if (forEntity) {
                await storage.uploadEntityProfilePicture(entity.id, img);
            } else {
                await storage.uploadUserProfilePicture(user.id, img);
            }

            setImage(undefined);

            if (forEntity) {
                entityProfilePictureRefresh();
                publicEntityprofilePictureRefresh();
            } else {
                userProfilePictureRefresh();
                publicUserprofilePictureRefresh();
            }
        } catch {
            createToast({
                description: t("common.toast.error"),
                status: "error",
            });
        }
    };

    // render

    const logo = forEntity ? (
        <PublicCompanyProfilePicture
            companyId={entity?.id}
            size="10rem"
        />
    ) : (
        <PublicUserProfilePicture
            entityId={entity?.id}
            userId={user?.id}
            size="10rem"
        />
    );

    const fileUpload = (
        <FileUploadInput
            onChange={async (files) => {
                const imageDataUrl = (await readFileToDataUrl(
                    files[0]
                )) as string;

                setImage(imageDataUrl);
            }}
            fileTypes={[".jpeg", ".png", ".gif", ".jpg", ".heic", ".heif"]}
            noRename
            customUploadButton={
                <VStack spacing={4}>
                    <Button
                        variant={"outline"}
                        colorScheme={"blue"}
                        pointerEvents="none"
                        leftIcon={
                            <Icon
                                as={MdOutlineFileUpload}
                                boxSize={5}
                            />
                        }
                    >
                        {t(changeButtonText, {
                            defaultValue: MISSING_TRANSLATION,
                        })}
                    </Button>
                    <Text
                        fontSize={"xs"}
                        color={"gray.500"}
                    >
                        {t("account.companyDetails.companyLogoFormats")} (
                        {t("fileUpload.maxFileSize")})
                    </Text>
                </VStack>
            }
        />
    );

    return (
        <>
            <Box
                h={"100%"}
                border={"1px solid"}
                borderColor={"gray.200"}
                borderRadius={"lg"}
            >
                <VStack
                    h={"100%"}
                    spacing={4}
                    py={6}
                    align={"center"}
                    justify={"center"}
                >
                    {logo}
                    {fileUpload}
                </VStack>
            </Box>

            <ImageCropModal
                image={image}
                onImageSave={saveImage}
            />
        </>
    );
};

export default ProfilePictureEditor;
