import { Icon, IconProps } from '@chakra-ui/icons';
import { OmitCommonProps } from '@chakra-ui/react';
import { JSX, SVGProps } from 'react';

type PropTypes = JSX.IntrinsicAttributes & OmitCommonProps<SVGProps<SVGSVGElement>, keyof IconProps> & IconProps & { as?: 'svg' | undefined; };

const WVWLogoWhite = (props: PropTypes) => (
    <Icon
        viewBox="0 0 50 50"
        h="auto"
        // eslint-disable-next-line react/jsx-props-no-spreading
        as={'svg'}
        {...props}
    >
        <svg width="auto" height="auto" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.7693 28.8608H24.9233V23.1058H29.0768V28.8608H33.2309V18.9517L27.0001 14.7983L20.7693 18.9517V28.8608ZM27.0001 43.9054C31.4018 39.9649 34.7703 36.1853 37.1054 32.5665C39.4405 28.9478 40.6081 25.7782 40.6081 23.058C40.6081 18.9562 39.305 15.5843 36.6987 12.942C34.0925 10.2998 30.8596 8.97862 27.0001 8.97862C23.1406 8.97862 19.9077 10.2998 17.3015 12.942C14.6952 15.5843 13.3921 18.9562 13.3921 23.058C13.3921 25.7782 14.5597 28.9478 16.8948 32.5665C19.2299 36.1853 22.5983 39.9649 27.0001 43.9054ZM27.0001 48.3964C21.3376 43.4899 17.0915 38.9237 14.2617 34.6978C11.432 30.4716 10.0171 26.5916 10.0171 23.058C10.0171 17.8657 11.6965 13.6622 15.0554 10.4473C18.4147 7.23244 22.3962 5.625 27.0001 5.625C31.604 5.625 35.5855 7.23244 38.9448 10.4473C42.3036 13.6622 43.9831 17.8657 43.9831 23.058C43.9831 26.5916 42.5682 30.4716 39.7385 34.6978C36.9087 38.9237 32.6626 43.4899 27.0001 48.3964Z" fill="#A0AEC0" />
        </svg>

    </Icon>
);

export default WVWLogoWhite;
