import { Box, Flex, Text } from "@chakra-ui/react";

type InformationRowItemProps = {
    label: string;
    content?: React.ReactNode | React.ReactNode[];
    customContent?: React.ReactNode | React.ReactNode[];
};

const InformationRowItem = (props: InformationRowItemProps) => {
    const { label, content: baseContent, customContent } = props;

    const content = baseContent ? (
        <InformationRowItemBox content={baseContent} />
    ) : (
        customContent
    );

    return (
        <Flex>
            <Text
                maxW={"17rem"}
                w={"100%"}
                fontSize={"sm"}
                color={"gray.500"}
                pt={2}
            >
                {label}
            </Text>

            {content}
        </Flex>
    );
};

type InformationRowItemBoxProps = {
    content: React.ReactNode | React.ReactNode[];
};

const InformationRowItemBox = (props: InformationRowItemBoxProps) => {
    const { content } = props;

    return (
        <Box
            borderRadius={"lg"}
            p={".75rem 1rem"}
            bg={"gray.50"}
            width={"100%"}
            fontSize={"sm"}
            fontWeight={"700"}
        >
            {content}
        </Box>
    );
};

export { InformationRowItem, InformationRowItemBox };
