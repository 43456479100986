import { Heading, VStack } from "@chakra-ui/react";
import { clamp } from "lodash";
import { useEffect, useState } from "react";
import {
    EnquiryType,
    OfferOnlyType,
    PropertyType,
    UserType
} from "../../../../../../types";
import { DeviceBreakpoints } from "../../../../common/hooks/useDevice";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Property } from "../../../../domain";
import { EnquiryMatchInfo, EnquiryMatchInfoWithValuerAndOffer } from "../../../../domain/enquiry/enquiryRequirements/EnquiryMatchInfo";
import { EnquiryRequirements } from "../../../../domain/enquiry/enquiryRequirements/EnquiryRequirements";
import { Section } from "../../../../layout";
import { CompanyOffers } from "./CompanyOffers";

const translationNS = "screens.platform.valuer.valuerEnquiryDetails";

type Props = {
    enquiry: EnquiryType;
    matchInfo: EnquiryMatchInfo;
    device: DeviceBreakpoints | undefined;
    companyMatchInfoList: EnquiryMatchInfoWithValuerAndOffer[];
    myOffer: React.ReactNode;
    offers: OfferOnlyType[];
    usersList: UserType[];
    user: UserType;
    piCoverWarning: React.ReactNode | undefined;
    onUpdate: () => any
};

const MultiPropertyLayout = (props: Props) => {
    const {
        enquiry: { properties },
        enquiry,
        matchInfo,
        companyMatchInfoList,
        device,
        myOffer,
        offers,
        usersList,
        user,
        piCoverWarning,
        onUpdate,
    } = props;

    const t = useSmartTranslation();

    const smallerDevices = device === "mobile" || device === "tablet";

    const [selectedProperty, setSelectedProperty] = useState<
        PropertyType | undefined
    >(!smallerDevices ? properties[0] : undefined);

    const selectedPropertyIndex = properties.findIndex(
        (i) => i.id === selectedProperty?.id
    );

    const requestedValuation = (
        <Section
            collapsable
            title={t("domain.enquiry.requestedValuationTitle")}
            content={
                <EnquiryRequirements
                    enquiry={enquiry}
                    matchInfo={matchInfo}
                />
            }
        />
    );

    const propertyPortfolio = (
        <Section
            collapsable
            title={t("domain.property.propertyPortfolio.title")}
            content={
                <VStack
                    w="100%"
                    spacing="4"
                >
                    <Property.PropertyPortfolioSummary
                        properties={properties}
                    />
                    <Property.PortfolioTable
                        properties={properties}
                        selectedProperty={selectedProperty}
                        onRowClick={(item) => {
                            setSelectedProperty(item);
                        }}
                    />
                </VStack>
            }
        />
    );

    const map = (
        <Section
            content={
                <Property.PropertiesMap
                    properties={properties}
                    selectedProperty={selectedProperty}
                />
            }
        />
    );

    const propertyDetails = selectedProperty && (
        <Section
            collapsable
            title={
                smallerDevices
                    ? t(`domain.property.propertyDetails.title`)
                    : `${selectedProperty.street}, ${selectedProperty.postalCode} ${selectedProperty.city}`
            }
            content={
                <>
                    {smallerDevices && (
                        <>
                            <Property.PropertiesMap
                                properties={properties}
                                selectedProperty={selectedProperty}
                            />
                            <Heading
                                size="md"
                                my=".5em"
                            >
                                {`${selectedProperty.street}, ${selectedProperty.postalCode} ${selectedProperty.city}`}
                            </Heading>
                        </>
                    )}

                    <Property.PropertyDetails property={selectedProperty} />
                </>
            }
        />
    );

    const companyOffers = (
        <Section
            collapsable
            title={t(`${translationNS}.companyOffers.title`)}
            content={
                <CompanyOffers
                    user={user}
                    enquiry={enquiry}
                    usersList={usersList}
                    companyMatchInfoList={companyMatchInfoList}
                    offers={offers}
                    onUpdate={onUpdate}
                />
            }
        />
    );

    useEffect(() => {
        if (smallerDevices) return;
        if (selectedProperty !== undefined) return;

        setSelectedProperty(properties[0]);
    }, [device]);

    return (
        <>
            <VStack>
                {piCoverWarning}
                {requestedValuation}
                {propertyPortfolio}
            </VStack>

            {!smallerDevices && (
                <VStack>
                    {map}
                    {propertyDetails}
                </VStack>
            )}

            {smallerDevices && (
                <Property.PropertyDrawer
                    properties={properties}
                    selectedProperty={selectedProperty}
                    onClose={() => setSelectedProperty(undefined)}
                    onNextProperty={() => {
                        const nextIndex = clamp(
                            selectedPropertyIndex + 1,
                            0,
                            properties.length - 1
                        );

                        setSelectedProperty(properties[nextIndex]);
                    }}
                    onPreviousProperty={() => {
                        const nextIndex = clamp(
                            selectedPropertyIndex - 1,
                            0,
                            properties.length - 1
                        );

                        setSelectedProperty(properties[nextIndex]);
                    }}
                />
            )}

            <VStack>
                {companyOffers}
                {myOffer}
            </VStack>
        </>
    );
};

export default MultiPropertyLayout;
