import { ChevronDownIcon } from "@chakra-ui/icons";
import {
    Button,
    Link as ChakraLink,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { BsMoon, BsPlay, BsTrash } from "react-icons/bs";
import { Link as ReactLink } from "react-router-dom";
import { EnquiryType, OfferOnlyType } from "../../types";
import { DataTableColumnType } from "../components/datatable/DataTable";
import { StatusBadge } from "../components/display";
import StatusBadgeEnquiry from "../components/display/StatusBadgeEnquiry";
import delimitNumber from "../functions/delimitNumber";
import { displayDateWithCurrentLocale } from "../functions/displayDateInLocale";
import useRole from "./useRole";
import useSmartTranslation from "./useSmartTranslation";

type ColumnType = DataTableColumnType<EnquiryType>;

type ConfigType = {
    setDormant?: (data: { enquiryId: number; dormant: boolean }) => void;
    cancelEnquiry?: (enquiryId: number) => void;
    reactivate?: (enquiryId: number) => void;
};

const useEnquiryColumns = (offerList: OfferOnlyType[], config: ConfigType) => {
    const { setDormant, cancelEnquiry, reactivate } = config;

    const t = useSmartTranslation("dashboard");
    const { userIsClient, userIsValuer } = useRole();

    const columnProperty: ColumnType = {
        title: t("enquiriesTable.header.property", { ns: "dashboard" }),
        maxWidth: "15rem",
        sorter: (a, b) => {
            const aProperty =
                a?.properties?.[0]?.street ||
                t("property_wc", { count: a.properties.length, ns: "common" });
            const bProperty =
                b?.properties?.[0]?.street ||
                t("property_wc", { count: b.properties.length, ns: "common" });

            return aProperty.localeCompare(bProperty);
        },
        render: (i) => {
            const { properties } = i;

            if (properties.length === 1) {
                return properties[0].street;
            }

            return t("property_wc", { count: properties.length, ns: "common" });
        },
    };

    const columnEstValue: ColumnType = {
        title: t("enquiriesTable.header.estimateValue"),
        sorter: (a, b) => {
            const { properties: aProperties = [] } = a;
            const { properties: bProperties = [] } = b;

            const aEstimateValue = aProperties.reduce(
                (acc, property) => acc + property.estimateValue,
                0
            );

            const bEstimateValue = bProperties.reduce(
                (acc, property) => acc + property.estimateValue,
                0
            );

            return aEstimateValue - bEstimateValue;
        },
        render: (i) => {
            const { properties = [] } = i;

            const estimateValue = properties.reduce(
                (acc, property) => acc + property.estimateValue,
                0
            );

            return (
                <Text pr={userIsClient ? "3.8rem" : "2rem"}>
                    {`${t("unitSymbol.currency", {
                        ns: "common",
                    })} ${delimitNumber(estimateValue)}`}
                </Text>
            );
        },
    };

    const columnCurrentPrice: ColumnType = {
        title: userIsValuer
            ? t("enquiriesTable.header.offeredPrice")
            : t("enquiriesTable.header.currentPrice"),
        sorter: (a, b) => {
            const aOffers = offerList.filter(
                (offer) => offer.enquiryId === a.id
            );
            const bOffer = offerList.filter(
                (offer) => offer.enquiryId === b.id
            );

            const aLowestOffer = Math.min(
                ...aOffers.map((offer) => offer.price)
            );
            const bLowestOffer = Math.min(
                ...bOffer.map((offer) => offer.price)
            );

            return aLowestOffer - bLowestOffer;
        },
        render: (i) => {
            const offers = offerList.filter(
                (offer) => offer.enquiryId === i.id
            );

            const lowestOffer = Math.min(...offers.map((offer) => offer.price));

            return (
                <Text pr={userIsClient ? "2.4rem" : "1rem"}>
                    {lowestOffer === Infinity
                        ? t("enquiriesTable.cellValue.awaitingOffers")
                        : `${t("unitSymbol.currency", {
                              ns: "common",
                          })} ${delimitNumber(lowestOffer)}`}
                </Text>
            );
        },
    };

    const columnEnquiryStatus: ColumnType = {
        title: t("enquiriesTable.header.status"),
        alignment: "center",
        maxWidth: "10rem",
        render: (i) => {
            const offerExists = offerList.some(
                (offer) => offer.enquiryId === i.id
            );
            const numberOfOffers = offerList.filter(
                (offer) => offer.enquiryId === i.id
            ).length;
            const isPending = i.status === "PENDING";
            const rejectedOffer = offerList.find(
                (offer) =>
                    offer.enquiryId === i.id && offer.status === "REJECTED"
            );

            if (rejectedOffer && !userIsClient) {
                return <StatusBadgeEnquiry status="AWAITING_YOUR_OFFER" />;
            }

            if (!offerExists && i.status !== "CANCELLED") {
                const status = userIsClient
                    ? "AWAITING_OFFERS"
                    : "AWAITING_YOUR_OFFER";
                return <StatusBadgeEnquiry status={status} />;
            }

            if (isPending && numberOfOffers !== 0) {
                const numberOfOffersText = `(${numberOfOffers}) ${
                    numberOfOffers === 1 ? t("offerUpper") : t("offersUpper")
                }`;
                const awaitingAcceptanceText = t("status.AWAITING_ACCEPTANCE", {
                    ns: "offer",
                });
                const status = userIsClient
                    ? (numberOfOffersText as "AWAITING_OFFERS")
                    : (awaitingAcceptanceText as "AWAITING_ACCEPTANCE");
                return (
                    <StatusBadge
                        color="wvwYellow10"
                        status={status}
                        textColor="wvwYellow"
                    />
                );
            }

            return <StatusBadgeEnquiry status={i.status} />;
        },
        sorter: (a, b) => {
            const aOfferExists = offerList.some(
                (offer) => offer.enquiryId === a.id
            );
            const bOfferExists = offerList.some(
                (offer) => offer.enquiryId === b.id
            );

            const aNumberOfOffers = offerList.filter(
                (offer) => offer.enquiryId === a.id
            ).length;
            const bNumberOfOffers = offerList.filter(
                (offer) => offer.enquiryId === b.id
            ).length;

            const aIsPending = a.status === "PENDING";
            const bIsPending = b.status === "PENDING";

            if (!aOfferExists && !bOfferExists) {
                return 0;
            }

            if (!aOfferExists) {
                return -1;
            }

            if (!bOfferExists) {
                return 1;
            }

            if (aIsPending && bIsPending) {
                return bNumberOfOffers - aNumberOfOffers;
            }

            if (aIsPending) {
                return -1;
            }

            if (bIsPending) {
                return 1;
            }

            return a.status.localeCompare(b.status);
        },
    };

    const columnDueDate: ColumnType = {
        title: t("enquiriesTable.header.dueDate"),
        dataIndex: "completionDate",
        maxWidth: "10rem",
        render: (i) => displayDateWithCurrentLocale(i.completionDate),
        sorter: (a, b) => {
            if (
                b.completionDate.replace(/-/g, "") <
                a.completionDate.replace(/-/g, "")
            ) {
                return -1;
            }
            if (
                b.completionDate.replace(/-/g, "") >
                a.completionDate.replace(/-/g, "")
            ) {
                return 1;
            }
            return 0;
        },
    };

    const columnSubmittedDate: ColumnType = {
        title: t("enquiriesTable.header.submittedDate"),
        maxWidth: "10rem",
        render: (i) => displayDateWithCurrentLocale(i.submittedAt || ""),
        sorter: (a, b) => {
            const dateA = new Date(a.submittedAt ?? "");
            const dateB = new Date(b.submittedAt ?? "");
            return dateB.getTime() - dateA.getTime();
        },
    };

    const columnActions: ColumnType = {
        title: t("enquiriesTable.header.action"),
        maxWidth: "10rem",
        render: (enquiry) => {
            const { status } = enquiry;

            const cancelButton = cancelEnquiry && (
                <MenuItem
                    icon={<BsTrash size="1.2rem" />}
                    width="100%"
                    color="wvwGreen"
                    onClick={() => cancelEnquiry(enquiry.id)}
                >
                    {t("button.cancel", { ns: "common" })}
                </MenuItem>
            );

            const reactivateButton = reactivate && (
                <MenuItem
                    icon={<BsPlay size="1.2rem" />}
                    width="100%"
                    color="wvwGreen"
                    onClick={() => reactivate(enquiry.id)}
                >
                    {t("button.reactivate", {
                        ns: "common",
                    })}
                </MenuItem>
            );

            const showCancel = userIsClient && status === "PENDING";
            const showReactivate = userIsClient && status === "CANCELLED";

            let setDormantDisabled = true;

            if (userIsClient) {
                if (status !== "PENDING") {
                    setDormantDisabled = false;
                }
            }

            if (userIsValuer) {
                const valuerOffer = offerList.find(
                    (offer) => offer.enquiryId === enquiry.id
                );

                if (!valuerOffer || valuerOffer.status !== "PENDING") {
                    setDormantDisabled = false;
                }
            }

            const setDormantButton = setDormant && (
                <MenuItem
                    icon={<BsMoon size="1.2rem" />}
                    isDisabled={setDormantDisabled}
                    width="100%"
                    color="wvwGreen"
                    onClick={() =>
                        setDormant({
                            enquiryId: enquiry.id,
                            dormant: true,
                        })
                    }
                >
                    {t("enquiriesTable.button.makeDormant")}
                </MenuItem>
            );

            return (
                <VStack
                    w="100%"
                    align="center"
                >
                    <HStack spacing="1">
                        <Menu autoSelect={false}>
                            <MenuButton
                                as={IconButton}
                                borderRadius="30"
                                icon={<ChevronDownIcon />}
                                size="sm"
                                variant="primary"
                            />

                            <MenuList>
                                {showCancel && cancelButton}

                                {showReactivate && reactivateButton}

                                {setDormantButton}
                            </MenuList>
                        </Menu>

                        <Spacer />

                        <ChakraLink
                            as={ReactLink}
                            to={`/dashboard/enquiry/${enquiry.id}/overview`}
                        >
                            <Button
                                borderRadius="30"
                                size="sm"
                                variant="primaryYellow"
                            >
                                {t("enquiriesTable.button.view")}
                            </Button>
                        </ChakraLink>
                    </HStack>
                </VStack>
            );
        },
    };

    const enquiryColumns = {
        columnProperty,
        columnEstValue,
        columnCurrentPrice,
        columnEnquiryStatus,
        columnDueDate,
        columnSubmittedDate,
        columnActions,
    };

    return enquiryColumns;
};

export default useEnquiryColumns;
