import { Box, Checkbox, FormLabel, SimpleGrid, Text } from "@chakra-ui/react";
import { FieldInputProps, useField, useFormikContext } from "formik";
import { useSmartTranslation } from "../hooks/useSmartTranslation";

type PropTypes = {
    name: string;
    options: {
        label: string;
        subtext?: string;
        value: string;
    }[];
    onChange?: (field?: FieldInputProps<any>, value?: string[]) => void;
};

const FormikCloudSelect = (props: PropTypes) => {
    const t = useSmartTranslation();

    const { name, options, onChange = () => {} } = props;

    const { values } = useFormikContext<{ [index: string]: any }>();

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const fieldValues = values[name] || [];

    return (
        <Box w="100%">
            <SimpleGrid
                {...field}
                minChildWidth="13rem"
                spacing="2"
            >
                {options.map(({ label, value, subtext }) => {
                    return (
                        <FormLabel
                            key={value}
                            width="100%"
                            display={"flex"}
                            flexFlow={"column"}
                            border={"1px solid"}
                            borderColor={
                                fieldValues.includes(value)
                                    ? "gray.100"
                                    : "gray.200"
                            }
                            bg={
                                fieldValues.includes(value)
                                    ? "gray.100"
                                    : "white"
                            }
                            borderRadius={"lg"}
                            gap={1}
                            p={".75rem 1rem"}
                            m={0}
                            cursor={"pointer"}
                            htmlFor={value}
                        >
                            <Checkbox
                                id={value}
                                size={"lg"}
                                fontWeight={"bold"}
                                color={"gray.900"}
                                lineHeight={"1.3"}
                                alignItems={"start"}
                                isChecked={fieldValues.includes(value)}
                                value={value}
                                onChange={({ target }) => {
                                    let newFieldValues = [...fieldValues];

                                    if (target.checked) {
                                        newFieldValues.push(value);
                                    } else {
                                        newFieldValues = [
                                            ...values[name].filter(
                                                (i: string) => i !== value
                                            ),
                                        ];
                                    }

                                    field.onChange({
                                        target: { name, value: newFieldValues },
                                    });

                                    onChange?.(field, newFieldValues);
                                }}
                            >
                                {label}
                            </Checkbox>

                            {subtext && (
                                <Text
                                    fontSize={"xs"}
                                    color={"gray.500"}
                                    lineHeight={4}
                                    pl={7}
                                >
                                    {subtext}
                                </Text>
                            )}
                        </FormLabel>
                    );
                })}
            </SimpleGrid>

            {meta.touched && meta.error && (
                <Text
                    color="red"
                    fontSize={"sm"}
                >
                    {t(meta.error, { defaultValue: meta.error })}
                </Text>
            )}
        </Box>
    );
};

export default FormikCloudSelect;
