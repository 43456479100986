import {
    StandardSystemStandardsOfferedWithOther,
    standardSystemStandardsOfferedWithOtherAndNone,
} from "../../../../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";
import { useSmartTranslation } from "../../../../hooks/useSmartTranslation";
import {
    EnquiryFilterFunctionType,
    EnquiryFilterPropsType,
} from "../../../../../screens/platform/enquiry-list/filters/EnquiryFilter";
import { FilterOptionType, FilterSelect } from "../../FilterSelect";

const FILTERGROUP_ID = "BY_STANDARD";

const ByStandard = (props: EnquiryFilterPropsType) => {
    const {
        activateFilter: activateFilterGroup,
        deactivateFilter,
        activeFilters,
    } = props;

    const t = useSmartTranslation();

    const activateFilter = (
        value: StandardSystemStandardsOfferedWithOther,
        filter: EnquiryFilterFunctionType
    ): void => {
        activateFilterGroup({
            id: FILTERGROUP_ID,
            filter,
            value,
            displayedData: {
                filterLabel: t(
                    "screens.platform.enquiryList.filters.byStandard.label"
                ),
                valueLabel: t(`domain.standards.systemStandard.${value}`),
            },
        });
    };

    const activeFilterValue = activeFilters.find(
        ({ id }) => id === FILTERGROUP_ID
    )?.value;

    const filteredStandards =
        standardSystemStandardsOfferedWithOtherAndNone.filter(
            (standard) => standard !== ""
        );

    const options: FilterOptionType[] = [
        {
            id: "allStandards",
            label: t(
                "screens.platform.enquiryList.filters.byStandard.options.allStandards"
            ),
            onSelect: () => deactivateFilter(FILTERGROUP_ID),
        },
        ...filteredStandards.map((standard) => ({
            id: standard,
            label: t(`domain.standards.systemStandard.${standard}`),
            onSelect: () =>
                activateFilter(
                    standard,
                    ({ valuationStandards }) => valuationStandards === standard
                ),
        })),
    ];

    return (
        <FilterSelect
            label={t("screens.platform.enquiryList.filters.byStandard.label")}
            filterOptions={options}
            value={activeFilterValue}
        />
    );
};

export { ByStandard };
