import { useRole } from "../../../../../../common/hooks";
import CustomersScreen from "./customers/CustomersScreen";
import ValuersScreen from "./valuers/ValuersScreen";

const ValuerPanelScreen = () => {
    const { userIsValuer } = useRole();

    if (userIsValuer) return <CustomersScreen />;

    return <ValuersScreen />;
};

export default ValuerPanelScreen;
