import { Box, Divider, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useFormikContext } from "formik";
import {
    FormikCloudSelect,
    FormikConditionalSection,
    FormikListInput,
} from "../../../../common/forms";
import { useSmartTranslation } from "../../../../common/hooks";
import { standardSystemStandardsOffered } from "../../../../common/vars/valuationsAndMembershipTypes";
import FormSectionProfileEdit from "./FormSectionProfileEdit";
import { useMe } from "../../../../utils/api/hooks";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { WvwText, WVWTitle } from "../../../../common/components/typography";
import UploadCertificate from "../../../../common/components/UploadCertificate";
import storage from "../../../../utils/storage/storage";
import { wvwPalette } from "../../../../theme";
import { StandardSystemStandardsOffered } from "../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";
import { systemStandardTooltips } from "../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";

const STANDARDS_OFFERED = [...standardSystemStandardsOffered, "Other"];

const ProfileValuationStandardsEdit = () => {
    const t = useSmartTranslation("profile");

    const { values } = useFormikContext<{
        systemStandardsOffered?: StandardSystemStandardsOffered[];
        otherStandardsOffered?: string[];
    }>();

    const isSystemStandardsMissing =
        !values.systemStandardsOffered ||
        values.systemStandardsOffered.length === 0;

    const [loadingUploads, setLoadingUploads] = useState<{
        [index: string]: boolean;
    }>({});

    const {
        data: user,
        isLoading: userLoading,
        myCertificates = [],
        myCertificatesIsLoading,
        myCertificatesRefresh,
    } = useMe();

    if (!user || userLoading || myCertificatesIsLoading)
        return <LoadingSpinner />;

    const { id: userId } = user;

    const {
        systemStandardsOffered: formDataSystemStandardsOffered = [],
        otherStandardsOffered = [],
    } = values as any;

    const systemStandardsOffered = formDataSystemStandardsOffered.filter(
        (i: any) => standardSystemStandardsOffered.includes(i)
    );

    const selectedMemberships = [
        ...otherStandardsOffered,
        ...systemStandardsOffered,
    ];

    return (
        <FormSectionProfileEdit
            header={t("heading.personalQualifications")}
            warningIcon={isSystemStandardsMissing}
            subHeader={t("subHeading.membershipsAndQualifications")}
        >
            <FormikCloudSelect
                name="systemStandardsOffered"
                options={STANDARDS_OFFERED.map((membership) => ({
                    label: t(membership, {
                        ns: "formik",
                        defaultValue: membership,
                    }),
                    value: membership,
                    tooltip: systemStandardTooltips[membership],
                }))}
            />

            <FormikConditionalSection
                formDataTarget="systemStandardsOffered"
                condition={(value) => value.includes("Other")}
            >
                <VStack
                    align="start"
                    backgroundColor={wvwPalette.wvwBackground.main}
                    borderRadius="8"
                    padding="1rem"
                    spacing="4"
                    w="100%"
                >
                    <Box>
                        <WVWTitle
                            level="3"
                            color="black"
                            content={t("heading.otherValuationStandards")}
                        />

                        <WvwText>
                            {t("subHeading.otherValuationStandards")}
                        </WvwText>
                    </Box>

                    <FormikListInput
                        name="otherStandardsOffered"
                        placeholder={t(
                            "membershipsAndQualifications.edit.addValuationStandard"
                        )}
                    />
                </VStack>
            </FormikConditionalSection>

            <Divider />

            {selectedMemberships.map((membership) => {
                const files = myCertificates
                    .filter(
                        (i) => i.type.toLowerCase() === membership.toLowerCase()
                    )
                    .map((i) => ({
                        name: i.label,
                        fileUrl: i.file.fullPath,
                    }));

                const isStandardMembership = (
                    standardSystemStandardsOffered as readonly string[]
                ).includes(membership);

                const certificatesTranslation = t(
                    "membershipsAndQualifications.certificate",
                    { count: 2 }
                );
                const certificateTranslation = t(
                    "membershipsAndQualifications.certificate",
                    { count: 1 }
                );

                const membershipName = isStandardMembership
                    ? t(membership, { ns: "formik", defaultValue: membership })
                    : membership;

                const title = `${membershipName} ${certificatesTranslation}`;
                const defaultFileName =
                    files.length === 0
                        ? `${membershipName} ${certificateTranslation}`
                        : undefined;

                return (
                    <VStack
                        key={membership}
                        align="start"
                        spacing="4"
                        w="100%"
                    >
                        <WVWTitle
                            level="2"
                            color="black"
                            content={title}
                        />

                        <UploadCertificate
                            defaultFileName={defaultFileName}
                            files={files}
                            loading={loadingUploads[membership]}
                            onFileDelete={(fileUrl) => {
                                storage
                                    .deleteUserFile(fileUrl)
                                    .then(myCertificatesRefresh);
                            }}
                            onRename={(fileUrl, label) => {
                                storage
                                    .updateFileMetadata(fileUrl, label)
                                    .then(myCertificatesRefresh);
                            }}
                            onUpload={(fileList) => {
                                setLoadingUploads({
                                    ...loadingUploads,
                                    [membership]: true,
                                });

                                fileList.forEach((file) => {
                                    storage
                                        .uploadUserCertificate(
                                            userId,
                                            file,
                                            membership
                                        )
                                        .then(myCertificatesRefresh)
                                        .finally(() => {
                                            setLoadingUploads({
                                                ...loadingUploads,
                                                [membership]: false,
                                            });
                                        });
                                });
                            }}
                        />
                    </VStack>
                );
            })}
        </FormSectionProfileEdit>
    );
};

export default ProfileValuationStandardsEdit;
