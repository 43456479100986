import {
    Box,
    Flex,
    Heading,
    HStack,
    IconButton,
    Spacer,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import standardSystemStandardsOffered, {
    StandardSystemStandardsOfferedWithOther,
    systemStandardTooltips,
} from "../../../../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";
import storage, {
    FirebaseCertificateType,
} from "../../../../../../../utils/storage/storage";
import useMe from "../../../../../app/useMeV2";
import {
    filesizeToString,
    getFileTypeBasedOnName,
} from "../../../../../common/components/display/File";
import LoadingSpinner from "../../../../../common/components/LoadingSpinner";
import { Forms } from "../../../../../common/forms/Forms";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../../common/hooks/useToast";
import { FileTypeIcon } from "../../../../../common/icons/FileTypeIcon";
import { FileUploadInput } from "../../../../../common/inputs";
import { MISSING_TRANSLATION } from "../../../../../common/MISSING_TRANSLATION";
import { documentColumns } from "../../../../../domain/document/columns/DocumentColumns";
import { useDocumentViewerModal } from "../../../../../domain/document/useDocumentViewerModal";
import useCertificateRemove from "../../../../../domain/user/useCertificateRemove";
import { Section } from "../../../../../layout";

const STANDARDS_OFFERED = [...standardSystemStandardsOffered, "Other"];

const ProfileValuationStandardsEdit = () => {
    const t = useSmartTranslation();

    const { createToast } = useToast();

    const { documentView, viewDocument } = useDocumentViewerModal();

    const {
        values: {
            systemStandardsOffered: formDataSystemStandardsOffered = [],
            otherStandardsOffered = [],
        },
    } = useFormikContext<{
        systemStandardsOffered?: StandardSystemStandardsOfferedWithOther[];
        otherStandardsOffered?: string[];
    }>();

    const {
        data: user,
        isLoading: userLoading,
        myCertificates = [],
        myCertificatesIsLoading,
        myCertificatesRefresh,
    } = useMe();

    const {
        removeCertificateModal,
        openRemoveCertificateModal,
        setRemovedCertificate,
    } = useCertificateRemove();

    const [loadingUploads, setLoadingUploads] = useState<{
        [index: string]: boolean;
    }>({});

    if (!user || userLoading || myCertificatesIsLoading)
        return <LoadingSpinner />;

    const { id: userId } = user;

    const systemStandardsOffered = formDataSystemStandardsOffered.filter(
        (i: any) => standardSystemStandardsOffered.includes(i)
    );

    const selectedMemberships = [
        ...otherStandardsOffered,
        ...systemStandardsOffered,
    ];

    const uploadCertificate = async (
        files: File[],
        standard: StandardSystemStandardsOfferedWithOther | string
    ) => {
        if (!files.length) return;

        try {
            setLoadingUploads({
                ...loadingUploads,
                [standard]: true,
            });

            await Promise.all(
                files.map((file) =>
                    storage
                        .uploadUserCertificate(userId, file, standard)
                        .finally(() => {
                            setLoadingUploads({
                                ...loadingUploads,
                                [standard]: false,
                            });
                        })
                )
            );

            myCertificatesRefresh();
        } catch {
            createToast({
                description: t("common.toast.error"),
                status: "error",
            });
        }
    };

    // render

    const standardsEdit = (
        <Forms.FormikCloudSelect
            name="systemStandardsOffered"
            options={STANDARDS_OFFERED.map((membership) => ({
                label: t(`domain.standards.systemStandard.${membership}`, {
                    defaultValue: MISSING_TRANSLATION,
                }),
                value: membership,
                subtext: systemStandardTooltips[membership],
            }))}
        />
    );

    const otherStandardsEdit = (
        <Forms.FormikConditionalSection
            condition={(value) =>
                value.systemStandardsOffered.includes("Other")
            }
        >
            <VStack
                align={"start"}
                bg={"gray.50"}
                borderRadius="lg"
                padding={4}
                spacing={4}
                mt={2}
            >
                <Box>
                    <Heading
                        size={"sm"}
                        color={"blue.700"}
                    >
                        {t("profile.heading.otherValuationStandards")}
                    </Heading>

                    <Text
                        fontSize={"sm"}
                        color={"gray.500"}
                    >
                        {t("profile.subHeading.otherValuationStandards")}
                    </Text>
                </Box>

                <Forms.FormikListInput
                    name="otherStandardsOffered"
                    width="15rem"
                    placeholder={t(
                        "profile.membershipsAndQualifications.edit.addValuationStandard"
                    )}
                />
            </VStack>
        </Forms.FormikConditionalSection>
    );

    const certificates = (
        <Section
            title={t("profile.membershipsAndQualifications.certificate_other")}
            subtitle={t(
                "profile.membershipsAndQualifications.otherCertificatesDescription"
            )}
            content={
                <>
                    {selectedMemberships.map((membership) => (
                        <CertificateWrapper
                            key={membership}
                            loading={loadingUploads[membership]}
                            standard={membership}
                            certificates={myCertificates}
                            onUpload={(files) =>
                                uploadCertificate(files, membership)
                            }
                            onDelete={(file) => {
                                setRemovedCertificate(file);
                                openRemoveCertificateModal();
                            }}
                            viewDocument={(file) => {
                                viewDocument({
                                    uri: file.url,
                                    fileType: getFileTypeBasedOnName(
                                        file.label
                                    ),
                                    label: file.label,
                                });
                            }}
                        />
                    ))}
                    {removeCertificateModal}
                    {documentView}
                </>
            }
        />
    );

    return (
        <>
            <Section
                title={t("profile.heading.personalQualifications")}
                subtitle={t("profile.subHeading.membershipsAndQualifications")}
                content={
                    <>
                        {standardsEdit}
                        {otherStandardsEdit}
                    </>
                }
            />

            {certificates}
        </>
    );
};

type CertificateWrapperProps = {
    standard: StandardSystemStandardsOfferedWithOther | string;
    loading: boolean;
    certificates: FirebaseCertificateType[];
    onUpload: (files: File[]) => void;
    onDelete: (file: FirebaseCertificateType) => void;
    viewDocument: (file: FirebaseCertificateType) => void;
};

const CertificateWrapper = (props: CertificateWrapperProps) => {
    const {
        standard,
        loading,
        certificates,
        onUpload,
        onDelete,
        viewDocument,
    } = props;

    const t = useSmartTranslation();

    const files = certificates.filter(
        (i) => i.type.toLowerCase() === standard.toLowerCase()
    );

    const isStandardMembership = (
        standardSystemStandardsOffered as readonly string[]
    ).includes(standard);

    const certificatesTranslation = t(
        "profile.membershipsAndQualifications.certificate",
        { count: 2 }
    );

    const membershipName = isStandardMembership
        ? t(`domain.standards.systemStandard.${standard}`, {
              defaultValue: MISSING_TRANSLATION,
          })
        : standard;

    const title = `${membershipName} ${certificatesTranslation}`;

    // render

    const uploader = (
        <VStack
            align={"stretch"}
            flex={"1 0 0%"}
        >
            <VStack spacing={4}>
                <FileUploadInput
                    fileTypes={[".pdf"]}
                    height="200px"
                    onChange={onUpload}
                />

                {loading}
            </VStack>
        </VStack>
    );

    const filesList = files.length > 0 && (
        <VStack
            align={"stretch"}
            flex={"1 0 0%"}
        >
            {files.map((file, idx) => (
                <FileItem
                    key={idx}
                    file={file}
                    idx={idx}
                    onDelete={() => onDelete(file)}
                    viewDocument={() => viewDocument(file)}
                />
            ))}
        </VStack>
    );

    return (
        <VStack
            align="stretch"
            spacing="4"
            mb={6}
        >
            <Heading
                size={"md"}
                variant="blue"
            >
                {title}
            </Heading>

            <HStack
                align={"stretch"}
                spacing={4}
            >
                {uploader}
                {filesList}
            </HStack>
        </VStack>
    );
};

type FileItemProps = {
    file: FirebaseCertificateType;
    idx: number;
    viewDocument: () => void;
    onDelete: () => void;
};

const FileItem = (props: FileItemProps) => {
    const { file, idx, viewDocument, onDelete } = props;

    const t = useSmartTranslation();

    return (
        <Box
            padding=".5rem 1rem"
            bg={"gray.50"}
            transition={".25s"}
            color="gray.900"
            borderRadius={"lg"}
            _hover={{
                bg: "blue.50",
                color: "blue.500",
            }}
        >
            <Flex
                w={"100%"}
                gap={3}
                align={"center"}
            >
                <FileTypeIcon
                    filetype={getFileTypeBasedOnName(file.label)}
                    size={6}
                />
                <Text
                    fontSize={"sm"}
                    cursor={"pointer"}
                    onClick={viewDocument}
                >
                    {file.label}
                </Text>
                <Spacer />
                <Text
                    fontSize={"sm"}
                    variant={"secondary"}
                >
                    {filesizeToString(file.meta.size)}
                </Text>

                <HStack spacing={0}>
                    {documentColumns.edit.render!(file, t, idx)}
                    <IconButton
                        icon={<RiDeleteBin6Line />}
                        size="sm"
                        variant="ghost"
                        color="gray.400"
                        aria-label="remove"
                        onClick={onDelete}
                    />
                </HStack>
            </Flex>
        </Box>
    );
};

export default ProfileValuationStandardsEdit;
