export const translations = {
    de: {
        account: "Firmenkonto",
        accountType: {
            CLIENT: "Kunde",
            VALUER: "Gutachter",
        },
        cases: "Geschäftsfälle",
        createEnquiry: {
            sendLink: "Link schicken",
        },
        dashboard: "Dashboard",
        enquiries: "Anfragen",
        entities: "Unternehmen",
        inspections: "Besichtigung",
        logout: "Abmelden",
        perspective: {
            me: "Ich",
            company: "Unternehmen",
        },
        profile: "Mein Profil",
        support: "Unterstützung",
        system: "System",
        users: "Nutzer",
    },
    en: {
        account: "Company account",
        accountType: {
            CLIENT: "Client",
            VALUER: "Valuer",
        },
        cases: "Cases",
        createEnquiry: {
            sendLink: "Send Link",
        },
        dashboard: "Dashboard",
        enquiries: "Enquiries",
        entities: "Entities",
        inspections: "Inspections",
        logout: "Logout",
        perspective: {
            me: "Me",
            company: "Company",
        },
        profile: "My profile",
        support: "Support",
        system: "System",
        users: "Users",
    },
};
