import {
    Box,
    Button,
    Center,
    Container,
    HStack,
    Icon,
    IconButton,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    getMultiFactorResolver,
    multiFactor,
    MultiFactorError,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier,
    User,
} from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { MdArrowBack, MdOutlineSms } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import PatternElement from '../../../../../assets/icons/PatternElement';
import { meGet } from '../../../../../utils/api/endpoints';
import { auth } from '../../../../../utils/firebase';
import WaterMarkBox from '../../../common/components/WaterMarkBox';
import FormikForm from '../../../common/forms/FormikForm';
import FormikPinInput from '../../../common/forms/FormikPinInput';
import { useSmartTranslation } from '../../../common/hooks/useSmartTranslation';

type PropTypes = {
    authError: MultiFactorError | undefined;
};

const PhoneVerification = (props: PropTypes) => {
    const t = useSmartTranslation();
    const { authError } = props;

    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);
    const [verificationId, setVerificationId] = useState('');

    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (loading || verificationId !== '') return;

        const recaptchaVerifier = new RecaptchaVerifier(
            auth,
            'google-recaptcha',
            {
                size: 'invisible',
            },
        );

        if (authError) {
            const resolver = getMultiFactorResolver(auth, authError);

            // Ask user which second factor to use.
            if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
                const phoneInfoOptions = {
                    multiFactorHint: resolver.hints[0],
                    session: resolver.session,
                };

                const phoneAuthProvider = new PhoneAuthProvider(auth);

                // Send SMS verification code
                phoneAuthProvider
                    .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
                    .then(vId => {
                        setVerificationId(vId);
                    });
            }
        } else {
            multiFactor(user as User)
                .getSession()
                .then(async multiFactorSession => {
                    const dbUser = await meGet();

                    const phoneInfoOptions = {
                        phoneNumber: dbUser?.mobile,
                        session: multiFactorSession,
                    };

                    const phoneAuthProvider = new PhoneAuthProvider(
                        auth,
                    );

                    // Send SMS verification code.
                    const vId = await phoneAuthProvider
                        .verifyPhoneNumber(
                            phoneInfoOptions,
                            recaptchaVerifier,
                        );

                    setVerificationId(vId);
                });
        }
    }, [loading]);

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    bg="white"
                    color="white"
                    padding="2rem"
                    marginTop="2rem"
                    borderRadius={10}
                    width="380px"
                    height="711px"
                    overflow="hidden"
                >
                    <VStack h="100%">
                        <HStack
                            width="100%"
                        >
                            <IconButton
                                aria-label="Back"
                                icon={<MdArrowBack />}
                                size="sm"
                                variant="primary"
                            // onClick={() => onBack()}
                            />

                            <Button
                                color="wvwGreen"
                                variant="link"
                            // onClick={() => onBack()}
                            >
                                {t('common.button.back')}
                            </Button>
                        </HStack>

                        <Spacer />

                        <Center>
                            <Icon
                                fontSize="6rem"
                                color="wvwGreen"
                            >
                                <MdOutlineSms />
                            </Icon>
                        </Center>

                        <Text
                            fontWeight="bold"
                            fontSize="20px"
                            color="wvwGreen"
                        >
                            {t('login.verifyLogin')}
                        </Text>

                        <>
                            <Text
                                textAlign="center"
                                fontSize="16px"
                                color="wvwGrey"
                            >
                                {t('login.otpSent')}
                            </Text>

                            <Spacer />

                            <FormikForm
                                initialValues={{
                                    code: '',
                                }}
                                onSubmit={({ code }) => {
                                    setSubmitting(true);

                                    const cred = PhoneAuthProvider
                                        .credential(verificationId, code);

                                    const multiFactorAssertion = PhoneMultiFactorGenerator
                                        .assertion(cred);

                                    if (authError) {
                                        const resolver = getMultiFactorResolver(
                                            auth,
                                            authError,
                                        );

                                        resolver
                                            .resolveSignIn(multiFactorAssertion)
                                            .then(() => {
                                                navigate('/dashboard');
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    } else {
                                        // Complete enrollment.
                                        multiFactor(user as User)
                                            .enroll(multiFactorAssertion, 'mobile')
                                            .then(() => {
                                                navigate('/dashboard');
                                            })
                                            .catch(error => {
                                                console.log(error);
                                            });
                                    }
                                }}
                            >
                                <Box
                                    paddingBottom="1rem"
                                >
                                    <FormikPinInput
                                        name="code"
                                    />
                                </Box>

                                {/* <Button variant="link">
                                <Text color="white" fontSize="16px" fontWeight="light">
                                    Resend code
                                </Text>
                            </Button> */}

                                <Spacer />

                                <Button
                                    background="#FF9F43"
                                    disabled={submitting || loading}
                                    type="submit"
                                    width="100%"
                                >
                                    {t('common.button.confirm')}
                                </Button>
                            </FormikForm>
                        </>

                        <Spacer />

                        {/* I know this is stinky, but it's the best I could figure out, for now */}
                        <PatternElement fontSize="6rem" color="rgba(193, 193, 194, 0.04)" z-zIndex={-1} pointerEvents="none" position="absolute" bottom="130px" left="700px" />
                        <PatternElement fontSize="6rem" color="rgba(193, 193, 194, 0.04)" z-zIndex={-1} pointerEvents="none" position="absolute" bottom="200px" left="800px" transform="rotate(180deg)" />
                        <PatternElement fontSize="6rem" color="rgba(193, 193, 194, 0.04)" z-zIndex={-1} pointerEvents="none" position="absolute" bottom="200px" left="960px" transform="rotate(180deg)" />
                        <PatternElement fontSize="6rem" color="rgba(193, 193, 194, 0.04)" z-zIndex={-1} pointerEvents="none" position="absolute" bottom="200px" left="1080px" />
                        <PatternElement fontSize="6rem" color="rgba(193, 193, 194, 0.04)" z-zIndex={-1} pointerEvents="none" position="absolute" bottom="240px" left="870px" />
                        <PatternElement fontSize="6rem" color="rgba(193, 193, 194, 0.04)" z-zIndex={-1} pointerEvents="none" position="absolute" bottom="70px" left="1020px" />

                    </VStack>
                </Container>
            </WaterMarkBox>
        </Box>
    );
};

export default PhoneVerification;
