import { Flex, VStack } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { WVWTitle } from '../typography';
import { useRole } from '../../hooks';
import AwaitingAccess from '../AwaitingAccess';

type PropTypes = {
    allowUnapprovedUser?: boolean;
    children: ReactNode | ReactNode[];
    extra?: ReactNode | ReactNode[];
    title: string;
    noBackToProfile?: boolean;
};

const PageWithTitleLayout = (props: PropTypes) => {
    const {
        allowUnapprovedUser = false,
        children,
        extra = null,
        title,
        noBackToProfile = false,
    } = props;

    const { userIsApproved, userIsSystemAdmin } = useRole();

    const showPage = userIsSystemAdmin || userIsApproved || allowUnapprovedUser;

    return (
        <VStack
            alignItems="space-between"
            spacing="2"
            w="100%"
        >
            <Flex justifyContent="space-between" w="100%">
                <WVWTitle
                    color="black"
                    content={title}
                    level="1"
                />

                {showPage && extra}
            </Flex>

            {!showPage && <AwaitingAccess noBackToProfile={noBackToProfile} />}

            {showPage && children}
        </VStack>
    );
};

export default PageWithTitleLayout;
