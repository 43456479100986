import { TranslationFunction } from "../../../../common/hooks/useSmartTranslation";

export const completeProfile = (t: TranslationFunction) => ({
    title: t("clientDashboard.customizeExperience.completeProfile.title"),
    subtext: t("clientDashboard.customizeExperience.completeProfile.subtext"),
    link: {
        label: t("common.button.complete"),
        nav: "/complete-profile",
    },
});

export const teamMembers = (t: TranslationFunction) => ({
    title: t("clientDashboard.customizeExperience.addTeamMembers.title"),
    subtext: t("clientDashboard.customizeExperience.addTeamMembers.subtext"),
    link: {
        label: t("common.button.add"),
        nav: "/dashboard/account/team",
    },
});

export const clientCustomizations = {
    completeProfile,
    teamMembers,
};
