import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    AbsoluteCenter,
    Box,
    Button,
    ButtonGroup,
    Center,
    Flex,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsInbox, BsTrash } from 'react-icons/bs';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { MdEdit, MdOutlineMessage } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import CommentsThread from '../../../../../../common/components/CommentsThread';
import ConfirmationModal from '../../../../../../common/components/ConfirmationModal';
import LoadingModal from '../../../../../../common/components/LoadingModal';
import LoadingSpinner from '../../../../../../common/components/LoadingSpinner';
import ModalDymanic from '../../../../../../common/components/ModalDynamic';
import StatusBadge from '../../../../../../common/components/StatusBadge';
import Toggle from '../../../../../../common/components/Toggle';
import VerticalDividerWithIcon from '../../../../../../common/components/VerticalDivider';
import WvwDeleteIconButton from '../../../../../../common/components/WvwDeleteIconButton';
import { FormikForm, FormikInput, FormikTextarea } from '../../../../../../common/forms';
import { useRole } from '../../../../../../common/hooks';
import {
    useDocumentRequestCreateDocumentComment,
    useDocumentRequestGetCaseDocuments,
    useDocumentRequestMarkDocumentCommentsAsRead,
    useDocumentRequestUpdateDocument,
    useDocumentRequestUpdateDocumentsStatus,
} from '../../../../../../utils/api/hooks';
import { ViewFilterContext } from '../DocumentManagementArea';
import useToast from '../../../../../../ui/v2/common/hooks/useToast';

type ParamsType = {
    caseId: string;
    docId: string;
};

const DocumentPreview = () => {
    const {
        docId = '',
        caseId = '',
    } = useParams<ParamsType>();

    const { t } = useTranslation(['common', 'documentmanagement']);

    const navigate = useNavigate();

    const { createToast } = useToast();

    const { userIsValuer } = useRole();

    const documentsFilter = useContext(ViewFilterContext);

    const [reject, setReject] = useState(false);
    const [rename, setRename] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

    const {
        data: allCaseDocuments,
        isLoading: documentsIsLoading,
        refresh: refreshDocuments,
    } = useDocumentRequestGetCaseDocuments({
        caseId: Number(caseId),
        includeDeleted: true,
    });

    const currentDocument = allCaseDocuments.find(
        x => x.id === Number(docId),
    );

    const requestId = currentDocument?.documentRequestId;

    let documentList = allCaseDocuments;

    if (documentsFilter !== 'DOCUMENTS') {
        documentList = allCaseDocuments.filter(
            x => x.documentRequestId === currentDocument?.documentRequestId,
        );
    }

    const {
        update: updateDocumentStatus,
    } = useDocumentRequestUpdateDocumentsStatus({
        caseId: Number(caseId),
        requestId: Number(requestId),
    });

    const {
        update: updateDocument,
        busy: documentUpdating,
    } = useDocumentRequestUpdateDocument({ caseId: Number(caseId) });

    const {
        update: deleteDocument,
    } = useDocumentRequestUpdateDocument({
        caseId: Number(caseId),
        onSuccess: () => {
            refreshDocuments();
            navigate(-1);
        },
    });

    const {
        update: createDocumentComment,
    } = useDocumentRequestCreateDocumentComment({ caseId });

    const {
        update: markAsRead,
    } = useDocumentRequestMarkDocumentCommentsAsRead({
        documentId: docId,
        onSuccess: () => refreshDocuments(),
    });

    if (documentsIsLoading) {
        return <LoadingModal />;
    }

    const docIndex = documentList.findIndex(
        x => x.id === Number(docId),
    );

    if (currentDocument === undefined) {
        createToast({
            description: t('documentmanagement:toast.errors.documentNotFound'),
            status: "error"
        });
        return null;
    }

    const docFileType = currentDocument?.url?.split('.').pop()?.split('?')[0];

    const documentComments = currentDocument?.comments || [];

    return (
        <ModalDymanic
            header={(
                <HStack w="100%">
                    <BsInbox />

                    <Text>
                        {currentDocument.documentRequest?.name || ''}
                    </Text>
                </HStack>
            )}
            isOpen
            onClose={() => navigate(-1)}
        >
            <VStack
                w="100%"
                spacing="0"
            >
                <Flex
                    w="100%"
                    align="center"
                >
                    <HStack
                        color="wvwGreen"
                        spacing="1"
                    >
                        {rename ? (
                            <FormikForm
                                submitting={documentUpdating}
                                initialValues={{
                                    name: currentDocument?.name,
                                }}
                                onSubmit={values => {
                                    updateDocument({
                                        documentRequestId: Number(requestId),
                                        documentId: currentDocument.id,
                                        name: values.name,
                                    });
                                    setRename(false);
                                }}
                            >
                                <HStack pb=".5rem">
                                    <FormikInput
                                        name="name"
                                    />
                                    <IconButton
                                        aria-label="Save"
                                        borderRadius="30"
                                        icon={<CheckIcon />}
                                        size="xs"
                                        type="submit"
                                        variant="primary"
                                    />

                                    <WvwDeleteIconButton
                                        onClick={() => setRename(false)}
                                    />
                                </HStack>
                            </FormikForm>
                        ) : (
                            <>
                                <HiOutlineDocumentText
                                    size="1.3rem"
                                />
                                <Text
                                    fontSize="xl"
                                    paddingRight=".5rem"
                                >
                                    {currentDocument?.name}
                                </Text>

                                {userIsValuer && (
                                    <Menu>
                                        <MenuButton
                                            as={IconButton}
                                            borderRadius="30"
                                            icon={<ChevronDownIcon />}
                                            size="xxs"
                                            variant="primary"
                                        />
                                        <MenuList>
                                            <MenuItem
                                                icon={<MdEdit size="1.2rem" />}
                                                width="100%"
                                                color="wvwGreen"
                                                onClick={() => setRename(true)}
                                            >
                                                {t('button.rename', { ns: 'common' })}
                                            </MenuItem>

                                            <MenuItem
                                                icon={<BsTrash size="1.2rem" />}
                                                width="100%"
                                                color="wvwGreen"
                                                onClick={() => {
                                                    setConfirmDeleteOpen(true);
                                                }}
                                            >
                                                {t('button.delete', { ns: 'common' })}
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                )}
                            </>
                        )}
                    </HStack>

                    <Spacer />

                    <Toggle
                        index={docIndex}
                        itemId={docId}
                        toggleList={documentList}
                        path={`dashboard/case/${caseId}/documents/document-preview`}
                    />
                </Flex>

                <Flex w="100%">
                    {currentDocument?.responseComment && currentDocument.status === 'REJECTED' ? (
                        <Flex
                            marginBottom=".5rem"
                            paddingBlock=".5rem"
                            paddingInline="1rem"
                            borderRadius="10"
                            w="100%"
                            bg="red05"
                            align="center"
                        >
                            <VStack
                                align="left"
                                spacing="0"

                            >
                                <Text
                                    fontWeight="500"
                                    color="red"
                                >
                                    {t('rejectionNote', { ns: 'documentmanagement', defaultValue: 'Rejection note' })}
                                </Text>

                                <Text>
                                    {currentDocument?.responseComment}
                                </Text>
                            </VStack>

                            <Spacer />

                            <StatusBadge
                                status={currentDocument?.status || ''}
                                margin="0"
                            />
                        </Flex>
                    ) : (
                        <Flex
                            marginBottom=".5rem"
                            paddingBlock=".5rem"
                            paddingInline="1rem"
                            borderRadius="10"
                            w="100%"
                            bg="wvwGrey05"
                            align="center"
                        >
                            <VStack
                                align="left"
                                spacing="0"

                            >
                                <Text fontWeight="500">
                                    {t('submissionNote', { ns: 'documentmanagement', defaultValue: 'Submission note' })}
                                </Text>

                                <Text>
                                {(currentDocument?.submissionComment === '' || currentDocument?.submissionComment === 'undefined' || currentDocument?.submissionComment === undefined)
                                ? 'No submission note' : currentDocument?.submissionComment}
                                </Text>
                            </VStack>

                            <Spacer />

                            <StatusBadge
                                status={currentDocument?.status || ''}
                                margin="0"
                            />
                        </Flex>
                    )}

                    <HStack
                        paddingLeft="2rem"
                        justifyContent="flex-end"
                    >
                        {userIsValuer && !reject && (
                            <>
                                <Tooltip hasArrow label={currentDocument?.status === 'REJECTED' && t('alreadyRejected', { ns: 'documentmanagement' })} bg="grey">
                                    <Button
                                        minW="fit-content"
                                        variant={currentDocument?.status === 'REJECTED' ? 'none' : 'danger'}
                                        bg={currentDocument?.status === 'REJECTED' ? 'wvwGrey05' : 'red'}
                                        color={currentDocument?.status === 'REJECTED' ? 'grey' : 'white'}
                                        onClick={currentDocument?.status !== 'REJECTED' ? (() => {
                                            setReject(true);
                                            setChatOpen(true);
                                        }) : undefined}
                                    >
                                        {t('button.reject', { ns: 'common' })}
                                    </Button>
                                </Tooltip>

                                <Tooltip hasArrow label={currentDocument?.status === 'ACCEPTED' && t('alreadyAccepted', { ns: 'documentmanagement' })} bg="grey">
                                    <Button
                                        minW="fit-content"
                                        variant={currentDocument?.status === 'ACCEPTED' ? 'grey' : 'primaryGreen'}
                                        color={currentDocument?.status === 'ACCEPTED' ? 'grey' : 'white'}
                                        onClick={currentDocument?.status !== 'ACCEPTED' ? (
                                            () => updateDocumentStatus({
                                                requestId: Number(requestId),
                                                documents: [{
                                                    id: Number(docId),
                                                    status: 'ACCEPTED',
                                                }],
                                            })
                                        ) : undefined}

                                    >
                                        {t('button.approve', { ns: 'common' })}
                                    </Button>
                                </Tooltip>
                            </>
                        )}
                    </HStack>
                </Flex>
            </VStack>

            <Flex w="100%">
                <Box
                    pr=".5rem"
                    w="100%"
                >
                    {!currentDocument?.url && currentDocument.status !== 'UNAVAILABLE' && <LoadingSpinner />}

                    {currentDocument.status === 'UNAVAILABLE' && (
                        <AbsoluteCenter>
                            <Text color="wvwGrey60">
                                {t('documentUnavailable', { ns: 'documentmanagement' })}
                            </Text>
                        </AbsoluteCenter>
                    )}

                    {
                        (docFileType === 'pdf' || docFileType === 'PDF') && (
                            <iframe
                                src={currentDocument?.url}
                                width="100%"
                                height="100%"
                                title="PDF Preview"
                            />
                        )
                    }

                    {
                        (docFileType === 'img' || docFileType === 'png' || docFileType === 'jpg' || docFileType === 'jpeg') && (
                            <Center
                                w="100%"
                                h="100%"
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                overflow="scroll"
                            >
                                <Box
                                    h="100%"
                                >
                                    <img
                                        src={currentDocument?.url}
                                        style={{ objectFit: 'contain' }}
                                        alt="Preview"
                                    />
                                </Box>
                            </Center>
                        )
                    }
                </Box>

                <VerticalDividerWithIcon
                    icon={chatOpen ? MdOutlineMessage : MdOutlineMessage}
                    onClick={() => setChatOpen(!chatOpen)}
                />

                {chatOpen && (
                    <VStack w="30rem">
                        {reject ? (
                            <FormikForm
                                initialValues={{
                                    note: '',
                                }}
                                onSubmit={values => {
                                    updateDocumentStatus({
                                        requestId: Number(requestId),
                                        documents: [{
                                            id: currentDocument.id,
                                            status: 'REJECTED',
                                            responseComment: values.note,

                                        }],
                                    });
                                    setReject(false);
                                }}
                            >
                                <VStack
                                    align="left"
                                >
                                    <Text color="wvwGreen">
                                        {t('rejectionNote', { ns: 'documentmanagement', defaultValue: 'Rejection note' })}
                                    </Text>

                                    <FormikTextarea
                                        name="note"
                                    />

                                    <ButtonGroup
                                        alignSelf="flex-end"
                                    >
                                        <Button
                                            variant="primaryYellow"
                                            onClick={() => setReject(false)}
                                        >
                                            {t('button.cancel', { ns: 'common' })}
                                        </Button>

                                        <Button
                                            variant="danger"
                                            type="submit"
                                        >
                                            {t('button.reject', { ns: 'common' })}
                                        </Button>
                                    </ButtonGroup>
                                </VStack>
                            </FormikForm>
                        ) : (
                            <CommentsThread
                                title={t('modal.title.documentComments', { ns: 'documentmanagement' })}
                                commentsList={documentComments}
                                onSend={values => {
                                    createDocumentComment({
                                        caseId: Number(caseId),
                                        documentId: Number(docId),
                                        comment: values.comment,
                                    });
                                }}
                                markAsRead={() => markAsRead(docId)}
                            />
                        )}
                    </VStack>
                )}
            </Flex>

            <ConfirmationModal
                cancelButton={t('button.cancel', { ns: 'common' })}
                continueButton={t('button.delete', { ns: 'common' })}
                isOpen={confirmDeleteOpen}
                content={t('modal.message.deleteDocument', { ns: 'documentmanagement' })}
                title={t('modal.title.deleteDocument', { ns: 'documentmanagement' })}
                onCancel={() => setConfirmDeleteOpen(false)}
                onContinue={() => {
                    deleteDocument({
                        documentRequestId: Number(requestId),
                        documentId: currentDocument.id,
                        deleted: true,
                    });
                }}
            />
        </ModalDymanic>
    );
};

export default DocumentPreview;
