import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import { WvwTooltip } from '../display';

type PropTypes = {
    label: string;
    isExternal?: boolean;
    to: string;
    noWrap?: boolean;
    isDisabled?: boolean;
    invertedColor?: boolean;
};

const WvwLink = (props: PropTypes) => {
    const {
        label,
        isDisabled = false,
        isExternal = false,
        noWrap = false,
        to: url,
        invertedColor = false,
    } = props;

    const noOfLines = noWrap ? 1 : undefined;
    const tooltipLabel = noWrap ? label : '';

    let href;
    let as;
    let to;

    if (isExternal) {
        href = url;
    } else {
        to = url;
        as = ReactLink;
    }

    if (isDisabled) {
        return (
            <ChakraLink
                cursor="not-allowed"
                noOfLines={noOfLines}
                color="wvwGrey60"
            >
                {label}
            </ChakraLink>
        );
    }

    return (
        <WvwTooltip label={tooltipLabel}>
            <ChakraLink
                href={href}
                isExternal={isExternal}
                as={as}
                to={to}
                noOfLines={noOfLines}
                color={invertedColor ? 'white' : 'wvwGreen'}
            >
                {label}
            </ChakraLink>
        </WvwTooltip>
    );
};

export default WvwLink;
