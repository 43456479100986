import { StandardSystemStandardsOfferedWithOther } from "../../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";

type TranslationNSObject = {
    [key in StandardSystemStandardsOfferedWithOther]: string;
};

type Translations = {
    de: TranslationNSObject;
    en: TranslationNSObject;
};

export const systemStandardTranslations: Translations = {
    de: {
        "Öffentlich Bestellt": "Öffentlich Bestellt",
        DIA: "DIA",
        "DIN 17024": "DIN 17024",
        Sprengnetter: "Sprengnetter",
        BIIS: "BIIS",
        BVS: "BVS",
        AI: "AI",
        ASA: "ASA",
        BDSF: "BDSF",
        DESAG: "DESAG",
        HypZert: "HypZert",
        "IVSC - White Book": "IVSC - White Book",
        Other: "Andere",
        "RICS - Red Book": "RICS - Red Book",
        TAF: "TAF",
        "Tegova - Blue Book": "Tegova - Blue Book",
        ValueV: "ValueV",
        "": "",
    },
    en: {
        "Öffentlich Bestellt": "Public Authorization",
        DIA: "DIA",
        "DIN 17024": "DIN 17024",
        Sprengnetter: "Sprengnetter",
        BIIS: "BIIS",
        BVS: "BVS",
        AI: "AI",
        ASA: "ASA",
        BDSF: "BDSF",
        DESAG: "DESAG",
        HypZert: "HypZert",
        "IVSC - White Book": "IVSC - White Book",
        Other: "Other",
        "RICS - Red Book": "RICS - Red Book",
        TAF: "TAF",
        "Tegova - Blue Book": "Tegova - Blue Book",
        ValueV: "ValueV",
        "": "",
    },
};
