import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { AdminManualEnquiryMatching } from '../../dto/admin/admin-manual-enquiry-matching';
import adminManualEnquiryMatching from '../../endpoints/admin/adminManualEnquiryMatching';
import useAdminGetEnquiry from './useAdminGetEnquiry';
import useToast from '../../../../ui/v2/common/hooks/useToast';

type ParamsType = {
    enquiryId: number;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useAdminManualEnquiryMatching = (params: ParamsType) => {
    const {
        enquiryId,
        onSuccess,
        onError,
    } = params;

    const { t } = useTranslation();

    const { createToast } = useToast();

    const { refresh: refreshEnquiry } = useAdminGetEnquiry(enquiryId);

    const { mutate: update } = useMutation(
        (dto: AdminManualEnquiryMatching) => adminManualEnquiryMatching(dto),
        {
            onSuccess: () => {
                refreshEnquiry();

                onSuccess?.();
            },
            onError: (error: Error) => {
                createToast({
                    status: "error",
                    description: t(error.message, { defaultValue: error.message })
                });

                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useAdminManualEnquiryMatching;
