import { Section } from "../../../../../layout";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { Forms } from "../../../../../common/forms/Forms";
import { standardPropertyTypes } from "../../../../../../../common/vars/valuationsAndMembershipTypes";

const ProfilePropertyTypesEdit = () => {
    const t = useSmartTranslation();

    return (
        <Section
            title={t("profile.heading.propertyTypes")}
            subtitle={t("profile.subHeading.propertyTypes")}
            content={
                <Forms.FormikCloudSelect
                    name="valuationPropertyTypes"
                    options={standardPropertyTypes.map((propertyType) => ({
                        label: t(`domain.standards.propertyType.${propertyType}`),
                        value: propertyType.toUpperCase(),
                    }))}
                />
            }
        />
    );
};

export default ProfilePropertyTypesEdit;
