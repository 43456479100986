import { Button, HStack, Text } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import PublicCompanyProfilePicture from "../../../../../common/components/PublicCompanyProfilePicture";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import { displayTimeInCurrentLocale } from "../../../../../common/functions/displayTime";
import { ValuerInviteType } from "../../../../../types";
import { DataTableColumnType } from "../../../common/components/data-table/DataTable";

type Column = DataTableColumnType<ValuerInviteType>;

const translationNameSpace = "account.customerPanelDetails.columns";

const entityName: Column = {
    title: (t) => t(`${translationNameSpace}.name`),
    render: (i) => {
        return (
            <HStack>
                <PublicCompanyProfilePicture
                    size="2.5rem"
                    companyId={i.fromEntityId}
                />
                <Text
                    fontSize={"sm"}
                    fontWeight={"700"}
                >
                    {i.fromEntityName}
                </Text>
            </HStack>
        );
    },
};

const valuerName: Column = {
    title: (t) => t(`${translationNameSpace}.name`),
    render: (i) => {
        return (
            <HStack>
                <PublicCompanyProfilePicture
                    size="2.5rem"
                    companyId={i.toEntityId!}
                />
                <Text
                    fontSize={"sm"}
                    fontWeight={"700"}
                >
                    {i.valuerCompanyName}
                </Text>
            </HStack>
        );
    },
};

const email: Column = {
    title: (t) => t(`${translationNameSpace}.email`),
    render: (i) => {
        return (
            <Button
                as={ReactLink}
                to={`mailto:${i.valuerEmail}`}
                variant={"link"}
                fontSize={"sm"}
                fontWeight={"400"}
                colorScheme="blue"
                onClick={(e) => e.stopPropagation()}
            >
                {i.valuerEmail}
            </Button>
        );
    },
};

const receivedAt: Column = {
    title: (t) => t(`${translationNameSpace}.sent`),
    render: (i) => {
        const date = displayDateWithCurrentLocale(i.createdAt);
        const time = displayTimeInCurrentLocale(new Date(i.createdAt));

        return (
            <Text fontSize={"sm"}>
                {date}, {time}
            </Text>
        );
    },
};

export const valuerInviteColumns = {
    entityName,
    valuerName,
    email,
    receivedAt,
};
