import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    HStack,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { MdOutlineLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Footer from '../../common/components/Footer';
import { WvwButton } from '../../common/components/inputs';
import LanguageToggleButton from '../../common/components/LanguageToggleButton';
import Logo from '../../common/components/Logo';
import WaterMarkBox from '../../common/components/WaterMarkBox';
import { useMe } from '../../utils/api/hooks';
import useSupportModal from '../../utils/api/hooks/useSupportModal';

const SuspendedScreen = () => {
    const { t } = useTranslation(['account', 'common', 'offer']);

    const {
        data: user,
        isLoading: userLoading,
    } = useMe();

    const navigate = useNavigate();

    const { modal: supportModal, openModal } = useSupportModal();

    return (
        <Box
            h={window.innerHeight}
            w="100%"
        >
            <WaterMarkBox>
                <Container
                    maxW="container.md"
                >
                    <Box
                        bg="white"
                        borderWidth="1px"
                        borderRadius="lg"
                        p="2em"
                        w="100%"
                        marginTop="2rem"
                    >
                        <Flex
                            bg="white"
                            w="100%"
                            boxShadow="0 0 8px lightwvwGrey"
                            align="center"
                        >
                            <Logo withColor />

                            <Spacer />

                            <HStack
                                align="center"
                                spacing="1rem"
                            >
                                <Box paddingInline=".8rem">
                                    <LanguageToggleButton />
                                </Box>

                                <Text
                                    pr=".5rem"
                                    fontWeight="500"
                                >
                                    {userLoading ? '' : `${user?.firstName} ${user?.lastName}`}
                                </Text>

                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        bg="white"
                                        rightIcon={<ChevronDownIcon />}
                                        w="1rem"
                                    />
                                    <MenuList>
                                        <MenuItem
                                            paddingBlock=".8rem"
                                            onClick={() => {
                                                navigate('/logout');
                                            }}
                                            icon={<MdOutlineLogout />}
                                        >
                                            <Text paddingInline=".5rem">{t('Logout', { ns: 'menubar' })}</Text>
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            </HStack>
                        </Flex>

                        <VStack
                            spacing="8"
                            pb="9em"
                            pt="6em"
                            w="100%"
                        >
                            <Heading
                                as="h1"
                                color="wvwGreen"
                                size="xl"
                                textAlign="center"
                            >
                                {t('Welcome to', { ns: 'common' })}
                                <br />
                                {t('whoValuesWhat', { ns: 'common' })}
                            </Heading>

                            <Box
                                w={{
                                    base: '100%', sm: '100%', md: '80%', lg: '80%', xl: '70%',
                                }}
                                textAlign={{
                                    sm: 'justify',
                                    md: 'center',
                                }}
                                fontSize="1rem"
                                paddingLeft="1rem"
                                paddingRight="1rem"
                            >
                                <Text>
                                    {t('allInOne', { ns: 'account' })}
                                </Text>

                                <Text
                                    paddingTop="2rem"
                                    fontWeight="bold"
                                >
                                    {t('accountSuspended', { ns: 'account' })}
                                </Text>
                            </Box>

                            <WvwButton
                                variant="primary"
                                content={t('contact', { ns: 'common' })}
                                onClick={() => openModal()}
                            />

                            {supportModal}
                        </VStack>
                    </Box>
                </Container>

                <Footer invertColor />
            </WaterMarkBox>
        </Box>
    );
};

export default SuspendedScreen;
