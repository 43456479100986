import {
    Button,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsSearch } from "react-icons/bs";
import { read, utils } from "xlsx";
import FileUploadInput from "../inputs/FileUploadInput";

type UserType = {
    firstName: string;
    lastName: string;
    email: string;
    userType: string;
};

type PropsType = {
    addUsers: (users: UserType[]) => void;
    asButton?: boolean;
};

const readFile = (
    file: File,
    setUsers: Dispatch<SetStateAction<UserType[]>>
) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
        // Parse data
        const bstr = e.target?.result;
        const wb = read(bstr, { type: rABS ? "binary" : "array" });

        // Get first worksheet
        const wsName = wb.SheetNames[0];
        const ws = wb.Sheets[wsName];

        // Convert array of arrays
        const xData: any = utils.sheet_to_json(ws, { header: 1 });

        const data = xData.reduce((acc: any, curr: any) => {
            const [firstName, lastName, email, admin = "NO"] = curr;

            if (firstName === "First_Name") return acc;
            if (!firstName || !lastName || !email) return acc;

            acc.push({
                firstName,
                lastName,
                email,
                userType: admin.toUpperCase() === "YES" ? "admin" : "user",
            });

            return acc;
        }, []);

        setUsers(data);
    };

    if (rABS) reader.readAsBinaryString(file as Blob);
    else reader.readAsArrayBuffer(file as Blob);
};

const ExcelUploadUsers = (props: PropsType) => {
    const { addUsers, asButton = false } = props;

    const { t } = useTranslation(["account", "common"]);

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [users, setUsers] = useState<UserType[]>([]);

    return (
        <>
            {!asButton && (
                <Button
                    variant="none"
                    color="wvwGreen"
                    fontWeight="light"
                    onClick={onOpen}
                >
                    <BsSearch size="1.2rem" />

                    <Spacer width=".5rem" />

                    {t("uploadContactList")}
                </Button>
            )}

            {asButton && (
                <Button
                    variant="primary"
                    w="fit-content"
                    paddingInline={4}
                    fontWeight="light"
                    onClick={onOpen}
                >
                    {t("teamDetails.button.excelUpload")}
                </Button>
            )}

            <Modal
                isOpen={isOpen}
                onClose={() => {
                    setUsers([]);
                    onClose();
                }}
            >
                <ModalOverlay />

                <ModalContent>
                    <ModalHeader>{t("uploadExcel")}</ModalHeader>

                    <ModalCloseButton />

                    <ModalBody>
                        {users.length === 0 && (
                            <VStack
                                align="start"
                                spacing="3"
                                w="100%"
                            >
                                <a
                                    href={`${process.env.PUBLIC_URL}/excel/wvw-users-excel-upload-example.xlsx`}
                                    download="wvw-users-excel-upload-example.xlsx"
                                >
                                    <Button>{t("downloadTemplate")}</Button>
                                </a>

                                <FileUploadInput
                                    noRename
                                    fileTypes={[".xlsx", ".xls", ".csv"]}
                                    onChange={(files) =>
                                        readFile(files[0], setUsers)
                                    }
                                />
                            </VStack>
                        )}

                        {users.length > 0 && (
                            <Text textAlign="end">
                                {`${t("common:count")}: ${users.length}`}
                            </Text>
                        )}

                        {users.map((u) => (
                            <Flex key={u.email}>
                                <Text>
                                    {`${u.firstName} ${u.lastName} - ${u.email}`}
                                </Text>

                                <Spacer />

                                <Text>
                                    {u.userType === "admin" ? "Admin" : "User"}
                                </Text>
                            </Flex>
                        ))}
                    </ModalBody>

                    <ModalFooter>
                        <HStack spacing="2">
                            <Button
                                variant="outline"
                                onClick={() => {
                                    onClose();
                                    setUsers([]);
                                }}
                            >
                                {t("button.cancel", { ns: "common" })}
                            </Button>

                            {users.length > 0 && (
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        addUsers(users);

                                        setUsers([]);
                                        onClose();
                                    }}
                                >
                                    {t("addUser", { ns: "common" })}
                                </Button>
                            )}
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ExcelUploadUsers;
