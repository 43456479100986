import { Box, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { BsBuilding } from "react-icons/bs";
import { FiMail, FiPhone, FiSmartphone } from "react-icons/fi";
import { EntityType, OfferType, UserType } from "../../../../../../types";
import { CopyableEntry } from "../../../../common/components/display";
import useDrawer from "../../../../common/hooks/useDrawer";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Enquiry } from "../../../../domain";
import { EnquiryWithRecipientInfo } from "../../../../domain/enquiry/Title";
import { ClientProfilePicture } from "../../../../domain/user/ClientProfilePicture";
import { useExposeClientData } from "../../../../domain/user/useExposeClientData";

type Props = {
    client: UserType;
    enquiry: EnquiryWithRecipientInfo;
    offer?: OfferType;
    panelValuerList: EntityType[];
    onUpdate: () => any;
    onError: (error: Error) => any;
};

export const ValuerEnquiryTitle = (props: Props) => {
    const { client, enquiry, offer, onUpdate, onError } = props;

    const t = useSmartTranslation();

    const { exposeClientData } = useExposeClientData({
        client,
        enquiry,
    });

    let clientName, clientPosition, clientCompany;
    if (exposeClientData) {
        clientName = `${client.firstName} ${client.lastName}`;
        clientPosition = `${client.orgPosition}`;
        clientCompany = `${client.entity.name}`;
    } else {
        clientName = t("domain.client.obscuredClient");
        clientPosition = t(
            "screens.platform.valuer.valuerEnquiryDetails.enquiryTitle.detailsAvailableAfterOfferAcceptance"
        );
    }

    const titleClient = (
        <TitleClient
            name={clientName}
            position={clientPosition}
            company={clientCompany}
            clientDisplay={
                <ClientProfilePicture
                    client={client}
                    enquiry={enquiry}
                    profileLogoSize={2.5}
                    companyLogoSize={1.5}
                />
            }
        />
    );

    const { drawer, openDrawer } = useDrawer({
        title: t("domain.user.previewDrawerTitle"),
        content: exposeClientData ? (
            <ClientDrawerContent client={client}>
                {titleClient}
            </ClientDrawerContent>
        ) : (
            titleClient
        ),
        size: "md",
    });

    const clientInfo = (
        <Box
            onClick={openDrawer}
            p={2}
            borderRadius={8}
            _hover={{
                cursor: "pointer",
                bg: "blue.50",
            }}
        >
            {titleClient}
            {drawer}
        </Box>
    );

    return (
        <Enquiry.Title
            enquiry={enquiry}
            userIsClient={false}
            onUpdate={onUpdate}
            onError={onError}
            clientInfo={clientInfo}
            offer={offer}
        />
    );
};

type TitleClientProps = {
    clientDisplay: React.ReactNode;
    company?: string;
    position: string;
    name: string;
};

const TitleClient = (props: TitleClientProps) => {
    const { clientDisplay, company, position, name } = props;

    const t = useSmartTranslation();

    return (
        <HStack>
            {clientDisplay}
            <VStack
                align={"flex-start"}
                spacing="0"
            >
                <Text fontWeight="bold">{name}</Text>
                <Text
                    fontSize={"0.8em"}
                    variant="secondary"
                >
                    {position}
                    {position && company && ` ${t("domain.client.at")} `}
                    {company}
                </Text>
            </VStack>
        </HStack>
    );
};

type ClientDrawerContentProps = {
    client: UserType;
    children: React.ReactNode | React.ReactNode[];
};

const ClientDrawerContent = (props: ClientDrawerContentProps) => {
    const {
        client: {
            street,
            postalCode,
            city,
            email,
            officeTel,
            officeTelDialCode,
            mobile,
            mobileDialCode,
        },
        children,
    } = props;

    return (
        <VStack
            width={"100%"}
            spacing={4}
            align={"stretch"}
        >
            <Divider
                mb={3}
                color={"gray.100"}
            />

            {children}

            {(street || postalCode || city) && (
                <CopyableEntry
                    leftIcon={BsBuilding}
                    label={`${street ? `${street}, ` : ""} ${
                        postalCode || ""
                    } ${city || ""}`}
                />
            )}
            <CopyableEntry
                leftIcon={FiMail}
                label={email}
            />
            {officeTel && (
                <CopyableEntry
                    leftIcon={FiPhone}
                    label={`${officeTelDialCode} ${officeTel}`}
                />
            )}
            {mobile && (
                <CopyableEntry
                    leftIcon={FiSmartphone}
                    label={`${mobileDialCode} ${mobile}`}
                />
            )}
        </VStack>
    );
};
