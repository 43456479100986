import { Select, SelectProps } from '@chakra-ui/react';
import countryCodes from '../../../../common/vars/countryCodes';
import { useSmartTranslation } from '../hooks/useSmartTranslation';

type PropTypes = {
    name: string;
    selectDialCode?: boolean;
} & SelectProps;

const CountrySelect = (props: PropTypes) => {
    const t = useSmartTranslation();
    const { selectDialCode = false } = props;

    const spreadProps = { ...props };

    delete spreadProps.selectDialCode;

    return (
        <Select
            bg="white"
            h="3rem"
            borderRadius="8"
            placeholder={t('forms.fieldPlaceholder.country')}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...spreadProps}
        >
            { countryCodes.map(i => (
                <option
                    key={`${i.name} ${i.dial_code}`}
                    disabled={i.disabled}
                    value={selectDialCode ? i.dial_code : i.name}
                >
                    {`${t(`forms.countries.${i.name}`, { defaultValue: i.name })} ${selectDialCode && i.dial_code !== '' ? `(${i.dial_code})` : ''}`}
                </option>
            )) }
        </Select>
    );
};

export default CountrySelect;
