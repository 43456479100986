import { VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import ModalDynamic from '../../../../common/components/ModalDynamic';
import { EnquiryType } from '../../../../types';
import { FormikForm, FormikInput } from '../../../../common/forms';
import { LabelAndInput } from '../../../../common/components/display';
import { WvwButton } from '../../../../common/components/inputs';
import useAdminManualEnquiryMatching from '../../../../utils/api/hooks/admin/useAdminManualEnquiryMatching';
import useToast from '../../../../ui/v2/common/hooks/useToast';

type PropTypes = {
    enquiry: EnquiryType;
    isOpen: boolean;
    onClose: () => void;
};

const convertToNumberArray = (str: string) => str.split(',').reduce((acc: number[], curr: string) => {
    if (curr.trim() === '') {
        return acc;
    }

    const num = Number(curr);

    if (Number.isNaN(num)) {
        return acc;
    }

    return [...acc, num];
}, []);

const AddManualMatchModal = (props: PropTypes) => {
    const { enquiry, isOpen, onClose } = props;

    const { toEntityIds, toUserIds } = enquiry;

    const { t } = useTranslation('admin');
    const { createToast } = useToast();

    const { update: manuallyMatch } = useAdminManualEnquiryMatching({
        enquiryId: enquiry.id,
        onSuccess: () => onClose(),
    });

    return (
        <ModalDynamic
            isOpen={isOpen}
            header={t('modal.addManualMatch.heading')}
            onClose={onClose}
        >
            <FormikForm
                initialValues={{
                    entityIds: '',
                    userIds: '',
                }}
                onSubmit={values => {
                    if (values.entityIds.trim() === '' && values.userIds.trim() === '') {
                        createToast({
                            status: "error",
                            description: t('error.idsRequired')
                        });

                        return;
                    }

                    const entityIds: number[] = convertToNumberArray(values.entityIds);

                    const userIds: number[] = convertToNumberArray(values.userIds);

                    if (toEntityIds.some(id => entityIds.includes(id))) {
                        createToast({
                            status: "error",
                            description: t('error.entityAlreadyMatched')
                        });

                        return;
                    }

                    if (toUserIds.some(id => userIds.includes(id))) {
                        createToast({
                            status: "error",
                            description: t('error.userAlreadyMatched')
                        });

                        return;
                    }

                    manuallyMatch({
                        enquiryId: enquiry.id,
                        valuerEntityIds: entityIds,
                        valuerUserIds: userIds,
                    });
                }}
            >
                <VStack
                    align="start"
                    spacing="4"
                >
                    <LabelAndInput
                        label={t('modal.addManualMatch.entityIds')}
                        input={(
                            <FormikInput
                                name="entityIds"
                                placeholder="1, 2, 3, 4, "
                            />
                        )}
                    />

                    <LabelAndInput
                        label={t('modal.addManualMatch.userIds')}
                        input={(
                            <FormikInput
                                name="userIds"
                                placeholder="1, 2, 3, 4, "
                            />
                        )}
                    />

                    <WvwButton
                        content={t('button.submit', { ns: 'common' })}
                        formSubmitButton
                        onClick={() => {}}
                    />
                </VStack>
            </FormikForm>
        </ModalDynamic>
    );
};

export default AddManualMatchModal;
