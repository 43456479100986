import { VStack } from "@chakra-ui/react";
import { useState } from "react";
import {
    StandardCompanyDocumentType,
    standardCompanyDocumentTypes,
} from "../../../../../../common/vars/valuationsAndMembershipTypes/standardCompanyDocumentTypes";
import { StandardPropertyType } from "../../../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";
import { useMyEntity } from "../../../../../../utils/api/hooks";
import storage from "../../../../../../utils/storage/storage";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../common/hooks/useToast";
import { FileUploadInput } from "../../../../common/inputs";

type PropsType = {
    documentType: StandardCompanyDocumentType | StandardPropertyType;
    uploadSuccess: () => void;
};

const DocumentsUpload = (props: PropsType) => {
    const { documentType, uploadSuccess } = props;

    const t = useSmartTranslation();

    const { createToast } = useToast();

    const [loadingUpload, setLoadingUpload] = useState(false);

    const {
        data: entity,
        entityCertificatesRefresh,
        entitySampleValuationsRefresh,
    } = useMyEntity();

    const uploadDocument = async (files: File[]) => {
        if (!files.length) return;

        const isCompanyDocuments = standardCompanyDocumentTypes.includes(
            documentType as StandardCompanyDocumentType
        );

        try {
            await Promise.all(
                files.map((file) =>
                    isCompanyDocuments
                        ? storage.uploadEntityCertificate(
                              entity.id,
                              file,
                              documentType as StandardCompanyDocumentType
                          )
                        : storage.uploadEntitySampleValuations(
                              entity.id,
                              file,
                              documentType
                          )
                )
            );

            entityCertificatesRefresh();
            entitySampleValuationsRefresh();

            setLoadingUpload(false);
            uploadSuccess();

            createToast({
                description: t("fileUpload.uploaded.description"),
                title: t("fileUpload.uploaded.label"),
                status: "success",
            });
        } catch {
            createToast({
                description: t("common.toast.error"),
                status: "error",
            });
        }
    };

    // render

    const loading = loadingUpload && (
        <LoadingSpinner customText={t("fileUpload.button.loader")} />
    );

    return (
        <VStack spacing={4}>
            <FileUploadInput
                fileTypes={[
                    ".pdf",
                    ".jpg",
                    ".jpeg",
                    ".png",
                    ".tiff",
                    ".xlsx",
                    ".docx",
                ]}
                height="260px"
                onChange={(fileList) => {
                    setLoadingUpload(true);
                    uploadDocument(fileList);
                }}
            />

            {loading}
        </VStack>
    );
};

export default DocumentsUpload;
