import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    HStack,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { object, string } from "yup";
import { useMyEntity } from "../../../../utils/api/hooks";
import storage, {
    FirebaseCertificateType,
} from "../../../../utils/storage/storage";
import { getFileTypeBasedOnName } from "../../common/components/display/File";
import ModalDynamic from "../../common/components/ModalDynamic";
import FormikForm from "../../common/forms/FormikForm";
import FormikInput from "../../common/forms/FormikInput";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";
import useToast from "../../common/hooks/useToast";
import { FileTypeIcon } from "../../common/icons/FileTypeIcon";
import useMe from "../../app/useMeV2";

const validationSchema = object({
    name: string().required("forms.errors.required"),
});

type PropTypes = {
    file: FirebaseCertificateType;
};

const useDocumentEditName = (props: PropTypes) => {
    const {
        file: { url, label },
    } = props;

    const t = useSmartTranslation();

    const { createToast } = useToast();

    const { entityCertificatesRefresh, entitySampleValuationsRefresh } =
        useMyEntity();

    const { myCertificatesRefresh } = useMe();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const updateFile = async (name: string) => {
        if (!url || !name) return;

        try {
            await storage.updateFileMetadata(url, name);

            entityCertificatesRefresh();
            entitySampleValuationsRefresh();
            myCertificatesRefresh();

            onClose();
        } catch {
            createToast({ status: "error" });
        }
    };

    // render

    const fileType = getFileTypeBasedOnName(label);

    const alert = (
        <Alert
            mb={4}
            alignItems={"start"}
        >
            <AlertIcon />
            <VStack
                align={"stretch"}
                spacing={0}
            >
                <AlertTitle>
                    {t("domain.document.ACTIVITIES.edit.alert.label")}
                </AlertTitle>
                <AlertDescription>
                    {t("domain.document.ACTIVITIES.edit.alert.description")}
                </AlertDescription>
            </VStack>
        </Alert>
    );

    const form = (
        <FormikForm
            initialValues={{
                name: label,
            }}
            onSubmit={(values) => {
                const name = createFileName(label, values.name.trim()) || "";
                updateFile(name);
            }}
            validationSchema={validationSchema}
        >
            <HStack>
                <FileTypeIcon
                    filetype={fileType}
                    size={6}
                />

                <FormikInput
                    name="name"
                    type="text"
                    rightinternal={
                        <Text
                            fontSize={"sm"}
                            color={"gray.400"}
                        >
                            .{fileType}
                        </Text>
                    }
                />
            </HStack>

            <HStack
                justifyContent={"flex-end"}
                gap={2}
                pt={4}
            >
                <Button
                    variant={"primary"}
                    type={"submit"}
                    w={"50%"}
                >
                    {t("common.button.save")}
                </Button>
            </HStack>
        </FormikForm>
    );

    const editModal = (
        <ModalDynamic
            variant="md"
            isOpen={isOpen}
            header={t("domain.document.ACTIVITIES.edit.NAME")}
            onClose={onClose}
        >
            {alert}
            {form}
        </ModalDynamic>
    );

    return {
        editDocumentModal: editModal,
        openEditDocumentModal: onOpen,
    };
};

const createFileName = (currentName: string, newName: string) => {
    if (!currentName && !newName) return "";

    if (newName !== currentName) {
        const extention = currentName?.split(".")?.pop()?.toLowerCase() || "";

        if (newName.split(".").pop()?.toLowerCase() !== extention) {
            newName += `.${extention}`;
        }
    }

    return newName;
};

export default useDocumentEditName;
