import { Icon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";

type PropsType = {
    color: "yellow" | "blue";
    size?: "md" | "lg";
    number?: number;
};

const MapMarkerIcon = ({ color, size = 'md', number }: PropsType) => {
    const mainYellow = "#FFA100";
    const mainBlue = "#3182CE";

    const boxSize = size === "md" ? 95 : 110;

    let fillColor: typeof mainYellow | typeof mainBlue = mainYellow;
    let textColor: "black" | "white" = "black";

    if (color === "blue") {
        fillColor = mainBlue;
        textColor = "white";
    } else {
        fillColor = mainYellow;
        textColor = "black";
    }

    return (
        <Icon
            as="svg"
            viewBox="0 0 53 78"
            boxSize={boxSize}
            fill="none"
        >
            <circle
                cx="26.3215"
                cy="64.4287"
                r="6.5"
                fill="white"
                stroke={fillColor}
                strokeWidth="5"
            />
            <g filter="url(#filter0_d_7881_12896)">
                <path
                    d="M44.3215 29.285C44.3215 32.8585 43.2634 36.1868 41.4409 38.9784C39.1498 42.488 32.4151 55.9095 28.7556 62.0512C27.6612 63.8879 24.9818 63.8879 23.8874 62.0512C20.228 55.9095 13.4933 42.488 11.2021 38.9784C9.37969 36.1868 8.32153 32.8585 8.32153 29.285C8.32153 19.4232 16.3804 11.4287 26.3215 11.4287C36.2627 11.4287 44.3215 19.4232 44.3215 29.285Z"
                    fill={fillColor}
                />

                { number && (
                    <foreignObject
                        className="node"
                        x="11"
                        y="25"
                        width="100"
                        height="100"
                    >
                        <Text
                            color={textColor}
                            fontWeight="bold"
                            maxW="30px"
                            textAlign="center"
                        >
                            {number}
                        </Text>
                    </foreignObject>
                )}

                <path
                    d="M43.8215 29.285C43.8215 32.7583 42.7934 35.9921 41.0223 38.7051C39.8609 40.484 37.5903 44.7447 35.17 49.2929L35.0307 49.5547C32.6411 54.0454 30.1133 58.7958 28.3261 61.7952C27.4256 63.3065 25.2175 63.3065 24.317 61.7952C22.5298 58.7958 20.002 54.0455 17.6124 49.5548L17.4731 49.2929C15.0528 44.7447 12.7821 40.484 11.6208 38.7051C9.84969 35.9921 8.82153 32.7583 8.82153 29.285C8.82153 19.7032 16.6528 11.9287 26.3215 11.9287C35.9903 11.9287 43.8215 19.7032 43.8215 29.285Z"
                    stroke={fillColor}
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_7881_12896"
                    x="0.321533"
                    y="9.42871"
                    width="52"
                    height="68"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="4" />
                    <feComposite
                        in2="hardAlpha"
                        operator="out"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_7881_12896"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_7881_12896"
                        result="shape"
                    />
                </filter>
            </defs>
        </Icon>
    );
};

export default MapMarkerIcon;
