import {
    Box,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    VStack,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { WVWTitle, WvwText } from './typography';
import { WvwButton } from './inputs';

type PropTypes = {
    cancelButton?: string;
    content: string;
    continueButton?: string;
    isOpen: boolean;
    hideCloseButton?: boolean;
    hideContinueButton?: boolean;
    subTitle?: string;
    subTitleIcon?: ReactNode;
    subTitleTwo?: ReactNode;
    title: string;
    onCancel?: () => void;
    onContinue?: () => void;
};

const ConfirmationModal = (props: PropTypes) => {
    const {
        cancelButton,
        content,
        continueButton,
        hideCloseButton,
        hideContinueButton,
        isOpen,
        subTitle,
        subTitleIcon,
        subTitleTwo,
        title,
        onCancel = () => { },
        onContinue = () => { },
    } = props;

    const { t } = useTranslation(['common']);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onCancel}
            size="lg"
        >
            <ModalOverlay />

            <ModalContent alignItems="center">
                <Box
                    bgColor="white"
                    borderRadius="md"
                    boxShadow="md"
                    p="1rem"
                    w="100%"
                >
                    <ModalHeader color="wvwGreen" textAlign="center">
                        {title}
                    </ModalHeader>

                    <ModalCloseButton />

                    <ModalBody
                        paddingBottom="1rem"
                        textAlign="center"
                    >
                        <VStack spacing="3">
                            {subTitle && (
                                <Box
                                    w="100%"
                                >
                                    <HStack
                                        alignItems="center"
                                        justifyContent="center"
                                        w="100%"
                                    >
                                        {subTitleIcon}

                                        <WVWTitle
                                            content={subTitle}
                                            level="3"
                                        />
                                    </HStack>
                                </Box>
                            )}

                            {subTitleTwo}

                            <WvwText textAlign="center">
                                {content}
                            </WvwText>
                        </VStack>
                    </ModalBody>

                    <ModalFooter w="100%">
                        <Flex
                            gap="2"
                            justifyContent="space-between"
                            w="100%"
                        >
                            {!hideCloseButton && (
                                <WvwButton
                                    block
                                    content={cancelButton || t('button.close', { ns: 'common' })}
                                    onClick={onCancel}
                                    variant="outline"
                                />
                            )}

                            {!hideContinueButton && (
                                <WvwButton
                                    block
                                    content={continueButton || t('button.continue', { ns: 'common' })}
                                    onClick={onContinue}
                                    variant="primary"
                                />
                            )}
                        </Flex>
                    </ModalFooter>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmationModal;
