import { useFormikContext } from "formik";
import { FormikCloudSelect } from "../../../../common/forms";
import { useSmartTranslation } from "../../../../common/hooks";
import { standardPropertyTypes } from "../../../../common/vars/valuationsAndMembershipTypes";
import FormSectionProfileEdit from "./FormSectionProfileEdit";
import { StandardPropertyType } from "../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";

const ProfilePropertyTypesEdit = () => {
    const t = useSmartTranslation("profile");

    const { values } = useFormikContext<{
        valuationPropertyTypes?: StandardPropertyType[];
    }>();

    const isPropertyTypesMissing =
        !values.valuationPropertyTypes ||
        values.valuationPropertyTypes.length === 0;

    return (
        <FormSectionProfileEdit
            header={t("heading.propertyTypes")}
            warningIcon={isPropertyTypesMissing}
            subHeader={t("subHeading.propertyTypes")}
        >
            <FormikCloudSelect
                name="valuationPropertyTypes"
                options={standardPropertyTypes.map((propertyType) => ({
                    label: t(propertyType, {
                        ns: "formik",
                        defaultValue: propertyType,
                    }),
                    value: propertyType.toUpperCase(),
                }))}
            />
        </FormSectionProfileEdit>
    );
};

export default ProfilePropertyTypesEdit;
