import { VStack } from "@chakra-ui/react";
import {
    FormikCheckboxGroup,
    FormikConditionalSection,
    FormikListInput,
} from "../../../../common/forms";
import { useSmartTranslation } from "../../../../common/hooks";
import { standardValuationOccasions } from "../../../../common/vars/valuationsAndMembershipTypes";
import FormSectionProfileEdit from "./FormSectionProfileEdit";
import { wvwPalette } from "../../../../theme";
import { WVWLabel } from "../../../../common/components/typography";
import { useFormikContext } from "formik";
import { StandardValuationOccasion } from "../../../../common/vars/valuationsAndMembershipTypes/standardValuationOccasions";

const ProfileValuationOccasionsEdit = () => {
    const t = useSmartTranslation("profile");

    const { values } = useFormikContext<{
        valuationOccasions?: StandardValuationOccasion[];
    }>();

    const isValuationOccasionsMissing =
        !values.valuationOccasions || values.valuationOccasions.length === 0;

    return (
        <FormSectionProfileEdit
            header={t("heading.valuationOccasions")}
            warningIcon={isValuationOccasionsMissing}
            subHeader={t("subHeading.valuationOccasions")}
        >
            <VStack
                width="100%"
                align="left"
            >
                <FormikCheckboxGroup
                    name="valuationOccasions"
                    selectAll
                    variant="outline"
                    options={standardValuationOccasions.map((type) => ({
                        label: t(type, { ns: "formik", defaultValue: type }),
                        value: type,
                    }))}
                />
            </VStack>

            <FormikConditionalSection
                formDataTarget="valuationOccasions"
                condition={(x) => x.includes("Other")}
            >
                <VStack
                    align="start"
                    backgroundColor={wvwPalette.wvwBackground.main}
                    borderRadius="8"
                    padding="1rem"
                    spacing="4"
                    w="100%"
                >
                    <WVWLabel
                        noColor
                        content={t("heading.otherValuationOccasions")}
                    />

                    <FormikListInput
                        name="otherValuationOccasions"
                        placeholder={t(
                            "membershipsAndQualifications.edit.addOtherValuationOccasion"
                        )}
                    />
                </VStack>
            </FormikConditionalSection>
        </FormSectionProfileEdit>
    );
};

export default ProfileValuationOccasionsEdit;
