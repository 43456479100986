import { Flex, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import ModalDynamic from '../../../../../common/components/ModalDynamic';
import { CaseReportType } from '../../../../../types';
import { useNavigateUpUrl, useRole, useSmartTranslation } from '../../../../../common/hooks';
import { ContentBox, LabelAndData, StatusBadgeCaseReport } from '../../../../../common/components/display';
import { WvwText } from '../../../../../common/components/typography';
import WvwHStack from '../../../../../common/components/display/WvwHStack';
import { WvwButton } from '../../../../../common/components/inputs';
import CaseReportDocumentList from './common/CaseReportDocumentList';
import SplitLayout from '../../../../../common/components/SplitLayout';
import CommentsThread from '../../../../../common/components/CommentsThread';
import { useCaseCommentOnReport, useCaseMarkReportCommentsRead, useCaseReportRespondToReport } from '../../../../../utils/api/hooks';
import ConfirmationModal from '../../../../../common/components/ConfirmationModal';

type PropsType = {
    caseId: number;
    report?: CaseReportType;
};

const CaseReportDetailsModal = (props: PropsType) => {
    const { caseId, report } = props;

    const t = useSmartTranslation('casereport');
    const navigateUpUrl = useNavigateUpUrl();

    const { userIsClient } = useRole();

    const [resondingToReport, setRespondingToReport] = useState<'ACCEPTED' | 'REJECTED' | undefined>(undefined);

    const { update: markCommentsAsRead } = useCaseMarkReportCommentsRead({ caseId });
    const { update: sendComment } = useCaseCommentOnReport({ caseId });
    const { update: respondToReport } = useCaseReportRespondToReport({ caseId });

    if (!report) return null;

    const {
        id: reportId,
        comments: commentsList,
        description = t('details.defaultReportDescription'),
        documents,
        number,
        status,
    } = report;

    const comments = commentsList.map(comment => ({
        ...comment,
        createdAt: new Date(comment.createdAt),
    }));

    const clientResponseSection = (
        <ContentBox color="wvwYellow10">
            <Flex
                alignItems="center"
                justifyContent="space-between"
            >
                <WvwText>
                    {t('details.reviewReport')}
                </WvwText>

                <WvwHStack>
                    <WvwButton
                        variant="danger"
                        content={t('button.reject', { ns: 'common' })}
                        onClick={() => setRespondingToReport('REJECTED')}
                    />

                    <WvwButton
                        variant="success"
                        content={t('button.accept', { ns: 'common' })}
                        onClick={() => setRespondingToReport('ACCEPTED')}
                    />
                </WvwHStack>
            </Flex>
        </ContentBox>
    );

    const renderClientResponseSection = () => {
        const displayClientResponseSection = userIsClient && status === 'SUBMITTED';

        if (!displayClientResponseSection) return null;

        return clientResponseSection;
    };

    let confirmationTitle = t('title.acceptReport');
    let confirmationContent = t('details.confirmAccept');

    if (resondingToReport === 'REJECTED') {
        confirmationTitle = t('title.rejectReport');
        confirmationContent = t('details.confirmReject');
    }

    return (
        <>
            <ModalDynamic
                isOpen
                header={`${t('title.report')} #${number}`}
                onClose={() => navigateUpUrl(2)}
            >
                <SplitLayout
                    splitPercentage={70}
                    contentLeft={(
                        <VStack
                            alignItems="flex-start"
                            spacing="6"
                            w="100%"
                        >
                            <LabelAndData
                                label={t('status', { ns: 'common' })}
                                data={<StatusBadgeCaseReport status={status} />}
                            />

                            <LabelAndData
                                label={t('description', { ns: 'common' })}
                                data={description}
                            />

                            {renderClientResponseSection()}

                            <CaseReportDocumentList documents={documents} />
                        </VStack>
                    )}
                    contentRight={(
                        <CommentsThread
                            commentsList={comments}
                            onSend={({ comment }) => (
                                sendComment({ reportId: reportId.toString(), message: comment })
                            )}
                            markAsRead={() => markCommentsAsRead(reportId)}
                        />
                    )}
                />
            </ModalDynamic>

            <ConfirmationModal
                title={confirmationTitle}
                content={confirmationContent}
                isOpen={resondingToReport !== undefined}
                onCancel={() => setRespondingToReport(undefined)}
                onContinue={() => {
                    const response = resondingToReport === 'ACCEPTED' ? 'ACCEPTED' : 'REJECTED';

                    respondToReport({ reportId: reportId.toString(), status: response });
                    setRespondingToReport(undefined);
                }}
            />
        </>
    );
};

export default CaseReportDetailsModal;
