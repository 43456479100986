import { useDisclosure } from "@chakra-ui/react";
import {
    useEntityPanelValuers,
    useEntityRemovePanelValuer,
} from "../../../../../../../utils/api/hooks";
import ConfirmationModal from "../../../../../common/components/ConfirmationModal";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../../common/hooks/useToast";

type PropTypes = {
    valuerId: number;
};

const useValuersRemove = (props: PropTypes) => {
    const { valuerId } = props;

    const t = useSmartTranslation();

    const { createToast } = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { refresh: panelValuerRefresh } = useEntityPanelValuers();

    const { update: updateRemoveValuer } = useEntityRemovePanelValuer({
        onSuccess: () => {
            panelValuerRefresh();
        },
        onError: (err) => {
            createToast({
                description: err.message,
                status: "error",
            });
        },
    });

    const removeValuerModal = (
        <ConfirmationModal
            confirmationButtonLabel={t("common.button.confirm")}
            confirmationButtonVariant="negative"
            isOpen={isOpen}
            title={t("account.customerPanelDetails.removeValuer.title")}
            blurb={t("account.customerPanelDetails.removeValuer.prompt")}
            onClose={onClose}
            onConfirmation={() => {
                updateRemoveValuer(valuerId);
                onClose();
            }}
        />
    );

    return {
        removeValuerModal,
        isOpenRemoveValuerModal: isOpen,
        openRemoveValuerModal: onOpen,
    };
};

export default useValuersRemove;
