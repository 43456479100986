import { CheckIcon } from '@chakra-ui/icons';
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Grid,
    GridItem,
    HStack,
    IconButton,
    Link as ChakraLink,
    Text,
    VStack,
    Button,
    Box,
} from '@chakra-ui/react';
import { useState } from 'react';
import { DragSourceMonitor, useDrag, useDrop } from 'react-dnd';
import { Link as ReactLink } from 'react-router-dom';
import {
    DocumentRequestDocumentType,
    DocumentRequestType,
    FolderDropTargetType,
    dragTargetTypes,
    DOCUMENT_IN_ACTION_AREA,
} from '../../../../../../types';
import {
    FormikForm,
    FormikInput,

} from '../../../../../../common/forms';
import Documents from '../../documents/Documents';
import StatusBadge from '../../../../../../common/components/StatusBadge';
import { useDocumentRequestUpdateRequest, useDocumentRequestDeleteRequest, useDocumentRequestGetCaseDocuments } from '../../../../../../utils/api/hooks';
import { useRole, useSmartTranslation } from '../../../../../../common/hooks';
import { displayDateWithCurrentLocale } from '../../../../../../common/functions/displayDateInLocale';
import { DocumentFiltersType } from '../../common/documentManagementAreaTypes';
import WvwCancelIconButton from '../../../../../../common/components/WvwCancelIconButton';
import WvwDeleteIconButton from '../../../../../../common/components/WvwDeleteIconButton';
import ConfirmationModal from '../../../../../../common/components/ConfirmationModal';

const { DOCUMENT_MOVE } = dragTargetTypes;

type PropTypes = {
    documentFilter: DocumentFiltersType;
    inEditMode: boolean;
    request: DocumentRequestType;
};

type DragDocumentType = {
    type: string;
    documentId: number;
    documentName: string;
};

const getCanDrop = (
    dragItem: DragDocumentType,
    acceptableItemTypes: string[],
    requestStatus: string,
    documents: DocumentRequestDocumentType[],
) => {
    let canDrop = true;
    let acceptableType = false;
    let reasonKey: undefined | 'dragAndDrop.drop.requestIsUnavailable' | 'dragAndDrop.drop.requestHasFile';

    if (!dragItem) {
        return {
            canDrop: false,
            reasonKey: undefined,
        };
    }

    if (acceptableItemTypes.includes(dragItem.type)) {
        acceptableType = true;
    }

    if (requestStatus === 'UNAVAILABLE') {
        canDrop = false;
        reasonKey = 'dragAndDrop.drop.requestIsUnavailable';
    }

    if (documents.some(doc => doc.name === dragItem.documentName)) {
        canDrop = false;
        reasonKey = 'dragAndDrop.drop.requestHasFile';
    }

    return {
        canDrop,
        acceptableType,
        reasonKey,
    };
};

const RequestOverviewItem = (props: PropTypes) => {
    const {
        documentFilter,
        inEditMode,
        request,
    } = props;

    const {
        id: requestId,
        caseId,
        folderId,
        name,
        updatedAt,
        updatedBy,
        status,
    } = request;

    const [editing, setEditing] = useState(false);

    const [deletedRequest, setDeletedRequest] = useState('');

    const t = useSmartTranslation('documentmanagement');

    const { userIsClient, userIsValuer } = useRole();

    const {
        data: allRequestDocuments = [],
    } = useDocumentRequestGetCaseDocuments({ caseId, requestId });

    const {
        update: updateRequest,
    } = useDocumentRequestUpdateRequest({ caseId });

    const {
        update: deleteRequest,
    } = useDocumentRequestDeleteRequest({ caseId });

    const [{ isDragging }, drag] = useDrag(() => ({
        type: dragTargetTypes.REQUEST_MOVE,
        item: { requestId: request.id },
        canDrag: () => {
            if (inEditMode) return false;
            if (userIsClient) return false;

            return true;
        },
        end(item, monitor) {
            const dropResult = monitor.getDropResult() as FolderDropTargetType;

            if (item && dropResult) {
                updateRequest({
                    requestId: item.requestId,
                    folderId: dropResult.folderId,
                });
            }
        },
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }), [inEditMode]);

    const acceptableDropItemTypes = [DOCUMENT_IN_ACTION_AREA, DOCUMENT_MOVE];

    const [
        dropMeta,
        dropRef,
    ] = useDrop(() => ({
        accept: acceptableDropItemTypes,
        canDrop: (item: DragDocumentType) => {
            const { canDrop } = getCanDrop(
                item,
                acceptableDropItemTypes,
                status,
                allRequestDocuments,
            );

            return canDrop;
        },
        drop: () => ({
            requestId: request.id,
            folderId,
        }),
        collect: monitor => {
            const item = monitor.getItem();

            const { canDrop, acceptableType, reasonKey } = getCanDrop(
                item,
                acceptableDropItemTypes,
                status,
                allRequestDocuments,
            );

            const acceptedObjectIsDragging = acceptableType;
            const acceptedObjectIsHovering = acceptedObjectIsDragging && monitor.isOver();
            const draggingObjectCanBeDropped = canDrop;
            const cannotbeDroppedReason = reasonKey ? t(reasonKey) : '';

            return {
                acceptedObjectIsDragging,
                acceptedObjectIsHovering,
                draggingObjectCanBeDropped,
                cannotbeDroppedReason,
            };
        },
    }), [status, allRequestDocuments]);

    const {
        acceptedObjectIsDragging,
        acceptedObjectIsHovering,
        draggingObjectCanBeDropped,
        cannotbeDroppedReason,
    } = dropMeta;

    let bgColor = 'wvwGrey05';
    let titleText = name;

    if (acceptedObjectIsDragging) {
        if (draggingObjectCanBeDropped) {
            bgColor = 'wvwGreen20';

            if (acceptedObjectIsHovering) {
                bgColor = 'wvwGreen80';
            }
        } else {
            bgColor = 'red20';
            titleText = cannotbeDroppedReason;
        }
    }

    if (isDragging) {
        return null;
    }

    return (
        <AccordionItem
            isFocusable={false}
            ref={node => {
                drag(node);
            }}
            onKeyUp={e => e.preventDefault()}
        >
            <AccordionButton
                bgColor={bgColor}
                paddingBlock="0"
                ref={node => {
                    dropRef(node);
                }}
            >
                <Grid
                    alignItems="center"
                    paddingBlock=".5rem"
                    templateColumns="repeat(4, 1fr)"
                    w="100%"
                >
                    <HStack
                        alignSelf="left"
                        w="18rem"
                        paddingLeft="2rem"
                    >
                        <AccordionIcon />

                        {(inEditMode && editing) ? (
                            <FormikForm
                                initialValues={{ requestName: name }}
                                onSubmit={values => {
                                    updateRequest({
                                        requestId: request.id,
                                        name: values.requestName,
                                    });

                                    setEditing(false);
                                }}
                            >
                                <HStack
                                    paddingLeft="1.1rem"
                                    width="100%"
                                >
                                    <Box
                                        onClick={e => e.stopPropagation()}
                                    >
                                        <FormikInput
                                            name="requestName"
                                            width="25rem"
                                        />
                                    </Box>

                                    <IconButton
                                        aria-label="Save"
                                        borderRadius="30"
                                        icon={<CheckIcon />}
                                        onClick={e => e.stopPropagation()}
                                        size="xs"
                                        type="submit"
                                        variant="primary"
                                    />

                                    <WvwCancelIconButton
                                        onClick={e => {
                                            setEditing(false);
                                            e.stopPropagation();
                                        }}
                                        color="wvwGreen"
                                    />
                                </HStack>
                            </FormikForm>
                        ) : (
                            <ChakraLink
                                as={ReactLink}
                                justifySelf="flex-end"
                                color="wvwGreen"
                                fontWeight="500"
                                to={inEditMode ? undefined : `request/${request.id}`}
                                onClick={() => {
                                    if (inEditMode) {
                                        setEditing(!editing);
                                    }
                                }}
                            >
                                <Text
                                    maxW="17rem"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                >
                                    {titleText}
                                </Text>
                            </ChakraLink>
                        )}
                    </HStack>

                    {!inEditMode && (
                        <>
                            <GridItem
                                justifySelf="center"
                                w="10rem"
                            >
                                <StatusBadge status={status} />
                            </GridItem>

                            <HStack
                                align="left"
                                pl="1rem"
                            >
                                <Text
                                    noOfLines={1}
                                    textAlign="left"
                                >
                                    {updatedBy ? `${updatedBy.firstName} ${updatedBy.lastName}` : '-'}
                                </Text>

                                <Text>
                                    {displayDateWithCurrentLocale(updatedAt)}
                                </Text>
                            </HStack>

                            <GridItem
                                justifySelf="flex-end"
                                w="10rem"
                                onClick={e => e.stopPropagation()}
                                onKeyDown={e => e.stopPropagation()}
                            >
                                {(userIsValuer && request.status === 'PENDING' && request.documents?.length === 0) && (
                                    <WvwDeleteIconButton
                                        onClick={() => setDeletedRequest(String(request.id))}
                                    />
                                )}
                                {(userIsClient && request.status === 'PENDING') && (
                                    <ChakraLink
                                        as={ReactLink}
                                        to={`request-unavailable-or-incomplete/${request.id}`}
                                    >
                                        <Button
                                            justifySelf="right"
                                            variant="danger"
                                        >
                                            {t('button.unavailable')}
                                        </Button>
                                    </ChakraLink>
                                )}
                            </GridItem>
                        </>
                    )}
                </Grid>
            </AccordionButton>

            <AccordionPanel paddingTop="0">
                <VStack
                    align="left"
                    w="100%"
                >
                    <Text
                        pt="1rem"
                        color="wvwGrey60"
                        paddingLeft="2.2rem"
                    >
                        {request.description}
                    </Text>

                    {request.unavailableReason && (
                        <Text
                            color="orange"
                            paddingLeft="3rem"
                        >
                            {request.unavailableReason}
                        </Text>
                    )}

                    <Documents
                        inEditMode={inEditMode}
                        requestId={request.id}
                        documentFilter={documentFilter}
                    />
                </VStack>
            </AccordionPanel>

            <ConfirmationModal
                isOpen={!!deletedRequest}
                onCancel={() => setDeletedRequest('')}
                onContinue={() => deleteRequest(deletedRequest)}
                title={t('modal.title.deleteRequest', { ns: 'documentmanagement' })}
                content={t('modal.message.deleteRequest', { ns: 'documentmanagement' })}
            />
        </AccordionItem>
    );
};

export default RequestOverviewItem;
