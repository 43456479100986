import {
    EnquiryWithOfferStatusTranslations,
    enquiryStatusTranslations,
} from "./enquiryStatus";

export const translations = {
    de: {
        ACTIVITIES: {
            archive: {
                NAME: "Ignorieren",
                confirmationBlurb:
                    "Möchten Sie diese Anfrage wirklich ignorieren? Sie können Sie später in der Liste ignorierte Anfragen sehen und wieder aktivieren.",
            },
            unarchive: {
                NAME: "Aktivieren",
                confirmationBlurb:
                    "Möchten Sie diese Anfrage wieder aktivieren und nicht länger ignorieren?",
            },
            assign: {
                NAME: "Zuordnen",
                title: "Anfrage zuordnen",
                modal: {
                    title: "Anfrage zuordnen",
                },
            },
            cancel: {
                NAME: "Verwerfen",
                confirmationTitle: "Anfrage Verwerfen",
                confirmationBlurb:
                    "Wollen Sie diese Anfrage wirklich verwerfen?",
            },
            edit: {
                NAME: "Fortsetzen",
            },
        },
        columns: {
            acceptedAt: "Angenommen am",
            activeOffers: "Aktive Angebote",
            assigned: "Zugewiesen",
            bestDelivery: "Früheste Lieferung",
            bestOffer: "Günstigstes Angebot",
            createdAt: "Erstellt am",
            delivery: "Lieferung bis:",
            daysAgo: "vor {{count}} Tagen",
            daysAgo_zero: "Heute",
            daysAgo_one: "vor {{count}} Tag",
            expectedDelivery: "Erwartet bis",
            lastOffer: "Letztes Angebot",
            myOffer: "Mein Angebot",
            moreText: "+{{count}} weitere",
            newText: "+{{count}} neu",
            piCover: "Haftpflicht",
            piCoverTooltip: "Haftpflichtdeckung",
            portfolioValue: "Gesamtwert",
            portfolioValueTooltip: "Vom Kunden geschätzter Immobilienwert",
            position: {
                at: "{{position}} bei {{company}}",
            },
            price: "Preis:",
            rejected: "Abgelehnt",
            scope: "Bewertungumfang",
            scopeAndStandard: "Umfang & Standard",
            outlet: "Radius",
            standard: "Bewertungsstandard",
        },
        companyEnquiries: "Firmenanfragen",
        createEnquiry: "Anfrage erstellen",
        daysLeft: "{{count}} Tage übrig",
        datePassed: "In der Vergangenheit",
        enquiries: "Anfragen",
        enquiry: "Anfrage",
        enquiryId: "Anfrage #{{id}}",
        matchmaking: {
            openMarket: "Offene Anfrage",
            valuerPanel: "Gutachter-Panel",
            exclusively: "Direkte Anfrage für {{name}}",
        },
        published: "Anfrage-Radius:",
        purpose: "Zweck der Wertermittlung",
        statusLabel: "Status:",
        status: enquiryStatusTranslations.de,
        statusWithOffer: EnquiryWithOfferStatusTranslations.de,
        standards: "Standards",
        typesOfEnquiry: {
            activeEnquiries: "Aktive Anfragen",
            dormantEnquiries: "Ignorierte Anfragen",
            draftEnquiries: "Anfrageentwürfe",
        },
        requestedValuationTitle: "Angeforderte Bewertung",
        valuationRequirements: {
            scopeAndStandard: "Umfang & Standard",
            expectedDelivery: "Erwartetes Fertigstellungsdatum",
            specificScopes: "Spezielle Bewertungsumfänge",
            purpose: "Zweck",
            additionalRequirements: "Zusätzliche Anforderungen",
        },
        valuerRequirementsTitle: "Anforderungen an den Gutachter",
        valuerRequirements: {
            qualification: {
                label: "Qualifikation",
                tooltip: "Erforderliche Qualifikation des Gutachters",
            },
            minExperience: {
                label: "Berufserfahrung",
                tooltip: "Mindesterfahrung des Gutachters (in Jahren)",
            },
            minPartners: {
                label: "Anz. Gutachter",
                tooltip: "Mindestanzahl von Gutachtern im Unternehmen",
            },
            maxDistance: {
                label: "Max. Distanz",
                tooltip:
                    "Maximale Wegstrecke vom Arbeitsstandort zum Bewertungsobjekt",
            },
            minPiCover: {
                label: "Haftpflicht",
                tooltip: "Mindestdeckung der Haftpflichtversicherung",
            },
            noRequirement: "Keine Anforderung",
        },
        requirements: {
            experience: {
                "0": "Keine",
                "1-3 years": "1-3 Jahre",
                "3-5 years": "3-5 Jahre",
                "5+ years": "5+ Jahre",
            },
            partners: {
                "Single Partner": "Einzelgutachter",
                "Two Partners": "Zwei Gutachter",
                "Three Partners": "Drei Gutachter",
                "Four or More Partners": "Vier oder mehr Gutachter",
            },
        },
    },
    en: {
        ACTIVITIES: {
            archive: {
                NAME: "Ignore",
                confirmationBlurb:
                    "Are you sure you want to ignore this enquiry?",
            },
            unarchive: {
                NAME: "Activate",
                confirmationBlurb:
                    "Are you sure you want to activate this enquiry again, no longer ignoring it?",
            },
            assign: {
                NAME: "Assign",
                title: "Assign Enquiry",
                modal: {
                    title: "Assign Enquiry",
                },
            },
            cancel: {
                NAME: "Discard",
                confirmationTitle: "Discard Equiry",
                confirmationBlurb:
                    "Do you really want to discard this enquiry?",
            },
            edit: {
                NAME: "Resume",
            },
        },
        columns: {
            acceptedAt: "Accepted at",
            activeOffers: "Active Offers",
            assigned: "Assigned",
            bestDelivery: "Best Delivery",
            bestOffer: "Best Offer",
            createdAt: "Created At",
            daysAgo: "{{count}} days ago",
            daysAgo_zero: "Today",
            daysAgo_one: "{{count}} day ago",
            delivery: "Delivery:",
            expectedDelivery: "Expected Delivery",
            lastOffer: "Last Offer",
            myOffer: "My Offer",
            moreText: "+{{count}} more",
            newText: "+{{count}} new",
            piCover: "PI Cover",
            portfolioValue: "Total Value",
            portfolioValueTooltip: "Total property value estimated by client",
            position: {
                at: "{{position}} at {{company}}",
            },
            price: "Price:",
            rejected: "Rejected",
            scope: "Valuation Scope",
            scopeAndStandard: "Scope & Standard",
            outlet: "Outlet",
            standard: "Standard",
        },
        companyEnquiries: "Company Enquiries",
        createEnquiry: "Create enquiry",
        daysLeft: "{{count}} days left",
        datePassed: "Date passed",
        enquiries: "Enquiries",
        enquiry: "Enquiry",
        enquiryId: "Enquiry #{{id}}",
        matchmaking: {
            openMarket: "Open Market",
            valuerPanel: "Valuer Panel",
            exclusively: "Direct enquiry to {{name}}",
        },
        published: "Enquiry Outlet:",
        purpose: "Purpose of Valuation",
        statusLabel: "Status:",
        status: enquiryStatusTranslations.en,
        statusWithOffer: EnquiryWithOfferStatusTranslations.en,
        standards: "Standards",
        typesOfEnquiry: {
            activeEnquiries: "Active Enquiries",
            dormantEnquiries: "Ignored Enquiries",
            draftEnquiries: "Draft Enquiries",
        },
        requestedValuationTitle: "Requested Valuation",
        valuationRequirements: {
            scopeAndStandard: "Scope & Standard",
            expectedDelivery: "Expected Delivery",
            specificScopes: "Specific Scopes",
            purpose: "Purpose of Valuation",
            additionalRequirements: "Additional Requirements",
        },
        valuerRequirementsTitle: "Valuer Requirements",
        valuerRequirements: {
            qualification: {
                label: "Qualification",
                tooltip: "Required valuer qualification",
            },
            minExperience: {
                label: "Min. Post-Qualification",
                tooltip: "Minimal working experience of valuer",
            },
            minPartners: {
                label: "Min. Valuers in Firm",
                tooltip: "Minimal number of valuers in firm",
            },
            maxDistance: {
                label: "Max. Distance",
                tooltip:
                    "Maximum travel distance from office location to valuation object",
            },
            minPiCover: {
                label: "Min. PI Cover",
                tooltip:
                    "Minimal liability cover provided by professional insurance",
            },
            noRequirement: "No requirement specified",
        },
        requirements: {
            experience: {
                "0": "0",
                "1-3 years": "1-3 Years",
                "3-5 years": "3-5 Years",
                "5+ years": "5+ Years",
            },
            partners: {
                "Single Partner": "Single Valuer",
                "Two Partners": "Two Valuers",
                "Three Partners": "Three Valuers",
                "Four or More Partners": "Four or More Valuers",
            },
        },
    },
};
