import { Select, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { LabelAndInput } from "../components/display";
import { FormikValidationContext } from "./FormikForm";

type PropTypes = {
    name: string;
    warningIcon?: boolean;
    label?: string;
    width?: string;
    options: {
        disabled?: boolean;
        label: string;
        value: string | number;
    }[];
    placeholder?: string;
};

const FormikSelect = (props: PropTypes) => {
    const { t } = useTranslation("formik");

    const { name, warningIcon, label = '', placeholder = '', options, width = '100%' } = props;

    const [field, meta] = useField(props);

    const isFieldRequired = useContext(FormikValidationContext);

    const isRequired = isFieldRequired(name);

    return (
        <LabelAndInput
            icon={warningIcon ? "warning" : undefined}
            label={label && `${label}${isRequired ? " *" : ""}`}
            input={
                <div
                    style={{
                        width,
                    }}
                >
                    <Select
                        bg="white"
                        h="3rem"
                        borderRadius="8"
                        fontSize="md"
                        isInvalid={!!meta.touched && !!meta.error}
                        placeholder={placeholder}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                    >
                        {options.map((option) => (
                            <option
                                key={`${option.label} ${option.value}`}
                                disabled={option.disabled}
                                value={option.value}
                            >
                                {option.label}
                            </option>
                        ))}
                    </Select>

                    {meta.touched && meta.error && (
                        <Text color="red">
                            {t(meta.error, { defaultValue: meta.error })}
                        </Text>
                    )}
                </div>
            }
        />
    );
};

export default FormikSelect;
