import { VStack } from "@chakra-ui/layout";
import { useContext } from "react";
import { FormikWarningsContext } from "./FormikForm";
import { FormikWarning } from "./FormikWarning";

export const FormikWarningList = () => {
    const warnings = useContext(FormikWarningsContext);

    return (
        <VStack
            alignItems="start"
            w="100%"
        >
            {warnings.map((i) => (
                <FormikWarning key={`${i.field}${i.message}`} warning={i} />
            ))}
        </VStack>
    );
};
