import { QuestionOutlineIcon } from "@chakra-ui/icons";
import { HStack, Text, Tooltip, VStack } from "@chakra-ui/react";

type PropTypes = {
    label?: string; // The label
    tooltip?: React.ReactNode;
    input: React.ReactNode; // The input, such as a text input or a select input
};

const LabelAndInput = (props: PropTypes) => {
    const { label, input, tooltip } = props;

    return (
        <VStack
            align="start"
            spacing="2"
            w="100%"
        >
            {(label || tooltip) && (
                <HStack spacing={2}>
                    {label && (
                        <Text
                            fontSize={"sm"}
                            whiteSpace={"nowrap"}
                            textOverflow={"ellipsis"}
                        >
                            {label}
                        </Text>
                    )}

                    {tooltip && (
                        <Tooltip
                            hasArrow
                            placement="top"
                            label={tooltip}
                        >
                            <QuestionOutlineIcon color={"gray.500"} />
                        </Tooltip>
                    )}
                </HStack>
            )}
            {input}
        </VStack>
    );
};

export default LabelAndInput;
