import { useDisclosure } from "@chakra-ui/react";
import ModalDynamic from "../../components/ModalDynamic";
import { useSmartTranslation } from "../../hooks/useSmartTranslation";
import FileUploadInput from "./FileUploadInput";

type PropTypes = {
    onUpload: (files: File[]) => void;
};

const useFileUploadModal = (props: PropTypes) => {
    const { onUpload } = props;

    const t = useSmartTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const fileUploadModal = (
        <ModalDynamic
            size="lg"
            isOpen={isOpen}
            header={t("fileUpload.button.uploadFile")}
            onClose={onClose}
        >
            <FileUploadInput
                fileTypes={[".pdf"]}
                height="200px"
                onChange={(files) => {
                    onUpload(files);
                    onClose();
                }}
            />
        </ModalDynamic>
    );

    return {
        fileUploadModal,
        isOpenFileUpload: isOpen,
        openFileUpload: onOpen,
        closeFileUpload: onClose,
    };
};

export { useFileUploadModal };
