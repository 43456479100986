import { DeleteIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Divider,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Heading,
    HStack,
    IconButton,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import PublicUserProfilePicture from "../../../../../../common/components/PublicUserProfilePicture";
import { displayDateWithCurrentLocale } from "../../../../../../common/functions/displayDateInLocale";
import { displayTimeInCurrentLocale } from "../../../../../../common/functions/displayTime";
import { useRole } from "../../../../../../common/hooks";
import { UserType } from "../../../../../../types";
import storage, {
    FirebaseCertificateType,
} from "../../../../../../utils/storage/storage";
import { usePermissions } from "../../../../app/permissions/usePermissions";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import useToast from "../../../../common/hooks/useToast";
import useTeamMemberRemove from "../../../../domain/user/useTeamMemberRemove";
import useTeamMemberUpdateRoleAccess from "../../../../domain/user/useTeamMemberUpdateRoleAccess";
import { InformationRowItem } from "../InformationRowItem";
import ProfileContactDetails from "../profile/ProfileContactDetails";
import ProfileOfficeDetails from "../profile/ProfileOfficeDetails";
import { ProfileValuationDetails } from "../profile/ProfileValuationDetails";
import { ProfileValuationStandards } from "../profile/ProfileValuationStandards";
import useTeamMemberUpdateDoesValuations from "../../../../domain/user/useTeamMemberUpdateDoesValuations";

type PropTypes = {
    user: UserType;
    closeDetails: () => void;
};

const TeamMemberDetails = (props: PropTypes) => {
    const { user, closeDetails } = props;

    const t = useSmartTranslation();

    const { createToast } = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const { userIsValuer } = useRole();

    const { permission: canRemove } = usePermissions({
        activity: "RemoveUserAccess",
        datatype: "User",
        objects: user,
    });

    const { permission: canEdit } = usePermissions({
        activity: "SetUserAccess",
        datatype: "User",
        objects: user,
    });

    const [certificates, setCertificates] = useState<FirebaseCertificateType[]>(
        []
    );

    const { removeMemberModal, openRemoveMemberModal } = useTeamMemberRemove({
        user,
        removeSuccess: () => {
            onClose();
            closeDetails();
        },
    });

    const { updateRoleAccessModal, openUpdateRoleAccessModal } =
        useTeamMemberUpdateRoleAccess({
            user,
            updateSuccess: () => {
                onClose();
                closeDetails();
            },
        });

    const { updateDoesValuationsAccessModal, openUpdateDoesValuationsAccessModal } =
        useTeamMemberUpdateDoesValuations({
            user,
            updateSuccess: () => {
                onClose();
                closeDetails();
            },
        });

    useEffect(() => {
        if (!user) return;

        const getCertificates = async () => {
            const certificateList = await storage.getUserCertificates(user.id);
            setCertificates(certificateList);
        };

        onOpen();

        try {
            getCertificates();
        } catch {
            createToast({ status: "error" });
        }
    }, []);

    // render

    const basicData = (
        <BasicDetails
            user={user}
            canRemove={canRemove}
            canEdit={canEdit}
            revoceAccess={openRemoveMemberModal}
            updateRoleAccess={openUpdateRoleAccessModal}
            updateDoesValuationsAccess={openUpdateDoesValuationsAccessModal}
        />
    );

    const companyData = (
        <>
            <Divider my={5} />
            <Heading
                size="md"
                variant="blue"
                mb={3}
            >
                {t("profile.heading.officeLocation")}
            </Heading>

            <ProfileOfficeDetails
                user={user}
                userIsValuer={userIsValuer}
                gridTemplateColumns={"100%"}
            />
        </>
    );

    const valuationStandards = (
        <>
            <Divider my={5} />
            <Heading
                size="md"
                variant="blue"
                mb={3}
            >
                {t("profile.heading.valuationStandards")}
            </Heading>

            <ProfileValuationStandards
                user={user}
                certificates={certificates}
                hideUploadButton
            />
        </>
    );

    const valuationDetails = (
        <>
            <Divider my={5} />
            <Heading
                size="md"
                variant="blue"
                mb={3}
            >
                {t("profile.heading.valuationDetails")}
            </Heading>

            <ProfileValuationDetails user={user} />
        </>
    );

    const valuerDetails = userIsValuer && (
        <>
            {valuationStandards}
            {valuationDetails}
        </>
    );

    return (
        <Drawer
            isOpen={isOpen}
            onClose={() => {
                onClose();
                closeDetails();
            }}
            size={userIsValuer ? "xl" : "lg"}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader
                    fontSize={"xl"}
                    color={"blue.700"}
                >
                    {t("account.teamDetails.teamMemberPreview.title")}
                </DrawerHeader>

                <DrawerBody>
                    {basicData}
                    {companyData}
                    {valuerDetails}

                    {removeMemberModal}
                    {updateRoleAccessModal}
                    {updateDoesValuationsAccessModal}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

type BasicDetailsProps = {
    user: UserType;
    canRemove: boolean | undefined;
    canEdit: boolean | undefined;
    updateRoleAccess: () => void;
    updateDoesValuationsAccess: () => void;
    revoceAccess: () => void;
};

const BasicDetails = (props: BasicDetailsProps) => {
    const {
        user: { id: userId, entityId, firstName, lastName, createdAt, role, doesValuations },
        user,
        canRemove,
        canEdit,
        revoceAccess,
        updateRoleAccess,
        updateDoesValuationsAccess,
    } = props;

    const t = useSmartTranslation();

    const date = displayDateWithCurrentLocale(createdAt);
    const time = displayTimeInCurrentLocale(new Date(createdAt));

    // render

    const removeAccess = canRemove && (
        <Button
            variant={"outline"}
            colorScheme={"red"}
            size={"xs"}
            leftIcon={<DeleteIcon />}
            onClick={revoceAccess}
        >
            {t("account.teamDetails.button.revokeAccess")}
        </Button>
    );

    const updateRole = (
        <Box mt={1}>
            <InformationRowItem
                label={t("account.teamInvites.role")}
                content={
                    <HStack justifyContent={"space-between"}>
                        <VStack
                            spacing={0}
                            align={"stretch"}
                        >
                            <Text fontSize={"sm"}>
                                {t(`domain.user.role.${role}`)}
                            </Text>
                            <Text
                                fontSize={"sm"}
                                color={"gray.500"}
                                fontWeight={"500"}
                            >
                                {t(
                                    `account.teamInvites.roleDescriptions.${role}`
                                )}
                            </Text>
                        </VStack>

                        {canEdit && (
                            <IconButton
                                aria-label="edit"
                                icon={<MdEdit />}
                                isRound={true}
                                onClick={updateRoleAccess}
                                size={"sm"}
                                variant={"ghost"}
                                color="gray.400"
                                _hover={{
                                    bg: "white",
                                    color: "blue.500",
                                }}
                            />
                        )}
                    </HStack>
                }
            />
        </Box>
    );

    const updateDoesValuations = (
        <Box mt={1}>
            <InformationRowItem
                label={t("profile.profileDetails.doesValuations")}
                content={
                    <HStack justifyContent={"space-between"}>
                        <Text fontSize={"sm"}>
                            {t(`common.${doesValuations ? "yes" : "no"}`)}
                        </Text>

                        {canEdit && (
                            <IconButton
                                aria-label="edit"
                                icon={<MdEdit />}
                                isRound={true}
                                onClick={updateDoesValuationsAccess}
                                size={"sm"}
                                variant={"ghost"}
                                color="gray.400"
                                _hover={{
                                    bg: "white",
                                    color: "blue.500",
                                }}
                            />
                        )}
                    </HStack>
                }
            />
        </Box>
    );

return (
    <>
        <Divider mb={4} />
        <HStack
            align={"center"}
            justify={"space-between"}
            mb={4}
        >
            <HStack spacing={4}>
                <PublicUserProfilePicture
                    size="2.5rem"
                    userId={userId}
                    entityId={entityId}
                />
                <VStack
                    align={"start"}
                    spacing={0}
                >
                    <Heading fontSize={"lg"}>
                        {firstName} {lastName}
                    </Heading>
                    <Text
                        fontSize={"xs"}
                        color={"gray.500"}
                    >
                        {t("domain.user.columns.createdAt")} {date}, {time}
                    </Text>
                </VStack>
            </HStack>

            {removeAccess}
        </HStack>

        <ProfileContactDetails user={user} />

        {updateDoesValuations}

        {updateRole}
    </>
);
};

export default TeamMemberDetails;
