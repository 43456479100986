import AccountScreen from "./AccountScreen";
import CompanyDetailsScreen from "./company-details/CompanyDetailsScreen";
import CompanyDetailsEdit from "./company-details/edit/CompanyDetailsEdit";
import DocumentsScreen from "./documents/DocumentsScreen";
import IntegrationsScreen from "./integrations/IntegrationsScreen";
import ProfileDetailsEdit from "./profile/edit/ProfileDetailsEdit";
import ProfileDetailsScreen from "./profile/ProfileDetailsScreen";
import TeamScreen from "./team/TeamScreen";
import ValuerPanelScreen from "./valuer-panel/ValuerPanelScreen";

export const Account = {
    Screen: AccountScreen,
    CompanyDetails: CompanyDetailsScreen,
    CompanyDetailsEdit: CompanyDetailsEdit,
    ProfileDetails: ProfileDetailsScreen,
    ProfileDetailsEdit: ProfileDetailsEdit,
    Team: TeamScreen,
    Integrations: IntegrationsScreen,
    ValuerPanel: ValuerPanelScreen,
    Documents: DocumentsScreen,
};
