import { AuthError, createUserWithEmailAndPassword } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { auth } from '../../firebase';
import useToast from '../../../ui/v2/common/hooks/useToast';

type ParamsType = {
    onSuccess?: (data?: unknown) => void;
    onError?: (error: AuthError) => void;
};

type DtoType = {
    email: string;
    password: string;
};

const useFbCreateUserWithEmailAndPassword = (params: ParamsType) => {
    const { t } = useTranslation(['common']);

    const {
        onSuccess,
        onError,
    } = params;

    const { createToast } = useToast();

    const { mutate: update } = useMutation(
        ({ email, password }: DtoType) => createUserWithEmailAndPassword(auth, email, password),
        {
            onSuccess,
            onError: (error: AuthError) => {
                if (error.code === 'auth/email-already-in-use') {
                    createToast({
                        description: t('error.authError.emailAlreadyInUse', { ns: 'common' }),
                        status: 'error',
                    });
                } else if (error.code === 'auth/invalid-email') {
                    createToast({
                        description: t('error.authError.invalidEmail', { ns: 'common' }),
                        status: 'error',
                    });
                } else {
                    createToast({
                        description: error.message,
                        status: 'error',
                    });
                }

                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useFbCreateUserWithEmailAndPassword;
