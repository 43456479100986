import {
    Box,
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
    Spacer,
    VStack,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import getCroppedImage from '../functions/getCroppedImage';

type PropTypes = {
    image: string | undefined;
    onImageSave: (image: File) => void;
};

const initialCropArea = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
};

const ImageCropModal = (props: PropTypes) => {
    const { t } = useTranslation('common');

    const {
        image,
        onImageSave,
    } = props;

    const [crop, setCrop] = useState({ x: 5, y: 5 });
    const [croppedArea, setCroppedArea] = useState(initialCropArea);
    const [zoom, setZoom] = useState(1);

    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        setOpenModal(!!image);
    }, [image]);

    const closeModal = () => {
        setOpenModal(false);
        setCroppedArea(initialCropArea);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
    };

    const cropComplete = useCallback((
        _area: { x: number; y: number; width: number; height: number; },
        areaPixels: { x: number; y: number; width: number; height: number; },
    ) => {
        setCroppedArea(areaPixels);
    }, []);

    return (
        <Modal
            isOpen={openModal}
            onClose={closeModal}
        >
            <ModalOverlay />

            <ModalContent>
                <ModalHeader>
                    {t('cropImage')}
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <VStack
                        alignItems="right"
                        spacing="6"
                        w="100%"
                    >
                        <Box
                            h="400px"
                            position="relative"
                            w="100%"
                        >
                            <Cropper
                                image={image}
                                crop={crop}
                                cropShape="round"
                                zoom={zoom}
                                objectFit="cover"
                                aspect={1}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={cropComplete}
                            />
                        </Box>

                        <Slider
                            min={1}
                            max={3}
                            step={0.1}
                            value={zoom}
                            onChange={setZoom}
                        >
                            <SliderTrack>
                                <SliderFilledTrack bg="wvwGreen" />
                            </SliderTrack>

                            <SliderThumb />
                        </Slider>

                        <Flex>
                            <Button
                                variant="default"
                                onClick={closeModal}
                            >
                                {t('button.discard')}
                            </Button>

                            <Spacer />

                            <Button
                                variant="primary"
                                onClick={() => {
                                    const croppedImage = getCroppedImage(
                                        image as string,
                                        croppedArea,
                                        true,
                                    );

                                    onImageSave(croppedImage as File);

                                    closeModal();

                                    setCroppedArea(initialCropArea);
                                    setCrop({ x: 0, y: 0 });
                                    setZoom(1);
                                }}
                            >
                                {t('button.save')}
                            </Button>
                        </Flex>
                    </VStack>
                </ModalBody>

                <ModalFooter />
            </ModalContent>
        </Modal>
    );
};

export default ImageCropModal;
