import { VStack } from "@chakra-ui/react";
import { ReactNode } from "react";
import FormSection from "./FormSection";

type PropsType = {
    warningIcon?: boolean;
    header: string;
    subHeader?: string;
    children: ReactNode | ReactNode[];
};

const FormSectionProfileEdit = (props: PropsType) => {
    const { header, subHeader, children, warningIcon } = props;

    return (
        <FormSection
            header={header}
            icon={warningIcon ? "warning" : undefined}
            subHeader={subHeader}
            variant="white"
            element={
                <VStack
                    spacing="6"
                    w="100%"
                >
                    {children}
                </VStack>
            }
        />
    );
};

export default FormSectionProfileEdit;
