import { Search2Icon } from "@chakra-ui/icons";
import { HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { displayDateWithCurrentLocale } from "../../../../../../common/functions/displayDateInLocale";
import { StandardSystemStandardsOfferedWithOther } from "../../../../../../common/vars/valuationsAndMembershipTypes/standardSystemStandardsOffered";
import { EnquiryType, UserType } from "../../../../../../types";
import { FirebaseCertificateType } from "../../../../../../utils/storage/storage";
import { useDocumentViewerModal } from "../../../../domain/document/useDocumentViewerModal";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Icons } from "../../../../common/icons/Icons";
import MatchableItem from "./MatchableItem";

type ValuerQualificationsProps = {
    valuer: UserType;
    enquiry: EnquiryType;
    certificates: FirebaseCertificateType[];
};

const ValuerQualifications = (props: ValuerQualificationsProps) => {
    const {
        valuer: { systemStandardsOffered },
        enquiry: { valuationStandards: requestedValuationStandards },
        certificates,
    } = props;

    const t = useSmartTranslation();

    const { documentView, viewDocument } = useDocumentViewerModal();

    // render

    const standarts = (
        <HStack
            spacing={3}
            mb={"1rem"}
            align={"start"}
        >
            <Text
                flex={"0 0 22%"}
                fontSize={"sm"}
                variant={"secondary"}
                lineHeight={"1.75rem"}
            >
                {t("domain.enquiry.standards")}
            </Text>
            <HStack wrap={"wrap"}>
                {systemStandardsOffered?.map((standard) => (
                    <MatchableItem
                        key={standard}
                        label={standard}
                        checked={requestedValuationStandards === standard}
                    />
                ))}
            </HStack>
        </HStack>
    );

    const uploadedCertificates = (
        <HStack
            spacing={3}
            align={"start"}
        >
            <Text
                flex={"0 0 22%"}
                fontSize={"sm"}
                variant={"secondary"}
                lineHeight={"1.75rem"}
            >
                {t(
                    "screens.platform.client.offerDetails.valuerQualifications.certificates"
                )}
            </Text>
            <VStack
                flexGrow={1}
                align={"stretch"}
            >
                {certificates.map((certificate) => (
                    <CertificateItem
                        key={certificate.label}
                        certificate={certificate}
                        standards={requestedValuationStandards}
                        onDocumentClick={() => {
                            viewDocument({
                                uri: certificate.url,
                                label: certificate.label,
                                fileType: certificate.meta.contentType,
                            });
                        }}
                    />
                ))}
            </VStack>
        </HStack>
    );

    return (
        <>
            {standarts}
            {uploadedCertificates}
            {documentView}
        </>
    );
};

type CertificateItemProps = {
    certificate: FirebaseCertificateType;
    standards: StandardSystemStandardsOfferedWithOther;
    onDocumentClick: () => void;
};

const CertificateItem = (props: CertificateItemProps) => {
    const {
        certificate: { label, type, meta },
        standards,
        onDocumentClick,
    } = props;

    const t = useSmartTranslation();

    return (
        <MatchableItem
            checked={type === standards}
            leftIcon={Icons.Certificate}
            label={label}
            rightSection={
                <HStack spacing={3}>
                    <Text
                        fontSize={"sm"}
                        color={"gray.500"}
                    >
                        {t(
                            "screens.platform.client.offerDetails.valuerQualifications.updated",
                            {
                                date: displayDateWithCurrentLocale(
                                    meta.updated
                                ),
                            }
                        )}
                    </Text>
                    <IconButton
                        icon={<Search2Icon />}
                        size="xs"
                        variant="ghost"
                        color="gray.500"
                        aria-label="search"
                        onClick={onDocumentClick}
                    />
                </HStack>
            }
            _hover={{
                bg: "blue.50",
                color: "blue.500",
            }}
        />
    );
};

export { ValuerQualifications };
