import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../../utils/firebase";
import { useAdminView } from "../../../common/features/admin-view/AdminViewContext";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";

const Logout = () => {
    const t = useSmartTranslation();
    const navigate = useNavigate();
    const { toggleAdminView } = useAdminView();


    useEffect(() => {
        logout();
        toggleAdminView(false);
        navigate("/login");
    }, []);

    return <div>{t("common.loading")}</div>;
};

export default Logout;
