import { UserType } from "../../../../../../types";
import DataTable from "../../../../common/components/data-table/DataTable";
import { teamColumns } from "./TeamColumns";

type TeamTableProps = {
    users: UserType[];
    onRowClick: (item: UserType) => void;
};

const TeamTable = (props: TeamTableProps) => {
    const { users, onRowClick } = props;

    return (
        <DataTable
            fullSectionWidth
            data={users}
            onRowClick={onRowClick}
            columns={[
                teamColumns.user,
                teamColumns.role,
                teamColumns.email,
                teamColumns.createdAt,
            ]}
        />
    );
};

export default TeamTable;
