import { Box, SimpleGrid, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../../../../../common/hooks";
import {
    useCaseActiveCases,
    useEnquiryGetAll,
    useMyEntity,
    useOfferGetAllMyOffers,
    useUsersByEntity,
} from "../../../../../../utils/api/hooks";
import { Feature } from "../../../../app/features/Feature";
import useMe from "../../../../app/useMeV2";
import DataTable from "../../../../common/components/data-table/DataTable";
import { GoogleMapV2 } from "../../../../common/components/display";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useAdminView } from "../../../../common/features/admin-view/AdminViewContext";
import useFilterEnquiryData from "../../../../common/features/admin-view/useFilterEnquiryData";
import AwaitingAccessBanner from "../../../../common/features/awaiting-access/AwaitingAccessBanner";
import CompletionImpulse from "../../../../common/features/completion-impulse/CompletionImpulse";
import Statistics from "../../../../common/features/statistics/Statistics";
import { useDevice } from "../../../../common/hooks/useDevice";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { offerValuerProfile } from "../../../../domain/enquiry/columns/EnquiryWithOfferColumns";
import { Section } from "../../../../layout/Section";
import ProfileCompletionBanner from "../../profile-completion/ProfileCompletionBanner";
import {
    columns as caseColumns,
    caseEnquiryColumns,
} from "./data-table/CasesTable";
import {
    createCurrentOfferEnquiryData,
    createRecentMatchesData,
    columns as enquiryColumns,
    enquiryWithMatchingOfferColumns,
} from "./data-table/EnquiryTable";
import {
    allActiveCases,
    allMatchingEnquiriesStatistic,
    allOffersSentStatistic,
    allPendingOffersStatistic,
    allRejectedOffersStatistic,
    caseConversionRate,
} from "./EnquiryAndCaseStatistics";
import { hasValuerRequiredMatchmakingFields } from "./experience-customization/checks";
import ValuerExperienceCustomization from "./experience-customization/ValuerExperienceCustomizationList";

const Dashboard = () => {
    const { data: unfilteredEnquiries = [], isLoading: enquiriesLoading } =
        useEnquiryGetAll();
    const { data: unfilteredOffers = [], isLoading: offersLoading } =
        useOfferGetAllMyOffers();
    const {
        data: entity,
        isLoading: entityLoading,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
    } = useMyEntity();
    const { data: cases = [], isLoading: casesLoading } = useCaseActiveCases();
    const {
        data: user,
        isLoading: userLoading,
        myCertificates = [],
        myCertificatesIsLoading,
    } = useMe();
    const { usersList = [], isLoading: usersLoading } = useUsersByEntity(
        entity?.id
    );
    const { userIsValuer, userIsClient, userIsCompanyAdmin, roleLoading } =
        useRole();

    const { enquiries, offers } = useFilterEnquiryData({
        enquiries: unfilteredEnquiries,
        offers: unfilteredOffers,
    });

    const { inAdminView } = useAdminView();

    const navigate = useNavigate();

    const awaitingAccess = user?.accessPending === true;

    const t = useSmartTranslation();

    const device = useDevice();

    const allEnquiryPropertyLocations = enquiries.flatMap((enquiry) =>
        enquiry.properties.map((property) => ({
            id: property.id,
            lat: Number(property.latitude) || 0,
            lng: Number(property.longitude) || 0,
        }))
    );

    if (
        !user ||
        enquiriesLoading ||
        offersLoading ||
        entityLoading ||
        casesLoading ||
        userLoading ||
        usersLoading ||
        roleLoading ||
        myCertificatesIsLoading ||
        entitySampleValuationsIsLoading
    )
        return <LoadingSpinner />;

    // profile completion
    const profileCompletionBanner = (
        <Feature.Off name={Feature.FEATURES.ONBOARDING_IMPULSES}>
            <ProfileCompletionBanner
                user={user}
                entity={entity}
                userCertificates={myCertificates}
                sampleValuations={entitySampleValuations}
                userRoles={{
                    userIsClient,
                    userIsCompanyAdmin,
                    userIsValuer,
                }}
            />
        </Feature.Off>
    );

    const companyOnboarding = userIsCompanyAdmin && (
        <Feature.On name={Feature.FEATURES.ONBOARDING_IMPULSES}>
            <CompletionImpulse
                title={t("completion.companyOnboarding.title")}
                subtitle={t("completion.companyOnboarding.description")}
                impulses={[
                    {
                        title: t("completion.completeProfile.title"),
                        completionTime: t("completion.completeProfile.completionTime"),
                        buttonUrl: "/complete-profile/personal",
                        requiredForMatchmaking: true,
                        isDone: hasValuerRequiredMatchmakingFields(user),
                    },
                ]}
            />
        </Feature.On>
    );

    // statistics

    const numPendingOffers = allPendingOffersStatistic(offers);
    const numMatchingEnquiries = allMatchingEnquiriesStatistic(enquiries);
    const numAllOffersSent = allOffersSentStatistic(offers, enquiries);
    const numAllRejectedOffers = allRejectedOffersStatistic(offers, enquiries);
    const numAllActiveCases = allActiveCases(cases, enquiries);
    const percentageOfCompletedCases = caseConversionRate(cases, enquiries);
    const enquiryStatsData = [
        numPendingOffers,
        numMatchingEnquiries,
        numAllOffersSent,
        numAllRejectedOffers,
    ];
    const caseStatsData = [numAllActiveCases, percentageOfCompletedCases];

    // statistics
    const enquiryStats = (
        <Section
            title={t("domain.enquiry.enquiries")}
            content={<Statistics stats={enquiryStatsData} />}
        />
    );

    const caseStats = (
        <Section
            title={t("domain.case.cases")}
            content={<Statistics stats={caseStatsData} />}
        />
    );

    // enquiries
    const recentMatchesData = createRecentMatchesData({
        enquiries: enquiries,
        offers: offers,
        user,
    });

    const recentMatches = (
        <Section
            collapsable
            title={t("valuerDashboard.recentMatchesWithCount", {
                count: recentMatchesData.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={recentMatchesData}
                    columns={enquiryColumns}
                    noDataText={t("valuerDashboard.noRecentMatches")}
                    onRowClick={(enquiry) => {
                        navigate(`/dashboard/enquiry/${enquiry.id}`);
                    }}
                />
            }
        />
    );

    const caseEnquiries = (
        <Section
            collapsable
            title={t("valuerDashboard.caseEnquiries", {
                count: cases.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={cases}
                    columns={caseEnquiryColumns}
                    noDataText={t("valuerDashboard.noCaseEnquiries")}
                    onRowClick={(cases) => {
                        navigate(`/dashboard/case/${cases.id}`);
                    }}
                />
            }
        />
    );

    const currentOfferEnquiryData = createCurrentOfferEnquiryData({
        enquiries,
        offers,
        user,
        usersList,
    });

    const sortedCurrentOfferEnquiryData = currentOfferEnquiryData.sort(
        (a, b) => {
            const latestOfferA = a.offers.sort(
                (x, y) =>
                    new Date(y.updatedAt).getTime() -
                    new Date(x.updatedAt).getTime()
            )[0];
            const latestOfferB = b.offers.sort(
                (x, y) =>
                    new Date(y.updatedAt).getTime() -
                    new Date(x.updatedAt).getTime()
            )[0];

            return (
                new Date(latestOfferB.updatedAt).getTime() -
                new Date(latestOfferA.updatedAt).getTime()
            );
        }
    );

    const enquiriesWithMatchingOffer = (
        <Section
            collapsable
            title={t("valuerDashboard.currentOffers", {
                count: sortedCurrentOfferEnquiryData.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={sortedCurrentOfferEnquiryData}
                    columns={
                        inAdminView
                            ? [
                                  enquiryWithMatchingOfferColumns[0],
                                  offerValuerProfile,
                                  ...enquiryWithMatchingOfferColumns.slice(1),
                              ]
                            : enquiryWithMatchingOfferColumns
                    }
                    noDataText={t("valuerDashboard.noCurrentOffers")}
                    onRowClick={(enquiry) => {
                        navigate(`/dashboard/enquiry/${enquiry.id}`);
                    }}
                />
            }
        />
    );

    // cases
    const ongoingCases = (
        <Section
            collapsable
            title={t("valuerDashboard.activeCasesWithCount", {
                count: cases.length,
            })}
            content={
                <DataTable
                    fullSectionWidth
                    data={cases}
                    columns={caseColumns}
                    noDataText={t("valuerDashboard.noAcceptedStartedValuation")}
                    onRowClick={(cases) => {
                        navigate(`/dashboard/case/${cases.id}`);
                    }}
                />
            }
        />
    );

    // account and profile

    const caseDependentTablesAndStats = (
        <>
            <SimpleGrid
                w="100%"
                columnGap={4}
                gridTemplateColumns={
                    device === "mobile"
                        ? "100%"
                        : "minmax(0, 50%) minmax(0, 50%)"
                }
            >
                <VStack spacing={4}>
                    {enquiryStats}
                    {recentMatches}
                </VStack>

                <Feature.Off name={Feature.FEATURES.CASE}>
                    <Box
                        marginTop={{
                            base: "1rem",
                            xl: "0",
                        }}
                        bgColor="white"
                        padding="1rem"
                        borderRadius={8}
                        minH="12rem"
                        w="100%"
                    >
                        <GoogleMapV2
                            height="100%"
                            width="100%"
                            markers={allEnquiryPropertyLocations}
                        />
                    </Box>
                </Feature.Off>

                <Feature.On name={Feature.FEATURES.CASE}>
                    <VStack spacing={4}>
                        {caseStats}
                        {enquiriesWithMatchingOffer}
                    </VStack>
                </Feature.On>
            </SimpleGrid>

            <Feature.Off name={Feature.FEATURES.CASE}>
                {enquiriesWithMatchingOffer}
                {caseEnquiries}
            </Feature.Off>
        </>
    );

    return (
        <VStack
            w="100%"
            p={4}
            spacing={4}
        >
            {profileCompletionBanner}
            {companyOnboarding}

            {awaitingAccess && <AwaitingAccessBanner />}
            {caseDependentTablesAndStats}
            <Feature.On name={Feature.FEATURES.CASE}>{ongoingCases}</Feature.On>
            <Feature.Off name={Feature.FEATURES.ONBOARDING_IMPULSES}>
                <ValuerExperienceCustomization user={user} />
            </Feature.Off>
        </VStack>
    );
};

export default Dashboard;
