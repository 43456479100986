import { FormikSelect } from "../../../../common/forms";
import { useSmartTranslation } from "../../../../common/hooks";
import { standardValuerMaxOperatingDistance } from "../../../../common/vars/valuationsAndMembershipTypes";
import FormSection from "./FormSection";

const ProfileOperationalAreaEdit = () => {
    const t = useSmartTranslation("profile");

    return (
        <FormSection
            header={t("heading.operationalArea")}
            subHeader={t("subHeading.operationalArea")}
            variant="white"
            element={
                <FormikSelect
                    name="maxOperatingDistance"
                    placeholder={t("profileDetails.valuerMaxOperationDistance")}
                    options={standardValuerMaxOperatingDistance.map((distance) => ({
                        label: t(distance.label, {
                            ns: "formik",
                            defaultValue: distance.label,
                        }),
                        value: distance.value,
                    }))}
                />
            }
        />
    );
};

export default ProfileOperationalAreaEdit;
