import { Flex } from "@chakra-ui/react";
import ActiveCasesWidget from "./widgets/ActiveCasesWidget";
import ActiveEnquiriesWidget from "./widgets/ActiveEnquiriesWidget";
import OfferConversionRateWidget from "./widgets/OfferConversionRateWidget";
import InviteLinkWidget from "./widgets/InviteLinkWidget";
import { Feature } from "../../../../ui/v2/app/features/Feature";

type PropTypes = {
    userIsValuer: boolean;
    scrollToRef?: () => void;
};

const DashboardWidgets = (props: PropTypes) => {
    const { userIsValuer, scrollToRef } = props;

    return (
        <Flex
            w="100%"
            justifyContent="space-evenly"
            flexWrap="wrap"
            paddingBlock="2rem"
            gap="1rem"
        >
            <ActiveEnquiriesWidget />

            <Feature.On name={Feature.FEATURES.CASE}>
                <ActiveCasesWidget />
            </Feature.On>

            {userIsValuer && (
                <>
                    <OfferConversionRateWidget scrollToRef={scrollToRef} />

                    <InviteLinkWidget />
                </>
            )}
        </Flex>
    );
};

export default DashboardWidgets;
