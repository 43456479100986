import { Account } from "./account/Account";
import { EnquiryList } from "./enquiry-list/EnquiryList";
import EnquirySuccess from "./EnquirySuccess";
import { NotificationsScreen } from "./notifications/NotificationsScreen";

export const PlatformScreens = {
    EnquiryList,
    EnquirySuccess,
    Account,
    NotificationsScreen,
};
