import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../../common/components/ConfirmationModal';

type PropTypes = {
    content?: string | undefined;
    isOpen: boolean;
    onCancel: () => void;
    onContinue?: () => void;
};

const CancelEnquiryModal = (props: PropTypes) => {
    const {
        content,
        isOpen,
        onCancel,
        onContinue,
    } = props;

    const { t } = useTranslation(['common', 'enquiryform']);
    const navigate = useNavigate();

    return (
        <ConfirmationModal
            cancelButton={t('button.continueEditing')}
            continueButton={t('button.exit')}
            isOpen={isOpen}
            title={t('warning.exit.title')}
            content={content || t('newEnquiry.exitModalText', { ns: 'enquiryform' })}
            onCancel={onCancel}
            onContinue={() => {
                if (onContinue) {
                    onContinue();
                } else {
                    navigate('/dashboard');
                }
            }}
        />
    );
};

export default CancelEnquiryModal;
