import { WithFeature, WithFeatureOff, WithFeatureOn } from "./FeatureFlag";
import { FEATURES } from "./FEATURES";
import { isFeatureOff, isFeatureOn } from "./isFeatureOn";

export const Feature = {
    FEATURES,
    WithFeature,
    On: WithFeatureOn,
    Off: WithFeatureOff,
    isOn: isFeatureOn,
    isOff: isFeatureOff,
};
