import { useRole } from "../../../../../../../common/hooks";
import {
    useSmartTranslation,
} from "../../../../../common/hooks/useSmartTranslation";
import { User } from "../../../../../domain";
import { Section } from "../../../../../layout/Section";
import CustomizeExperience from "../../../client/customize-experience/CustomizeExperience";
import { isOfficeAddressComplete, isProfileComplete } from "./checks";
import { valuerCustomizations } from "./customizations";

type ValuerExperienceCustomizationProps = {
    user: User;
};

const ValuerExperienceCustomization = ({
    user,
}: ValuerExperienceCustomizationProps) => {
    const { doesValuations } = user;

    const { userIsValuer, userIsCompanyAdmin } = useRole();

    const t = useSmartTranslation();

    const matchmakingInfoCustomiseExperienceCompleted = isProfileComplete(
        user,
        userIsCompanyAdmin
    );

    const officeAddressComplete = isOfficeAddressComplete(user);

    return (
        <Section
            collapsable
            title={t(
                "valuerDashboard.customizeExperience.customizeYourExperience"
            )}
            content={
                <CustomizeExperience
                    customizeList={[
                        valuerCustomizations.completeProfile(t),
                        ...(doesValuations && !officeAddressComplete
                            ? [valuerCustomizations.officeAddress(t)]
                            : []),
                        ...((doesValuations ||
                            (userIsCompanyAdmin && userIsValuer)) &&
                        !matchmakingInfoCustomiseExperienceCompleted
                            ? [valuerCustomizations.matchmakingInfo(t, userIsCompanyAdmin)]
                            : []),
                        ...(userIsCompanyAdmin
                            ? [
                                valuerCustomizations.teamMembers(t),
                                valuerCustomizations.marketingMaterial(t),
                                valuerCustomizations.piCover(t),
                                valuerCustomizations.valuationSamples(t),
                              ]
                            : []),
                    ]}
                />
            }
        />
    );
};

export default ValuerExperienceCustomization;
