import { Button } from "@chakra-ui/react";
import { useFormikContext } from "formik";

type PropTypes = {
    content: string;
    variant?: "primary" | "outline";
    mustBeClicked?: boolean;
    width?: string;
    type?: "submit" | "button";
};

const FormikSubmitButton = (props: PropTypes) => {
    const { width = '15rem', content, variant = 'primary', mustBeClicked = false, type = "submit" } = props;

    const {
        handleSubmit,
        // isValid,
        isSubmitting
    } = useFormikContext();

    let eventualType = type;
    let onClick;

    if (mustBeClicked) {
        eventualType = "button";
        onClick = () => handleSubmit();
    }

    return (
        <Button
            onClick={onClick}
            width={width}
            variant={variant}
            isDisabled={isSubmitting}
            type={eventualType}
        >
            {content}
        </Button>
    );
};

export default FormikSubmitButton;
