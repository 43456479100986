import {
    Text,
} from '@chakra-ui/react';
import { FieldInputProps, useField } from 'formik';
import Calendar from 'react-calendar';
import { useTranslation } from 'react-i18next';
import 'react-calendar/dist/Calendar.css';

type PropTypes = {
    name: string;
    onChange?: (value: Date, field?: FieldInputProps<any>) => void,
};

const FormikDateSelect = (props: PropTypes) => {
    const { t } = useTranslation('formik');

    const [field, meta] = useField({
        ...props,
        onChange: undefined,
    });

    const { onChange } = props;

    return (
        <div
            style={{
            }}
        >
            <Calendar
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                onChange={e => {
                    if (e !== null) {
                        const dateString = e.toString();

                        const date = new Date(dateString);

                        field.onChange({
                            target: {
                                value: date,
                                name: field.name,
                            },
                        });

                        onChange?.(date, field);
                    }
                }}
                value={field.value}
            />

            {meta.touched
                && meta.error
                && (
                    <Text
                        color="red"
                    >
                        {t(meta.error, { defaultValue: meta.error })}
                    </Text>
                )}
        </div>
    );
};

export default FormikDateSelect;
