import {
    Alert,
    AlertIcon,
    Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

type PropType = {
    message: string;
    updateNeeded?: string;
    isMandatory?: boolean;
};

const WvwAlertBanner = (props: PropType) => {
    const { message, updateNeeded = null, isMandatory = false } = props;

    const navigate = useNavigate();

    return (
        <Flex
            textColor="white"
            bgColor={isMandatory ? 'red' : 'wvwYellow'}
            _hover={updateNeeded ? { bgColor: isMandatory ? 'red80' : 'wvwYellow80' } : undefined}
            paddingInline="1rem"
            fontWeight="bold"
            as={updateNeeded ? 'button' : 'div'}
            w="100%"
            textAlign="left"
            onClick={updateNeeded ? () => navigate(updateNeeded) : undefined}
        >
            <Alert>
                <AlertIcon />
                {message}
            </Alert>
        </Flex>
    );
};

export default WvwAlertBanner;
