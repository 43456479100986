import { HStack, VStack } from "@chakra-ui/react";
import { Forms } from "../../../../../common/forms/Forms";
import { useSmartTranslation } from "../../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../../layout";

const CompanyDetailsBillingEdit = () => {
    const t = useSmartTranslation();

    return (
        <Section
            title={t("account.companyDetails.billingAddress")}
            subtitle={t("account.companyDetails.billingAddressDescription")}
            content={
                <VStack
                    spacing="4"
                    align={"stretch"}
                >
                    <HStack spacing={4}>
                        <Forms.FormikInput
                            name="name"
                            label={t("account.companyDetails.name")}
                            placeholder={t(
                                "account.fieldPlaceholder.companyName"
                            )}
                        />

                        <HStack
                            spacing={4}
                            w={"100%"}
                        >
                            <Forms.FormikInput
                                name="contactFirstName"
                                label={t("account.companyDetails.firstName")}
                                placeholder={t(
                                    "account.companyDetails.firstName"
                                )}
                            />

                            <Forms.FormikInput
                                name="contactLastName"
                                label={t("account.companyDetails.lastName")}
                                placeholder={t(
                                    "account.companyDetails.lastName"
                                )}
                            />
                        </HStack>
                    </HStack>

                    <HStack
                        spacing={4}
                        flexWrap={{
                            base: "wrap",
                            md: "nowrap",
                        }}
                    >
                        <Forms.FormikInput
                            name="street"
                            label={t("account.companyDetails.street")}
                            placeholder={t("account.companyDetails.street")}
                        />
                        <Forms.FormikInput
                            name="postalCode"
                            label={t("account.companyDetails.postalCode")}
                            placeholder={t("account.companyDetails.postalCode")}
                        />
                        <Forms.FormikInput
                            name="city"
                            label={t("account.companyDetails.city")}
                            placeholder={t("account.companyDetails.city")}
                        />
                        <Forms.FormikCountrySelect
                            name="country"
                            label={t("account.companyDetails.country")}
                            width="100%"
                        />
                    </HStack>

                    <Forms.FormikInput
                        name="vatNumber"
                        label={t("account.companyDetails.vatNumber")}
                        placeholder={t("account.companyDetails.vatNumber")}
                        width="calc(50% - .5rem)"
                    />
                </VStack>
            }
        />
    );
};

export default CompanyDetailsBillingEdit;
