import { useContext } from "react";
import { FormikValidationContext, FormikWarningsContext, hasFormikWarning } from "./FormikForm";
import { useField } from "formik";

export const useFormikMeta = (fieldName: string) => {
    const [field, meta] = useField({
        name: fieldName,
    });

    const isFieldRequired = useContext(FormikValidationContext);
    const warnings = useContext(FormikWarningsContext);

    const warning = hasFormikWarning(fieldName, warnings);
    const hasWarning = warning !== undefined;
    const errorBorderColor = warning && "orange.500";
    const isRequired = isFieldRequired(fieldName);
    const isInvalid = (!!meta.touched && !!meta.error) || !!warning;

    return {
        field,
        meta,
        errorBorderColor,
        hasWarning,
        warning,
        isRequired,
        isInvalid,
    };
};
