import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { fromAddress } from "react-geocode";
import { entityGet, entityUpdate } from "../endpoints";
import { EntityType } from "../../../types";
import { UpdateEntityDto } from "../dto";
import storage from "../../storage/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import useToast from "../../../ui/v2/common/hooks/useToast";

type ParamsType = {
    onUpdateSuccess?: () => void;
    onUpdateError?: (error: Error) => void;
};

const MY_ENTITY = "MY_ENTITY";
const MY_ENTITY_CERTIFICATES = "MY_ENTITY_CERTIFICATES";
const MY_ENTITY_PROFILE_PICTURE = "MY_ENTITY_PROFILE_PICTURE";
const MY_ENTITY_SAMPLE_VALUATIONS = "MY_ENTITY_SAMPLE_VALUATIONS";

const useMyEntity = (params: ParamsType = {}) => {
    const { onUpdateSuccess, onUpdateError } = params;

    const { t } = useTranslation("common");
    const { createToast } = useToast();

    const [user] = useAuthState(auth);

    const queryClient = useQueryClient();

    const { data, error, isError, isLoading } = useQuery(MY_ENTITY, entityGet, {
        enabled: !!user,
    });

    const entityId = data?.id;

    const refresh = () => {
        queryClient.invalidateQueries(MY_ENTITY);
    };

    const {
        data: entityCertificates,
        error: entityCertificatesError,
        isError: entityCertificatesIsError,
        isLoading: entityCertificatesIsLoading,
    } = useQuery(
        [MY_ENTITY_CERTIFICATES, entityId],
        async () => {
            const certificateList = await storage.getEntityCertificates(
                entityId
            );

            return certificateList;
        },
        {
            enabled: !!entityId,
        }
    );

    const entityCertificatesRefresh = () => {
        queryClient.invalidateQueries(MY_ENTITY_CERTIFICATES);
    };

    const {
        data: entitySampleValuations,
        error: entitySampleValuationsError,
        isError: entitySampleValuationsIsError,
        isLoading: entitySampleValuationsIsLoading,
    } = useQuery(
        [MY_ENTITY_SAMPLE_VALUATIONS, entityId],
        async () => {
            const documents = await storage.getEntitySampleValuations(entityId);

            return documents;
        },
        {
            enabled: !!entityId,
        }
    );

    const entitySampleValuationsRefresh = () => {
        queryClient.invalidateQueries(MY_ENTITY_SAMPLE_VALUATIONS);
    };

    const {
        data: entityProfilePictureUrl,
        error: entityProfilePictureUrlError,
        isError: entityProfilePictureUrlIsError,
        isLoading: entityProfilePictureUrlIsLoading,
    } = useQuery(
        [MY_ENTITY_PROFILE_PICTURE, entityId],
        async () => {
            const profilePicture = await storage.getEntityProfilePictureUrl(
                entityId
            );

            if (!profilePicture) {
                return "";
            }

            return profilePicture;
        },
        {
            enabled: !!entityId,
        }
    );

    const entityProfilePictureRefresh = () => {
        queryClient.invalidateQueries(MY_ENTITY_PROFILE_PICTURE);
    };

    const { mutate: updateEntity } = useMutation(
        async (entityUpdates: UpdateEntityDto) => {
            const { city, country, postalCode, street } = entityUpdates;

            const dto: UpdateEntityDto = {
                ...data,
                ...entityUpdates,
                id: Number(data?.id),
            };

            if (street || city || country || postalCode) {
                const address = `${entityUpdates.street}, ${entityUpdates.city}, ${entityUpdates.country}, ${entityUpdates.postalCode}`;

                const location = await fromAddress(address).then(
                    (response) => response.results[0].geometry.location
                );

                dto.latitude = location.lat.toString();
                dto.longitude = location.lng.toString();
            }

            return entityUpdate(dto);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(MY_ENTITY);

                onUpdateSuccess?.();
            },
            onError: (e: Error) => {
                createToast({
                    status: "error",
                    description: t(e.message, { defaultValue: e.message }),
                });

                onUpdateError?.(e);
            },
        }
    );

    return {
        data: data as EntityType,
        error,
        isError,
        isLoading,
        updateEntity,
        refresh,

        entityCertificates,
        entityCertificatesError,
        entityCertificatesIsError,
        entityCertificatesIsLoading,
        entityCertificatesRefresh,

        entityProfilePictureUrl,
        entityProfilePictureUrlError,
        entityProfilePictureUrlIsError,
        entityProfilePictureUrlIsLoading,
        entityProfilePictureRefresh,

        entitySampleValuations,
        entitySampleValuationsError,
        entitySampleValuationsIsError,
        entitySampleValuationsIsLoading,
        entitySampleValuationsRefresh,
    };
};

export default useMyEntity;
