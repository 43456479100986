import { Box, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { Offer, User } from "../..";
import PublicUserProfilePicture from "../../../../../common/components/PublicUserProfilePicture";
import { displayDateWithCurrentLocale } from "../../../../../common/functions/displayDateInLocale";
import { newestToOldest } from "../../../../../common/functions/sortDates";
import { EnquiryType, EnquiryWithFromUserAndFromEntityType, OfferOnlyType, UserType } from "../../../../../types";
import { DataTableColumnType } from "../../../common/components/data-table/DataTable";
import {
    calculateBestDelivery,
    calculateBestOffer,
} from "../../../common/components/data-table/TableDataCalculations";
import { NO_DATA } from "../../../common/NO_DATA";
import {
    filterActiveOffers,
    filterRejectedeOffers,
} from "../../offer/offerFunctions";

export type EnquiryWithOffersType = EnquiryType & {
    offers: OfferOnlyType[];
};

type Column = DataTableColumnType<EnquiryWithOffersType>;

type OfferWithValuerType = OfferOnlyType & {
    fromValuer?: UserType;
};

type EnquiryWithUsersList = EnquiryWithFromUserAndFromEntityType & {
    offers: OfferWithValuerType[];    
};

const translationNameSpace = "domain.enquiry.columns";

export const offers: Column = {
    title: (t) => t(`domain.offer.offers`),
    render: (i) =>
        i.offers ? (
            <Heading size="xs">{i.offers.length}</Heading>
        ) : (
            <Text></Text>
        ),
};

export const activeOffers: Column = {
    alignment: "center",
    title: (t) => t(`${translationNameSpace}.activeOffers`),
    render: (i, t) => {
        const activeOffers = filterActiveOffers(i.offers);

        if (!i.offers.length) {
            return (
                <Text
                    fontSize={"sm"}
                    fontWeight={700}
                    color="gray.400"
                    data-testid="active-offers"
                >
                    {t("common.n/a")}
                </Text>
            );
        }

        // TODO track new offers
        const nNewOffers = 0;

        return (
            <Flex
                direction="column"
                align="center"
                gap={1}
                data-testid="active-offers"
            >
                <Heading size={"xs"}>{activeOffers}</Heading>
                {nNewOffers > 0 && (
                    <Box
                        bg="green.500"
                        borderRadius="2"
                        color="white"
                        fontWeight="bold"
                        paddingInline={1}
                        fontSize={"xs"}
                        textAlign="center"
                        textTransform="uppercase"
                    >
                        {t(`${translationNameSpace}.newText`, { count: 1 })}
                    </Box>
                )}
            </Flex>
        );
    },
};

export const rejectedOffers: Column = {
    alignment: "center",
    title: (t) => t(`${translationNameSpace}.rejected`),
    render: (i, t) => {
        const rejectedOffers = filterRejectedeOffers(i.offers);

        if (!i.offers.length) {
            return (
                <Text
                    fontSize={"sm"}
                    fontWeight={700}
                    color="gray.400"
                    data-testid="rejected-offers"
                >
                    {t("common.n/a")}
                </Text>
            );
        }

        return (
            <Flex
                direction="column"
                align="center"
                gap={1}
                data-testid="rejected-offers"
            >
                <Heading size={"xs"}>{rejectedOffers}</Heading>
            </Flex>
        );
    },
};

export const bestOffer: Column = {
    title: (t) => t(`${translationNameSpace}.bestOffer`),
    render: (i, t) => {
        if (!i.offers.length) {
            return (
                <Text
                    fontSize={"sm"}
                    fontWeight={700}
                    color="gray.400"
                    data-testid="best-offer"
                >
                    {t("common.n/a")}
                </Text>
            );
        }

        const bestOffer = calculateBestOffer(i.offers);

        let bestOfferDeliveryDate = "";

        if (bestOffer) {
            bestOfferDeliveryDate = displayDateWithCurrentLocale(
                Offer.calculateDeliveryDate(bestOffer)
            );
        }

        return (
            <Flex
                direction="column"
                gap={1}
                data-testid="best-offer"
            >
                <Heading
                    color="green.500"
                    size={"xs"}
                >
                    €{bestOffer?.price}
                </Heading>
                <Text
                    color="gray.500"
                    fontSize={"xs"}
                >
                    {t("domain.enquiry.columns.delivery")}{" "}
                    {bestOfferDeliveryDate}
                </Text>
            </Flex>
        );
    },
};

export const bestDelivery: Column = {
    title: (t) => t(`${translationNameSpace}.bestDelivery`),
    render: (i, t) => {
        if (!i.offers.length) {
            return (
                <Text
                    fontSize={"sm"}
                    fontWeight={700}
                    color="gray.400"
                    data-testid="best-delivery"
                >
                    {t("common.n/a")}
                </Text>
            );
        }

        const bestDelivery = calculateBestDelivery(i.offers);

        let bestDeliveryDate = "";

        if (bestDelivery) {
            bestDeliveryDate = displayDateWithCurrentLocale(
                Offer.calculateDeliveryDate(bestDelivery)
            );
        }

        return (
            <Flex
                direction="column"
                gap={1}
                data-testid="best-delivery"
            >
                <Heading size={"xs"}>{bestDeliveryDate}</Heading>
                <Text
                    color="gray.500"
                    fontSize={"xs"}
                >
                    {t("domain.enquiry.columns.price")} €{bestDelivery?.price}
                </Text>
            </Flex>
        );
    },
};

export const lastOffer: Column = {
    title: (t) => t(`domain.enquiry.columns.lastOffer`),
    render: ({ offers }, t) => {
        let text;
        if (!offers.length) text = t("common.n/a");
        else {
            const lastOffer = offers.sort((o1, o2) =>
                newestToOldest(o1.createdAt, o2.createdAt)
            )[0];
            // const nDaysAgo = -calculateDaysFromToday(lastOffer.createdAt);
            const nDaysAgo = 1;
            text = t("domain.enquiry.columns.daysAgo", { count: nDaysAgo });
        }

        return <Text fontSize={"sm"}>{text}</Text>;
    },
};

export const valuerOfferStatus: Column = {
    title: (t) => t(`domain.offer.offerStatus`),
    render: (i, t) => {
        const latestOffer = i.offers.sort((a, b) => {
            return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            );
        })[0];

        if (!latestOffer) return <Text fontSize={"sm"}>{NO_DATA}</Text>;

        return (
            <Offer.StatusText
                offer={{
                    ...latestOffer,
                    enquiry: i,
                }}
            />
        );
    },
};

// Column only to be used with custom data object containing fromValuer info
export const offerValuerProfile: DataTableColumnType<EnquiryWithUsersList> = {
    alignment: "left",
    title: (t) => t(`domain.offer.columns.valuer`),
    render: (i, t) => {
        const offer = i.offers[0];
        
        if (!offer || !offer.fromValuer) {
            return <Text fontSize={"sm"}>{NO_DATA}</Text>;
        }
        
        const valuer = offer.fromValuer;
        const valuerId = offer.fromValuerId;
        const valuerEntityId = offer.fromEntityId;

        const name = `${valuer?.firstName} ${valuer?.lastName}`;

        return (
            <HStack spacing="3">
                <PublicUserProfilePicture
                    size="2.5rem"
                    userId={valuerId}
                    entityId={valuerEntityId}
                />

                <VStack
                    align="flex-start"
                    spacing="0"
                >
                    <Text
                        fontSize={"sm"}
                    >
                        {name}
                    </Text>
                </VStack>
            </HStack>
        )
    },
};

export const latestOfferCreatedAt: Column = {
    title: (t) => t(`domain.enquiry.columns.createdAt`),
    render: (i) => {
        const latestOffer = i.offers.sort((a, b) => {
            return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            );
        })[0];

        if (!latestOffer) return <Text fontSize={"sm"}>{NO_DATA}</Text>;

        return (
            <Text fontSize={"sm"}>
                {displayDateWithCurrentLocale(latestOffer.createdAt)}
            </Text>
        );
    },
};


export const enquiryWithOfferColumns = {
    offers,
    activeOffers,
    bestOffer,
    bestDelivery,
    lastOffer,
    rejectedOffers,
    valuerOfferStatus,
};
