import { Button, VStack } from "@chakra-ui/react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { Link as ReactLink } from "react-router-dom";
import { useRole } from "../../../../../../common/hooks";
import { useMyEntity } from "../../../../../../utils/api/hooks";
import { usePermissions } from "../../../../app/permissions/usePermissions";
import { Title } from "../../../../common/components/display";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { Section } from "../../../../layout";
import CompanyDetails from "./CompanyDetails";
import CompanyDetailsBilling from "./CompanyDetailsBilling";
import CompanyDetailsPiCover from "./CompanyDetailsPiCover";

const CompanyDetailsScreen = () => {
    const t = useSmartTranslation();

    const { userIsValuer } = useRole();

    const {
        data: entity,
        isLoading: entityLoading,
        entityCertificates = [],
    } = useMyEntity();

    const { permission: canEdit } = usePermissions({
        activity: "UpdateEntity",
        datatype: "Entity",
        objects: entity,
    });

    if (entityLoading || !entity) {
        return <LoadingSpinner />;
    }

    // render

    const header = (
        <Title
            title={t("account.heading.companyDetails")}
            rightSide={
                canEdit && (
                    <Button
                        as={ReactLink}
                        to={"edit"}
                        variant="outline"
                        colorScheme="blue"
                        leftIcon={<MdOutlineModeEditOutline />}
                    >
                        {t("common.button.edit")}
                    </Button>
                )
            }
        />
    );

    const details = (
        <Section
            title={t("account.companyDetails.contactInfo")}
            content={<CompanyDetails entity={entity} />}
        />
    );

    const billing = (
        <Section
            title={t("account.companyDetails.billingAddress")}
            content={<CompanyDetailsBilling entity={entity} />}
        />
    );

    const piCover = userIsValuer && (
        <Section
            title={t("account.heading.piCover")}
            content={
                <CompanyDetailsPiCover
                    entity={entity}
                    certificates={entityCertificates}
                />
            }
        />
    );

    return (
        <VStack
            align={"stretch"}
            gap={4}
        >
            {header}
            {details}
            {billing}
            {piCover}
        </VStack>
    );
};

export default CompanyDetailsScreen;
