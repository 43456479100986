import {
    Button,
    Link as ChakraLink,
    Flex,
    Heading,
    Spacer,
    Text,
    VStack
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link as ReactRouterLink, useLocation } from "react-router-dom";
import ConfirmationModal from "../../../../common/components/ConfirmationModal";
import AccountAccessDeniedAlert from "../../../../common/components/display/AccountAccessDeniedAlert";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { useRole } from "../../../../common/hooks";
import { StandardCompanyDocumentWithValuationSamplesType } from "../../../../common/vars/valuationsAndMembershipTypes/standardCompanyDocumentTypes";
import { useMyEntity } from "../../../../utils/api/hooks";
import storage, {
    FirebaseCertificateType,
} from "../../../../utils/storage/storage";
import EditCompanyDocsSlider from "./common/EditCompanyDocsSlider";
import { CompanyDocsTable } from "./CompanyDocsTable";

type PropTypes = {
    subHeading?: string;
};

const CompanyDocuments = (props: PropTypes) => {
    const { subHeading } = props;

    const { pathname } = useLocation();
    const { t } = useTranslation(["profile", "common"]);

    const [deleteFile, setDeleteFile] = useState("");
    const [openSlider, setOpenSlider] = useState<{
        type: StandardCompanyDocumentWithValuationSamplesType | undefined;
        isOpen: boolean;
    }>({
        type: undefined,
        isOpen: false,
    });

    const { userIsCompanyAdmin, userIsClient, roleLoading } = useRole();

    const {
        data: entity,
        entityCertificates = [],
        entityCertificatesIsLoading,
        entityCertificatesRefresh,
        entitySampleValuations = [],
        entitySampleValuationsIsLoading,
        entitySampleValuationsRefresh,
    } = useMyEntity();

    if (entityCertificatesIsLoading || entitySampleValuationsIsLoading)
        return <LoadingSpinner />;

    const awardCerts = entityCertificates.filter(
        (i) => i.type.toLowerCase() === "award"
    );
    const marketingDocs = entityCertificates.filter(
        (i) => i.type.toLowerCase() === "marketing"
    );
    const piCerts = entityCertificates.filter(
        (cert) => cert.type.toLowerCase() === "pi"
    );
    const offerDocs = entityCertificates.filter(
        (i) => i.type.toLowerCase() === "offer"
    );
    const otherCerts = entityCertificates.filter(
        (i) => i.type.toLowerCase() === "other"
    );

    if (roleLoading) {
        return <LoadingSpinner noText />;
    }

    if (userIsClient) {
        return <AccountAccessDeniedAlert />;
    }

    const onAccountPage = pathname.includes("account");
    const allowEditing = userIsCompanyAdmin && onAccountPage;

    const docsTable = (
        title: string,
        files: FirebaseCertificateType[],
        type: StandardCompanyDocumentWithValuationSamplesType
    ) => {
        return (
            <CompanyDocsTable
                allowEditing={allowEditing}
                title={title}
                files={files}
                onDelete={(i) => setDeleteFile(i.url)}
                onEdit={() =>
                    setOpenSlider({
                        type,
                        isOpen: true,
                    })
                }
            />
        );
    };

    return (
        <>
            <VStack
                w="100%"
                align="left"
                paddingInline=".5rem"
                paddingBlock="2rem"
                spacing="10"
            >
                <Flex>
                    <VStack
                        alignSelf="flex-start"
                        spacing="4"
                        w="25%"
                    >
                        <Heading
                            as="h4"
                            size="md"
                            fontWeight="light"
                            w="100%"
                        >
                            {t("sharedDocuments.sectionTitle")}
                        </Heading>

                        <Text color="wvwGrey">
                            {t("sharedDocuments.blurb")}
                        </Text>

                        <Text color="wvwGrey">{subHeading}</Text>

                        <ChakraLink
                            as={ReactRouterLink}
                            to="email"
                        >
                            <Button
                                variant="primaryYellow"
                                fontWeight="light"
                            >
                                {t("emailDocs", { ns: "account" })}
                            </Button>
                        </ChakraLink>
                    </VStack>

                    <Spacer />

                    <VStack
                        w="70%"
                        align="left"
                        spacing="3"
                    >
                        {docsTable(t("sharedDocuments.offer"), offerDocs, "offer")}

                        {docsTable(t("sharedDocuments.piDocs"), piCerts, "pi")}

                        {docsTable(
                            t("sharedDocuments.valuationSamples"),
                            entitySampleValuations,
                            "sampleValuations"
                        )}

                        {docsTable(
                            t("sharedDocuments.marketing"),
                            marketingDocs,
                            "marketing"
                        )}
                    </VStack>
                </Flex>
            </VStack>

            {/* To render modals and sliders */}
            <Outlet />

            <EditCompanyDocsSlider
                isOpen={openSlider.isOpen}
                onClose={() =>
                    setOpenSlider({
                        type: undefined,
                        isOpen: false,
                    })
                }
                type={openSlider.type}
            />

            <ConfirmationModal
                isOpen={deleteFile !== ""}
                onCancel={() => setDeleteFile("")}
                onContinue={async () => {
                    await storage.deleteEntityFile(deleteFile);

                    entityCertificatesRefresh();
                    entitySampleValuationsRefresh();

                    setDeleteFile("");
                }}
                title={t("warning.deleteFile.title", { ns: "common" })}
                content={t("warning.deleteFile.prompt", { ns: "common" })}
            />
        </>
    );
};

export default CompanyDocuments;
