import { WarningTwoIcon } from "@chakra-ui/icons";
import { Heading, HStack, Icon, Link, Text, VStack } from "@chakra-ui/react";
import { MdInfoOutline } from "react-icons/md";
import toUrl from "../../../../../../common/functions/toUrl";
import { useRole, useStandards } from "../../../../../../common/hooks";
import { EntityType } from "../../../../../../types";
import ProfilePictureCompany from "../../../../common/components/display/ProfilePictureCompany";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { InformationRowItem } from "../InformationRowItem";
import RenderFieldData from "../RenderFieldData";

type CompanyDetailsProps = {
    entity: EntityType;
};

const CompanyDetails = (props: CompanyDetailsProps) => {
    const {
        entity: {
            valuerNumberOfPartners,
            valuerYearsOfExperience,
            websiteAddress = "",
            companyEmail,
            contactNumberDialCode,
            contactNumber,
            name,
        },
    } = props;

    const t = useSmartTranslation();

    const { userIsValuer } = useRole();
    const { yearsOfExperience } = useStandards();

    // render

    const logo = <LogoWrapper name={name} />;

    const website = (
        <InformationRowItem
            label={t("account.companyDetails.website")}
            content={
                websiteAddress ? (
                    <Link
                        color="blue.500"
                        href={toUrl(websiteAddress)}
                        isExternal
                        textOverflow="elipsis"
                        noOfLines={1}
                    >
                        {websiteAddress}
                    </Link>
                ) : (
                    <Text
                        fontSize={"sm"}
                        fontWeight={"700"}
                    >
                        {t("profile.fieldNotProvided.website")}
                    </Text>
                )
            }
        />
    );

    const valuerData = userIsValuer && (
        <>
            <InformationRowItem
                label={t("account.companyDetails.numberOfValuers")}
                content={
                    <RenderFieldData
                        field="numberOfPartners"
                        data={[valuerNumberOfPartners]}
                        config={{ required: true }}
                    />
                }
            />

            <InformationRowItem
                label={t("account.companyDetails.yearsOfExperience")}
                content={
                    <RenderFieldData
                        field="yearsOfExperience"
                        data={[
                            yearsOfExperience.find(
                                (i) => i.value === valuerYearsOfExperience
                            )?.label,
                        ]}
                        config={{ required: true }}
                    />
                }
            />
        </>
    );

    const email = (
        <InformationRowItem
            label={t("account.companyDetails.email")}
            content={
                <RenderFieldData
                    field="email"
                    data={[companyEmail]}
                    config={{ required: userIsValuer }}
                />
            }
        />
    );

    const phone = (
        <InformationRowItem
            label={t("account.companyDetails.phoneNumber")}
            content={
                <RenderFieldData
                    field="phoneNumber"
                    data={[contactNumberDialCode, contactNumber]}
                />
            }
        />
    );

    return (
        <HStack
            spacing={4}
            align={"stretch"}
        >
            <VStack
                spacing={2}
                width={"100%"}
                align={"stretch"}
            >
                {email}
                {phone}
                {website}
                {valuerData}
            </VStack>

            <VStack
                spacing={2}
                width={"100%"}
                align={"stretch"}
            >
                {logo}
            </VStack>
        </HStack>
    );
};

type LogoWrapperProps = {
    name: string;
};

const LogoWrapper = (props: LogoWrapperProps) => {
    const { name } = props;

    const t = useSmartTranslation();

    return (
        <>
            <VStack
                h={"100%"}
                justify={"center"}
                spacing={4}
                p={"2rem 1.5rem"}
                border={"1px solid"}
                borderColor={"gray.200"}
                borderRadius={"lg"}
            >
                <ProfilePictureCompany size="10rem" />

                <Heading fontSize={"1.5rem"}>
                    {!name && (
                        <>
                            <WarningTwoIcon
                                color={"gray.400"}
                                mr={2}
                            />
                            {t("account.companyDetails.noNameProvided")}
                        </>
                    )}

                    {name !== "" && name}
                </Heading>
            </VStack>

            <HStack
                spacing={1}
                align={"start"}
            >
                <Icon
                    as={MdInfoOutline}
                    color={"gray.400"}
                />
                <Text
                    fontSize={"xs"}
                    lineHeight={4}
                    color={"gray.500"}
                >
                    {t("account.companyDetails.companyLogoDescription")}
                </Text>
            </HStack>
        </>
    );
};

export default CompanyDetails;
