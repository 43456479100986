import { HStack, Icon, Text } from "@chakra-ui/react";
import { AiOutlineStop } from "react-icons/ai";
import { BsCheckCircle, BsClockHistory } from "react-icons/bs";
import { UserInviteType } from "../../../../../../types";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";

type Status = UserInviteType["status"];

const COLORS: Record<Status, string> = {
    PENDING: "gray.500",
    ACCEPTED: "green.500",
    REJECTED: "red.500",
};

const ICONS: Record<Status, React.ComponentType> = {
    PENDING: BsClockHistory,
    ACCEPTED: BsCheckCircle,
    REJECTED: AiOutlineStop,
};

type InviteStatusProps = {
    status: Status;
};

const InviteStatus = (props: InviteStatusProps) => {
    const { status } = props;

    const t = useSmartTranslation();

    const icon = ICONS[status];
    const color = COLORS[status];

    return (
        <HStack
            spacing={2}
            data-testid="status"
            color={color}
        >
            <Icon
                as={icon}
                boxSize={4}
            />

            <Text
                color={color}
                fontSize={"sm"}
            >
                {t(`account.teamInvites.status.${status}`)}
            </Text>
        </HStack>
    );
};

export default InviteStatus;
