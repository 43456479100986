export const translations = {
    de: {
        adminMenu: {
            uploadedContracts: "Hochgeladene Verträge",
            caseReports: "Geschäftsfallberichte",
        },
        activeCasesWithCount: "Aktive Geschäftsfälle: {{count}}",
        awaitingOffer: {
            title: "Warten auf Angebot",
            subtext:
                "Liste aller Anfragen, die Ihrem Unternehmen zugeordnet wurden und für die kein aktives Angebot vorliegt.",
        },
        companysAcceptedEnquiries: {
            title: "Gewonnene Anfragen",
            subtitle:
                "Liste aller Anfragen, für die Ihr Unternehmen den Zuschlag bekommen hat.",
        },
        customizeExperience: {
            addTeamMembers: {
                title: "Teammitglieder hinzufügen",
                subtext:
                    "Es fehlen Pflichtangaben zu Ihrem Unternehmen, bitte füllen Sie alle relevanten Felder aus.",
            },
            completeValuerOfficeAddress: {
                title: "Vervollständigen Sie die Angaben zu Ihrem Arbeitsstandort",
                subtext:
                    "Ihr Bürostandort ist erforderlich um Sie mit Anfragen und potenziellen Kunden zu verbinden.",
            },
            completeProfile: {
                title: "Profil vervollständigen",
                subtext:
                    "Fügen Sie gegebenenfalls Teammitglieder zu Ihrem Unternehmensprofil hinzu.",
            },
            customizeYourExperience: "Passen Sie Ihr Nutzungserlebnis an",
            missingMatchmakingInfo: {
                title: "Vervollständigen Sie Ihre Angaben für das Matchmaking",
                subtext:
                    "Für das Matchmaking fehlen noch erforderliche Angaben. Bitte füllen Sie die relevanten Formularfelder aus, damit Kundenanfragen Sie erreichen können.",
            },
            uploadMarketing: {
                title: "Marketingmaterial hochladen",
                subtext:
                    "Marketingmaterial erhöht die Transparenz bei Ihren Kunden und kann sich positiv auf die Annahme Ihrer Angebote auswirken.",
            },
            uploadPiCover: {
                title: "Unterlagen Haftpflichtversicherung hochladen",
                subtext:
                    "Diese Nachweisdokumente erhöhen die Transparenz bei Ihren Kunden und können sich positiv auf die Annahme Ihrer Angebote auswirken.",
            },
            uploadValuationSample: {
                title: "Musterwertgutachten hochladen",
                subtext:
                    "Musterwertgutachten erhöhen die Transparenz bei Ihren Kunden und können sich positiv auf die Annahme Ihrer Angebote auswirken.",
            },
        },
        noAcceptedEnquiries: "Keine akzeptierten Anfragen.",
        noAcceptedStartedValuation:
            "Keine akzeptierten und begonnenen Bewertungen.",
        noAwaitingOffers: "Keine wartenden Angebote.",
        noRecentMatches: "Keine aktuellen Anfragen.",
        noCaseEnquiries: "Aktuell keine angenommenen Angebote.",
        noCurrentOffers: "Keine aktuellen Angebote.",
        recentMatchesWithCount: "Aktuelle Anfragen: {{count}}",
        caseEnquiries: "Angenommene Angebote: {{count}}",
        currentOffers: "Aktuell abgegebene Angebote: {{count}}",
        statistics: {
            cases: {
                active: "Aktiv",
                conversionRate: "Konversionsrate",
            },
            enquiries: {
                allOffersSent: "Abgegebene Angebote",
                lostEnquiries: "Verlorene Anfragen",
                matches: "Anfragen",
                rejectedOffers: "Abgelehnte Angebote",
                underReview: "Entscheidung ausstehend",
            },
        },
    },
    en: {
        adminMenu: {
            uploadedContracts: "Uploaded Contracts",
            caseReports: "Case Reports",
        },
        activeCasesWithCount: "Active Cases: {{count}}",
        awaitingOffer: {
            title: "Awaiting offer",
            subtext:
                "List of all enquiries that your company has been matched to and no active offer is available.",
        },
        companysAcceptedEnquiries: {
            title: "Company’s accepted enquiries",
            subtitle: "List of all enquiries that your company won.",
        },
        customizeExperience: {
            addTeamMembers: {
                title: "Add team members",
                subtext:
                    "Consider adding your team members to your company profile.",
            },
            completeProfile: {
                title: "Complete your profile",
                subtext:
                    "You have missing mandatory information about your company, please complete all relevant fields.",
            },
            completeValuerOfficeAddress: {
                title: "Complete your office address",
                subtext:
                    "Your office location is required to match you with enquiries and prospective clients.",
            },
            customizeYourExperience: "Customize Your Experience",
            missingMatchmakingInfo: {
                title: "Complete matchmaking details",
                subtext:
                    "You have missing mandatory information for matchmaking. Please complete all relevant fields to be eligible for matchmaking.",
            },
            uploadMarketing: {
                title: "Upload marketing material",
                subtext:
                    "Uploading marketing material increases transparency among clients and can have positive impact on your offers acceptance.",
            },
            uploadPiCover: {
                title: "Upload PI cover",
                subtext:
                    "Uploading those documents increases transparency among clients and can have positive impact on your offers acceptance.",
            },
            uploadValuationSample: {
                title: "Upload valuation sample",
                subtext:
                    "Uploading valuation samples increases transparency among clients and can have positive impact on your offers acceptance.",
            },
        },
        noAcceptedEnquiries: "No accepted enquiries.",
        noAcceptedStartedValuation: "No accepted and started valuations.",
        noAwaitingOffers: "No awaiting offers.",
        noRecentMatches: "No recent matches.",
        noCaseEnquiries: "No case enquiries.",
        noCurrentOffers: "No current offers.",
        recentMatchesWithCount: "Recent Matches: {{count}}",
        caseEnquiries: "Case Enquiries: {{count}}",
        currentOffers: "Current Offers: {{count}}",
        statistics: {
            cases: {
                active: "Active",
                conversionRate: "Conversion Rate",
            },
            enquiries: {
                allOffersSent: "All Offers Sent",
                lostEnquiries: "Lost enquiries",
                matches: "Matches",
                rejectedOffers: "Rejected Offers",
                underReview: "Under Review",
            },
        },
    },
};
