import { EnquiryWithFromUserAndFromEntityType, OfferOnlyType } from "../../../../../types";
import { useAdminView } from "./AdminViewContext";
import { useAuthedUser } from "../../../app/useMeV2";

type FilterType = {
    enquiries?: EnquiryWithFromUserAndFromEntityType[];
    offers?: OfferOnlyType[];
};

type FilteredDataType = {
    enquiries: EnquiryWithFromUserAndFromEntityType[];
    offers: OfferOnlyType[];
};

const useFilterEnquiryData = (filter: FilterType): FilteredDataType => {
    const { enquiries = [], offers = [] } = filter;
    const user = useAuthedUser();
    const { inAdminView } = useAdminView();

    if (inAdminView) {
        return {
            enquiries,
            offers,
        };
    } else {
        return {
            enquiries: enquiries.filter(({ toUserIds, manualMatchUserIds }) => {
                return toUserIds.includes(user.id) || manualMatchUserIds.includes(user.id);
            }),
            offers: offers.filter(({ fromValuerId }) => {
                return fromValuerId === user.id;
            })
        };
    }
};

export default useFilterEnquiryData;