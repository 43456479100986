import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { FormikForm } from "../../../common/forms";
import { useRole } from "../../../common/hooks";
import {
    enquiryCommonValuerMemberships,
    enquiryOtherValuerMemberships,
} from "../../../common/vars/valuationsAndMembershipTypes";
import useToast from "../../../ui/v2/common/hooks/useToast";
import {
    useEnquiry,
    useEnquiryCreate,
    useMyEntity,
} from "../../../utils/api/hooks";
import EnquiryFormBox from "../common/EnquiryFormBox";
import useEnquirySteps from "../common/hooks/useEnquirySteps";
import CompletionDate from "./valuation-forms/CompletionDate";
import ValuationPurpose from "./valuation-forms/ValuationPurpose";
import ValuationStandards from "./valuation-forms/ValuationStandards";
import ValuationType from "./valuation-forms/ValuationType";

const baseValiadtionSchema = {
    valuationType: Yup.string().required("errors.selectValuationType"),
    valuationSpecial: Yup.array().test(
        "valuationSpecial",
        "errors.required",
        function (value = []) {
            const { valuationType = "", valuationOther = "" } = this.parent;

            if (valuationType === "Other Valuation") {
                if (value.length === 0 && !valuationOther) {
                    return false;
                }
            }

            return true;
        }
    ),
    valuationOther: Yup.string().test(
        "valuationSpecial",
        "errors.required",
        function (value) {
            const { valuationType = "", valuationSpecial = [] } = this.parent;

            if (valuationType === "Other Valuation") {
                if (valuationSpecial.length === 0 && !value) {
                    return false;
                }
            }

            return true;
        }
    ),
    valuationPurposes: Yup.array()
        .min(1, "errors.selectValuationPurpose")
        .required("errors.selectValuationPurpose"),
    completionDate: Yup.string().required("errors.selectCompletionDate"),
};

const individualValidationSchema = Yup.object(baseValiadtionSchema);

const entityValidationSchema = Yup.object({
    ...baseValiadtionSchema,
    valuationStandards: Yup.string().required("errors.selectValuationStandard"),
    valuationStandardsExtra: Yup.string().test(
        "valuationStandardsExtra",
        "errors.required",
        function (value) {
            const { valuationStandards = "", valuationStandardsOther = "" } =
                this.parent;

            if (valuationStandards === "More") {
                if (!value && !valuationStandardsOther) {
                    return false;
                }
            }

            return true;
        }
    ),
    valuationStandardsOther: Yup.string().test(
        "valuationStandardsOther",
        "errors.required",
        function (value) {
            const { valuationStandards = "", valuationStandardsExtra = "" } =
                this.parent;

            if (valuationStandards === "More") {
                if (!value && valuationStandardsExtra === "Other") {
                    return false;
                }
            }

            return true;
        }
    ),
});

const EnquiryValuationInfo = () => {
    const { t } = useTranslation(["enquiryform", "formik", "common"]);
    const { createToast } = useToast();

    const { enquiryId = "" } = useParams();

    const { onContinue, onBack } = useEnquirySteps();

    const { userIsIndividual } = useRole();

    const { data: entity, isLoading } = useMyEntity();

    const {
        enquiry = {
            id: undefined,
            valuationType: "",
            valuationStandards: "",
            valuationPurposes: [],
            valuationSpecial: [],
            valuationOther: "",
            valuationStandardsOther: "",
            valuationPurposesOther: "",
            completionDate: "",
        },
        isLoading: isEnquiryLoading,
        update: updateEnquiry,
    } = useEnquiry({
        enquiryId,
        onUpdateSuccess: () => onContinue(Number(enquiryId)),
        onUpdateError: (error) =>
            createToast({
                description: error.message,
                status: "error",
            }),
    });

    const { update: createEnquiry } = useEnquiryCreate({
        onSuccess: (data) => onContinue(data.data.id),
        onError: (error) =>
            createToast({
                description: error.message,
                status: "error",
            }),
    });

    if (isLoading || isEnquiryLoading) {
        return <LoadingSpinner noText />;
    }

    const useIndividualForm = userIsIndividual;

    const valuationTypeSection = {
        key: "valuationType",
        header: t("newEnquiry.valuationSection.valuationScope.sectionTitle"),
        subHeader: t("newEnquiry.valuationSection.valuationScope.leadQuestion"),
        element: <ValuationType />,
    };

    const valuationStandardsSection = {
        key: "valuationStandards",
        header: t(
            "newEnquiry.valuationSection.valuationStandards.sectionTitle"
        ),
        subHeader: t(
            "newEnquiry.valuationSection.valuationStandards.leadQuestion"
        ),
        element: <ValuationStandards />,
    };

    const valuationPurposeSection = {
        key: "valuationPurposes",
        header: t("newEnquiry.valuationSection.valuationPurpose.sectionTitle"),
        subHeader: t(
            "newEnquiry.valuationSection.valuationPurpose.leadQuestion"
        ),
        element: <ValuationPurpose reducedOptions={useIndividualForm} />,
    };

    const completionDateSection = {
        key: "completionDate",
        header: t("newEnquiry.valuationSection.completionDate.sectionTitle"),
        subHeader: t("newEnquiry.valuationSection.completionDate.leadQuestion"),
        element: <CompletionDate />,
    };

    const individualContent = [
        valuationTypeSection,
        valuationPurposeSection,
        completionDateSection,
    ];

    const entityContent = [
        valuationTypeSection,
        valuationStandardsSection,
        valuationPurposeSection,
        completionDateSection,
    ];

    const content = useIndividualForm ? individualContent : entityContent;

    const validationSchema = useIndividualForm
        ? individualValidationSchema
        : entityValidationSchema;

    let valuationStandards = "";
    let valuationStandardsExtra = "";

    if (!enquiry.valuationStandards) {
        valuationStandards = "";
    } else if (
        enquiryCommonValuerMemberships.includes(enquiry.valuationStandards)
    ) {
        valuationStandards = enquiry.valuationStandards;
    } else if (
        enquiryOtherValuerMemberships.includes(enquiry.valuationStandards)
    ) {
        valuationStandards = "More";
        valuationStandardsExtra = enquiry.valuationStandards;
    }

    return (
        <FormikForm
            validationSchema={validationSchema}
            initialValues={{
                valuationType: enquiry.valuationType,
                valuationStandards,
                valuationPurposes: enquiry.valuationPurposes,
                valuationSpecial: enquiry.valuationSpecial,
                valuationOther: enquiry.valuationOther,
                valuationStandardsOther: enquiry.valuationStandardsOther,
                valuationStandardsExtra,
                valuationPurposesOther: enquiry.valuationPurposesOther,
                completionDate: enquiry.completionDate,
            }}
            onSubmit={(values: any) => {
                let valuationStandard = values.valuationStandards;

                if (values.valuationStandards === "More") {
                    valuationStandard = values.valuationStandardsExtra;
                }

                if (enquiryId === "") {
                    const data = {
                        ...values,
                        status: "DRAFT",
                        properties: [],
                        fromEntityId: entity.id,
                        allowFullMatchmaking: true,
                        valuationStandards: valuationStandard,
                        valuationStandardsOther: values.valuationStandardsOther,
                    };

                    createEnquiry(data);
                } else {
                    const data = {
                        ...values,
                        id: enquiry.id,
                        valuationStandards: valuationStandard,
                        valuationStandardsOther: values.valuationStandardsOther,
                    };

                    updateEnquiry(data);
                }
            }}
        >
            <EnquiryFormBox
                onBack={onBack}
                content={content}
            />
        </FormikForm>
    );
};

export default EnquiryValuationInfo;
