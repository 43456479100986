import { Button, Icon, SimpleGrid, Tooltip, VStack } from "@chakra-ui/react";
import { ElementType } from "react";
import { BsBookmarkCheck, BsBuilding } from "react-icons/bs";
import {
    MdChatBubbleOutline,
    MdOutlineAccountCircle,
    MdOutlineGroup,
    MdOutlineIntegrationInstructions,
    MdOutlineInventory2,
} from "react-icons/md";
import { Outlet, Link as ReactLink, useLocation } from "react-router-dom";
import { useRole } from "../../../../../common/hooks";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import { useSmartTranslation } from "../../../common/hooks/useSmartTranslation";
import { Feature } from "../../../app/features/Feature";

type MenuItemType = {
    label: string;
    url: string;
    icon?: ElementType;
    disabled?: boolean;
    disabledPopover?: string;
};

const AccountScreen = () => {
    const t = useSmartTranslation();

    const { userIsApproved, roleLoading, userIsIndividual, userIsValuer } =
        useRole();

    if (roleLoading) return <LoadingSpinner />;

    const menu: MenuItemType[] = [
        {
            label: t("account.accountSectionLink.profile"),
            url: "",
            icon: MdOutlineAccountCircle,
        },
        {
            label: t("account.accountSectionLink.companyDetails"),
            url: "company",
            icon: BsBuilding,
        },
        ...(!userIsIndividual
            ? [
                  {
                      label: t("account.accountSectionLink.team"),
                      url: "team",
                      icon: MdOutlineGroup,
                  },
                  {
                      label: userIsValuer
                          ? t("account.accountSectionLink.customers")
                          : t("account.accountSectionLink.panelValuers"),
                      url: "valuer-panel",
                      icon: BsBookmarkCheck,
                  },
              ]
            : []),
        ...(userIsValuer
            ? [
                  {
                      label: t("account.accountSectionLink.sharedDocs"),
                      url: "documents",
                      icon: MdOutlineInventory2,
                  },
                  {
                      label: t("account.accountSectionLink.integration"),
                      url: "integration",
                      disabled: !userIsApproved,
                      disabledPopover: t("accountDoesNotHaveAccessToFeature", {
                          ns: "common",
                      }),
                      icon: MdOutlineIntegrationInstructions,
                  },
              ]
            : []),
        ...(userIsValuer && Feature.isOn(Feature.FEATURES.DMS)
            ? [
                  {
                      label: t("account.accountSectionLink.templates"),
                      url: "templates",
                      icon: MdChatBubbleOutline,
                  },
              ]
            : []),
    ];

    // render

    const sidenav = (
        <VStack
            minH={"100vh"}
            align={"stretch"}
            p={"1rem .5rem"}
            spacing={1}
            bg={"white"}
        >
            <SidenavMenu menu={menu} />
        </VStack>
    );

    const content = (
        <VStack
            align={"stretch"}
            p={4}
            spacing={4}
        >
            <Outlet />
        </VStack>
    );

    return (
        <SimpleGrid
            width={"100%"}
            gridTemplateColumns={"minmax(0, 320px) minmax(0, 1fr)"}
        >
            {sidenav}
            {content}
        </SimpleGrid>
    );
};

type SidenavMenuProps = {
    menu: MenuItemType[];
};

const ACTIVE_MENU_STYLE = {
    bg: "blue.50",
    color: "blue.500",
};

const DEFAULT_MENU_STYLE = {
    bg: "white",
    color: "gray.900",
};

const SidenavMenu = (props: SidenavMenuProps) => {
    const { menu } = props;

    const { pathname } = useLocation();

    const activeIndex = menu.findIndex((item) => {
        if (item.url === "") return pathname === "/dashboard/account";

        return pathname.search(item.url) !== -1;
    });

    return (
        <>
            {menu.map(
                ({ url, icon, disabled, disabledPopover, label }, idx) => (
                    <Button
                        role="group"
                        as={ReactLink}
                        key={url}
                        to={url}
                        gap={4}
                        isDisabled={disabled}
                        fontSize={"sm"}
                        fontWeight={"500"}
                        justifyContent={"start"}
                        _hover={ACTIVE_MENU_STYLE}
                        {...(activeIndex === idx
                            ? ACTIVE_MENU_STYLE
                            : DEFAULT_MENU_STYLE)}
                    >
                        {icon && (
                            <Icon
                                as={icon}
                                fontSize={"lg"}
                                color={
                                    activeIndex === idx
                                        ? "blue.500"
                                        : "gray.400"
                                }
                                _groupHover={{
                                    color: "blue.500",
                                }}
                            />
                        )}

                        {disabled && disabledPopover && (
                            <Tooltip
                                label={disabledPopover}
                                placement="bottom"
                            >
                                {label}
                            </Tooltip>
                        )}

                        {!disabled && label}
                    </Button>
                )
            )}
        </>
    );
};

export default AccountScreen;
