import { useTranslation } from 'react-i18next';
import { useOfferGetAllMyOffers } from '../../../../../utils/api/hooks';
import InfoWidget from './InfoWidget';

type PropTypes = {
    scrollToRef?: () => void;
};

const OfferConversionRateWidget = (props: PropTypes) => {
    const { scrollToRef } = props;

    const { t } = useTranslation('dashboard');

    const { data } = useOfferGetAllMyOffers();

    const totalOffers = data?.length ?? 0;
    const acceptedOffers = data?.filter(offer => offer?.status === 'ACCEPTED').length ?? 0;
    const acceptedOfferPercentage = totalOffers > 0 ? (acceptedOffers / totalOffers) * 100 : 0;

    return (
        <InfoWidget
            title={`${t('section.conversionRate')} (${t('offers')})`}
            info={acceptedOfferPercentage === 0 ? '-' : `${acceptedOffers} ${t('of')} ${totalOffers}`}
            infoText={t('offersAccepted')}
            buttonText={t('enquiriesTable.button.viewLatestOffers')}
            buttonClick={scrollToRef}
        />
    );
};

export default OfferConversionRateWidget;
