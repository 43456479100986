import {
    Center, Spinner, VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { WvwText } from './typography';

type PropTypes = {
    noText?: boolean;
};

const LoadingSpinner = (props: PropTypes) => {
    const { noText = false } = props;

    const { t } = useTranslation('common');

    const color = 'wvwYellow';
    const size = noText ? 'xl' : 'lg';
    const thickness = noText ? '4px' : undefined;

    return (
        <Center
            w="100%"
            h="100%"
        >
            <VStack
                spacing="4"
                w="100%"
            >
                <Spinner
                    color={color}
                    size={size}
                    thickness={thickness}
                />

                { !noText && (
                    <WvwText>
                        {t('loading')}
                    </WvwText>
                ) }
            </VStack>
        </Center>
    );
};

export default LoadingSpinner;
