import JSZip from 'jszip';
import { useTranslation } from 'react-i18next';
import WvwIcon from '../../../../../../../common/components/display/WvwIcon';
import { WvwButton } from '../../../../../../../common/components/inputs';

type PropTypes = {
    filesToDownload: {
        fileName: string;
        url: string;
    }[];
    downloadFolderName?: string;
};

const DownloadAllFiles = (props: PropTypes) => {
    const { filesToDownload, downloadFolderName = 'download'} = props;

    const { t } = useTranslation(['common']);

    const downloadZip = (blob: Blob) => {
        // Create download link for zip file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${downloadFolderName}.zip`;

        link.style.display = 'none';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(link.href);
    };

    const handleDownloadAll = async () => {
        const zip = new JSZip();
        let count = 0;
        const fileNames = new Map();

        filesToDownload.forEach(({ url, fileName }) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';

            xhr.onload = () => {
                const blob = xhr.response;

                const fileExtension = fileName.split('.').pop();

                const baseName = fileName.split('.').slice(0, -1).join('.');

                let uniqueFileName = baseName;
                let currentCount = fileNames.get(baseName) || 0;

                while (fileNames.has(`${uniqueFileName} (${currentCount})`)) {
                    currentCount += 1;
                }

                uniqueFileName = currentCount > 0 ? `${baseName} (${currentCount})` : baseName;
                fileNames.set(uniqueFileName, currentCount + 1);

                uniqueFileName = `${uniqueFileName}.${fileExtension}`;

                zip.file(uniqueFileName, blob, { binary: true });
                count += 1;

                if (count === filesToDownload.length) {
                    zip.generateAsync({ type: 'blob' }).then(content => {
                        downloadZip(content);
                    });
                }
            };

            xhr.open('GET', url);
            xhr.send();
        });
    };

    return (
        <WvwButton
            onClick={handleDownloadAll}
            icon={<WvwIcon icon="documentToDownload" />}
            variant="link"
            content={`${t('button.downloadAll', { ns: 'common' })} (${filesToDownload.length})`}
            isDisabled={filesToDownload.length === 0}
        />
    );
};

export default DownloadAllFiles;
