import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Flex,
    Heading,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

type PropTypes = {
    width?: string;
    bg?: string;
    children: ReactNode;
    title: string | ReactNode;
};

const AccordionContainer = (props: PropTypes) => {
    const {
        width = '100%',
        children,
        title,
        bg = 'none',
    } = props;

    return (
        <Accordion
            allowToggle
            width={width}
        >
            <AccordionItem
                border="1px solid #E2E8F0"
                borderRadius="8px"
                padding=".3rem"
                width="100%"
                bg={bg}
            >
                <>
                    <Heading>
                        <AccordionButton
                            padding="0"
                            _hover={{
                                bg: 'none',
                            }}
                        >
                            <Flex
                                width="100%"
                                paddingInline=".5rem"
                            >
                                <Text
                                    paddingBlock=".5rem"
                                    color="wvwGreen"
                                    fontWeight="600"
                                >
                                    {title}
                                </Text>

                                <Spacer />

                                <AccordionIcon alignSelf="center" color="wvwGreen" />
                            </Flex>
                        </AccordionButton>
                    </Heading>

                    <AccordionPanel>
                        {children}
                    </AccordionPanel>
                </>
            </AccordionItem>
        </Accordion>

    );
};

export default AccordionContainer;
