import { AddIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
    Avatar,
    AvatarBadge,
    Box,
    Button,
    Link as ChakraLink,
    Collapse,
    Hide,
    HStack,
    Icon,
    IconButton,
    Show,
    Text,
    VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, Link as ReactLink, useLocation } from "react-router-dom";
import { wvwPalette } from "../../../../theme/theme-v2";
// utils
import { useNotificationGetUnread } from "../../../../../../utils/api/hooks";
import useSupportModal from "../../../../../../utils/api/hooks/useSupportModal";
// v2
import useMe from "../../../../app/useMeV2";
import DropdownMenu from "../../../../common/components/DropDownMenu";
import LanguageToggleButton from "../../../../common/features/languageToggle/LanguageToggleButton";
import NotificationPanel from "../../../../common/features/notifications/NotificationPanel";
import MobiMenuBarDrawer from "../../../platform/screen/menu-bar/MobiMenuBarDrawer";
// v1
import { BsMoon } from "react-icons/bs";
import { FiArchive } from "react-icons/fi";
import {
    MdOutlineAccountCircle,
    MdOutlineHeadphones,
    MdOutlineLogout,
    MdOutlineMenu,
    MdOutlineNotifications,
    MdOutlineSave,
    MdPersonOutline,
} from "react-icons/md";
import { PiBuildingOffice } from "react-icons/pi";
import { useRole } from "../../../../../../common/hooks";
import { Feature } from "../../../../app/features/Feature";
import ProfilePictureCompany from "../../../../common/components/display/ProfilePictureCompany";
import LoadingSpinner from "../../../../common/components/LoadingSpinner";
import { PageTitleBar } from "../../../../common/components/PageTitleBar";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import CreateEnquiryButton from "./CreateEnquiryButton";
import PerspectiveToggleSwitch from "./PerspectiveToggleSwitch";
import AccountTypeIdentifier from "./AccountTypeIdentifier";

const MenuBar = () => {
    const {
        userIsApproved,
        userIsSystemAdmin,
        userIsClient,
        userIsValuer,
        userIsCompanyAdmin,
    } = useRole();

    const [notificationsOpen, setNotificationsOpen] = useState(false);

    const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

    const [burgerMenuItem, setBurgerMenuItem] = useState("");

    const { data: user, isLoading: userLoading } = useMe();

    const { notificationUnreadList } = useNotificationGetUnread(5);

    const t = useSmartTranslation();

    const { pathname: path } = useLocation();

    const [active, setActive] = useState(t("menubar.dashboard"));

    const { modal: supportModal, openModal } = useSupportModal();

    useEffect(() => {
        if (userLoading) return;

        switch (path) {
            case "/dashboard":
                setActive(t("menubar.dashboard"));
                break;
            case "/dashboard/dormant-enquiries":
            case "/dashboard/drafts":
            case "/dashboard/enquiries":
                setActive(t("menubar.enquiries"));
                break;
            case "/dashboard/dormant-cases":
            case "/dashboard/active-cases":
                setActive(t("menubar.cases"));
                break;
            case "/dashboard/notifications":
            case "/contact-us":
                setActive(t("menubar.system"));
                break;
            case "/dashboard/account":
            case "/dashboard/account/company":
                setActive(`${user?.firstName} ${user?.lastName}`);
                break;
            case "/admin":
            case "/admin/cases":
            case "/admin/enquiries":
            case "/admin/entities":
            case "/admin/offers":
            case "/admin/users":
                setActive(t("domain.user.role.ADMIN"));
                break;
            case "/company-admin/cases":
            case "/company-admin/enquiries":
                setActive(t("domain.user.companyAdmin"));
                break;
            default:
                break;
        }

        setBurgerMenuItem("");
        setBurgerMenuOpen(false);
    }, [path, userLoading]);

    if (!user || userLoading || !notificationUnreadList)
        return <LoadingSpinner />;

    const userAccessDeterminedMenuItemSettings = {
        isDisabled: !userIsApproved,
        tooltip: userIsApproved ? "" : t("common.platformAccessRequired"),
    };

    const userMenu = [
        {
            menuTitle: userLoading
                ? ""
                : `${user?.firstName} ${user?.lastName}`,
            leftIcon: <ProfilePictureCompany size="2rem" />,
            rightIcon: <ChevronDownIcon />,
            menuList: [
                {
                    name: t("menubar.profile"),
                    key: "profile",
                    nav: "/dashboard/account",
                    icon: MdOutlineAccountCircle,
                },
                {
                    name: t("menubar.account"),
                    key: "account",
                    nav: "/dashboard/account/company",
                    icon: PiBuildingOffice,
                },
                {
                    name: t("menubar.logout"),
                    key: "logout",
                    nav: "/logout",
                    icon: MdOutlineLogout,
                },
            ],
        },
    ];

    let systemMenu;
    if (userIsClient)
        systemMenu = [
            {
                menuTitle: t("menubar.enquiries"),
                rightIcon: <ChevronDownIcon />,
                menuList: [
                    {
                        name: t("menubar.enquiries"),
                        key: "enquiries",
                        nav: "/dashboard/enquiries",
                        icon: MdOutlineSave,
                        ...userAccessDeterminedMenuItemSettings,
                    },
                    {
                        name: t(
                            "domain.enquiry.typesOfEnquiry.dormantEnquiries"
                        ),
                        key: "archived-enquiries",
                        nav: "/dashboard/enquiries/archived",
                        icon: BsMoon,
                        ...userAccessDeterminedMenuItemSettings,
                    },
                    {
                        name: t("domain.enquiry.typesOfEnquiry.draftEnquiries"),
                        key: "drafts",
                        nav: "/dashboard/enquiries/draft",
                        icon: FiArchive,
                        ...userAccessDeterminedMenuItemSettings,
                    },
                ],
            },
            // {
            //     menuTitle: t("cases", { ns: "dashboard" }),
            //     rightIcon: <ChevronDownIcon />,
            //     menuList: [
            //         {
            //             name: t("Active Cases", { ns: "activecases" }),
            //             key: "active-cases",
            //             nav: "/dashboard/active-cases",
            //             icon: <HomeTrendUp />,
            //             ...userAccessDeterminedMenuItemSettings,
            //         },
            //         {
            //             name: t("section.dormantCases", { ns: "dashboard" }),
            //             key: "dormant-cases",
            //             nav: "/dashboard/dormant-cases",
            //             icon: <Home2 />,
            //             ...userAccessDeterminedMenuItemSettings,
            //         },
            //     ],
            // },
            {
                menuTitle: t("menubar.system"),
                rightIcon: <ChevronDownIcon />,
                menuList: [
                    // {
                    //     name: t("section.messages", { ns: "dashboard" }),
                    //     key: "notifications",
                    //     nav: "/dashboard/notifications",
                    //     icon: <ClipboardText />,
                    // },
                    {
                        name: t("menubar.support"),
                        key: "support",
                        onClick: () => openModal(),
                        icon: MdOutlineHeadphones,
                    },
                ],
            },
        ];
    else
        systemMenu = [
            {
                menuTitle: t("menubar.enquiries"),
                rightIcon: <ChevronDownIcon />,
                menuList: [
                    {
                        name: t("menubar.enquiries"),
                        key: "enquiries",
                        nav: "/dashboard/enquiries",
                        icon: MdOutlineSave,
                        ...userAccessDeterminedMenuItemSettings,
                    },
                    {
                        name: t(
                            "domain.enquiry.typesOfEnquiry.dormantEnquiries"
                        ),
                        key: "archived-enquiries",
                        nav: "/dashboard/enquiries/archived",
                        icon: BsMoon,
                        ...userAccessDeterminedMenuItemSettings,
                    },
                ],
            },
            // {
            //     menuTitle: t("cases", { ns: "dashboard" }),
            //     rightIcon: <ChevronDownIcon />,
            //     menuList: [
            //         {
            //             name: t("Active Cases", { ns: "activecases" }),
            //             key: "active-cases",
            //             nav: "/dashboard/active-cases",
            //             icon: <HomeTrendUp />,
            //             ...userAccessDeterminedMenuItemSettings,
            //         },
            //         {
            //             name: t("section.dormantCases", { ns: "dashboard" }),
            //             key: "dormant-cases",
            //             nav: "/dashboard/dormant-cases",
            //             icon: <Home2 />,
            //             ...userAccessDeterminedMenuItemSettings,
            //         },
            //     ],
            // },
            {
                menuTitle: t("menubar.system"),
                rightIcon: <ChevronDownIcon />,
                menuList: [
                    // {
                    //     name: t("section.messages", { ns: "dashboard" }),
                    //     key: "notifications",
                    //     nav: "/dashboard/notifications",
                    //     icon: <ClipboardText />,
                    // },
                    {
                        name: t("menubar.support"),
                        key: "support",
                        onClick: () => openModal(),
                        icon: MdOutlineHeadphones,
                    },
                ],
            },
        ];

    // const companyAdminMenu = {
    //     menuTitle: t('companyAdmin', { ns: 'common' }),
    //     rightIcon: <ChevronDownIcon />,
    //     menuList: [
    //         {
    //             name: t('Active Cases', { ns: 'activecases' }),
    //             key: 'cases',
    //             nav: '/company-admin/cases',
    //             icon: <HomeTrendUp />,
    //         },
    //         {
    //             name: t('section.enquiries', { ns: 'dashboard' }),
    //             key: 'enquiries',
    //             nav: '/company-admin/enquiries',
    //             icon: <SaveAdd />,
    //         },
    //     ],
    // };

    const adminMenu = {
        menuTitle: t("domain.user.role.ADMIN"),
        rightIcon: <ChevronDownIcon />,
        menuList: [
            {
                name: t("menubar.cases"),
                key: "cases",
                nav: "/admin/cases",
                icon: MdPersonOutline,
            },
            {
                name: t("valuerDashboard.adminMenu.uploadedContracts"),
                key: "uploadedContracts",
                nav: "/admin/uploaded-contracts",
                icon: MdPersonOutline,
            },
            {
                name: t("valuerDashboard.adminMenu.caseReports"),
                key: "caseReports",
                nav: "/admin/case-reports",
                icon: MdPersonOutline,
            },
            {
                name: t("common.documents"),
                key: "documents",
                nav: "/admin/documents",
                icon: MdPersonOutline,
            },
            {
                name: t("menubar.enquiries"),
                key: "enquiries",
                nav: "/admin/enquiries",
                icon: MdPersonOutline,
            },
            {
                name: t("menubar.entities"),
                key: "entities",
                nav: "/admin/entities",
                icon: MdPersonOutline,
            },
            {
                name: t("menubar.inspections"),
                key: "inspections",
                nav: "/admin/inspections",
                icon: MdPersonOutline,
            },
            {
                name: t("domain.offer.offers"),
                key: "offers",
                nav: "/admin/offers",
                icon: MdPersonOutline,
            },
            {
                name: t("menubar.users"),
                key: "users",
                nav: "/admin/users",
                icon: MdPersonOutline,
            },
        ],
    };

    const currentPath = path.split("/")[2];

    let toggleAdminViewButton;

    if (Feature.isOn(Feature.FEATURES.ADMIN_VIEW))
        if (userIsValuer && userIsCompanyAdmin) {
            toggleAdminViewButton = <PerspectiveToggleSwitch />;
        }

    let createEnquiryButton;
    if (userIsClient) {
        createEnquiryButton = (
            <Link to={"create-client-enquiry"}>
                <Button variant="primary">
                    <Show above="md">{t("domain.enquiry.createEnquiry")}</Show>
                    <Hide above="md">
                        <AddIcon w=".8rem" />
                    </Hide>
                </Button>
            </Link>
        );
    } else {
        createEnquiryButton = (
            <CreateEnquiryButton valuerEntity={user?.entity} />
        );
    }

    const leftContent = (
        <>
            <Show below="md">
                <IconButton
                    icon={<MdOutlineMenu />}
                    bgColor="transparent"
                    _hover={{
                        bgColor: "var(--action-disabledBackground, #2B6CB0)",
                    }}
                    color="white"
                    boxSize="6"
                    onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}
                    aria-label="Mobile menu"
                />
            </Show>

            <Hide below="md">
                <HStack>
                    <ChakraLink
                        as={ReactLink}
                        to="/dashboard"
                        borderRadius="8"
                        paddingInline=".8rem"
                        paddingBlock=".4rem"
                        color="white"
                        fontWeight={"700"}
                        lineHeight={"1.2"}
                        _hover={{
                            bgColor:
                                "var(--action-disabledBackground, #2B6CB0)",
                        }}
                        bgColor={
                            path === "/dashboard" || path === "/"
                                ? "var(--action-disabledBackground, #2B6CB0)"
                                : "transparent"
                        }
                    >
                        {t("menubar.dashboard")}
                    </ChakraLink>

                    {systemMenu.map((item) => (
                        <DropdownMenu
                            key={item.menuTitle}
                            menu={item}
                            active={active}
                        />
                    ))}

                    {userIsSystemAdmin && (
                        <DropdownMenu
                            menu={adminMenu}
                            active={active}
                        />
                    )}

                    {/* {userIsCompanyAdmin && (
                    <DropdownMenu
                    menu={companyAdminMenu}
                    active={active}
                    />
                    )} */}
                </HStack>
            </Hide>
            {createEnquiryButton}
        </>
    );

    const rightContent = (
        <HStack spacing="1">
            <Box
                paddingInline=".5rem"
                borderRadius={10}
                _hover={{
                    bg: "blue.600",
                }}
            >
                <LanguageToggleButton />
            </Box>

            <Box
                paddingInline=".5rem"
                _hover={{
                    bg: wvwPalette.wvwBackground.menubarSecondary,
                }}
                borderRadius="10"
                paddingBlock=".3rem"
            >
                <Avatar
                    color="white"
                    bg="none"
                    icon={
                        <Icon
                            as={MdOutlineNotifications}
                            boxSize={"1.5rem"}
                        />
                    }
                    aria-label="notification"
                    as="button"
                    size="xs"
                    onClick={() => {
                        setNotificationsOpen(true);
                    }}
                >
                    {Number(notificationUnreadList?.length) > 0 && (
                        <AvatarBadge
                            boxSize=".65rem"
                            marginBottom=".9rem"
                            marginRight=".15rem"
                            bg="orange"
                            color="orange"
                            borderColor="none"
                        />
                    )}
                </Avatar>
            </Box>

            {userMenu.map((item) => (
                <Box
                    borderRadius={10}
                    _hover={{
                        bg: wvwPalette.wvwBackground.menubarSecondary,
                    }}
                    key={item.menuTitle}
                >
                    <DropdownMenu
                        menu={item}
                        active={active}
                    />
                </Box>
            ))}

            <Feature.On name={Feature.FEATURES.ACCOUNT_TYPE_DISPLAY}>
                <AccountTypeIdentifier user={user} />
            </Feature.On>

            {toggleAdminViewButton}
        </HStack>
    );

    return (
        <>
            <PageTitleBar
                leftContent={leftContent}
                rightContent={rightContent}
            />

            <MobiMenuBarDrawer
                isOpen={burgerMenuOpen}
                onClose={() => {
                    setBurgerMenuOpen(false);
                    setBurgerMenuItem("");
                }}
            >
                <>
                    {systemMenu.map((item) => (
                        <VStack
                            width="100%"
                            bg={
                                burgerMenuItem === item.menuTitle
                                    ? "var(--action-disabledBackground, #2B6CB0)"
                                    : "transparent"
                            }
                            align="left"
                            key={item.menuTitle}
                        >
                            <Box
                                width="100%"
                                justifyContent="left"
                                padding="1em"
                                paddingLeft="2rem"
                                _hover={{
                                    bgColor:
                                        "var(--action-disabledBackground, #2B6CB0)",
                                    curser: "pointer",
                                }}
                                onClick={() =>
                                    setBurgerMenuItem(item.menuTitle)
                                }
                            >
                                <Text
                                    color="white"
                                    fontWeight="bold"
                                >
                                    {item.menuTitle}
                                </Text>
                            </Box>

                            <Collapse in={burgerMenuItem === item.menuTitle}>
                                <VStack
                                    width="100%"
                                    spacing="4"
                                    align="left"
                                    paddingBottom="1em"
                                    paddingLeft="3em"
                                >
                                    {item.menuList.map((listItem) => (
                                        <HStack
                                            color="white"
                                            key={listItem.key}
                                            onClick={() => {
                                                if (
                                                    "nav" in listItem &&
                                                    currentPath === listItem.nav
                                                ) {
                                                    setBurgerMenuOpen(false);
                                                }
                                                if (
                                                    listItem.key === "support"
                                                ) {
                                                    setBurgerMenuOpen(false);
                                                    openModal();
                                                }
                                                setBurgerMenuItem("");
                                            }}
                                        >
                                            <Icon
                                                as={listItem.icon}
                                                color={"gray.400"}
                                                boxSize={6}
                                            />
                                            {/* 
                                            <WvwLink
                                                label={listItem.name}
                                                to={
                                                    "nav" in listItem
                                                        ? listItem.nav
                                                        : ""
                                                }
                                                invertedColor
                                            /> */}
                                        </HStack>
                                    ))}
                                </VStack>
                            </Collapse>
                        </VStack>
                    ))}
                </>
            </MobiMenuBarDrawer>

            {notificationsOpen && (
                <NotificationPanel
                    isOpen={notificationsOpen}
                    onClose={() => setNotificationsOpen(false)}
                />
            )}

            {supportModal}
        </>
    );
};

export default MenuBar;
