import { useAuthedUser } from "../../app/useMeV2";
import WarningMessage from "../../common/components/display/WarningMessage";
import { useSmartTranslation } from "../../common/hooks/useSmartTranslation";

const OfferPiCoverWarning = () => {
    const t = useSmartTranslation();

    const user = useAuthedUser();
    const role = user.entity.accountType;

    return (
        <WarningMessage
            label={t(`domain.offer.piCoverWarningBanner.${role}.label`)}
            caption={t(`domain.offer.piCoverWarningBanner.${role}.description`)}
        />
    );
};

export default OfferPiCoverWarning;
