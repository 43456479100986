import { Box } from "@chakra-ui/react";
import { WvwText } from "../../../../common/components/typography";
import { User } from "../../../../domain";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";

type AccountTypeIdentifierProps = {
    user: User;
};

const AccountTypeIdentifier = (props: AccountTypeIdentifierProps) => {
    const { user } = props;

    const t = useSmartTranslation();

    const accountType = user.entity.accountType;

    return (
        <Box
            bg="#718096"
            px="4px"
            gap="2px"
            borderRadius="sm"
        >
            <WvwText
                color="white"
                bold
            >
                {t(`menubar.accountType.${accountType}`)}
            </WvwText>
        </Box>
    );
};

export default AccountTypeIdentifier;
