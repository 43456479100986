import { Box } from "@chakra-ui/react";
import {
    DocRenderer,
    MSDocRenderer as LegacyMSDocRenderer,
} from "react-doc-viewer";

const MSDocRenderer: DocRenderer = ({ mainState: { currentDocument } }) => {
    if (!currentDocument) return null;

    return (
        <Box
            w={"100%"}
            h={"100%"}
        >
            <iframe
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
                    currentDocument.uri
                )}`}
                frameBorder="0"
                width={"100%"}
                height={"100%"}
            />
        </Box>
    );
};
MSDocRenderer.fileTypes = LegacyMSDocRenderer.fileTypes;
MSDocRenderer.weight = 1;

export default MSDocRenderer;
