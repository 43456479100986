import {
    Form,
    Formik,
    FormikHelpers,
    FormikValues,
    useFormikContext,
} from "formik";
import {
    createContext,
    ReactNode,
    useCallback,
    useEffect,
    useState,
} from "react";
import { ObjectSchema, ValidationError } from "yup";

type PropTypes = {
    children: ReactNode[] | ReactNode;
    initialValues: FormikValues;
    submitting?: boolean;
    validationSchema?: ObjectSchema<any>;
    warningSchema?: ObjectSchema<any>;
    onSubmit: (
        values: FormikValues,
        formikHelpers?: FormikHelpers<FormikValues>
    ) => void;
};

// type WarningConfig = {
//     name: string;
//     message: string;
//     testFn: (values: FormikValues) => boolean; // return true if current state needs warning
// };

export type FormikWarning = {
    field: string;
    message: string;
};

type ValidationContextType = (fieldName: string) => boolean;

export const FormikValidationContext = createContext<ValidationContextType>(
    () => false
);

export const FormikWarningsContext = createContext<FormikWarning[]>([]);

export const hasFormikWarning = (
    fieldName: string,
    warnings: FormikWarning[]
) => warnings.find((i) => i.field === fieldName);

const WarningWrapper = (props: {
    children: ReactNode[] | ReactNode;
    warningSchema?: ObjectSchema<any>;
}) => {
    const { children, warningSchema } = props;

    const { values } = useFormikContext<FormikValues>();

    const [currentWarnings, setCurrentWarnings] = useState<FormikWarning[]>([]);

    useEffect(() => {
        if (!warningSchema) return;

        warningSchema
            .validate(values, { abortEarly: false })
            .then(() => setCurrentWarnings([]))
            .catch((error: ValidationError) => {
                if (!error.inner) return;

                const newWarnings: FormikWarning[] = error.inner.map((i) => ({
                    field: i.path || "",
                    message: i.message,
                }));

                setCurrentWarnings(newWarnings);
            });
    }, [values]);

    return (
        <FormikWarningsContext.Provider value={currentWarnings}>
            {children}
        </FormikWarningsContext.Provider>
    );
};

const FormikForm = (props: PropTypes) => {
    const {
        children,
        initialValues,
        submitting = false,
        validationSchema,
        warningSchema,
        onSubmit,
    } = props;

    const isFieldRequired = useCallback(
        (fieldName: string) => {
            const requiredFields: string[] = [];

            if (validationSchema && validationSchema.fields) {
                const description = validationSchema.describe();

                Object.keys(validationSchema.fields).forEach((key) => {
                    const item = description.fields[key] as any;

                    if (!item.optional) {
                        requiredFields.push(key);
                    }
                });
            }

            return requiredFields.includes(fieldName);
        },
        [validationSchema]
    );

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(...params) => {
                if (submitting) return;

                onSubmit(...params);
            }}
        >
            <FormikValidationContext.Provider value={isFieldRequired}>
                <WarningWrapper warningSchema={warningSchema}>
                    <Form style={{ width: "100%" }}>{children}</Form>
                </WarningWrapper>
            </FormikValidationContext.Provider>
        </Formik>
    );
};

export default FormikForm;
