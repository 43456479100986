import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    ListItem,
    UnorderedList,
    VStack,
} from "@chakra-ui/react";
import toTitleCase from "../../../../../../common/functions/toTitleCase";
import { StandardCompanyDocumentType } from "../../../../../../common/vars/valuationsAndMembershipTypes/standardCompanyDocumentTypes";
import { StandardPropertyType } from "../../../../../../common/vars/valuationsAndMembershipTypes/standardPropertyTypes";
import { useSmartTranslation } from "../../../../common/hooks/useSmartTranslation";
import { MISSING_TRANSLATION } from "../../../../common/MISSING_TRANSLATION";
import DocumentsUpload from "./DocumentsUpload";

type PropTypes = {
    onClose: () => void;
    type: StandardCompanyDocumentType | StandardPropertyType;
};

const DocumentsUploadDrawer = (props: PropTypes) => {
    const { onClose, type } = props;

    const t = useSmartTranslation();

    // render

    const header = (
        <DrawerHeader
            color={"blue.700"}
            fontWeight={"bold"}
        >
            {t(`domain.document.category.${toTitleCase(type)}`, {
                defaultValue: MISSING_TRANSLATION,
            })}
        </DrawerHeader>
    );

    const alert = (
        <Alert
            mb={4}
            alignItems={"start"}
        >
            <AlertIcon />
            <VStack
                align={"stretch"}
                spacing={0}
            >
                <AlertTitle>
                    {t("account.sharedDocuments.uploadAlert.label")}
                </AlertTitle>
                <AlertDescription>
                    <UnorderedList>
                        <ListItem>{t("account.sharedDocuments.uploadAlert.option1")}</ListItem>
                        <ListItem>{t("account.sharedDocuments.uploadAlert.option2")}</ListItem>
                    </UnorderedList>
                </AlertDescription>
            </VStack>
        </Alert>
    );

    const uploader = (
        <DocumentsUpload
            documentType={type}
            uploadSuccess={onClose}
        />
    );

    const body = (
        <DrawerBody>
            {alert}
            {uploader}
        </DrawerBody>
    );

    const footer = (
        <DrawerFooter justifyContent={"start"}>
            <Button
                variant={"outline"}
                colorScheme={"blue"}
                width={"50%"}
                onClick={onClose}
            >
                {t("common.button.close")}
            </Button>
        </DrawerFooter>
    );

    return (
        <Drawer
            isOpen={true}
            placement="right"
            onClose={onClose}
            size="lg"
        >
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                {header}
                {body}
                {footer}
            </DrawerContent>
        </Drawer>
    );
};

export default DocumentsUploadDrawer;
