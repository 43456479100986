import { WvwIconButton } from '../inputs';
import { IconButtonPropType } from '../inputs/WvwIconButton';

const WvwEditIconButton = (props: IconButtonPropType) => {
    const {
        color = 'wvwGreen',
        onClick = () => {},
    } = props;

    return (
        <WvwIconButton
            ariaLabel="Edit"
            color={color}
            icon="edit"
            onClick={onClick}
        />
    );
};

export default WvwEditIconButton;
