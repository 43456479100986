import {
    Input,
    InputGroup,
    InputLeftAddon,
    InputLeftElement,
    InputRightAddon,
    InputRightElement,
    Text,
} from "@chakra-ui/react";
import { FieldInputProps } from "formik";
import delimitNumber from "../../../../common/functions/delimitNumber";
import parseDelimitedNumber from "../../../../common/functions/parseDelimitedNumber";
import { LabelAndInput } from "../components/display";
import { useSmartTranslation } from "../hooks/useSmartTranslation";
import { useFormikMeta } from "./useFormikMeta";
import { JSX } from "react";

type PropTypes = {
    name: string;
    label?: string;
    readOnly?: boolean;
    disabled?: boolean;
    prefix?: string;
    tooltip?: string;
    leftinternal?: string | JSX.Element;
    rightinternal?: string | JSX.Element;
    maxW?: string;
    subtext?: string;
    suffix?: string;
    width?: string;
    onChange?: (value: string, field?: FieldInputProps<any>) => void;
    variant?: string;
    size?: string;
};

const FormikCurrencyInput = (props: PropTypes) => {
    const t = useSmartTranslation();

    const {
        prefix = null,
        leftinternal,
        rightinternal,
        disabled = false,
        readOnly,
        maxW = "100%",
        name,
        label = "",
        subtext,
        suffix = null,
        width = "100%",
        onChange,
        variant,
        size = "md",
        tooltip,
    } = props;

    const { errorBorderColor, isRequired, isInvalid, field, meta } =
        useFormikMeta(name);

    return (
        <LabelAndInput
            label={label && `${label}${isRequired ? " *" : ""}`}
            tooltip={tooltip}
            input={
                <div
                    style={{
                        alignSelf: "top",
                        maxWidth: maxW,
                        width,
                    }}
                >
                    <InputGroup variant={variant}>
                        {prefix && (
                            <InputLeftAddon
                                minW="3rem"
                                bg={"white"}
                                fontSize={"sm"}
                                color={"gray.500"}
                                opacity={disabled ? ".4" : 1}
                            >
                                {prefix}
                            </InputLeftAddon>
                        )}

                        {leftinternal && (
                            <InputLeftElement>{leftinternal}</InputLeftElement>
                        )}

                        <Input
                            borderRadius="lg"
                            bg="white"
                            isDisabled={disabled}
                            readOnly={readOnly}
                            cursor={readOnly ? "not-allowed" : undefined}
                            isInvalid={isInvalid}
                            errorBorderColor={errorBorderColor}
                            maxW={maxW}
                            w={width}
                            size={size}
                            {...field}
                            {...props}
                            onChange={(e) => {
                                let value = parseDelimitedNumber(
                                    e.target.value
                                );

                                if (value !== "") {
                                    value = delimitNumber(value);
                                }

                                field.onChange({ target: { name, value } });

                                onChange?.(value, field);
                            }}
                        />

                        {rightinternal && (
                            <InputRightElement>
                                {rightinternal}
                            </InputRightElement>
                        )}

                        {suffix && (
                            <InputRightAddon
                                minW="3rem"
                                bg={"white"}
                                fontSize={"sm"}
                                color={"gray.500"}
                                opacity={disabled ? ".4" : 1}
                            >
                                {suffix}
                            </InputRightAddon>
                        )}
                    </InputGroup>

                    {subtext && (
                        <Text
                            fontSize={"sm"}
                            color="gray.500"
                        >
                            {subtext}
                        </Text>
                    )}

                    {meta.touched && meta.error && (
                        <Text
                            fontSize={"sm"}
                            color="red"
                        >
                            {t(meta.error, { defaultValue: meta.error })}
                        </Text>
                    )}
                </div>
            }
        />
    );
};

export default FormikCurrencyInput;
