import {
    Center,
    Flex,
    HStack,
    Spacer,
    VStack,
} from '@chakra-ui/react';
import { MdSettings } from 'react-icons/md';
import '../App.css';
import LanguageToggleButton from '../common/components/LanguageToggleButton';
import Logo from '../common/components/Logo';
import { WvwText, WVWTitle } from '../common/components/typography';
import { useSmartTranslation } from '../common/hooks';

const MaintenanceMode = () => {
    const t = useSmartTranslation('common');

    return (
        <VStack
            width="100%"
            h="100vh"
            bg="linear-gradient(90deg, #10528F 25.98%, #3396BF 100%)"
        >
            <Flex
                w="100%"
                alignItems="center"
                padding="2rem"
                justifyContent="space-between"
                maxWidth="1600px"
                marginLeft="auto"
                marginRight="auto"
            >
                <HStack spacing="0">
                    <Logo />

                    <WVWTitle
                        content={t('whoValuesWhat')}
                        level="2"
                        color="white"
                    />
                </HStack>

                <Spacer />

                <LanguageToggleButton />
            </Flex>
            <Center
                width="100vw"
                h="80vh"
            >
                <VStack maxW="30em">
                    <MdSettings
                        size="4em"
                        color="white"
                        className="spinAnimation"
                    />

                    <WVWTitle
                        content={t('maintenanceMode.title')}
                        color="white"
                        level="1"
                    />

                    <WvwText color="white" textAlign="center">
                        {t('maintenanceMode.subtitle')}
                    </WvwText>

                </VStack>
            </Center>
        </VStack>
    );
};

export default MaintenanceMode;
