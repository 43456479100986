import { UserType } from "../../../../../../../types";
import BaseFilterInput from "../../BaseFilterInput";
import { FilterPropsType } from "../../Filter";

const ByUserDataFilter = (props: FilterPropsType<UserType>) => {
    return (
        <BaseFilterInput
            {...props}
            filterGroupId="BY_USER_DATA"
            placeholder="Search by User data"
            searchFn={({ email, firstName, lastName, entity }, search) => {
                const compareEntity = entity
                    ? entity.name.toLowerCase().includes(search)
                    : false;

                const compareAddress = entity
                    ? `${entity.city} ${entity.street} ${entity.postalCode}`
                          .toLowerCase()
                          .includes(search)
                    : false;

                const compareEmail = email.toLowerCase().includes(search);

                const compareName = `${firstName} ${lastName}`
                    .toLowerCase()
                    .includes(search);

                return (
                    compareEntity ||
                    compareEmail ||
                    compareName ||
                    compareAddress
                );
            }}
        />
    );
};

export { ByUserDataFilter };
