import { HStack } from "@chakra-ui/react";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
import DataTable from "../../../common/components/datatable/DataTable";
import offerColumns from "../../../common/components/datatable/columns/offerColumns";
import PageWithTitleLayout from "../../../common/components/display/PageWithTitleLayout";
import { useSmartTranslation } from "../../../common/hooks";
import { OfferType } from "../../../types";
import { ByOfferDataFilter } from "../../../ui/v2/common/features/filters/domain/offer/ByOfferDataFilter";
import useDataFilter from "../../../ui/v2/common/features/filters/useDataFIlter";
import { AdminOffersDto } from "../../../utils/api/dto";
import { useAdminOffers } from "../../../utils/api/hooks";

const headers = [
    offerColumns.company,
    offerColumns.valuer,
    offerColumns.price,
    offerColumns.availability,
    offerColumns.status,
];

const AdminOffers = () => {
    const { data: offers, isLoading } = useAdminOffers({});

    const t = useSmartTranslation("admin");

    const {
        filteredData,
        searchFilter,
        updateSearchFilter,
        deactivateSearchFilter,
    } = useDataFilter<OfferType>(offers);

    if (isLoading) {
        return <LoadingSpinner />;
    }

    const offerFilters = [
        {
            filterName: "Offer status",
            placeholder: "Status",
            options: [
                {
                    label: t("filters.offers.status.ACCEPTED", { ns: "admin" }),
                    value: "ACCEPTED",
                },
                {
                    label: t("filters.offers.status.PENDING", { ns: "admin" }),
                    value: "PENDING",
                },
                {
                    label: t("filters.offers.status.REJECTED", { ns: "admin" }),
                    value: "REJECTED",
                },
                {
                    label: t("filters.offers.status.WITHDRAWN", {
                        ns: "admin",
                    }),
                    value: "WITHDRAWN",
                },
            ],
            filterFunction: (i: AdminOffersDto, filterValue?: string) =>
                i.status === filterValue,
        },
    ];

    return (
        <PageWithTitleLayout title="Admin Offers">
            <HStack justify={"end"}>
                <ByOfferDataFilter
                    activateFilter={updateSearchFilter}
                    deactivateFilter={deactivateSearchFilter}
                    activeFilters={searchFilter ? [searchFilter] : []}
                />
            </HStack>

            <DataTable
                columns={headers}
                data={filteredData}
                filters={offerFilters}
            />
        </PageWithTitleLayout>
    );
};

export default AdminOffers;
