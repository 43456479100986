import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import useInspectionGetCaseInspections from './useInspectionGetCaseInspections';
import { reportOutcome } from '../../endpoints';
import { ReportOutcomeDto } from '../../dto/inspection/reportOutcome.dto';
import useToast from '../../../../ui/v2/common/hooks/useToast';

type ParamsType = {
    caseId: number;
    onSuccess?: () => void;
    onError?: (error: Error) => void;
};

const useInspectionReportOutcome = (params: ParamsType) => {
    const {
        caseId,
        onSuccess,
        onError,
    } = params;

    const { refresh: refreshInspections } = useInspectionGetCaseInspections(caseId);

    const { t } = useTranslation();

    const { createToast } = useToast();

    const { mutate: update } = useMutation(
        (dto: ReportOutcomeDto) => reportOutcome(dto),
        {
            onSuccess: () => {
                refreshInspections();
                onSuccess?.();
            },
            onError: (error: Error) => {
                createToast({
                    status: "error",
                    description: t(error.message, { defaultValue: error.message })
                });
                onError?.(error);
            },
        },
    );

    return {
        update,
    };
};

export default useInspectionReportOutcome;
