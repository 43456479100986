import {
    Box,
    Flex,
    Text,
    HStack,
    Tooltip,
    VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { WvwText, WVWTitle } from '../../../../../common/components/typography';
import { WvwButton } from '../../../../../common/components/inputs';

type PropTypes = {
    title: string;
    info: string | number;
    infoText?: string;
    infoPercentage?: number | string;
    buttonVariant?: 'primary' | 'outline';
    buttonText?: string | null;
    buttonLink?: string;
    buttonClick?: () => void;
    tooltipInfoLabel?: string;
    tooltipPercentageLabel?: string;
};

const InfoWidget = (props: PropTypes) => {
    const {
        title,
        info,
        infoText = '',
        infoPercentage = 0,
        buttonVariant = 'primary',
        buttonText = null,
        buttonLink = null,
        buttonClick = () => {},
        tooltipInfoLabel = '',
        tooltipPercentageLabel = '',

    } = props;

    const navigate = useNavigate();

    return (
        <Flex
            paddingInline="2.5rem"
            paddingBlock="1rem"
            backgroundColor="white"
            borderRadius="10"
            border="1px solid #e2e8f0"
            width="20rem"
            height="15rem"
            alignItems="top"
        >
            <VStack
                spacing="2"
                justifyItems="top"
                width="100%"
                alignItems="left"
                paddingTop="1rem"
                paddingBottom="1rem"
            >
                <Box>
                    <WVWTitle
                        content={title}
                        level="3"
                        color="black"
                    />
                </Box>

                <Tooltip label={tooltipInfoLabel} aria-label="A tooltip">
                    <Box>
                        <Text
                            color="black"
                            fontSize="3xl"
                            fontWeight="bold"
                        >
                            {info === 0 ? '-' : info.toString()}
                        </Text>

                        {infoText && (
                            <Box
                                marginTop="1.2rem"
                            >
                                <WvwText color="wvwGrey60">
                                    {infoText}
                                </WvwText>
                            </Box>
                        )}
                    </Box>
                </Tooltip>

                <HStack height="3rem">
                    {infoPercentage && (
                        <Tooltip label={tooltipPercentageLabel} aria-label="A tooltip">
                            <>
                                {Number(infoPercentage) >= 0 ? (
                                    <TriangleUpIcon color="green" />
                                ) : (
                                    <TriangleDownIcon color="red" />
                                )}

                                <WvwText color="wvwGrey60">
                                    {`${infoPercentage}%`}
                                </WvwText>
                            </>
                        </Tooltip>
                    )}
                </HStack>

                {buttonText && (
                    <Box
                        alignSelf="flex-end"
                        width="100%"
                    >
                        <WvwButton
                            onClick={() => {
                                if (buttonLink) {
                                    navigate(buttonLink);
                                } else if (buttonClick) {
                                    buttonClick();
                                }
                            }}
                            block
                            variant={buttonVariant}
                            content={buttonText}
                        />
                    </Box>
                )}
            </VStack>
        </Flex>
    );
};

export default InfoWidget;
