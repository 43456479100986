import { Button, HStack, Text } from "@chakra-ui/react";
import { Link as ReactLink } from "react-router-dom";
import PublicCompanyProfilePicture from "../../../../common/components/PublicCompanyProfilePicture";
import { displayDateWithCurrentLocale } from "../../../../common/functions/displayDateInLocale";
import { displayTimeInCurrentLocale } from "../../../../common/functions/displayTime";
import { EntityType } from "../../../../types";
import { DataTableColumnType } from "../../common/components/data-table/DataTable";
import { NO_DATA } from "../../common/NO_DATA";

type Column = DataTableColumnType<EntityType>;

const translationNameSpace = "account.customerPanelDetails.columns";

const name: Column = {
    title: (t) => t(`${translationNameSpace}.name`),
    render: (i) => {
        return (
            <HStack>
                <PublicCompanyProfilePicture
                    size="2.5rem"
                    companyId={i.id}
                />
                <Text
                    fontSize={"sm"}
                    fontWeight={"700"}
                >
                    {i.name}
                </Text>
            </HStack>
        );
    },
};

const address: Column = {
    title: (t) => t(`${translationNameSpace}.address`),
    render: (i) => {
        return (
            <Text fontSize={"sm"}>
                {i.street}, {i.postalCode} {i.city}
            </Text>
        );
    },
};

const email: Column = {
    title: (t) => t(`${translationNameSpace}.email`),
    render: (i) => {
        return (
            <Button
                as={ReactLink}
                to={`mailto:${i.companyEmail}`}
                variant={"link"}
                fontSize={"sm"}
                fontWeight={"400"}
                colorScheme="blue"
                onClick={(e) => e.stopPropagation()}
            >
                {i.companyEmail}
            </Button>
        );
    },
};

const website: Column = {
    title: (t) => t(`${translationNameSpace}.website`),
    render: (i) => {
        return i.websiteAddress ? (
            <Button
                as={ReactLink}
                to={`//${i.websiteAddress}`}
                variant={"link"}
                fontSize={"sm"}
                fontWeight={"400"}
                colorScheme="blue"
                target="_blank"
                onClick={(e) => e.stopPropagation()}
            >
                {i.websiteAddress}
            </Button>
        ) : (
            NO_DATA
        );
    },
};

const invitedAt: Column = {
    title: (t) => t(`${translationNameSpace}.createdAt`),
    render: (i) => {
        const date = displayDateWithCurrentLocale(i.createdAt);
        const time = displayTimeInCurrentLocale(new Date(i.createdAt));

        return (
            <Text fontSize={"sm"}>
                {date}, {time}
            </Text>
        );
    },
};

export const entityColumns = {
    name,
    address,
    email,
    website,
    invitedAt,
};
